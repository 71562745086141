import React from 'react';
import CurrencyInput from 'react-currency-input';
import { Control, Controller, FieldErrors, UseFormClearErrors, UseFormWatch } from 'react-hook-form';
import { InputGroup, Radio, RadioGroup, Toggle } from 'rsuite';
import { InputBox } from '../../../../../../../components';
import { ShowErrors } from '../../../../components/ShowErrors';
import { FormOptimizationPrices } from '../../../../types/schema.form';
import styles from './CoordinateSection.module.scss';
import { IAComponent } from './ia';

interface CoordinateSectionProps {
    control: Control<FormOptimizationPrices>;
    watch: UseFormWatch<FormOptimizationPrices>;
    errors: FieldErrors<FormOptimizationPrices>;
    clearErrors: UseFormClearErrors<FormOptimizationPrices>;
}
export const CoordinateSection = ({ control, watch, errors, clearErrors }: CoordinateSectionProps) => {
    const isActiveMargin = watch('coordinates.margin.active');
    const isActiveCompetitiveness = watch('coordinates.competitiveness.active');

    return (
        <main className={styles.container}>
            <header className={styles.title}>
                <span>Defina as coordenadas para a otimização de preços</span>
            </header>
            <section className={styles.content}>
                <div className={styles.text}>
                    <IAComponent />
                    <p>
                        Nossa IA analisa seus dados para calcular preços otimizados. Defina o objetivo e restrições de margem e competitividade para garantir
                        estratégia e segurança.
                    </p>
                </div>

                {/* Objetivo */}
                <section className={styles.box}>
                    <div className={styles.title}>
                        <span>Objetivo</span>
                        <p>Escolha um objetivo para a categoria</p>
                    </div>

                    <Controller
                        name="coordinates.objective"
                        control={control}
                        render={({ field }) => {
                            return (
                                <div className={styles.body}>
                                    <RadioGroup {...field} value={field.value} onChange={(value) => field.onChange(value)}>
                                        <Radio value="profit">
                                            <span style={{ fontWeight: field.value === 'profit' ? 'bold' : 'normal' }}>Maximizar lucro</span>
                                        </Radio>
                                        <Radio value="revenue">
                                            <span style={{ fontWeight: field.value === 'revenue' ? 'bold' : 'normal' }}>Maximizar faturamento</span>
                                        </Radio>
                                    </RadioGroup>
                                </div>
                            );
                        }}
                    />
                </section>

                {/* restricoes */}
                <section className={styles.box}>
                    <header className={styles.title}>
                        <span>restrições</span>
                        <p>
                            Garanta que os preços otimizados respeitem as estratégias de margem e competitividade. É necessário definir ao menos uma dessas
                            restrições para salvar.
                        </p>
                    </header>

                    {/* margem */}
                    <section className={styles.body}>
                        <Controller
                            name="coordinates.margin.active"
                            control={control}
                            render={({ field }) => (
                                <div className={styles.label}>
                                    <Toggle
                                        size="sm"
                                        {...field}
                                        checked={field.value}
                                        onChange={(checked) => {
                                            clearErrors('coordinates.margin.active');
                                            clearErrors('coordinates.competitiveness.active');
                                            field.onChange(checked);
                                        }}
                                    />
                                    <span>margem</span>
                                    <p>Defina um range de margem para os preços da categoria</p>
                                </div>
                            )}
                        />

                        {errors.coordinates?.margin?.active?.message && <span className={styles.error}>{errors.coordinates.margin?.active.message}</span>}

                        {isActiveMargin ? (
                            <section className={styles.inputs}>
                                <Controller
                                    name="coordinates.margin.min"
                                    control={control}
                                    render={({ field, fieldState: { error } }) => {
                                        return (
                                            <InputBox>
                                                <div className={styles['input-group']}>
                                                    <span>% Mínimo</span>
                                                    <InputGroup>
                                                        <CurrencyInput
                                                            className={['rs-input'].join(' ')}
                                                            allowNegative
                                                            decimalSeparator=","
                                                            thousandSeparator="."
                                                            precision="1"
                                                            // allowEmpty
                                                            {...field}
                                                            // @ts-ignore
                                                            ref={(ref: any) => (field.ref = ref)}
                                                            value={field.value}
                                                            onChangeEvent={(e, mask, value) => field.onChange(value)}
                                                        />
                                                        <InputGroup.Addon>%</InputGroup.Addon>
                                                    </InputGroup>
                                                </div>
                                                <ShowErrors message={errors?.coordinates?.margin?.message} />
                                            </InputBox>
                                        );
                                    }}
                                />

                                <Controller
                                    name="coordinates.margin.max"
                                    control={control}
                                    render={({ field, fieldState: { error } }) => (
                                        <InputBox>
                                            <div className={styles['input-group']}>
                                                <span>% Máximo</span>
                                                <InputGroup>
                                                    <CurrencyInput
                                                        className={['rs-input'].join(' ')}
                                                        allowNegative={true}
                                                        decimalSeparator=","
                                                        thousandSeparator="."
                                                        precision="1"
                                                        allowEmpty
                                                        {...field}
                                                        // @ts-ignore
                                                        ref={(ref: any) => (field.ref = ref)}
                                                        value={field.value}
                                                        onChangeEvent={(e, mask, value) => field.onChange(value)}
                                                    />
                                                    <InputGroup.Addon>%</InputGroup.Addon>
                                                </InputGroup>
                                                <ShowErrors message={error?.message} />
                                            </div>
                                        </InputBox>
                                    )}
                                />
                            </section>
                        ) : null}
                    </section>

                    {/* competitividade */}
                    <section className={styles.body}>
                        <Controller
                            name="coordinates.competitiveness.active"
                            control={control}
                            render={({ field }) => (
                                <div className={styles.label}>
                                    <Toggle
                                        size="sm"
                                        {...field}
                                        checked={field.value}
                                        onChange={(checked) => {
                                            clearErrors('coordinates.competitiveness.active');
                                            clearErrors('coordinates.margin.active');
                                            field.onChange(checked);
                                        }}
                                    />
                                    <span>competitividade</span>
                                    <p>Defina um intervalo de competitividade para os preços da categoria</p>
                                </div>
                            )}
                        />

                        {errors.coordinates?.competitiveness?.active?.message && (
                            <span className={styles.error}>{errors.coordinates?.competitiveness?.active.message}</span>
                        )}

                        {isActiveCompetitiveness ? (
                            <section className={styles.inputs}>
                                <Controller
                                    name="coordinates.competitiveness.min"
                                    control={control}
                                    render={({ field, fieldState: { error } }) => (
                                        <InputBox>
                                            <div className={styles['input-group']}>
                                                <span>% Mínimo</span>
                                                <InputGroup>
                                                    <CurrencyInput
                                                        className={['rs-input'].join(' ')}
                                                        allowNegative
                                                        decimalSeparator=","
                                                        thousandSeparator="."
                                                        precision="1"
                                                        allowEmpty
                                                        {...field}
                                                        // @ts-ignore
                                                        ref={(ref: any) => (field.ref = ref)}
                                                        value={field.value}
                                                        onChangeEvent={(e, mask, value) => field.onChange(value)}
                                                    />
                                                    <InputGroup.Addon>%</InputGroup.Addon>
                                                </InputGroup>
                                            </div>
                                            <ShowErrors message={error?.message} />
                                        </InputBox>
                                    )}
                                />

                                <Controller
                                    name="coordinates.competitiveness.max"
                                    control={control}
                                    render={({ field, fieldState: { error } }) => (
                                        <InputBox>
                                            <div className={styles['input-group']}>
                                                <span>% Máximo</span>
                                                <InputGroup>
                                                    <CurrencyInput
                                                        className={['rs-input'].join(' ')}
                                                        allowNegative
                                                        decimalSeparator=","
                                                        thousandSeparator="."
                                                        precision="1"
                                                        allowEmpty
                                                        {...field}
                                                        // @ts-ignore
                                                        ref={(ref: any) => (field.ref = ref)}
                                                        value={field.value}
                                                        onChangeEvent={(e, mask, value) => field.onChange(value)}
                                                    />
                                                    <InputGroup.Addon>%</InputGroup.Addon>
                                                </InputGroup>
                                            </div>
                                            <ShowErrors message={error?.message} />
                                        </InputBox>
                                    )}
                                />
                            </section>
                        ) : null}
                    </section>

                    {/* variacao minima */}
                    <section className={styles.body}>
                        <Controller
                            control={control}
                            name="coordinates.minVariationToChangePrice"
                            render={({ field, fieldState: { error } }) => (
                                <InputBox>
                                    <div className={styles['input-group']}>
                                        <span>Variação mínima para a troca de preços</span>
                                        <InputGroup style={{ maxWidth: '214px' }}>
                                            <CurrencyInput
                                                className={['rs-input'].join(' ')}
                                                allowNegative={false}
                                                decimalSeparator=","
                                                thousandSeparator="."
                                                precision="1"
                                                allowEmpty
                                                {...field}
                                                // @ts-ignore
                                                ref={(ref: any) => (field.ref = ref)}
                                                value={field.value}
                                                onChangeEvent={(e, mask, value) => field.onChange(value)}
                                            />
                                            <InputGroup.Addon>%</InputGroup.Addon>
                                        </InputGroup>
                                        <ShowErrors message={error?.message} />
                                    </div>
                                </InputBox>
                            )}
                        />
                    </section>
                </section>

                <section className={styles.architecture}>
                    <span>Arquitetura</span>
                    <p>
                        Para assegurar coerência nos preços otimizados, adotamos arquitetura de produtos por proporcionalidade, resultando em preços que
                        respeitam a relação entre eles. <b>Certifique-se de que as categorias selecionadas seguem essa estrutura de preços</b>.
                    </p>
                    {/* <Button appearance="link">
                        Abrir Arquitetura de preços
                        <MdOpenInNew />
                    </Button> */}
                </section>
            </section>
        </main>
    );
};
