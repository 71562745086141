import { useQuery } from '@tanstack/react-query';
import { format } from 'date-fns';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { optimizationPricesResultSelector } from '../../../../reducers/OtimizacaoPrecos';
import {
    BigNumbers,
    PeriodStatus,
    setOptimizationResult,
    setOptimizationResultBigNumbers,
    setOptimizationResultChart,
} from '../../../../reducers/OtimizacaoPrecos/optimizationPricesResult';
import { snakeCaseToCamelCase } from '../../../../utils/snakeCaseToCamelCase';
import { getMargemObjetivaSegmento } from '../../Estrategia/pages/MargemObjetiva/services';
import { getOptimizationBigNumbers, getOptimizationChartData, getOptimizationResult } from '../services';

export function useResult() {
    const { id } = useParams() as Record<'id', string>;
    const dispatch = useDispatch();
    const { result } = useSelector(optimizationPricesResultSelector);
    const { selectedStoresId } = result;

    const { data: defaultCategory } = useQuery({
        queryKey: ['optimization-prices-default-category-level'],
        retry: true,
        keepPreviousData: true,
        enabled: !!id,
        queryFn: () => getMargemObjetivaSegmento(),
    });

    const { isLoading: isLoadingResult, data: optimizationResult } = useQuery({
        queryKey: ['optimization-prices-result', id],
        retry: true,
        keepPreviousData: true,
        enabled: !!id && !!defaultCategory,
        queryFn: () => getOptimizationResult(id),
        onSuccess(data) {
            const result = snakeCaseToCamelCase(data);

            dispatch(
                setOptimizationResult({
                    result: {
                        ...result,
                        category: {
                            name: defaultCategory?.name,
                            level: defaultCategory?.level,
                            value: result.categories,
                        },
                        lastOptimization: format(new Date(result.lastOptimization), 'dd/MM/yyyy'),
                        period: {
                            value: result.period,
                            label: PeriodStatus[result.period as keyof typeof PeriodStatus],
                        },
                        stores: result.stores,
                        totalProducts: result.totalProducts,
                    },
                    validity: {
                        start: format(new Date(result.validity.startDate), 'dd/MM/yyyy'),
                        end: result.validity.endDate ? format(new Date(result.validity.endDate), 'dd/MM/yyyy') : undefined,
                    },
                }),
            );
        },
    });

    const storesId = useMemo(() => optimizationResult?.stores?.map((store: any) => store.store_id), [optimizationResult]);

    const { isLoading: isLoadingResultBigNumbers } = useQuery({
        queryKey: ['optimization-prices-forecast-big-numbers', id, selectedStoresId],
        retry: true,
        keepPreviousData: true,
        enabled: !!id && storesId?.length > 0,
        queryFn: () => getOptimizationBigNumbers({ id, stores_id: selectedStoresId?.length > 0 ? selectedStoresId : storesId }),
        onSuccess(data) {
            const result = snakeCaseToCamelCase(data);

            const bigNumbersData: BigNumbers = {
                ...result,
                startDate: format(new Date(result.startDate), 'dd/MM/yyyy'),
                endDate: result.endDate ? format(new Date(result.endDate), 'dd/MM/yyyy') : null,
            };

            dispatch(
                setOptimizationResultBigNumbers({
                    data: bigNumbersData,
                }),
            );
        },
    });

    const { isLoading: isLoadingResultChartData } = useQuery({
        queryKey: ['optimization-prices-forecast-chart-data', id, selectedStoresId],
        retry: true,
        keepPreviousData: true,
        enabled: !!id && storesId?.length > 0,
        queryFn: () => getOptimizationChartData({ id, stores_id: selectedStoresId?.length > 0 ? selectedStoresId : storesId }),
        onSuccess(data) {
            const result = snakeCaseToCamelCase(data);
            dispatch(setOptimizationResultChart({ data: result }));
        },
    });

    return {
        isLoadingResult,
        isLoadingResultBigNumbers,
        isLoadingResultChartData,
    };
}
