import { useQuery } from '@tanstack/react-query';
import { isEqual } from 'lodash';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useSearchParams } from '../../../../hooks/useSearchParams';
import { optimizationPricesSelector } from '../../../../reducers/OtimizacaoPrecos';
import {
    CategoryLevel,
    setCategories,
    setCategoryLevel,
    setSelectedFilterCategoryLevel,
    setSelectedFilters,
    setStores,
} from '../../../../reducers/OtimizacaoPrecos/optimizationPrices';
import { getCattegories } from '../../../../services/ProdutoService_ts';
import { getMargemObjetivaSegmento } from '../../Estrategia/pages/MargemObjetiva/services';
import { StoreDto } from '../../SimulacoesAnalises/PrevisaoDemanda/v2/@types/store-response';
import { getStores, getStoresById } from '../../SimulacoesAnalises/PrevisaoDemanda/v2/services';
import { getCategories } from '../services';

export function useFilterSection() {
    const { id } = useParams() as Record<'id', string>;

    const { getParam } = useSearchParams();
    const { filters } = useSelector(optimizationPricesSelector, (a, b) => isEqual(a.filters, b.filters));
    const dispatch = useDispatch();

    const { selectedFilters, list, search } = filters;

    const listCategoriesLevel = async () => {
        const defaultLevel = await getMargemObjetivaSegmento();
        const categoriesLevelResponse = await getCattegories();

        if (defaultLevel) {
            const categoryLevelDefault = {
                field: defaultLevel.field,
                name: defaultLevel.name,
                label: defaultLevel.level + ' - ' + defaultLevel.name,
                value: defaultLevel.level.toString(),
            };

            const categoriesLevel = categoriesLevelResponse.map((item) => {
                return {
                    field: item.field,
                    name: item.name,
                    label: item.level + ' - ' + item.name,
                    value: item.level.toString(),
                };
            });

            dispatch(setCategoryLevel({ category: categoriesLevel }));
            dispatch(setSelectedFilterCategoryLevel({ categoryLevel: categoryLevelDefault }));
            return [categoriesLevelResponse];
        }
    };

    const { isLoading: isListCategoryLevelLoading } = useQuery({
        queryKey: ['optimization-prices-category-level'],
        retry: true,
        keepPreviousData: true,
        queryFn: () => listCategoriesLevel(),
    });

    const listStores = async (query?: string[]) => {
        const ids = getParam('storesId')?.split(',');

        const hasQuery = !!query?.length;

        const response: StoreDto[] = ids?.length && !hasQuery ? await getStoresById(ids) : await getStores({ query: query });
        if (response) {
            const storesResponse = response?.map((item) => ({
                value: item.store_id,
                label: item.store,
                store_id: item.store_id,
                description: item.store,
            }));

            const storesSelected = list.stores.filter((category) => selectedFilters.storesId.includes(category.value));
            const storesSearch = storesResponse.filter((category) => !selectedFilters.storesId.includes(category.value));
            const stores = [...storesSelected, ...storesSearch];

            dispatch(setStores({ stores: stores }));
            return storesResponse;
        }
    };

    const { isLoading: isListStoresLoading } = useQuery({
        queryKey: ['optimization-prices-stores', search.stores],
        retry: true,
        keepPreviousData: true,
        enabled: id ? selectedFilters?.categoriesId?.length > 0 : true,
        queryFn: () => listStores(search.stores ? [search.stores] : []),
    });

    const listCategories = async (nivel: string, query?: string) => {
        if (!nivel) return;

        const response: string[] = await getCategories({ params: { query: query }, nivel });
        if (response) {
            const categoriesResponse = response?.map((item) => ({
                value: item,
                label: item,
                category_id: item,
                description: item,
            }));

            const oldCategories = selectedFilters.categoriesId?.map((item) => ({
                value: item,
                label: item,
                category_id: item,
                description: item,
            }));

            const categoriesSearch = categoriesResponse.filter((category) => !selectedFilters.categoriesId.includes(category.value));
            const categories = [...oldCategories, ...categoriesSearch];

            dispatch(setCategories({ categories: categories }));
            return categoriesResponse;
        }
    };

    const { isLoading: isListCategoriesLoading } = useQuery({
        queryKey: ['optimization-prices-categories', search.categories, selectedFilters.categoryLevel.field],
        retry: true,
        keepPreviousData: true,
        enabled: !id ? !!selectedFilters.categoryLevel.field : !!selectedFilters.categoryLevel.field && selectedFilters?.categoriesId?.length > 0,
        queryFn: () => listCategories(selectedFilters.categoryLevel.field, search.categories || ''),
    });

    const handleSelectedFilters = ({ type, value }: { type: 'storesId' | 'categoriesId'; value: string[] }) => {
        dispatch(setSelectedFilters({ type, value }));
    };

    const handleSetSelectedFilterCategoryLevel = (categoryLevel: CategoryLevel) => {
        dispatch(setSelectedFilterCategoryLevel({ categoryLevel }));
    };

    const isCheckedAllStores = selectedFilters?.storesId?.length > 0 && list.stores?.every((store: any) => selectedFilters.storesId.includes(store.store_id));

    const indeterminateStores = selectedFilters?.storesId?.length > 0 && !isCheckedAllStores;

    const isCheckedAllCategories =
        selectedFilters?.categoriesId?.length > 0 && list.categories?.every((store: any) => selectedFilters.categoriesId.includes(store.category_id));

    const indeterminateCategories = selectedFilters?.categoriesId?.length > 0 && !isCheckedAllCategories;

    const sicronizeParams = () => {
        const storesId = getParam('storesId')?.split(',') || [];
        const categoriesId = getParam('categoriesId')?.split(',') || [];

        if (storesId?.length) {
            handleSelectedFilters({
                type: 'storesId',
                value: storesId,
            });
        }

        if (categoriesId?.length) {
            handleSelectedFilters({
                type: 'categoriesId',
                value: categoriesId,
            });
        }
    };

    useEffect(() => {
        if (id) return;
        sicronizeParams();
    }, [id]);

    return {
        listStores,
        isListStoresLoading,
        handleSelectedFilters,
        handleSetSelectedFilterCategoryLevel,
        states: {
            isListStoresLoading,
            isListCategoriesLoading,
            isCheckedAllStores,
            indeterminateStores,
            isCheckedAllCategories,
            indeterminateCategories,
            isListCategoryLevelLoading,
        },
    };
}
