import { create } from 'zustand';
import {
    CustoFabricacaoBodyType,
    CustosGeraisTabelaBodyType,
    CustosProdutoBodyType,
    EditBaseTableItemDataType,
    PriceAnalysisModalTableType,
    ProdutoDataType
} from '../../pages/EditBaseTable/types';

type State = {
    productItemData: EditBaseTableItemDataType;
    editBaseTableListContent: ProdutoDataType[];
    openModalProductCosts: boolean;
    currentBaseTableSelectAll: boolean;
    priceApplyList: number[];
    tabelaBaseItemsSelectedList: ProdutoDataType[];
}

type Actions = {
    setCurrentBaseTableState: (name: string, data: any) => void;
    setOpenModalProductCosts: (open: boolean, data) => void;
    reset: () => void;
}

const initialState: State = {
    productItemData: {
        id: 0,
        criadoEm: '',
        atualizadoEm: '',
        versao: 0,
        produtoBase: {
            id: 0,
            criadoEm: '',
            atualizadoEm: '',
            versao: 0,
            produto: {
                id: null,
                criadoEm: '',
                atualizadoEm: '',
                versao: null,
                idCliente: null,
                data: '',
                codigoInternoProduto: '',
                tipoCodigoProduto: '',
                codigoEmbalagemProduto: '',
                codigoUnidadeProduto: '',
                codigoBarras: '',
                tipoCodigoBarras: '',
                descricaoProduto: '',
                status: '',
                unidadesPorEmbalagem: null,
                tamanhoEmbalagem: null,
                unidadeTamanhoEmbalagem: '',
                tipoEmbalagem: '',
                unidadeTipoEmbalagem: '',
                idFamiliaProduto: '',
                produtoMasterFamilia: false,
                marca: '',
                categoriaNivel1: '',
                categoriaNivel2: '',
                categoriaNivel3: '',
                categoriaNivel4: '',
                categoriaNivel5: '',
                categoriaNivel6: '',
                categoriaNivel7: '',
                ultimaAlteracao: '',
                codigoPrincipal: null,
                tierProduto: '',
            },
            parametros: {
                custoFabricacao: 0,
                precoMedio: {
                    valorAtual: 0,
                    ultimaDiferencaPercentual: 0,
                },
                novoPrecoTabela: 0,
            },
            tabela: {
                id: 0,
                criadoEm: '',
                atualizadoEm: '',
                versao: 0,
                idCliente: 0,
                uf: 'NACIONAL',
            },
            impostos: {
                ipi: 0,
                icms: 0,
                pis: 0,
                cofins: 0,
                outros: 0,
            },
            custosVenda: {
                freteMedio: 0,
                comissao: 0,
                trocasOuDevolucao: 0,
                gratificacao: 0,
                outros: 0,
            },
            despesaVenda: {
                comerciais: 0,
                administrativas: 0,
                outras: 0,
            },
        },
    },
    editBaseTableListContent: [],
    tabelaBaseItemsSelectedList: [],
    openModalProductCosts: false,
    currentBaseTableSelectAll: false,
};

export const useCurrentBaseTableStore = create<State & Actions>((set) => ({
    ...initialState,
    setCurrentBaseTableState: (name, data) => {
        set(() => ({
            [name]: data,
        }));
    },
    setOpenModalProductCosts: (open: boolean, data: EditBaseTableItemDataType) => {
        set(() => ({
            openModalProductCosts: open,
            productItemData: data || initialState.productItemData,
            custosProdutoBody: {
                codigoRascunhosProduto: [data?.id || 0],
                impostos: {
                    ipi: data?.impostos.ipi,
                    icms: data?.impostos.icms,
                    pis: data?.impostos.pis,
                    cofins: data?.impostos.cofins,
                    outros: data?.impostos.outros
                },
                custos: {
                    freteMedio: data?.custosVenda.freteMedio,
                    comissao: data?.custosVenda.comissao,
                    trocasOuDevolucao: data?.custosVenda.trocasOuDevolucao,
                    gratificacao: data?.custosVenda.gratificacao,
                    outros: data?.custosVenda.outros
                },
                despesas: {
                    comerciais: data?.despesaVenda.comerciais,
                    administrativas: data?.despesaVenda.administrativas,
                    outras: data?.despesaVenda.outras
                }
            },
        }));
    },
    reset: () => {
        set(initialState);
    },
}));
