import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import classNames from 'classnames';
import React, { ComponentProps, forwardRef, memo, useCallback } from 'react';
import { Alert } from 'rsuite';
import { ButtonSecondary } from '../../../../../../../components';
import { deleteCluster, listClusters, patchEditCluster } from '../../../../../../../components/FilterSection/services';
import styles from './ClusterSection.module.scss';
import { ClusterSectionItem } from './ClusterSectionItem';

export type ClusterSectionProps = Omit<ComponentProps<'ul'>, 'children'> & {
    onClickAddNewClusterModal: () => void;
};

const ClusterSection = memo(
    forwardRef<HTMLUListElement, ClusterSectionProps>(({ className, onClickAddNewClusterModal, ...props }, ref) => {
        const { data, refetch } = useQuery({ queryKey: ['ipa.setup.clusters'], queryFn: () => listClusters() });


        const queryClient = useQueryClient();
        const { mutateAsync: onDeleteCluster } = useMutation({
            mutationFn: deleteCluster,
            onSuccess: async () => {
                Alert.success('Cluster deletado com sucesso');
                await queryClient.invalidateQueries({ queryKey: ['table-data'] });
                await queryClient.invalidateQueries({ queryKey: ['ipa.setup.clusters'] });
            },
            onError: () => {
                Alert.error('Erro ao deletar cluster');
            },
        });

        const { mutateAsync: onEditCluster } = useMutation({
            mutationFn: patchEditCluster,
            onSuccess: () => refetch(),
            onError: () => {
                Alert.error('Erro ao editar cluster');
            },
        });

        const handleDeleteCluster = useCallback(
            async (cluster: DataItem) => await onDeleteCluster(cluster.value),
            [onDeleteCluster]
        );
        const handleEditCluster = useCallback(async (name: string, cluster: DataItem) => await onEditCluster({ name, id: cluster.value }), [onEditCluster]);



        return (
            <ul {...props} ref={ref} className={classNames(styles['wrapper'], className)}>
                {data?.map((cluster) => (
                    <li key={cluster.value}>
                        <ClusterSectionItem cluster={cluster} onSubmitEdit={handleEditCluster} onSubmitDelete={handleDeleteCluster} />
                    </li>
                ))}
                <li>
                    <ButtonSecondary onClick={onClickAddNewClusterModal}>Adicionar novo</ButtonSecondary>
                </li>
            </ul>
        );
    }),
);

ClusterSection.displayName = 'ClusterSection';

export { ClusterSection };
