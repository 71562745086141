import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'rsuite';
import { LoadingSpinerArea } from '../../../../../components';
import {
    MainContainer,
    TableActionArea
} from '../../components';
import { ModalCustosProduto, ModalPriceAnalysis } from '../EditBaseTable/components';
import styles from './ManageComercialTable.module.scss';
import { useManageComercialTableStore } from './ManageComercialTable.store';
import {
    HeaderManageComercialTable,
    ManageComercialTableTable,
    ModalActivateProductPromo,
    ModalDescriptionPriceAndDiscount,
    ModalPriceLimit,
} from './components';
import { useComercialPriceApply } from './hooks/useComercialPriceApply';
import { useComercialTableList } from './hooks/useComercialTableList';
import { useComercialTableProductList } from './hooks/useComercialTableProductList';
import { useConsultComercialTable } from './hooks/useConsultComercialTable';
import { useCreateComercialTableStore } from '../CreateComercialTable/CreateComercialTable.store';
import { editComercialTableObj } from '../../utils';
import { ComercialProductItemSelectedListTypes, ComercialTablePriceLimitAndRulesTypes } from './types';

const MemoModalPriceLimit = React.memo(ModalPriceLimit);

export const ManageComercialTable = () => {
    const history = useHistory();

    const {
        priceAnalysisComercialTableData,
        openModalPriceAnalysisComercial,
        openModalActivateProductOffer,
        openModalProductCostComercialTable,
        openModalPriceLimit,
        productItemData,
        custosProdutoBody,
        comercialTableItem,
        comercialProductItemSelectedList,
        selectAllTable,
        comercialTablePriceLimitAndRules,
        setManageComercialTableState,
        setOpenModalProductCostComercialTable,
        setOpenModalPriceAnalysisComercial,
    } = useManageComercialTableStore()

    const { setCreateComercialTableState } = useCreateComercialTableStore();

    const {
        comercialTableHeaderData,
        comercialTableMutateData,
    } = useComercialTableList(1000);

    const { setConsultComercialTableID } = useConsultComercialTable()

    const {
        comercialTableProductData,
        comercialTableParams,
        setComercialTableParams,
        handleSaveComercialTablePorductCost,
        handleBlurNovoPrecoTabela,
        handleChangeTabelaComercialPage,
        handleChangeTabelaComercialLength
    } = useComercialTableProductList();

    const { handleConfirmPriceApply, handleConfirmPriceApplyAll } = useComercialPriceApply()

    const handleChangeCustosProduto = (objType: string, name: string, value: number) => {
        const objData = {
            ...custosProdutoBody,
            [objType]: {
                ...custosProdutoBody[objType],
                [name]: value
            }
        }

        const custoFabricacao = {
            ...custosProdutoBody,
            [name]: value,
        }

        const isCustoFabricacao = objType === 'custoFabricacao' ? custoFabricacao : objData

        setManageComercialTableState('custosProdutoBody', isCustoFabricacao)
    }

    const handleSelectTableType = (id: number) => {
        const newItem = comercialTableMutateData.data.content.filter((item) => item.id === id)
        setComercialTableParams({ // chama a lista da tabela
            ...comercialTableParams,
            idTabelaComercial: id,
        })
        setConsultComercialTableID(id)
        setManageComercialTableState('comercialTableItem', newItem[0])
    }

    const handleChangeCheckboxComercialProduct = (product: ComercialProductItemSelectedListTypes, checked: boolean) => {
        const addProduct = checked
            ? [...comercialProductItemSelectedList, product]
            : comercialProductItemSelectedList.filter((item) => item.id !== product.id);

        const productIds = addProduct.map((item) => item.id)

        setManageComercialTableState('comercialProductItemSelectedList', addProduct)
        setManageComercialTableState('comercialPriceApplyList', productIds)
        setManageComercialTableState('selectAllTable', false)
    }

    const handleClickSelectAllProducts = (all: boolean) => {
        setManageComercialTableState('comercialProductItemSelectedList', all ? comercialTableProductData?.data?.content : [])
        setManageComercialTableState('selectAllTable', all)
    }

    const handlePriceApply = (all: boolean) => {
        if (all) {
            handleConfirmPriceApplyAll();
        } else {
            handleConfirmPriceApply();
        }
    }

    const handleEditTableRules = (data: ComercialTablePriceLimitAndRulesTypes) => {
        setCreateComercialTableState('createComercialTableBody', editComercialTableObj(data))
        setCreateComercialTableState('isCreateComercialTable', false)
        history.push(`/ipa/criar-tabela-comercial/${data.tabela.id}`)
    }

    useEffect(() => {
        if (typeof comercialTableItem?.id === 'number') {
            setComercialTableParams({
                ...comercialTableParams,
                idTabelaComercial: comercialTableItem?.id,
            })
            setConsultComercialTableID(comercialTableItem?.id)
        }
    }, [comercialTableItem?.id])

    return (
        <MainContainer>
            <LoadingSpinerArea area='table-edit-base' />
            <HeaderManageComercialTable
                tableTypeData={comercialTableHeaderData}
                comercialTableItem={comercialTableItem}
                onClickEdit={() => handleEditTableRules(comercialTablePriceLimitAndRules)}
                onChangeTableType={handleSelectTableType}
            />
            {/* <BigNumbersArea />
            <FiltersArea /> */}
            <TableActionArea
                data={comercialTableProductData?.data}
                selectedItems={comercialProductItemSelectedList}
                selectAll={selectAllTable}
                onClickSelectAll={handleClickSelectAllProducts}
            >
                <Button
                    className={styles['btn-apply']}
                    appearance="primary"
                    disabled={!comercialProductItemSelectedList.length}
                    onClick={() => handlePriceApply(selectAllTable)}
                >
                    Aplicar preço
                </Button>
            </TableActionArea>
            <ManageComercialTableTable
                data={comercialTableProductData?.data}
                onBlurNovoPrecoTabela={handleBlurNovoPrecoTabela}
                onChangeCheckbox={handleChangeCheckboxComercialProduct}
                onChangePage={handleChangeTabelaComercialPage}
                onChangeLength={handleChangeTabelaComercialLength}
            />
            <ModalActivateProductPromo
                show={openModalActivateProductOffer}
                onCancel={() => setManageComercialTableState('openModalActivateProductOffer', false)}
            />
            <ModalDescriptionPriceAndDiscount />
            <MemoModalPriceLimit show={openModalPriceLimit} />
            <ModalPriceAnalysis
                className="comercial"
                show={openModalPriceAnalysisComercial}
                tableHeight={273}
                title='Análise preço médio vs preço de tabela'
                data={priceAnalysisComercialTableData}
                onCancel={() => setOpenModalPriceAnalysisComercial(false, null)}
            />
            <ModalCustosProduto
                show={openModalProductCostComercialTable}
                hasCustoFabricacao
                title="Custos do produto"
                subtitle="Defina os custos específicos dos produtos selecionados"
                cancelButton="Fechar"
                loadingArea='table-edit-base'
                disabledImpostos
                disabledCustoFabricacao
                disabledCustosVenda
                disabledOutrasDespesas
                data={productItemData}
                onCancel={() => setOpenModalProductCostComercialTable(false, null)}
                onChangeCustosProduto={handleChangeCustosProduto}
            />
        </MainContainer>
    );
};
