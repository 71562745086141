import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../@types/RootState';

const initialState = 0;

const slice = createSlice({
    name: 'getClusterStoreModal/totalElements',
    initialState,
    reducers: {
        SET_GET_CLUSTER_STORE_MODAL_TOTAL_ELEMENTS: (
            _state,
            { payload }: PayloadAction<number>,
        ) => payload,
    },
});

export const selectorGetClusterStoreModalTotalElements = (state: RootState) => {
    return state.getClusterStoreModalReducer.totalElements;
};

export const { SET_GET_CLUSTER_STORE_MODAL_TOTAL_ELEMENTS } = slice.actions;

export default slice.reducer;
