import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    reducerOnDemandSelector,
    setOnDemandState,
    setOnDemandCleanFilter,
    OnDemandOptions,
} from '../../../../../../reducers/reducerOnDemand';
import {
    filtersNameConversion,
    requestNameConversion,
} from '../../data';

import * as service from '../../services';
import useOnDemandTableRequest from '../useOnDemandTableRequest';
import { string } from 'zod';

export const useOnDemandSelectFilters = () => {
    const [timer, setTimer] = useState(null);

    const dispatch = useDispatch();

    const reducer = useSelector(reducerOnDemandSelector);

    const { refetch } = useOnDemandTableRequest();

    const setList = (name, list, query) => {
        let newList = [];
        const isArrayValue = typeof query !== 'string'

        if (name === 'radius') {
            newList = list.keys
                .sort((a, b) => parseInt(a, 10) - parseInt(b, 10))
                .map((item) => ({
                    value: item,
                    label: item,
                }));
        } else {
            newList = list.keys.sort().map((item) => ({
                value: item,
                label: item,
            }));
        }

        const valueList = isArrayValue ? query : reducer[name].value;
        const cacheList = isArrayValue ? newList : reducer[name].cache;
        const removeDuplicate = newList.filter(
            (i) => !reducer[name].value.includes(i.value),
        );
        const concatList = reducer[name].value.length
            ? cacheList.concat(removeDuplicate)
            : newList;

        const filteredValueList = valueList.filter((item) => cacheList.some((i) => i.value === item))

        dispatch(setOnDemandState({
            name,
            data: {
                ...reducer[name],
                data: concatList,
                cache: cacheList,
                value: filteredValueList
            },
        }));
    };

    const removeItemFilter = (name, value, item) => {
        const removedCache = reducer[name].cache.filter(
            (i) => i.value !== item.value,
        );

        dispatch(setOnDemandState({
            name,
            data: {
                ...reducer[name],
                cache: removedCache,
                value,
            },
        }));
    };

    const addItemFilter = (name, value, item) => {
        dispatch(setOnDemandState({
            name,
            data: {
                ...reducer[name],
                cache: [...reducer[name].cache, item],
                value,
            },
        }));
    };

    const handleSelectOnDemandFilter = (name, value: string, item: any) => {
        const hasValue = reducer[name].value?.includes(item.value);

        dispatch(setOnDemandState({
            name: 'tableRequestBody',
            data: {
                ...reducer.tableRequestBody,
                page: 0,
                [requestNameConversion[name]]: value,
            },
        }));

        if (hasValue) {
            removeItemFilter(name, value, item);
        } else {
            addItemFilter(name, value, item);
        }
    };

    const handleSelectOnDemandFilterStatus = (value: string) => {
        dispatch(setOnDemandState({
            name: 'tableRequestBody',
            data: {
                ...reducer.tableRequestBody,
                page: 0,
                status: [value],
            },
        }));

        setTimeout(() => {
            refetch();
        }, 300);
    };

    const searchOnDemandFilters = async (name, query) => {
        const serviceParams = { typeField: filtersNameConversion[name], query };
        const response = await service.getFiltersData(serviceParams);

        if (response) {
            setList(name, response, query);
        }
    };

    const handleOnSearchOnDemandFilter = (name: OnDemandOptions, inputValue: string) => {
        let filtros;

        if (
            inputValue &&
            typeof inputValue === 'string' &&
            inputValue.match(/^[^\s]+(?:\s+[^\s]+)+$/)
        ) {
            const concatValue = reducer[name].value.concat(inputValue.split(' '));
            const newValue = [...new Set(concatValue)];
            filtros = { query: newValue };

            dispatch(setOnDemandState({
                name,
                data: {
                    ...reducer[name],
                    value: newValue,
                },
            }));
        } else {
            filtros = {
                query: typeof inputValue === 'string' ? inputValue : '',
            };
        }

        clearTimeout(timer);

        const newTimer = setTimeout(() => {
            searchOnDemandFilters(name, filtros.query);
        }, 500);

        setTimer(newTimer);
    };

    // Valida o comportamento de selecionar os itens no checkPicker e chamar a requisição toda vez que a seleção mudar
    const validateSelectBehavior = (name) => {
        const isDiff = reducer[name]?.value.some((item, index) => item !== reducer.fakeFilterArray[name][index]);
        const hasDiffLength = reducer[name]?.value.length !== reducer.fakeFilterArray[name].length;

        if (hasDiffLength) {
            return true;
        }

        if (!hasDiffLength && isDiff) {
            return true;
        }

        return false;
    };

    const handleOnCloseOnDemandFilter = (name) => {
        if (validateSelectBehavior(name)) {
            dispatch(setOnDemandState({
                name: 'fakeFilterArray',
                data: {
                    ...reducer.fakeFilterArray,
                    [name]: reducer[name]?.value,
                },
            }));

            dispatch(setOnDemandState({
                name: 'tableRequestBody',
                data: {
                    ...reducer.tableRequestBody,
                    page: 0,
                    [requestNameConversion[name]]: reducer[name].value,
                },
            }));

            setTimeout(() => {
                refetch();
            }, 100);
        }
    };

    const handleClearOnDemandFilter = (name) => {
        const valueType = name === 'status' ? null : [];

        dispatch(
            setOnDemandCleanFilter({
                name,
                tableRequestBodyName: requestNameConversion[name],
                data: {
                    ...reducer[name],
                    cache: [],
                    value: valueType,
                },
            }),
        );

        dispatch(setOnDemandState({
            name: 'tableRequestBody',
            data: {
                ...reducer.tableRequestBody,
                page: 0,
                [requestNameConversion[name]]: null,
            },
        }));

        setTimeout(() => {
            refetch();
        }, 100);
    };

    const handleSelectAllFilter = (name, check) => {
        const hasChecked = check
            ? reducer[name]?.data?.map((item) => item.value)
            : [];

        dispatch(
            setOnDemandState({
                name,
                data: {
                    ...reducer[name],
                    value: hasChecked,
                    cache: reducer[name]?.data,
                },
            }),
        );
    };

    return {
        handleOnSearchOnDemandFilter,
        handleSelectOnDemandFilter,
        handleSelectOnDemandFilterStatus,
        handleOnCloseOnDemandFilter,
        handleClearOnDemandFilter,
        handleSelectAllFilter,
    };
};
