import classNames from 'classnames';
import React, { CSSProperties } from 'react';
import CurrencyInput from 'react-currency-input';
import { ButtonGroup, Dropdown, InputGroup, Radio, RadioGroup } from 'rsuite';
import { MarginFilterProps } from '../../../../../../reducers/gerenciadorPrecos/quickActionsFilter';
import { BaseQuickActionFilterProps } from '../QuickActionFilter.types';
import styles from './FilterMargin.module.scss';
import { MdKeyboardArrowDown } from './Icon/assets';
import { IconButton } from './IconButton';

const OPTIONS = [
    {
        id: 1,
        label: 'Preço sugerido',
        value: 'SUGGESTED',
    },
    {
        id: 2,
        label: 'Preço vigente',
        value: 'CURRENT',
    },
];

const SELECTOR = [
    { label: 'Maior ou igual', value: 'HIGHEST' },
    { label: 'Menor ou igual', value: 'LOWEST' },
    { label: 'Entre', value: 'BETWEEN' },
];
type FilterMarginProps = {
    icon?: React.ReactNode;
    unitLabel?: string;
    menuStyle: CSSProperties;
    skin?: 'neutral' | 'primary' | 'error';
    defaultValue?: string;
    placeholder?: string;
    state: MarginFilterProps;
    onClose: () => void;
    onOpen: () => void;
    onClick?: (event: React.SyntheticEvent<Element, Event>) => void;
    onChangeValue: (paramName: keyof MarginFilterProps, value: any) => void;
};

export const FilterMargin = ({ icon, menuStyle, placeholder, onClick, onClose, state, onChangeValue, onOpen }: FilterMarginProps) => {
    const [menuStyleState, setMenuStyleState] = React.useState(menuStyle);
    const [open, setOpen] = React.useState(false);

    const skinColor: BaseQuickActionFilterProps['skin'] = state.isActive || open ? 'primary' : 'neutral';

    const handleChangeValue = (paramName: keyof MarginFilterProps, value: number) => {
        onChangeValue(paramName, value);
    };

    return (
        <ButtonGroup className={classNames('quick-action-filter__button__container quick-action-filter__toggle', styles.container)}>
            <IconButton icon={icon} placeholder={placeholder} skin={skinColor} onClick={onClick} />

            <Dropdown
                activeKey="1"
                eventKey="1"
                menuStyle={menuStyleState}
                placement="bottomEnd"
                onClose={() => {
                    setOpen(false);
                    setMenuStyleState(menuStyle);
                    onClose();
                }}
                onOpen={() => {
                    setOpen(true);
                    onOpen?.();
                }}
                renderTitle={() => <IconButton icon="MdKeyboardArrowDown" className="quick-action-filter__toggle__arrow" skin={skinColor} />}
                className={classNames(styles['dropdown-container'], styles['main-container'])}
            >
                <Dropdown.Item panel>
                    <p className={styles.title}>Filtrar preços com margem</p>

                    <div className={styles['operator-container']}>
                        <Dropdown
                            onOpen={() => {
                                setMenuStyleState({ ...menuStyle, height: 220 });
                            }}
                            onClose={() => {
                                setMenuStyleState(menuStyle);
                            }}
                            className={styles['operator-content']}
                            title={SELECTOR.find((item) => item.value === state.operator)?.label}
                            value={state.operator}
                            renderTitle={(item) => {
                                return (
                                    <div className={styles['render-title']}>
                                        <p>{item}</p>
                                        <MdKeyboardArrowDown color="#959595" />
                                    </div>
                                );
                            }}
                        >
                            {SELECTOR.filter((item) => item.value !== state.operator)
                                .concat(SELECTOR.find((item) => item.value === state.operator) || { label: '', value: '' })
                                .map((item) => {
                                    return (
                                        <Dropdown.Item
                                            className={styles['dropdown-item']}
                                            key={item.value}
                                            value={item.value}
                                            onSelect={() => {
                                                onChangeValue('operator', item.value);
                                            }}
                                        >
                                            {item.label}
                                        </Dropdown.Item>
                                    );
                                })
                                .reverse()}
                        </Dropdown>

                        {state.operator === 'BETWEEN' ? (
                            <>
                                <InputGroup style={{ maxWidth: 102 }}>
                                    <CurrencyInput
                                        className={styles['currency-input']}
                                        value={state.minValue}
                                        onChangeEvent={(_event, _mask, float) => handleChangeValue('minValue', float)}
                                        allowNegative
                                        precision="1"
                                        decimalSeparator=","
                                        autoFocus
                                    />
                                    <InputGroup.Addon>%</InputGroup.Addon>
                                </InputGroup>
                                <span>e</span>
                                <InputGroup style={{ maxWidth: 102 }}>
                                    <CurrencyInput
                                        className={styles['currency-input']}
                                        value={state.maxValue}
                                        onChangeEvent={(_event, _mask, float) => handleChangeValue('maxValue', float)}
                                        allowNegative
                                        precision="1"
                                        decimalSeparator=","
                                    />
                                    <InputGroup.Addon>%</InputGroup.Addon>
                                </InputGroup>
                            </>
                        ) : (
                            <>
                                <span>a</span>
                                <InputGroup style={{ maxWidth: 102 }} className={styles['input-group']}>
                                    <CurrencyInput
                                        className={styles['currency-input']}
                                        value={state.value}
                                        onChangeEvent={(_event, _mask, float) => handleChangeValue('value', float)}
                                        allowNegative
                                        precision="1"
                                        decimalSeparator=","
                                        autoFocus
                                    />
                                    <InputGroup.Addon>%</InputGroup.Addon>
                                </InputGroup>
                            </>
                        )}
                    </div>
                </Dropdown.Item>

                <Dropdown.Item panel>
                    <RadioGroup name="marginFilters" value={state.priceTypeFilter} onChange={(e) => onChangeValue('priceTypeFilter', e)}>
                        {OPTIONS.map((item) => {
                            return (
                                <Radio key={item.id} value={item.value}>
                                    {item.label}
                                </Radio>
                            );
                        })}
                    </RadioGroup>
                </Dropdown.Item>
            </Dropdown>
        </ButtonGroup>
    );
};
