import React, { useState } from 'react';
import { TabelaBaseFormBox } from '../TabelaBaseFormBox';
import { LabelInputGroup } from '../LabelInputGroup';
import { CustosVendaDataType } from '../../pages/EditBaseTable/types';
import { totalCustosVenda } from '../../calculos';
import styles from './CustosVendaBox.module.scss';

type CustosVendaBoxProps = {
    custosVendaData: CustosVendaDataType,
    disabled?: boolean,
    onChange?: (name: string, value: number) => void,
}

export const CustosVendaBox = ({ custosVendaData, disabled, onChange }: CustosVendaBoxProps) => {
    const [custosVenda, setCustosVenda] = useState(custosVendaData)

    const handleChangeCustosVenda = (name: string, value: number) => {
        setCustosVenda({...custosVenda, [name]: value })
        onChange?.(name, value)
    }

    return (
        <TabelaBaseFormBox
            title="Custos de Venda"
            totalValue={totalCustosVenda(custosVenda)}
        >
            <LabelInputGroup
                label="Frete médio"
                symbol="%"
                decimalSeparator=","
                disabled={disabled}
                value={custosVenda?.freteMedio}
                onChangeEvent={(_e: any, _s: string, number: number) => handleChangeCustosVenda('freteMedio', number)}
            />
            <LabelInputGroup
                label="Comissão"
                symbol="%"
                decimalSeparator=","
                disabled={disabled}
                value={custosVenda?.comissao}
                onChangeEvent={(_e: any, _s: string, number: number) => handleChangeCustosVenda('comissao', number)}
            />
            <LabelInputGroup
                label="Trocas ou"
                subLabel="devolução"
                symbol="%"
                decimalSeparator=","
                disabled={disabled}
                value={custosVenda?.trocasOuDevolucao}
                onChangeEvent={(_e: any, _s: string, number: number) => handleChangeCustosVenda('trocasOuDevolucao', number)}
            />
            <LabelInputGroup
                label="Gratificação"
                symbol="%"
                decimalSeparator=","
                disabled={disabled}
                value={custosVenda?.gratificacao}
                onChangeEvent={(_e: any, _s: string, number: number) => handleChangeCustosVenda('gratificacao', number)}
            />
            <LabelInputGroup
                label="Outros"
                symbol="%"
                decimalSeparator=","
                disabled={disabled}
                value={custosVenda?.outros}
                onChangeEvent={(_e: any, _s: string, number: number) => handleChangeCustosVenda('outros', number)}
            />
        </TabelaBaseFormBox>
    );
};
