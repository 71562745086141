import classNames from 'classnames';
import React, { ChangeEvent, FormEvent, useCallback, useDeferredValue, useState } from 'react';
import Modal, { ModalProps } from 'rsuite/lib/Modal';
import { ButtonPrimary, InputBox } from '../../../../../../../../components';
import styles from '../Modals.module.scss';

export type AddClusterModalProps = ModalProps & {
    onSubmit?: (name: string) => void;
    onCancel?: () => void;
};

const AddClusterModal = ({ onSubmit, onCancel, onHide, className, ...props }: AddClusterModalProps) => {
    const [name, setName] = useState('');
    const [error, setError] = useState('');

    const value = useDeferredValue(name);
    const isValid = value.trim().length > 0;

    const handleClose = useCallback(
        (e: React.MouseEvent) => {
            setName('');
            onHide?.(e);
        },
        [onHide],
    );

    const handleChange = useCallback((ev: ChangeEvent<HTMLInputElement>) => {
        setName(ev.target.value);
        setError('');
    }, []);

    const handleSubmit = useCallback(
        (ev: FormEvent<HTMLFormElement>) => {
            ev.preventDefault();

            if (!value.trim()) {
                setError('O nome do cluster é obrigatório');
                return;
            }

            onSubmit?.(value);
            setName('');
        },
        [onSubmit, value],
    );

    const handleCancel = useCallback(() => {
        setName('');
        onCancel?.();
    }, [onCancel]);

    return (
        <Modal {...props} className={classNames('info-modal', styles['wrapper'], className)} onHide={handleClose}>
            <form onSubmit={handleSubmit} onReset={handleCancel}>
                <Modal.Header>
                    <Modal.Title>Novo cluster</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className={styles['modal-description']}>Crie um novo cluster para usá-lo para agrupar suas lojas</p>
                    <InputBox>
                        <InputBox.Label htmlFor="name">Nome do cluster</InputBox.Label>
                        <InputBox.Input
                            className={styles['input-placeholder']}
                            placeholder="Defina um nome para o cluster"
                            id="name"
                            name="name"
                            value={value}
                            onChange={handleChange}
                            autoFocus
                        />
                        {error && <span className={styles.errorMessage}>{error}</span>}
                    </InputBox>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonPrimary type="reset" theme="ghost">
                        Cancelar
                    </ButtonPrimary>
                    <ButtonPrimary type="submit" disabled={!isValid}>
                        Salvar
                    </ButtonPrimary>
                </Modal.Footer>
            </form>
        </Modal>
    );
};

export { AddClusterModal };
