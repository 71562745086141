import { GetClusterStoreModalPaginationState } from "../../../../../reducers/getClusterStoreModal/pagination";
import { GetClusterStoreModalPaginationStateSortState } from "../../../../../reducers/getClusterStoreModal/sort";
import { GET_ITEMS_CLUSTER_STORE_MODAL_PARAMS } from "./types";


namespace GetClusterStoreModalDatapoints {
    export type Params = {
        sort: GetClusterStoreModalPaginationStateSortState;
        pagination: GetClusterStoreModalPaginationState;
        clusters: string[];
        productIds: string;
    };
    export type Return = GET_ITEMS_CLUSTER_STORE_MODAL_PARAMS;
}

export const GET_CLUSTER_STORE_DATAPOINTS_MODEL = (
    params: GetClusterStoreModalDatapoints.Params,
): GetClusterStoreModalDatapoints.Return => {
    const { sort, pagination, clusters, productIds } = params;

    return {
        sort: `${sort.type},${sort.orderBy}`,
        page: pagination.activePage,
        size: pagination.displayLength,
        clusters,
        productIds

    };
};
