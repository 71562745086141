import Immutable from 'seamless-immutable';
import {
    SET_CONSOLIDADO_DATA,
    SET_ENDERECO_LAT_LON,
    SET_ENDERECO_LIST,
    SET_ENDERECO_RAIO,
    SET_GENERATE_REPORT_DATA,
    SET_GENERATE_REPORT_HISTORY_DATA,
    SET_GENERATE_REPORT_RESPONSE,
    SET_IS_SPECIFIC_DATES,
    SET_MODULO_RELATORIO_DATA_TO_INITIAL_DATA,
    SET_SAVED_REPORT_ITEM_IN_MODULE_DATA,
    SET_SAVED_REPORT_ITEM_UPDATE,
    SET_SAVED_REPORT_LIST,
    SET_STATE_VALUE,
    SET_TIPO_PROMOCAO_DATA,
    SET_DATE_TYPE_RECORRENCIA,
} from '../actions/actionsModuloRelatorio';
import { setLocalDateISOFormat, tipoPromocaoEnumValue } from '../pages/ISA/InfoPanel/ModuloRelatorio/utils';

const yesterday = new Date(Date.now() - 24 * 60 * 60 * 1000);
const sevenDaysInterval = new Date(yesterday.getTime() - 6 * 24 * 60 * 60 * 1000);

const moduloRelatorioInitialState = Immutable({
    moduloRelatorioData: {
        categoria: [],
        cidade: [],
        cnpj: [],
        codigoCliente: JSON.parse(localStorage.getItem('cliente'))?.codigo,
        dataFim: setLocalDateISOFormat(yesterday),
        dataInicio: setLocalDateISOFormat(sevenDaysInterval),
        dataEspecifica: false,
        dimensoes: [],
        endereco: [],
        fabricante: [],
        gramatura_descricao: [],
        marca: [],
        origemPreco: [],
        produto: [],
        precoEscolhido: ['PRECO_PAGO'],
        quebraPeriodo: 'DIAS',
        rede: [],
        secao: [],
        tipoLoja: [],
        tipoProduto: [],
        tipoPromocao: [],
        uf: [],
        flagPromocao: [],
        relatorioSalvo: false,
        recorrencia: true,
        reportEngineRecorrenciaDTO: null,
        fileName: '',
        enderecoDTO: {
            latitude: null,
            longitude: null,
            endereco: '',
            raio: null,
        },
    },
    consolidadoData: {
        produto: [],
        local: [],
        preco: [],
        quantidade: [],
        comFiltro: [],
    },
    tipoPromocaoSelectData: [],
    showModalConfirmDownload: false,
    showModalNoDownload: false,
    generateReportHistoryData: null,
    clienteAdicionais: [],
    tableLoading: true,
    savedTableLoading: true,
    isSpecificDates: false,
    savedReportData: [],
    savedReportContent: false,
    reportSaveSuccess: false,
    saveReportItemData: {},
    deleteSavedReportSuccess: false,
    produto: {
        lista: [],
        value: [],
        cache: [],
        limitProducts: null,
    },
    reportLineQuantity: 0,
    report: [],
    previousReport: [],
    endereco: {
        lista: [],
        value: [],
        cache: [],
        token: null,
    },
    editSpecificDateRangeValue: [],
    dateIntervalKey: 0,
    openModalGenerateReportError: false,
    showModalSpeakWithSpecialistModuloRelatorio: false,
});

function moduloRelatorioDataReducer(state = moduloRelatorioInitialState, action) {
    switch (action.type) {
        case SET_GENERATE_REPORT_DATA:
            return state.merge({
                moduloRelatorioData: {
                    ...state.moduloRelatorioData,
                    [action.name]: action.data,
                },
            });
        case SET_CONSOLIDADO_DATA:
            return state.merge({
                consolidadoData: {
                    ...state.consolidadoData,
                    [action.name]: action.data,
                },
            });
        case SET_GENERATE_REPORT_RESPONSE:
            return state.merge({
                showModalConfirmDownload: (action.data === 200 && !action.saveReport) || false,
                showModalNoDownload: action.data !== 200 ? action.data : false,
                reportSaveSuccess: (action.data === 200 && action.saveReport) || false,
                reportLineQuantity: action.line,
            });
        case SET_GENERATE_REPORT_HISTORY_DATA:
            return state.merge({
                generateReportHistoryData: action.data,
                tableLoading: false,
            });
        case SET_TIPO_PROMOCAO_DATA:
            return state.merge({
                tipoPromocaoSelectData:
                    action.name === 'edit'
                        ? action.data.map((item) => ({
                              label: tipoPromocaoEnumValue[item],
                              value: item,
                          }))
                        : action.data,
            });
        case SET_IS_SPECIFIC_DATES:
            return state.merge({
                isSpecificDates: action.data,
            });
        case SET_SAVED_REPORT_LIST:
            return state.merge({
                savedReportData: action.data,
                savedTableLoading: action.loading,
            });
        case SET_STATE_VALUE:
            return state.merge({
                ...state,
                [action.name]: action.data,
            });
        case SET_SAVED_REPORT_ITEM_IN_MODULE_DATA:
            if (action.name === 'insert') {
                const data = {
                    ...action.data, 
                    reportEngineDTO: {
                        ...action.data.reportEngineDTO,
                        cnpj: action.data.reportEngineDTO.cnpjLoja
                    },
                }; 
                delete data.reportEngineDTO.cnpjLoja;
                return state.merge({
                    saveReportItemData: data,
                    moduloRelatorioData: data.reportEngineDTO,
                });
            }
            return state.merge({
                moduloRelatorioData: moduloRelatorioInitialState.moduloRelatorioData,
            });
        case SET_MODULO_RELATORIO_DATA_TO_INITIAL_DATA:
            return state.merge({
                ...state,
                moduloRelatorioData: moduloRelatorioInitialState.moduloRelatorioData,
            });
        case SET_SAVED_REPORT_ITEM_UPDATE:
            return state.merge({
                ...state,
                reportSaveSuccess: action.status === 200,
                showModalNoDownload: action.status !== 200 ? action.status : false,
                reportLineQuantity: action.line,
            });
        case SET_ENDERECO_LIST:
            return state.merge({
                ...state,
                endereco: {
                    ...state.endereco,
                    lista: action.list.predictions?.map((item) => ({
                        label: item.description,
                        value: item.description,
                        place_id: item.place_id,
                    })),
                    token: action.list.session_token,
                },
            });
        case SET_ENDERECO_LAT_LON:
            return state.merge({
                moduloRelatorioData: {
                    ...state.moduloRelatorioData,
                    enderecoDTO: {
                        ...state.moduloRelatorioData.enderecoDTO,
                        latitude: action.data.latitude,
                        longitude: action.data.longitude,
                        endereco: action.data.endereco,
                    },
                },
            });
        case SET_ENDERECO_RAIO:
            return state.merge({
                moduloRelatorioData: {
                    ...state.moduloRelatorioData,
                    enderecoDTO: {
                        ...state.moduloRelatorioData.enderecoDTO,
                        raio: action.data,
                    },
                },
            });
        case SET_DATE_TYPE_RECORRENCIA:
            return state.merge({
                moduloRelatorioData: {
                    ...state.moduloRelatorioData,
                    dataInicio: action.data.dataInicio,
                    dataFim: action.data.dataFim,
                    dataEspecifica: action.data.dataEspecifica,
                    recorrencia: action.data.recorrencia,
                },
                editSpecificDateRangeValue: action.data.editSpecificDateRangeValue,
                isSpecificDates: action.data.editSpecificDateRangeValue.isSpecificDates,
            });
        default:
            return state;
    }
}

export default moduloRelatorioDataReducer;
