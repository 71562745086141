import { useHistory } from 'react-router-dom';
import { User } from '../../../../@types';

export interface CheckUserAllowAccessProps {
    usuario: User | null;
}

export const MODULE_OPTIMIZATION_NAME = 'MODULO_OTIMIZACAO';
export function useCheckUserAllowAccess({ usuario }: CheckUserAllowAccessProps) {
    const history = useHistory();

    if (!usuario) {
        return null;
    }

    // TODO remover return quando habilitar permissoes
    if (usuario?.codigoCliente !== 77575) {
        history.push('/ipa/gerenciador-de-precos');
        return null;
    }

    // if (!usuario?.tipoAdicionalContratos?.IPA?.includes('MODULO_OTIMIZACAO')) {
    //     history.push('/ipa/gerenciador-de-precos');
    //     return null;
    // }
}
