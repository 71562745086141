import { format, parseISO } from 'date-fns';

export const downloadFile = (data, fileName) => {
    const blob = new Blob([data]);
    const hiddenElement = document.createElement('a');
    hiddenElement.href = window.URL.createObjectURL(blob);
    hiddenElement.download = fileName;
    hiddenElement.click();
};

export const getDownloadBody = (extension, filters) => ({
    extension,
    filters,
});

export const dateLocale = {
    sunday: 'Dom',
    monday: 'Seg',
    tuesday: 'Ter',
    wednesday: 'Qua',
    thursday: 'Qui',
    friday: 'Sex',
    saturday: 'Sáb',
    ok: 'OK',
    today: 'Hoje',
};

export const formatDate = (value) => {
    const index = value.indexOf('+');

    if (index !== -1) {
        const newDate = value.substring(0, index);
        return format(new Date(parseISO(newDate)), 'dd/MM/yyyy');
    }
    return format(new Date(parseISO(value)), 'dd/MM/yyyy');
};

export const setStatusChangeFiltersModel = (filters) => ({
    gtin: filters.gtin?.length ? filters.gtin : null,
    palavraChave: filters.palavraChave?.length ? filters.palavraChave : null,
    dataCriacao: filters.dataCriacao?.length ? filters.dataCriacao : null,
    criador: filters.criador?.length ? filters.criador : null,
    uf: filters.uf?.length ? filters.uf : null,
    cidade: filters.cidade?.length ? filters.cidade : null,
    raio: filters.raio?.length ? filters.raio : null,
    status: filters.status?.length ? filters.status : null,
});

export const dataVideoOnDemand = [
    {
        link: (
            <iframe
                width="100%"
                height="524"
                src="https://www.youtube.com/embed/AU7aJNP2HbY?&autoplay=1"
                title="Conheça o On Demand"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
            />
        ),
    },
];