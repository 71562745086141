import { api } from '../../../../../lib';
import { SetupBasicConfigSchema } from './schema';

export const getSetupIpaBasicConfig = async (): Promise<SetupBasicConfigSchema> => {


    const response = await api.get<SetupBasicConfigSchema>('/setup/basic-config', {
        expectToken: true,
    });

    return response.data;
};

export const postSetupIpaBasicConfig = async (data: Omit<SetupBasicConfigSchema, 'id'>): Promise<Required<SetupBasicConfigSchema>> => {


    const response = await api.post<Required<SetupBasicConfigSchema>>('/setup/basic-config', data, {
        expectToken: true,
    });
    return response.data;
};

export const putSetupIpaBasicConfig = async (data: Required<SetupBasicConfigSchema>): Promise<Required<SetupBasicConfigSchema>> => {


    const response = await api.put<Required<SetupBasicConfigSchema>>('/setup/basic-config', data, {
        expectToken: true,
    });
    return response.data;
};
