import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Button,
    Checkbox,
    Dropdown,
    Popover,
    Table,
    Tooltip,
    Whisper,
} from 'rsuite';

import EditIcon from '@material-ui/icons/Edit';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import ThreeDotsIcon from '../../../../../../../assets/icons/icon_three_dots_vertical_gray.svg';
import { LoadingSpinerArea } from '../../../../../../../components/LoadingSpinner';
import styles from './TablePriceTableList.module.scss';
import { canalType, formatDateUpdatedAt, getQuantityByStateLength, localType } from '../../utils';

type TablePriceTableListProps = {
    data: any,
    onChangePage?: (value: any) => void,
    onChangeLength?: (value: any) => void,
    onSelectThreeDotButtonOption?: (value: any, id: any) => void,
}

const {
 Column, HeaderCell, Cell, Pagination,
} = Table;

const lengthMenu = [
    {
        value: 10,
        label: 10,
    },
    {
        value: 20,
        label: 20,
    },
];

const MenuPopover = ({ onSelect, ...rest }) => (
    <Popover {...rest} full>
        <Dropdown.Menu onSelect={onSelect} style={{ width: '196px' }}>
            <Dropdown.Item eventKey={1}>Gerenciar tabela</Dropdown.Item>
            <Dropdown.Item eventKey={2} className={styles['border-bottom']}>Download tabela</Dropdown.Item>
            <Dropdown.Item eventKey={3}>Editar as regras</Dropdown.Item>
            <Dropdown.Item eventKey={4}>Excluir</Dropdown.Item>
        </Dropdown.Menu>
    </Popover>
);

const revisaoPendenteAlert = (
    <div className={styles['pending-alert']}>
        <ReportProblemIcon className={styles['alert-icon']} />
        <span className={styles['alert-text']}>Tabela com alterações pendente de revisão</span>
    </div>
)

export const TablePriceTableList = ( { data, onChangePage, onChangeLength, onSelectThreeDotButtonOption }: TablePriceTableListProps) => {
    const [reloadStyle, setReloadStyle] = useState({});

    useEffect(() => {
        setReloadStyle({ width: '99%' });
        setTimeout(() => {
            setReloadStyle({ width: '100%' });
        }, 100);
    }, []);

    return (
        <div className={styles['table-list-table-price-container']}>
            <LoadingSpinerArea area="table-edit-base" />
            <Table
                data={data?.content}
                autoHeight
                id="table-list-table-price"
                className={styles['table-list-table-price']}
                rowClassName={styles['table-list-table-price-row']}
                rowHeight={64}
                headerHeight={46}
                style={reloadStyle}
                // sortColumn={tableState.sortColumn}
                // sortType={tableState.sortType}
                // onSortColumn={tableHandlers.handleSortColumns}
            >
                <Column sortable flexGrow={2}>
                    <HeaderCell>
                        Tabela de preços
                    </HeaderCell>
                    <Cell className={styles['cell-center']} dataKey="tabela-precos" height={60}>
                        {(rowData) => (
                            <p className={styles['main-bold-text']}>
                                {rowData.nome}
                            </p>
                        )}
                    </Cell>
                </Column>
                <Column flexGrow={2}>
                    <HeaderCell />
                    <Cell className={styles['cell-right']} dataKey="tabela-precos" height={60}>
                        {(rowData) => rowData.revisaoPendente ? revisaoPendenteAlert : null}
                    </Cell>
                </Column>
                <Column sortable flexGrow={1}>
                    <HeaderCell>
                        Local
                    </HeaderCell>
                    <Cell className={styles['cell-center']} dataKey="local" height={60}>
                        {(rowData) => (
                            <div>
                                <p className={styles['main-bold-text']}>{getQuantityByStateLength(rowData)}</p>
                                <p className={styles['sub-text']}>{localType[rowData?.dadosVenda?.local]}</p>
                            </div>
                            )}
                    </Cell>
                </Column>
                <Column sortable flexGrow={1}>
                    <HeaderCell>Canal</HeaderCell>
                    <Cell dataKey="canal">
                        {(rowData) => (
                            <div>
                                <p className={styles['main-bold-text']}>{canalType[rowData?.dadosVenda?.canal]}</p>
                                <p className={styles['sub-text']}>{rowData?.dadosVenda?.subCanal}</p>
                            </div>
                            )}
                    </Cell>
                </Column>
                <Column sortable flexGrow={2}>
                    <HeaderCell>
                        Última aplicação
                    </HeaderCell>
                    <Cell
                        dataKey="responsavel"
                    >
                        {(rowData) => (
                            <div>
                                <p className={styles['regular-text']}>{rowData.aplicadoPor}</p>
                                <p className={styles['sub-text']}>{formatDateUpdatedAt(rowData.atualizadoEm)}</p>
                            </div>
                            )}
                    </Cell>
                </Column>
                <Column flexGrow={1}>
                    <HeaderCell />
                    <Cell className={styles['cell-action']}>
                        {(rowData) => (
                            <Whisper
                                placement="bottomEnd"
                                trigger="click"
                                speaker={(
                                    <MenuPopover
                                        onSelect={(key) => onSelectThreeDotButtonOption(
                                            key,
                                            rowData,
                                        )}
                                    />
                                )}
                            >
                                <Button className={styles['btn-row-action']}>
                                    <img src={ThreeDotsIcon} alt="" className={styles['more-icon']} />
                                </Button>
                            </Whisper>
                        )}
                    </Cell>
                </Column>

            </Table>
            <Pagination
                activePage={data?.page.number + 1 || null}
                displayLength={data?.page.size}
                total={data?.page.totalElements || null}
                onChangePage={onChangePage}
                onChangeLength={onChangeLength}
                lengthMenu={lengthMenu}
            />
        </div>

    );
};
