import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Container } from 'rsuite';
import { FiltersArea } from '../../Components/FiltersArea/Filters';
import { Header } from '../../Components/Header/Header';
import { ProductDetails } from '../../Components/ProductDetails/ProductDetails';
import { SimulatorArea } from '../../Components/SimulatorArea/Inputs';

import { ResultScenarios } from '../../Components/ResultScenarios/ResultScenarios';
import styles from './Principal.module.scss';
const PrevisaoDemandaV2 = () => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    }, []);
    return (
        <Container className="main-container ">
            <div className={styles.container}>
                <Header />
                <FiltersArea />
                <SimulatorArea />
                <ProductDetails />
                <ResultScenarios />
            </div>
        </Container>
    );
};

export default withRouter(PrevisaoDemandaV2);
