import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IGerenciadorPrecosDatapoint } from '../../@types';
import { RootState } from '../../@types/RootState';

export type RowData = Partial<IGerenciadorPrecosDatapoint>;

export type GetClusterStoreModalDatapointsState = RowData[];

const initialState: GetClusterStoreModalDatapointsState = [];

const slice = createSlice({
    name: 'getClusterStoreModal/datapoints',
    initialState,
    reducers: {
        SET_GET_CLUSTER_STORE_MODAL_DATAPOINTS: (
            _state,
            { payload }: PayloadAction<GetClusterStoreModalDatapointsState>,
        ) => payload,

        UPDATE_GET_CLUSTER_STORE_MODAL_DATAPOINT: (
            state,
            {
                payload,
            }: {
                payload: {
                    index: number;
                    updatedData: Partial<RowData>;
                };
            },
        ) => {

            state[payload.index] = {
                ...state[payload.index],
                ...payload.updatedData,
            };
        },
    },
});

export const {
    SET_GET_CLUSTER_STORE_MODAL_DATAPOINTS,
    UPDATE_GET_CLUSTER_STORE_MODAL_DATAPOINT,
} = slice.actions;

export const selectorGetClusterStoreModalDatapoints = (state: RootState) =>
    state.getClusterStoreModalReducer.datapoints;

export default slice.reducer;
