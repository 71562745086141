import Immutable from 'seamless-immutable';
import {
    RESET_TO_INITIAL_STATE,
    SET_COMPETITIVIDADE_FILTER,
    SET_FILTROS_DATA_COMPETITIVIDADE,
    SET_INDUSTRIA_COMPETITIVIDADE_FILTER,
    SET_ITEM_PREFERENCE_FILTER,
    SET_PREFERENCE_FILTER,
    SET_SAVED_COMPETITIVIDADE_FILTER,
    SET_SAVED_COMPETITIVIDADE_INDUSTRY_FILTER,
    SET_SINGLE_REDUCER,
} from '../actions/actionsAnaliseCompetitividade';

const analiseCompetitividadeInitialState = Immutable({
    analiseCompetitividadeData: {
        tipoLocalizacao: null,
        gtins: [],
        periodoSemanas: 12,
        redeReferencia: null,
        redesCompetidoras: [],
        listaLocalizacao: [],
    },
    analiseIndustriaCompetitividadeData: {
        tipoLocalizacao: null,
        produtoReferencia: null,
        produtosCompetidores: [],
        periodoSemanas: 12,
        redes: [],
        listaLocalizacao: [],
    },
    preferences: {
        id: '',
        canalDigital: true,
        login: '',
        canalFisico: true,
        tipoPreco: 'PRECO_PAGO',
        tipoCalculo: 'PRECO_MEDIO',
    },
    competitividadeResponse: {
        competitividadeMedia: 0,
        lojasRedeReferencia: 0,
        produtosComparados: 0,
        redesEncontradas: 0,
    },
    filtrosDataCompetitividade: {
        produto: [],
        rede: [],
        cidade: [],
        uf: [],
    },
    evolutionResponse: [],
    detailsResponse: [],
    productsResponse: {},
    loadingPage: false,
    loadingChart: false,
    loadingProducts: false,
    loadingDetails: false,
    isEmptyResponse: false,
    productLabel: '',
    filterIsEmpty: {
        tipoLocalizacao: false,
        gtins: false,
        periodoSemanas: false,
        redeReferencia: false,
        redesCompetidoras: false,
        listaLocalizacao: false,
        produtoReferencia: false,
        produtosCompetidores: false,
        redes: false,
    },
    showModalSpeakWithSpecialistAnaliseCompetitividade: false,
});

function analiseCompetitividadeDataReducer(
    state = analiseCompetitividadeInitialState,
    action,
) {
    switch (action.type) {
        case SET_COMPETITIVIDADE_FILTER:
            return state.merge({
                ...state,
                analiseCompetitividadeData: {
                    ...state.analiseCompetitividadeData,
                    [action.name]: action.item,
                },
            });
        case SET_INDUSTRIA_COMPETITIVIDADE_FILTER:
            return state.merge({
                ...state,
                analiseIndustriaCompetitividadeData: {
                    ...state.analiseIndustriaCompetitividadeData,
                    [action.name]: action.item,
                },
            });
        case SET_PREFERENCE_FILTER:
            return state.merge({
                ...state,
                preferences: action.preferences,
            });
        case SET_ITEM_PREFERENCE_FILTER:
            return state.merge({
                ...state,
                preferences: {
                    ...state.preferences,
                    [action.name]: action.item,
                },
            });
        case SET_SINGLE_REDUCER:
            return state.merge({
                ...state,
                [action.name]: action.value,
            });
        case RESET_TO_INITIAL_STATE:
            return state.merge({
                analiseCompetitividadeData:
                    analiseCompetitividadeInitialState.analiseCompetitividadeData,
                analiseIndustriaCompetitividadeData:
                    analiseCompetitividadeInitialState.analiseIndustriaCompetitividadeData,
                evolutionResponse: [],
                detailsResponse: [],
                filterIsEmpty: analiseCompetitividadeInitialState.filterIsEmpty,
            });
        case SET_FILTROS_DATA_COMPETITIVIDADE:
            return state.merge({
                ...state,
                filtrosDataCompetitividade:
                    action.name === 'initialState'
                        ? analiseCompetitividadeInitialState.filtrosDataCompetitividade
                        : {
                              ...state.filtrosDataCompetitividade,
                              [action.name]: action.value,
                          },
            });
        case SET_SAVED_COMPETITIVIDADE_FILTER:
            return state.merge({
                ...state,
                analiseCompetitividadeData: {
                    ...state.analiseCompetitividadeData,
                    tipoLocalizacao: action.item.tipoLocalizacao,
                    gtins: action.item.gtins,
                    periodoSemanas: action.item.periodoSemanas,
                    redeReferencia: action.item.redeReferencia,
                    redesCompetidoras: action.item.redesCompetidoras,
                    listaLocalizacao: action.item.listaLocalizacao,
                },
            });
        case SET_SAVED_COMPETITIVIDADE_INDUSTRY_FILTER:
            return state.merge({
                ...state,
                analiseIndustriaCompetitividadeData: {
                    ...state.analiseIndustriaCompetitividadeData,
                    tipoLocalizacao: action.item.tipoLocalizacao,
                    produtosCompetidores: action.item.gtins,
                    periodoSemanas: action.item.periodoSemanas,
                    produtoReferencia: action.item.redeReferencia,
                    redes: action.item.redesCompetidoras,
                    listaLocalizacao: action.item.listaLocalizacao,
                },
            });
        default:
            return state;
    }
}

export default analiseCompetitividadeDataReducer;
