import { format, parseISO } from 'date-fns';

export const localType = {
    REGIONAL: 'UF',
    NACIONAL: 'Nacional',
};

export const canalType = {
    REDES: 'Redes',
    VENDA_DIRETA: 'Direta',
    VENDA_INDIRETA: 'Indireta',
    VENDA_INTERNA: 'Interna',
};

export const getQuantityByStateLength = (rowData) => {
    if (rowData.dadosVenda.local === 'REGIONAL') {
        return rowData.ufs.length;
    } else {
        return null;
    }
};

export const formatDateUpdatedAt = (date) =>  format(parseISO(date), 'dd/MM/yyy');
