import { combineReducers } from 'redux';
import { RootState } from '../../@types/RootState';
import basicConfig from './basicConfig/root';
import dadosInfopanel from './dadosInfopanel';
import lastIntegrationDate from './lastIntegrationDate';
import storesAndClusters from './storesAndClusters/root';
import wholesale from './wholesale';

const reducers = combineReducers({
    dadosInfopanel,
    lastIntegrationDate,
    wholesale,
    basicConfig,
    storesAndClusters,
});

export type ReducerIpaProps = ReturnType<typeof reducers>;

export const selector = (state: RootState) => state.reducerIPA;

export default reducers;
