import React from 'react';
import { IoMdArrowRoundDown, IoMdArrowRoundUp } from 'react-icons/io';
import { Skeleton } from '../../../../../../../components';
import { formatNumbersToPtBr, styleFormat } from '../../../../../../../utils/FormatNumbers';
import styles from './BigNumbers.module.scss';

interface BigNumbersProps {
    mainValue: number;
    variation: number;
    description: string;
    oldValue: number;
    positiveVariation: boolean;
    styleNumber: {
        style: styleFormat;
        options: {
            maximumFractionDigits?: number;
            minimumFractionDigits?: number;
            showSuffix?: boolean;
        };
    };
    highlight?: boolean;
    isLoading?: boolean;
}
export const BigNumbers = ({
    mainValue,
    variation,
    description,
    oldValue,
    positiveVariation = true,
    highlight = false,
    isLoading,
    styleNumber,
}: BigNumbersProps) => {
    const mainValueFormatted = formatNumbersToPtBr(mainValue, styleNumber.style, { ...styleNumber.options });
    const oldValueFormatted = formatNumbersToPtBr(oldValue, styleNumber.style, { ...styleNumber.options });
    const variationFormatted = formatNumbersToPtBr(variation, 'percent', { maximumFractionDigits: 2, minimumFractionDigits: 2, showSuffix: true });
    return (
        <section data-variation={positiveVariation ? 'positive' : 'negative'} data-highlight={highlight ? 'true' : 'false'} className={styles.container}>
            {isLoading ? (
                <Skeleton width={140} height={10} style={{ marginBottom: '4px' }} />
            ) : (
                <div className={styles.main}>
                    <span className={styles.value}>{mainValueFormatted}</span>
                    <div className={styles.variation}>
                        <span>{positiveVariation ? <IoMdArrowRoundUp color="#2D9D78" /> : <IoMdArrowRoundDown color="#D7373F" />}</span>
                        <span data-variation={positiveVariation ? 'positive' : 'negative'}>{variationFormatted}</span>
                    </div>
                </div>
            )}

            {isLoading ? <Skeleton width={140} height={10} style={{ marginBottom: '4px' }} /> : <p className={styles.description}>{description},</p>}

            {isLoading ? (
                <Skeleton width={140} height={10} style={{ marginBottom: '4px' }} />
            ) : (
                <div className={styles.content}>
                    <span>vs</span>
                    <span>{oldValueFormatted}</span>
                </div>
            )}
        </section>
    );
};
