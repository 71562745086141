import classNames from 'classnames';
import React, { ChangeEvent, FormEvent, useCallback, useDeferredValue, useState } from 'react';
import Modal, { ModalProps } from 'rsuite/lib/Modal';
import { ButtonPrimary, InputBox } from '../../../../../../../../components';
import styles from '../Modals.module.scss';

export type EditClusterModalProps = ModalProps & {
    cluster: DataItem;
    onSubmit?: (name: string, cluster: DataItem) => void;
    onCancel?: () => void;
};

const EditClusterModal = ({ cluster, onSubmit, onCancel, className, ...props }: EditClusterModalProps) => {
    const [name, setName] = useState(() => cluster.label);
    const value = useDeferredValue(name);

    const handleChange = useCallback((ev: ChangeEvent<HTMLInputElement>) => {
        setName(ev.target.value);
    }, []);

    const handleSubmit = useCallback(
        (ev: FormEvent<HTMLFormElement>) => {
            ev.preventDefault();
            onSubmit?.(value, cluster);
        },
        [onSubmit, value, cluster],
    );

    const handleCancel = useCallback(() => {
        setName('');
        onCancel?.();
    }, [onCancel]);

    return (
        <Modal {...props} className={classNames('info-modal', styles['wrapper'], className)}>
            <form onSubmit={handleSubmit} onReset={handleCancel}>
                <Modal.Header>
                    <Modal.Title>Editar cluster</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className={styles['modal-description']}>Edite cluster para usá-lo para agrupar suas lojas</p>
                    <InputBox>
                        <InputBox.Label htmlFor="name">Nome do cluster</InputBox.Label>
                        <InputBox.Input id="name" name="name" value={value} onChange={handleChange} autoFocus />
                    </InputBox>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonPrimary type="reset" theme="ghost">
                        Cancelar
                    </ButtonPrimary>
                    <ButtonPrimary type="submit">Salvar</ButtonPrimary>
                </Modal.Footer>
            </form>
        </Modal>
    );
};

export { EditClusterModal };
