import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Alert } from 'rsuite';
import VerificationInput from "react-verification-input";
import * as UsuarioService from '../../services/UsuarioService';
import { setPropertiesHeap } from '../../utils/HeapConfig';
import { setPropertiesTagManager } from '../../utils/TagManagerProperties';
import iconAlertCircle from '../../assets/icons/icon_alert_circle.svg'
import { setAdministrador, setAdministradores } from '../../actions/actionsAdmin';
import { setCliente, setServicosCliente } from '../../actions/actionsCliente';
import { setPermissoesUsuario } from '../../actions/actionsUsuario';

import iconErrorCircle from '../../assets/icons/icon_error_circle.svg'
import styles from './TwoFactorAuthentication.module.scss'
import { LoadingSpinerArea } from '../../components';

type TwoFactorAuthenticationProps = {
    codeStatus?: string,
    onCompleteCode?: () => void,
    onClickResendCode?: () => void,
    onClickConfirm?: () => void,
}

export const TwoFactorAuthentication = ({
    onCompleteCode,
    onClickResendCode,
    onClickConfirm
}: TwoFactorAuthenticationProps) => {
    const [code, setCode] = useState('')
    const [codeStatus, setCodeStatus] = useState(false)
    const dispatch = useDispatch();
    const history = useHistory();

        const setLogProperties = (userClientAllData) => {
            setPropertiesTagManager(userClientAllData);
            setPropertiesHeap(userClientAllData);
        };

        const mapDataUsuario = (userData) => ({
            email: userData.usuario.login,
            nome: userData.usuario.pessoaFisica.nome,
        });

        const mapDataCliente = (clientData, user) => ({
            codigo: clientData.codigoCliente,
            nome: clientData.cliente,
            organizacao: user.usuario.organizacao.nome,
        });

        const redirectToPage = (userClientAllData) => {
            setLogProperties(userClientAllData);
            if (history?.location?.state?.from) {
                history.replace(history.location.state.from);
                return;
            }
            if (userClientAllData.servicos.some((item) => item.name === 'IPA')) {
                if (userClientAllData.servicoIpa.pacoteIpa === 'LITE') {
                    history.push('/ipa/lite/onboarding');
                } else {
                    history.push('/ipa/gerenciador-de-precos');
                }
                return;
            }
            if (userClientAllData.servicos.some((item) => item.name === 'IPA') && userClientAllData.servicos.some((item) => item.name === 'IRA')) {
                history.push('/ipa/gerenciador-de-precos');
                return;
            }
            if (userClientAllData.servicos.some((item) => item.name === 'SDD')) {
                history.push('/ipa/calculadora-demanda');
                return;
            }
            if (userClientAllData.servicos.some((item) => item.name === 'PAN')) {
                history.push('/isa/painel-geral');
                return;
            }
            if (userClientAllData.servicos.some((item) => item.name === 'COL')) {
                history.push('/isa/historico-preco');
                return;
            }
            if (userClientAllData.servicos.some((item) => item.name === 'IRA')) {
                history.push('/ira');
                return;
            }
            if (userClientAllData.servicos.some((item) => item.name === 'BI')) {
                setPaineis(userClientAllData);
                return;
            }
        };

    const setDataReducer = (clientId, userClientAllData) => {
        dispatch(setCliente(clientId));
        dispatch(setAdministrador(userClientAllData.usuarioAdministrador));
        setPermissoesUsuario(userClientAllData.servicos, userClientAllData.servicoBi, userClientAllData.servicoPanel, userClientAllData.servicoIra);
        dispatch(setServicosCliente(userClientAllData.servicosCliente));
        dispatch(setAdministradores(userClientAllData.administradores));
    };

    const setLocalStorageUserData = (userData, userClientAllData, token) => {
        localStorage.setItem('usuario', JSON.stringify(mapDataUsuario(userData)));
        localStorage.setItem('cliente', JSON.stringify(mapDataCliente(userClientAllData, userData)));
        localStorage.setItem('autenticacao.token', token);
    };

    const handleChangeCode = (value) => {
        setCode(value)
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem('autenticacao.token');

        if (token && code.length === 4) {
            UsuarioService.getUsuarioInformation(token, code).then((data) => {
                UsuarioService.getClienteCompletoByToken(data.data.usuario.login, token).then(async (dataUsuarioCliente) => {
                    if (dataUsuarioCliente) {
                        await setLocalStorageUserData(data.data, dataUsuarioCliente, token);

                        await setDataReducer(dataUsuarioCliente.codigoCliente, dataUsuarioCliente);

                        redirectToPage(dataUsuarioCliente);
                    } else {
                        Alert.error(
                            'Você não está associado a nenhum de nossos produtos.' +
                                'Por favor, entre em contato com o responsável pelo seu acesso para mais informações.',
                            5000,
                        );
                    }
                });
            })
            .catch((err) => {
                if (err.response.status === 412) {
                    setCodeStatus(true);
                }
            });
        }
    };

    const handleResend2faCode = async () => {
        const email = localStorage.getItem('userEmail');
        const resp = await UsuarioService.resend2faCode(email);

        if (resp) {
            Alert.success('Código reenviado com sucesso. Verifique seu email e tente novamente.')
        }
    }

    return (
        <div className={styles.container}>
            <LoadingSpinerArea
                height="100%"
                size="md"
                area="auth"
            />
            <div className={styles['content-container']}>
                <img src={codeStatus ? iconErrorCircle : iconAlertCircle} alt="" />
                <div className={styles['message-container']}>
                    <h5 className={styles.title}>
                        {codeStatus ? 'O código inserido está incorreto.' : 'Verifique seu e-mail.'}
                    </h5>
                    {codeStatus ? (
                        <p className={styles.text}>
                            Por favor, verifique e tente novamente
                        </p>
                    ) : (
                        <>
                            <p className={styles.text}>
                                Para garantir que você continue usando nosso serviço,
                                precisamos confirmar que seu e-mail está ativo.
                                Um código de verificação foi enviado ao seu e-mail cadastrado.
                            </p>
                            <p className={styles.text} style={{marginTop: '20px'}}>
                                Por favor, insira o código abaixo para manter sua conta ativa.
                            </p>
                        </>
                    )}
                </div>
                <VerificationInput
                    length={4}
                    placeholder=''
                    classNames={{
                        container: styles['container-input'],
                        character: styles[codeStatus ? 'character-input-error' : 'character-input'],
                        characterInactive: styles['character-inactive-input'],
                    }}
                    onComplete={handleChangeCode}
                />
                {!codeStatus ? (
                    <p className={styles.text} style={{marginBottom: '24px'}}>
                        Caso você não tenha recebido o código, verifique sua caixa de spam ou solicite o reenvio.
                    </p>
                ) : null}
                <Button
                    className={styles['btn-resend-code']}
                    onClick={handleResend2faCode}
                >
                    Reenviar código
                </Button>
                {codeStatus ? (
                    <p className={styles.text} style={{marginTop: '24px'}}>
                        Caso o seu código não funcione, entre em contato conosco através do ícone do chat na tela.
                    </p>
                ) : null}
                <Button
                    className={styles['btn-confirm']}
                    appearance='primary'
                    onClick={handleSubmit}
                >
                    Confirmar conta
                </Button>
            </div>
        </div>
    )
}
