import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type FILTER_KEYS =
    | 'productIds'
    | 'productFamilyIds'
    | 'storeIds'
    | 'status'
    | 'responsible'
    | 'categoryLevel1'
    | 'categoryLevel2'
    | 'categoryLevel3'
    | 'categoryLevel4'
    | 'categoryLevel5'
    | 'categoryLevel6'
    | 'categoryLevel7';

export type RowData = {
    optimizationId: string;
    categories: string;
    stores: Store[];
    period: string;
    responsible: string;
    lastOptimization: string;
    resultsProjection: any;
    status: 'ativa' | 'inativa';
    totalProducts: number;
};

export interface Store {
    storeId: string;
    storeName: string;
}

export type Pageable = {
    sort: any[];
    offset: number;
    pageNumber: number;
    pageSize: number;
    paged: boolean;
    unpaged: boolean;
};

export type PaginationScenarioProps = {
    number: number;
    size: number;
    totalElements: number;
    hasContent: boolean;
    first: boolean;
    last: boolean;
    numberOfElements: number;
    totalPages: number;
    activePage: number;
    pageable: Pageable;
    empty: boolean;
    sortColumn: string;
    orderBy: 'asc' | 'desc';
};

type ModalsType = 'delete';
export type Modals = {
    [key in ModalsType]: {
        isOpen: boolean;
        id: string | null;
    };
};

export type OptimizationPricesListProps = {
    filters: Record<FILTER_KEYS, string[]>;
    search: string;
    searchInputValue: string;
    pagination: PaginationScenarioProps;
    data: RowData[];
    modals: Modals;
};

const initialState: OptimizationPricesListProps = {
    filters: {
        productIds: [],
        productFamilyIds: [],
        storeIds: [],
        status: [],
        responsible: [],
        categoryLevel1: [],
        categoryLevel2: [],
        categoryLevel3: [],
        categoryLevel4: [],
        categoryLevel5: [],
        categoryLevel6: [],
        categoryLevel7: [],
    },
    search: '',
    searchInputValue: '',
    pagination: {
        number: 0,
        size: 0,
        totalElements: 0,
        hasContent: false,
        first: false,
        last: false,
        numberOfElements: 0,
        totalPages: 0,
        activePage: 0,
        pageable: {
            sort: [],
            offset: 0,
            pageNumber: 0,
            pageSize: 0,
            paged: false,
            unpaged: false,
        },
        empty: false,
        sortColumn: '',
        orderBy: 'asc',
    },
    data: [],
    modals: {
        delete: {
            isOpen: false,
            id: null,
        },
    },
};

const slice = createSlice({
    name: 'optimization-prices-grid',
    initialState,
    reducers: {
        setFiltersValues(state, { payload }: PayloadAction<{ key: FILTER_KEYS; value: string[] }>) {
            state.filters[payload.key] = payload.value;
        },
        setSearch(state, { payload }: PayloadAction<{ value: string }>) {
            state.search = payload.value;
        },
        setSearchInputValue(state, { payload }: PayloadAction<{ value: string }>) {
            state.searchInputValue = payload.value;
        },
        setPagination(state, { payload }: PayloadAction<{ pagination: PaginationScenarioProps }>) {
            state.pagination = payload.pagination;
        },
        setGridData(state, { payload }: PayloadAction<{ data: RowData[]; pagination: PaginationScenarioProps }>) {
            state.data = payload.data;
            state.pagination = payload.pagination;
        },
        toggleModal(state, { payload }: PayloadAction<{ name: ModalsType; id?: string }>) {
            state.modals[payload.name].isOpen = !state.modals[payload.name].isOpen;
            state.modals[payload.name].id = payload.id ? payload.id : null;
        },
        resetGridData() {
            return initialState;
        },
    },
});

export const { setFiltersValues, setSearch, setPagination, setGridData, resetGridData, toggleModal, setSearchInputValue } = slice.actions;

export default slice.reducer;
