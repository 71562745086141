import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'rsuite';
import { LinkButton } from '../../../../../components';
import {
    MainContainer,
    TableActionArea
} from '../../components';
import { SelectUpdatedHeader } from '../../components/SelectUpdatedHeader';
import { useManageComercialTableStore } from '../ManageComercialTable/ManageComercialTable.store';
import { useComercialTableList } from '../ManageComercialTable/hooks/useComercialTableList';
import styles from './ConsultComercialTable.module.scss';
import { RetailConsultCurrentTable } from './components/RetailConsultCurrentTable';
import { useConsultComercialTableProductList } from './hooks/useConsultComercialTableProductList';
import { useConsultComercialTable } from '../ManageComercialTable/hooks/useConsultComercialTable';
import { useConsultComercialTableStore } from './ConsultComercialTable.store';
import { downloadTabelaComercialAtual } from './services';

export const ConsultComercialTable = () => {
    const history = useHistory()
    const {
        comercialTableItem,
        setManageComercialTableState,
    } = useManageComercialTableStore()
    const {
        consultComercialProductItemSelectedList,
        selectAllConsultComercialTable,
        setConsultComercialTableState,
    } = useConsultComercialTableStore();

    const {
        comercialTableHeaderData,
        comercialTableMutateData,
    } = useComercialTableList(1000);

    const {
        consultComercialTableProductData,
        consultComercialTableParams,
        setConsultComercialTableParams,
        handleChangeConsultarTabelaComercialLength,
        handleChangeConsultarTabelaComercialPage,
    } = useConsultComercialTableProductList();

    const { setConsultComercialTableID } = useConsultComercialTable()

    const handleSelectTableType = (id: number) => {
        const newItem = comercialTableMutateData.data.content.filter((item) => item.id === id)
        setConsultComercialTableParams({
            ...consultComercialTableParams,
            idTabelaComercial: id,
        })
        setConsultComercialTableID(id)
        setManageComercialTableState('comercialTableItem', newItem[0])
    }

    const handleChangeCheckboxConsultComercialProduct = (product, checked) => {
        const addProduct = checked
            ? [...consultComercialProductItemSelectedList, product]
            : consultComercialProductItemSelectedList.filter((item) => item.id !== product.id);

        setConsultComercialTableState('consultComercialProductItemSelectedList', addProduct)
        setConsultComercialTableState('selectAllConsultComercialTable', false)
    }

    const handleClickSelectAllProducts = (all: boolean) => {
        setConsultComercialTableState(
            'consultComercialProductItemSelectedList',
            all ? consultComercialTableProductData?.data?.content : []
        )
        setConsultComercialTableState('selectAllConsultComercialTable', all)
    }

    const handleClickDownload = () => {
        const id = comercialTableItem?.id
        downloadTabelaComercialAtual(id)
    }

    useEffect(() => {
        if (typeof comercialTableItem?.id === 'number') {
            setConsultComercialTableParams({
                ...consultComercialTableParams,
                idTabelaComercial: comercialTableItem?.id,
            })
            setConsultComercialTableID(comercialTableItem?.id)
        }
    }, [comercialTableItem?.id])

    return (
        <MainContainer>
            <div className={styles['header-wrapper']}>
                <div>
                    <SelectUpdatedHeader
                        isPending={comercialTableItem?.revisaoPendente}
                        date={comercialTableItem?.atualizadoEm}
                        value={comercialTableItem?.id}
                        data={comercialTableHeaderData}
                        onChange={handleSelectTableType}
                    />
                    <div className={styles['subtitle-wrapper']}>
                        <p className={styles.subtitle}>As alterações são salvas automaticamente</p>
                        <LinkButton fontSize="12px">Editar regras da tabela</LinkButton>
                    </div>
                </div>
                <Button
                    className={styles['btn-consult']}
                    onClick={() => history.push('/ipa/gerenciar-tabela-comercial')}
                >
                    Editar tabela
                </Button>
            </div>
            <TableActionArea
                data={consultComercialTableProductData?.data}
                // selectedItems={consultComercialProductItemSelectedList}
                // selectAll={selectAllConsultComercialTable}
                // onClickSelectAll={handleClickSelectAllProducts}
            >
                <Button
                    className={styles['btn-apply']}
                    appearance="primary"
                    onClick={handleClickDownload}
                >
                    Download
                </Button>
            </TableActionArea>
            <RetailConsultCurrentTable
                data={consultComercialTableProductData}
                onChangePage={handleChangeConsultarTabelaComercialPage}
                onChangeLength={handleChangeConsultarTabelaComercialLength}
            />
        </MainContainer>
    );
};
