import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import React from 'react';

import moment from 'moment';
import { useSelector } from 'react-redux';
import IconQuery from '../../../../../../../assets/icons/icon_query.svg';
import { selectorDemandForecastFiltersArea, selectorDemandForecastOutputArea } from '../../../../../../../reducers/previsaoDemanda';

import styles from './ForecastDetails.module.scss';
export const ForecastDetails = () => {
    const filtersArea = useSelector(selectorDemandForecastFiltersArea);
    const outputArea = useSelector(selectorDemandForecastOutputArea);

    const { hasSimulation, isSearchDone } = outputArea.calculations;
    const { fromDate } = outputArea.query;

    return (
        <main className={styles['forecast-details']}>
            {filtersArea.isCompleted && isSearchDone && !hasSimulation ? (
                <div className={styles['empty-state']}>
                    <WarningRoundedIcon style={{ fontSize: '24px', color: '#e34850' }} />
                    <p>Não temos dados suficientes para calcular a elasticidade desse produto.</p>
                    <span>Busque outro produto ou loja.</span>
                </div>
            ) : (
                <div className={styles['forecast-wrapper']}>
                    <div className={styles['forecast-volume-info']}>
                        <img alt="ícone de previsão" src={IconQuery} />
                        <h3>
                            Previsão de Demanda de Volume e Receita <span className={styles['beta-badge']}>BETA</span>
                        </h3>
                        <p className={styles['date']}>
                            Data do cálculo: <span>{moment(fromDate).format('DD/MM/yyyy')}</span>
                        </p>
                    </div>
                </div>
            )}
        </main>
    );
};
