import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../@types/RootState';
import { RowData } from './datapoints';

export type EditedClusterStoreDatapointsState = Record<string, Partial<RowData>>;

const initialState: EditedClusterStoreDatapointsState = {};

const slice = createSlice({
    name: 'getClusterStoreModal/editedClusterStoreDatapoints',
    initialState,
    reducers: {
        SET_EDITED_CLUSTER_STORE_DATAPOINTS: (
            _state,
            { payload }: PayloadAction<EditedClusterStoreDatapointsState>
        ) => payload,

        UPDATE_EDITED_CLUSTER_STORE_DATAPOINT: (
            state,
            {
                payload,
            }: {
                payload: {
                    key: string;
                    updatedData: Partial<RowData>;
                };
            },
        ) => {
            state[payload.key] = {
                ...state[payload.key],
                ...payload.updatedData,
            };
        },
        CLEAR_EDITED_CLUSTER_STORE_DATAPOINTS: () => initialState,
    },
});

export const {
    SET_EDITED_CLUSTER_STORE_DATAPOINTS,
    UPDATE_EDITED_CLUSTER_STORE_DATAPOINT,
    CLEAR_EDITED_CLUSTER_STORE_DATAPOINTS,
} = slice.actions;

export const selectorGetEditedClusterStoreDatapoints = (state: RootState) =>
    state.getClusterStoreModalReducer.editedClusterStoreDatapoints;

export default slice.reducer;
