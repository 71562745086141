import { combineReducers } from '@reduxjs/toolkit';
import { default as datapoints } from './datapoints';
import editedClusterStoreDatapoints from './editedClusterStoreDatapoints';
import pagination from './pagination';
import sort from './sort';
import totalElements from './totalElements';


const rootReducer = combineReducers({
    datapoints,
    pagination,
    totalElements,
    sort,
    editedClusterStoreDatapoints,
});

export type GetClusterStoreModalStateProps = ReturnType<typeof rootReducer>;

export default rootReducer;
