// import MarkerClusterer from '@googlemaps/markerclustererplus';
// import { GridAlgorithm, MarkerClusterer } from '@googlemaps/markerclusterer';
import React, { useEffect } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { shallowEqual, useSelector } from 'react-redux';
import {
    Content, FlexboxGrid,
} from 'rsuite';
import { trackEvent } from '../../../../../utils/MatomoConfig';
import iconInfo from '../../../../../assets/icons/icon_info.svg';
import iconPinpointStore from '../../../../../assets/icons/icon_pinpoint_store_blue_baloon.svg';
import iconPinpointSelected from '../../../../../assets/icons/icon_pinpoint_store_selected.svg';
import iconSearch from '../../../../../assets/icons/icon_search.svg';
import SelectPill from '../../../../../components/SelectPill';
import { MAPS_LOADER } from '../../../../../utils/GoogleChartsConfig';
import { PageBlocker } from '../../../InfoPanel/InfoPanel/Components/PageBlocker';

import { LoadingMenu, LoadingSpinerArea } from '../../../../../components/LoadingSpinner';
import { getCompetitors } from '../services';
import { storeCompetitorsPinpoints } from '../utils';

const StoreLocationMap = ({
    chartData,
    searchStoreByArea,
    handleSelectedStore,
    recenterMap,
    onOpen,
    onClean,
    onSelectRegion,
    onSelectCheckPicker,
    onSearchAddress,
    onSelectAddress,
    onSearchFilter,
    disabledRegion,
    referenceStore,
    autoCompleteData,
    searchStoreByInput,
    filterInput,
    hasPermission,
    onClickPageBlocker,
}) => {
    // eslint-disable-next-line no-unused-vars
    let map;
    let markers;

    const storesLimit = 3000;
    const inicialLat = -9.626452197447286;
    const inicialLng = -54.29766185954271;

    const { filtrosData } = useSelector(
        (state) => ({
            filtrosData: state.lojasConcorrentesDataReducer.filtrosData,
        }),
        shallowEqual,
    );

    const getBounds = (map) => {
        const lat0 = map.getBounds().getNorthEast().lat();
        const lng0 = map.getBounds().getNorthEast().lng();
        const lat1 = map.getBounds().getSouthWest().lat();
        const lng1 = map.getBounds().getSouthWest().lng();

        const bottonLeft = {
            latitude: lat1,
            longitude: lng1,
        };

        const upperRight = {
            latitude: lat0,
            longitude: lng0,
        };

        return { bottonLeft, upperRight };
    };

    const searchAreaButton = (map) => {
        const controlButton = document.createElement('button');

        // Set CSS for the control.
        controlButton.style.backgroundColor = '#fff';
        controlButton.style.border = '2px solid #fff';
        controlButton.style.borderRadius = '50px';
        controlButton.style.boxShadow = '0 2px 6px rgba(0,0,0,.3)';
        controlButton.style.color = 'rgb(25,25,25)';
        controlButton.style.cursor = 'pointer';
        controlButton.style.fontFamily = 'Open sans';
        controlButton.style.fontSize = '12px';
        controlButton.style.height = '32px';
        controlButton.style.margin = '8px 0 22px';
        controlButton.style.padding = '0 18px';
        controlButton.style.textAlign = 'center';
        controlButton.title = 'Click to recenter the map';
        controlButton.type = 'button';
        controlButton.innerHTML = `<img src=${iconSearch} style="margin-right: 6px" alt=""/> Buscar nessa área`;
        // Setup the click event listeners: simply set the map to Chicago.

        controlButton.addEventListener('click', () => {
            const bounds = getBounds(map);

            searchStoreByArea(bounds.bottonLeft, bounds.upperRight);
        });
        return controlButton;
    };

    const getCenter = (map) => {
        if (map?.mapOptions) {
            return document.getElementById('map-store').mapOptions.center;
        }
        return null;
    };

    const getZoom = (map) => {
        if (map?.mapOptions) {
            return document.getElementById('map-store').mapOptions.zoom;
        }
        return null;
    };

    // Guardo a instancia inicial do mapa no elemento para poder utilizar em outras funções
    const defineMapOptions = (map) => {
        const mapElement = document.getElementById('map-store');
        mapElement.mapOptions = map;
    };

    const resetDefaultInfoWindow = (marker) => {
        const storeRegular = `<div>
            <p style="color: #323232; font-size: 12px; font-weight: 600; margin-bottom: 2px;">${marker.data.chain}</p>
            <p style="color: #323232; font-size: 10px; font-weight: 400; margin-top: 0">${marker.data.storeType}</p>
        </div>`;

        return storeRegular;
    };

    const setInitialDefaultInfoWindow = (store) => {
        const storeRegular = `<div>
            <p style="color: #323232; font-size: 12px; font-weight: 600; margin-bottom: 2px;">${store.rede}</p>
            <p style="color: #323232; font-size: 10px; font-weight: 400; margin-top: 0">${store.tipoLoja}</p>
        </div>`;

        return storeRegular;
    };

    const setCompetitorInfoWindow = (competitor) => {
        const storeCompetitor = `
            <div>
                <p style="color: #323232; font-size: 12px; font-weight: 600; margin-bottom: 2px;">${competitor.store_brand_competitor}</p>
                <p style="color: #323232; font-size: 10px; font-weight: 400; margin-top: 0">${competitor.store_type_competitor}</p>

                <hr style="margin: 8px 0">
                <div style="display: flex;">
                    <div style="margin: 0 6px 0 0">
                        <p style="color: #323232; font-size: 10px; font-weight: 600; margin: 0 0 4px 0">Índice de competitividade</p>
                        <p style="color: #323232; font-size: 12px; font-weight: 400; margin-top: 0">${competitor.cpi.toFixed(2)}%</p>
                    </div>
                    <div>
                        <p style="color: #323232; font-size: 10px; font-weight: 600; margin: 0 0 4px 0">Itens em comum</p>
                        <p style="color: #323232; font-size: 12px; font-weight: 400; margin-top: 0">${competitor.common_products}</p>
                    </div>
                </div>
            </div>
        `;
        return storeCompetitor;
    };

    const setReferenceInfoWindow = (store) => {
        const selectedStore = `<div>
            <p style="color: #747474; font-size: 10px; font-weight: 600; margin-bottom: 2px">LOJA SELECIONADA</p>
            <p style="color: #323232; font-size: 14px; font-weight: 600; margin-bottom: 2px; margin-top: 0">${store.data.chain}</p>
            <p style="color: #323232; font-size: 12px; font-weight: 400; margin-bottom: 8px; margin-top: 0">${store.data.storeType}</p>
            <a class="tooltip-button" onclick="getElementById('name-selected').scrollIntoView({ behavior: 'smooth' })">Ver análises</a>
        </div>`;

        return selectedStore;
    };

    const mapDefaultMarker = (markers) => markers.map((item) => {
        item.setIcon(iconPinpointStore);
        item.infowindow.setContent(resetDefaultInfoWindow(item));
        item.setZIndex(window.google.maps.Marker.MAX_ZINDEX - 1);
        return item;
    });

    const changeSelectedMarkerLayoutToDefault = (marker) => {
        marker.setIcon(iconPinpointStore);
        marker.setZIndex(window.google.maps.Marker.MAX_ZINDEX + 1);
        marker.infowindow.setContent(resetDefaultInfoWindow(marker));
        marker.toggle = false;
    };

    const changeSelectedMarkerLayoutToReference = (marker) => {
        marker.setIcon(iconPinpointSelected);
        marker.setZIndex(window.google.maps.Marker.MAX_ZINDEX + 1);
        marker.infowindow.setContent(setReferenceInfoWindow(marker));
        marker.toggle = true;
        marker.infowindow.open(map, marker);
    };

    const showCompetitors = async (show, selectedMarker) => {
        const newMarkers = [...markers];

        if (show === 'show') {
            const competitors = await getCompetitors(
                selectedMarker.data.cnpj,
            );
            const newCompetitorsMarkers
                = await mapCompetitorsMarker(
                    competitors,
                    newMarkers,
                );

            changeSelectedMarkerLayoutToReference(selectedMarker);
            selectedMarker.setMap(map);
            markers = newCompetitorsMarkers;
            trackEvent('Lojas Concorrentes', 'Gerar análise');
        } else {
            const newDefaultMarkers = await mapDefaultMarker(
                newMarkers,
            );

            changeSelectedMarkerLayoutToDefault(selectedMarker);
            selectedMarker.setMap(map);
            markers = newDefaultMarkers;
        }
    };

    const setZoomToCompetitor = (marker) => {
        const mapZoom = map.getZoom();
        map.panTo(marker.getPosition());

        if (mapZoom < 12) {
            map.setZoom(12);
        }
    };

    const createNewMarker = (data) => {
        const toggle = false;

        const marker = new window.google.maps.Marker({
            id: data.cnpj,
            data: {
                cnpj: data.cnpj,
                storeType: data.tipoLoja,
                chain: data.rede,
            },
            icon: iconPinpointStore,
            position: {
                lat: data.latitude,
                lng: data.longitude,
            },
            map,
        });

        const infowindow = new window.google.maps.InfoWindow({
            content: setInitialDefaultInfoWindow(data),
        });
        marker.toggle = toggle;
        marker.infowindow = infowindow;

        marker.addListener('mouseover', () => {
            infowindow.open(map, marker);
        });

        marker.addListener('mouseout', () => {
            if (!marker.toggle) {
                infowindow.close();
            }
        });

        marker.addListener('click', () => {
            if (!marker.toggle) {
                setZoomToCompetitor(marker);
                handleSelectedStore(marker);
                showCompetitors('show', marker);
            } else {
                handleSelectedStore(null);
                showCompetitors('hide', marker);
            }
            marker.toggle = !marker.toggle;
        });

        return marker;
    };

    const mapCompetitorData = (competitor) => ({
        cnpj: competitor.cnpj_competitor,
        rede: competitor.store_brand_competitor,
        tipoLoja: competitor.store_type_competitor,
        latitude: competitor.latitude_competitor,
        longitude: competitor.longitude_competitor,
    });

    const mapCompetitorsMarker = (competitors, markers) => {
        const competitorsMarkers = [];
        const allCompetitors = competitorsMarkers.concat(competitors);

        const resetMarkers = markers.map((item) => {
            item.setIcon(iconPinpointStore);
            item.infowindow.setContent(resetDefaultInfoWindow(item));
            item.setZIndex(window.google.maps.Marker.MAX_ZINDEX - 1);
            item.toggle = false;
            item.infowindow.close();
            return item;
        });

        allCompetitors.forEach((item) => {
            if (resetMarkers.find((marker) => marker.id === item.cnpj_competitor)) {
                const index = resetMarkers.findIndex((marker) => marker.id === item.cnpj_competitor);

                resetMarkers[index].setIcon(storeCompetitorsPinpoints[item.position - 1]);
                resetMarkers[index].infowindow.setContent(setCompetitorInfoWindow(item));
                resetMarkers[index].setZIndex(window.google.maps.Marker.MAX_ZINDEX + 1);
            } else {
                const newMarker = createNewMarker(mapCompetitorData(item));
                newMarker.setIcon(storeCompetitorsPinpoints[item.position - 1]);
                newMarker.infowindow.setContent(setCompetitorInfoWindow(item));
                newMarker.setZIndex(window.google.maps.Marker.MAX_ZINDEX + 1);

                resetMarkers.push(newMarker);
            }
        });

        return resetMarkers;
    };

    const setMapDefaultOptions = (options) => {
        map = new window.google.maps.Map(
            document.getElementById('map-store'),
            {
                zoom: options?.zoom || 4,
                streetViewControl: false,
                mapTypeControl: false,
                center: options?.center || { lat: inicialLat, lng: inicialLng },
            },
        );

        const searchButtonDiv = document.createElement('div');
        const centerSearchButton = searchAreaButton(map);

        // Append the control to the DIV.
        searchButtonDiv.appendChild(centerSearchButton);
        map.controls[window.google.maps.ControlPosition.TOP_CENTER].push(
            searchButtonDiv,
        );

        map.setOptions({
            styles: [
                {
                    featureType: 'poi',
                    stylers: [{ visibility: 'off' }],
                },
            ],
        });

        return map;
    };

    const firstLoadMap = async () => {
        MAPS_LOADER.load().then(() => {
            map = setMapDefaultOptions();

            defineMapOptions(map);
        });
    };

    const initMap = (zoom, center, bounds) => {
        if (chartData?.length) {
            const newMakers = [...chartData];
            markers = [];

            const mapZoom = zoom || 4;
            const mapCenter = center || new window.google.maps.LatLng(
                newMakers[0].latitude,
                newMakers[0].longitude,
            );

            trackPromise(
                MAPS_LOADER.load().then(() => {
                    map = setMapDefaultOptions({
                        center: mapCenter,
                        zoom: mapZoom,
                    });

                    const stores = newMakers.map((item) => createNewMarker(item));
                    markers = stores;

                    if (bounds) {
                        map.fitBounds(bounds);
                    }

                    defineMapOptions(map);
                }),
            );
        } else {
            markers = [];
            firstLoadMap();
        }
    };

    const getNewCenter = (data) => {
        const bounds = new window.google.maps.LatLngBounds();
        for (let i = 0; i < data.length; i += 1) {
            bounds.extend(
                new window.google.maps.LatLng(
                    data[i].latitude,
                    data[i].longitude,
                ),
            );
        }

        return bounds;
    };

    // const selectReferenceStore = async (referenceStore) => {
    //     const cnpj = referenceStore?.loja?.pessoaJuridica?.cnpj || null;

    //     // Centraliza o mapa na loja selecionada
    //     // const center = new window.google.maps.LatLng(
    //     //     referenceStore.endereco.latitude,
    //     //     referenceStore.endereco.longitude,
    //     // );

    //     // map = await setMapDefaultOptions({ center, zoom: 6 });

    //     // Usar a busca por endereço para recentralizar e buscar as lojas

    //     if (cnpj) {
    //         const competitors = await getCompetitors(cnpj);
    //         const newCompetitorsMarkers = await mapCompetitorsMarker(competitors);
    //     }
    // };

    useEffect(() => {
        firstLoadMap({ lat: inicialLat, lng: inicialLng });
    }, []);

    useEffect(() => {
        const zoom = getZoom(document.getElementById('map-store'));
        const center = getCenter(document.getElementById('map-store'));
        const bounds = recenterMap ? getNewCenter(chartData) : null;

        initMap(zoom, center, bounds);
    }, [chartData, recenterMap]);

    const renderMenu = (menu) => (
        <span>
            <LoadingMenu area="panel-filter" />
            {menu}
        </span>
    );

    const renderValue = (value) => <span>{value}</span>;

    return (
        <Content className="store-location-map">
            <p className="header-title">Localização de loja pelo mapa</p>
            <div className="map-wrapper">
                <form>
                    <FlexboxGrid justify="space-between">
                        <FlexboxGrid>
                            <SelectPill
                                id="select-picker-pill"
                                placeholder="UF"
                                searchable
                                disabled={disabledRegion}
                                data={filtrosData?.uf?.list}
                                value={filtrosData?.uf?.value?.[0]}
                                onOpen={() => onOpen('uf')}
                                onClean={() => onClean('uf')}
                                onSelect={(value, item) => onSelectRegion('uf', value, item)}
                                onSearch={(query) => onSearchFilter('uf', query)}
                                renderValue={renderValue}
                                renderMenu={renderMenu}
                                locale={{
                                    noResultsText: 'Nenhum UF encontrado',
                                    searchPlaceholder: 'Buscar UF',
                                }}
                            />
                            <SelectPill
                                id="select-picker-pill"
                                placeholder="Cidade"
                                searchable
                                disabled={disabledRegion}
                                filter={false}
                                data={filtrosData?.cidade?.list}
                                value={filtrosData?.cidade?.value?.[0]}
                                onOpen={() => onOpen('cidade')}
                                onClean={() => onClean('cidade')}
                                onSelect={(value, item) => onSelectRegion('cidade', value, item)}
                                onSearch={(query) => onSearchFilter('cidade', query)}
                                renderMenu={renderMenu}
                                locale={{
                                    noResultsText: 'Nenhuma cidade encontrada',
                                    searchPlaceholder: 'Buscar cidade',
                                }}
                            />
                            <SelectPill
                                id="select-picker-pill"
                                placeholder="Endereço"
                                menuClassName="address"
                                searchable
                                data={filtrosData?.endereco?.list}
                                onOpen={() => onOpen('endereco')}
                                onClean={() => onClean('endereco')}
                                onSelect={(value, item) => onSelectAddress(item)}
                                onSearch={onSearchAddress}
                                renderValue={() => <span>Endereço</span>}
                                renderMenu={renderMenu}
                                locale={{
                                    noResultsText: 'Nenhum endereço encontrado',
                                    searchPlaceholder:
                                        'Busque e selecione o endereço desejado',
                                }}
                            />
                            <SelectPill
                                type="check"
                                id="check-picker-pill"
                                placeholder="Rede"
                                searchable
                                data={filtrosData?.rede?.list}
                                value={filtrosData?.rede?.value}
                                onOpen={() => onOpen('rede')}
                                onClean={() => onClean('rede')}
                                onSelect={(value, item) => onSelectCheckPicker('rede', value, item)}
                                onSearch={(query) => onSearchFilter('rede', query)}
                                renderMenu={renderMenu}
                                locale={{
                                    noResultsText: 'Nenhuma rede encontrada',
                                    searchPlaceholder: 'Buscar rede',
                                }}
                            />
                            <SelectPill
                                type="check"
                                id="check-picker-pill"
                                placeholder="Tipo de loja"
                                searchable
                                data={filtrosData?.tipoLoja?.list}
                                value={filtrosData?.tipoLoja?.value}
                                onOpen={() => onOpen('tipoLoja')}
                                onClean={() => onClean('tipoLoja')}
                                onSelect={(value, item) => onSelectCheckPicker('tipoLoja', value, item)}
                                onSearch={(query) => onSearchFilter('tipoLoja', query)}
                                renderMenu={renderMenu}
                                locale={{
                                    noResultsText:
                                        'Nenhum tipo de loja encontrado',
                                    searchPlaceholder: 'Buscar tipo de loja',
                                }}
                            />
                        </FlexboxGrid>

                        {/* <InputGroup style={{ maxWidth: '500px' }}>
                            <InputGroup.Addon style={{ background: '#ffffff' }}>
                                <img src={iconSearch} alt="" />
                            </InputGroup.Addon>
                            <InputPicker
                                className="search-input"
                                menuClassName="reference-store-autocomplete-menu"
                                maxHeight={270}
                                filter={false}
                                data={autoCompleteData}
                                onSearch={(query) => searchStoreByInput(query)}
                                placeholder="Pesquise a loja pelo CNPJ, nome ou endereço"
                                onSelect={(value, item) => selectReferenceStore(item)}
                                renderMenuItem={(label, item) => (
                                    <div className="menu-item">
                                        <div className="menu-item__alias">{item?.alias}</div>
                                        <div className="menu-item__address">{item?.endereco?.resumeEndereco}</div>
                                    </div>
                                )}
                                renderValue={(value, item, selectedElement) => (
                                    <div>
                                        <span style={{ color: '#323232' }}>
                                            {value}
                                        </span>
                                        {' '}
                                    </div>
                                )}
                            />
                            <LoadingSpinerComponent styleClass="search-input-loading" size="sm" area="search-store-input" />
                        </InputGroup> */}
                    </FlexboxGrid>
                </form>
                {chartData.length === storesLimit
                    ? (
                        <div className="quantity-limit-message">
                            Serão exibidas no mapa a quantidade máxima de 3.000 lojas.
                        </div>
                    )
                    : null}
                <div className="map-content">
                    {hasPermission ? (
                        <PageBlocker
                            title="Identifique e acompanhe seus concorrentes de forma estratégica."
                            subtitle="Disponível em outros planos do InfoPanel."
                            buttonText="quero saber mais"
                            // style={pageBlockerStyle}
                            onClick={onClickPageBlocker}
                        />
                    ) : null}
                    {!referenceStore
                        ? (
                            <div className="choose-store-message">
                                <img src={iconInfo} alt="" />
                                Selecione no mapa uma loja para analisar
                            </div>
                        )
                        : null}
                    <div style={{ height: '500px' }} id="map-store" />
                    <LoadingSpinerArea size="md" area="lojas-by-bounds" />
                </div>
            </div>
        </Content>
    );
};

export default StoreLocationMap;
