import classNames from 'classnames';
import React, { FormEvent, useCallback } from 'react';
import Modal, { ModalProps } from 'rsuite/lib/Modal';
import { ButtonPrimary, Callout } from '../../../../../../../../components';
import styles from '../Modals.module.scss';

export type DeleteClusterModalProps = ModalProps & {
    cluster: DataItem;
    onSubmit?: (cluster: DataItem) => void;
    onCancel?: () => void;
};

const DeleteClusterModal = ({ cluster, onSubmit, onCancel, className, ...props }: DeleteClusterModalProps) => {
    const handleSubmit = useCallback(
        (ev: FormEvent<HTMLFormElement>) => {
            ev.preventDefault();
            onSubmit?.(cluster);
        },
        [onSubmit, cluster],
    );

    const handleCancel = useCallback(() => {
        onCancel?.();
    }, [onCancel]);

    return (
        <Modal {...props} className={classNames('info-modal', styles['wrapper'], className)}>
            <form onSubmit={handleSubmit} onReset={handleCancel}>
                <Modal.Header>
                    <Modal.Title>Excluir cluster</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        Você está deletando um cluster. Ao excluir um cluster ele será automaticamente desassociado das lojas.{' '}
                        <strong>Você tem certeza que que quer excluir o cluster?</strong>
                    </p>
                    <Callout style={{ marginTop: '16px' }}>
                        <Callout.Title>Atenção!</Callout.Title>
                        <Callout.Description>A exclusão impacta nas regras e limites criadas com esse cluster selecionado</Callout.Description>
                    </Callout>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonPrimary onClick={handleCancel} type="reset" theme="ghost">
                        Cancelar
                    </ButtonPrimary>
                    <ButtonPrimary type="submit">Excluir</ButtonPrimary>
                </Modal.Footer>
            </form>
        </Modal>
    );
};

export { DeleteClusterModal };
