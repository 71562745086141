import { useMutation, useQueryClient } from '@tanstack/react-query';
import * as service from '../services';
import { useEditBaseTableStore } from '../EditBaseTable.store';
import { useCustosGeraisTabela } from './useCustosGeraisTabela';
import { NotificationMessage } from '../../../../../../components';

export const usePriceApply = () => {
    const queryClient = useQueryClient();

    const {
        priceApplyList,
        setEditBaseTableState
    } = useEditBaseTableStore();

    const { handleGetCustosGeraisTabelaData } = useCustosGeraisTabela()

    const priceApplySuccess = () => {
        NotificationMessage(
            'success',
            5000,
            'Preço aplicado com sucesso',
        );
        handleGetCustosGeraisTabelaData();
        setEditBaseTableState('productItemSelectedList', []); // limpa a lista de produtos selecionados na tabela (checkbox)
        queryClient.invalidateQueries(['header-update-time-view']); // chama e atualiza o atualizadoEm do header
        queryClient.invalidateQueries(['edit-base-table-list']); // chama e atualiza a lista de produtos da tabela
    }

    const mutation = useMutation({
        mutationFn: () => service.priceApply(priceApplyList),
        onSuccess: priceApplySuccess,
    });

    const mutationAll = useMutation({
        mutationFn: () => service.selectAllAplicarPrecos('NACIONAL'),
        onSuccess: priceApplySuccess,
    });

    return {
        handleConfirmPriceApply: mutation.mutateAsync,
        handleConfirmPriceApplyAll: mutationAll.mutateAsync,
    };
};
