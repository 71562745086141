import React from 'react';
import { Button, Modal } from 'rsuite';
import styles from './modal.module.scss';

interface ModalProps {
    showModal: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ModalDescriptionOptmization = ({ setShowModal, showModal }: ModalProps) => {
    const { Header, Body, Footer } = Modal;

    return (
        <Modal title="Otimização de preços" show={showModal} onHide={() => setShowModal(false)} size="md" className={styles['modal-container']}>
            <Header>Entenda a distribuição da projeção de lucro</Header>
            <Body>
                <p className={styles.title}>O gráfico exibe a distribuição dos valores projetados para o lucro em dois cenários:</p>
                <ul>
                    <li>Projeção Base: Representa a distribuição do lucro sem otimização, incluindo valores mínimos, máximos e medianos.</li>
                    <li>Projeção Otimizada: Mostra a estimativa otimizada de lucro, considerando diferentes níveis de incerteza e erro.</li>
                </ul>

                <p className={styles.title}>Componentes da Projeção:</p>
                <ul>
                    <li>Erro Estendido (Superior/Inferior): Limites extremos de incerteza.</li>
                    <li>Erro Superior/Inferior: Faixa provável de variação.</li>
                    <li>Previsão: Melhor estimativa do lucro otimizado.</li>
                    <li>Quartis e Mediana: Indicadores centrais da projeção base.</li>
                </ul>
                <p>Esses dados auxiliam na tomada de decisão ao apresentar cenários alternativos para planejamento financeiro.</p>
            </Body>
            <Footer>
                <Button appearance="primary" onClick={() => setShowModal(false)}>
                    Fechar
                </Button>
            </Footer>
        </Modal>
    );
};
