import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../@types/RootState';
import { ButtonPrimary, Layout, TableHeading } from '../../../../../components';
import { OPEN_WHOLESALE_MODAL } from '../../../../../reducers/PrecoAtacado/modals';
import { selectorPrecoAtacadoSelectedDatapoints, SET_PRECO_ATACADO_SELECT_ALL } from '../../../../../reducers/PrecoAtacado/selectedDatapoints';
import { selectorPrecoAtacadoTotalElements } from '../../../../../reducers/PrecoAtacado/totalElements';
import { LinkButton } from '../../../RevisaoPrecos/Components';
import { getTotalProducts, isEmptyFilters } from '../../../RevisaoPrecos/utils';

const { Container, TotalProducts } = TableHeading;

const PrecoAtacadoTableHeading = () => {
    const dispatch = useDispatch();

    const totalElements = useSelector(selectorPrecoAtacadoTotalElements);

    const filters = useSelector((state: RootState) => {
        return state.precoAtacadoReducer.filters.values;
    });

    const selectedDatapoints = useSelector(selectorPrecoAtacadoSelectedDatapoints);

    const { excludedIds, selectedAll, selectedIds } = selectedDatapoints;

    const currentList = useMemo(() => {
        return selectedAll ? excludedIds : selectedIds;
    }, [selectedDatapoints]);

    const isSelected = useMemo(() => currentList.length > 0 || selectedAll, [currentList, selectedAll]);

    const total = useMemo(() => getTotalProducts(totalElements, selectedDatapoints), [totalElements, selectedDatapoints]);

    const isFilterEmpty = useMemo(() => isEmptyFilters(filters), [filters]);

    const handleSelectAll = useCallback(() => {
        dispatch(SET_PRECO_ATACADO_SELECT_ALL(!selectedAll));
    }, [dispatch, selectedAll]);

    const handleChangeMassivePrice = useCallback(() => {
        dispatch(OPEN_WHOLESALE_MODAL('CHANGE_MASSIVE_PRICE'));
    }, [dispatch]);

    return (
        <Layout.Section>
            <TableHeading isSelected={isSelected}>
                <Container>
                    <TotalProducts total={total} />
                    {!isFilterEmpty && <LinkButton onClick={handleSelectAll}>{selectedAll ? 'Limpar seleção' : 'Selecionar tudo'}</LinkButton>}
                </Container>
                <Container>
                    <ButtonPrimary onClick={handleChangeMassivePrice} disabled={!isSelected} id="change-massive-price-btn">
                        Alterar
                    </ButtonPrimary>
                </Container>
            </TableHeading>
        </Layout.Section>
    );
};

export default PrecoAtacadoTableHeading;
