import React, { ReactNode } from 'react'
import styles from './ButtonLeftIcon.module.scss'

type ButtonLeftIconProps = {
    text?: string,
    icon?: ReactNode,
    onClick?: () => void,
}

export const ButtonLeftIcon = ({ onClick, text, icon}: ButtonLeftIconProps) => {
    return (
        <button className={styles['btn-left-icon']} onClick={onClick}>
            <div className={styles['icon-wrapper']}>{icon}</div>
            <div className={styles['text-wrapper']}>{text}</div>
        </button>
    )
}
