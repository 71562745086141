import React from 'react';
import { Control, Controller, FieldErrors, UseFormWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, CheckPicker, SelectPicker } from 'rsuite';
import { FilterSeparator, SelectPill } from '../../../../../../../components';
import { useDebounce } from '../../../../../../../hooks';
import { useSearchParams } from '../../../../../../../hooks/useSearchParams';
import { optimizationPricesSelector } from '../../../../../../../reducers/OtimizacaoPrecos';
import { setSearch } from '../../../../../../../reducers/OtimizacaoPrecos/optimizationPrices';
import { useFilterSection } from '../../../../hooks/useFilterSection';
import { FormOptimizationPrices } from '../../../../types/schema.form';
import styles from './FilterSection.module.scss';

interface FilterSectionProps {
    control: Control<FormOptimizationPrices>;
    watch: UseFormWatch<FormOptimizationPrices>;
    errors: FieldErrors<FormOptimizationPrices>;
}
export const FilterSection = ({ control, errors }: FilterSectionProps) => {
    const debounce = useDebounce(500);
    const dispatch = useDispatch();
    const { listStores, handleSelectedFilters, handleSetSelectedFilterCategoryLevel, states } = useFilterSection();
    const { isListStoresLoading, indeterminateStores, isCheckedAllStores, isListCategoriesLoading, indeterminateCategories, isCheckedAllCategories } = states;

    const { deleteParam, setParam } = useSearchParams();
    const { filters } = useSelector(optimizationPricesSelector);
    const { selectedFilters, list } = filters;

    // categoryLevel ja vem preenchido e por ora nao vamos permitir selecionar outra opcao
    const ignoreList = list.categoryLevel.filter((item) => item.value !== selectedFilters.categoryLevel.value).map((item) => item.value);

    return (
        <section className={styles.container}>
            <div className={styles.title}>
                <span>Defina onde otimizar os preços</span>
                <p>Escolha as categorias e indique em quais lojas os preços serão otimizados</p>
            </div>
            <div className={styles.content}>
                <div className={styles['filter-selection-wrapper']}>
                    <span>Categorização</span>
                    <SelectPill
                        id="select-picker-pill"
                        placeholder="Selecione uma opção"
                        type="select"
                        name="category"
                        filter={false}
                        searchable={false}
                        cleanable={false}
                        value={selectedFilters.categoryLevel.value}
                        data={list.categoryLevel}
                        defaultValue={list.categoryLevel[0]?.label}
                        onSelect={(v, item: any) => {
                            handleSetSelectedFilterCategoryLevel(item);
                        }}
                        renderValue={(v, item: any) => {
                            return (
                                <span>
                                    <strong>{item?.value?.toString()?.padStart(2, '0')}</strong> {item?.name}
                                </span>
                            );
                        }}
                        disabledItemValues={ignoreList}
                    />

                    <FilterSeparator style={{ height: '20px', width: '20px' }} />

                    <Controller
                        control={control}
                        name="filters.categories"
                        render={({ field }) => (
                            <>
                                <CheckPicker
                                    id="select-picker-pill"
                                    style={{ maxWidth: '300px' }}
                                    cleanable
                                    searchable
                                    placeholder={'Selecione as categorias'}
                                    type="select"
                                    name="categories"
                                    filter={false}
                                    value={selectedFilters.categoriesId}
                                    data={list.categories}
                                    onClose={() => {
                                        listStores();
                                    }}
                                    onSelect={(value) => {
                                        handleSelectedFilters({ type: 'categoriesId', value });
                                        field.onChange(value);
                                        setParam('categoriesId', value.join(','));
                                    }}
                                    onClean={() => {
                                        handleSelectedFilters({ type: 'categoriesId', value: [] });
                                        field.onChange([]);
                                        deleteParam('categoriesId');
                                    }}
                                    locale={{
                                        noResultsText: isListCategoriesLoading ? 'Carregando categorias...' : 'Nenhum item encontrado',
                                        searchPlaceholder: 'Pesquisar categorias',
                                    }}
                                    renderValue={(selected: any) => (
                                        <span>
                                            Categorias selecionadas <span className={styles.badge}>{selected.length}</span>
                                        </span>
                                    )}
                                    renderExtraFooter={() => (
                                        <div className={styles['render-extra-footer']}>
                                            <Checkbox
                                                block
                                                indeterminate={indeterminateCategories}
                                                checked={isCheckedAllCategories}
                                                onChange={(value: any, checked: boolean) => {
                                                    const nextValue = checked ? list.categories.map((item: { value: string }) => item.value) : [];

                                                    handleSelectedFilters({
                                                        type: 'categoriesId',
                                                        value: nextValue,
                                                    });
                                                    setParam('categoriesId', nextValue.join(','));
                                                }}
                                            >
                                                Selecionar todas as categorias
                                            </Checkbox>
                                        </div>
                                    )}
                                    onSearch={(search) => {
                                        debounce(() => {
                                            dispatch(setSearch({ type: 'categories', value: search }));
                                        });
                                    }}
                                />
                                {errors.filters?.categories?.message ? <p className={styles.error}>{errors.filters?.categories?.message} </p> : null}
                            </>
                        )}
                    />
                </div>

                <div className={styles['filter-selection-wrapper']}>
                    <span>Lojas</span>
                    <Controller
                        control={control}
                        name="filters.storesId"
                        render={({ field, fieldState: { error } }) => (
                            <>
                                <CheckPicker
                                    id="select-picker-pill"
                                    style={{ maxWidth: '300px' }}
                                    cleanable
                                    searchable
                                    placeholder={'Selecione as lojas'}
                                    type="select"
                                    name="loja"
                                    filter={false}
                                    value={selectedFilters.storesId}
                                    data={list.stores}
                                    onClose={() => {
                                        listStores();
                                    }}
                                    onSelect={(value) => {
                                        handleSelectedFilters({ type: 'storesId', value });
                                        field.onChange(value);
                                        setParam('storesId', value.join(','));
                                    }}
                                    onClean={() => {
                                        handleSelectedFilters({ type: 'storesId', value: [] });
                                        field.onChange([]);
                                        deleteParam('storesId');
                                    }}
                                    locale={{
                                        noResultsText: isListStoresLoading ? 'Carregando lojas...' : 'Nenhum item encontrado',
                                        searchPlaceholder: 'Pesquisar lojas',
                                    }}
                                    renderValue={(selected: any) => (
                                        <span>
                                            Lojas selecionadas <span className={styles.badge}>{selected.length}</span>
                                        </span>
                                    )}
                                    renderExtraFooter={() => (
                                        <div className={styles['render-extra-footer']}>
                                            <Checkbox
                                                block
                                                indeterminate={indeterminateStores}
                                                checked={isCheckedAllStores}
                                                onChange={(value: any, checked: boolean) => {
                                                    const nextValue = checked ? list.stores.map((item: { value: string }) => item.value) : [];

                                                    handleSelectedFilters({
                                                        type: 'storesId',
                                                        value: nextValue,
                                                    });
                                                    setParam('storesId', nextValue.join(','));
                                                }}
                                            >
                                                Selecionar todas as lojas
                                            </Checkbox>
                                        </div>
                                    )}
                                    onSearch={(search) => {
                                        debounce(() => {
                                            dispatch(setSearch({ type: 'stores', value: search }));
                                        });
                                    }}
                                />
                                {errors.filters?.storesId?.message ? <p className={styles.error}>{errors.filters?.storesId?.message} </p> : null}
                            </>
                        )}
                    />

                    {selectedFilters.storesId.length > 0 && (
                        <>
                            <FilterSeparator style={{ height: '20px', width: '20px' }} />
                            <Controller
                                control={control}
                                name="filters.controlStoreId"
                                render={({ field, fieldState: { error } }) => (
                                    <>
                                        <SelectPicker
                                            id="select-picker-pill"
                                            style={{ maxWidth: '300px' }}
                                            cleanable
                                            searchable
                                            placeholder={'Selecione a loja controle'}
                                            type="select"
                                            name="loja"
                                            filter={false}
                                            value={field.value}
                                            data={list.stores}
                                            onClose={() => {
                                                listStores();
                                            }}
                                            onSelect={(value) => {
                                                field.onChange(value);
                                                setParam('controlStoreId', value);
                                            }}
                                            onClean={() => {
                                                field.onChange([]);
                                                deleteParam('controlStoreId');
                                            }}
                                            locale={{
                                                noResultsText: isListStoresLoading ? 'Carregando lojas...' : 'Nenhum item encontrado',
                                                searchPlaceholder: 'Pesquisar lojas',
                                            }}
                                            onSearch={(search) => {
                                                debounce(() => {
                                                    dispatch(setSearch({ type: 'stores', value: search }));
                                                });
                                            }}
                                        />
                                        {errors.filters?.controlStoreId?.message ? (
                                            <p className={styles.error}>{errors.filters?.controlStoreId?.message} </p>
                                        ) : null}
                                    </>
                                )}
                            />
                        </>
                    )}
                </div>
            </div>
        </section>
    );
};
