import { CellContext } from '@tanstack/react-table';
import React from 'react';
import { BaseCell } from '../../../../../../../../components';
import { SetupIpaStoresAndClustersRowData } from '../../../service';
import styles from './Cells.module.scss';

export const Store = ({ row: { original: rowData } }: CellContext<SetupIpaStoresAndClustersRowData, unknown>) => {
    return (
        <>
            <BaseCell.MainContainer className={styles['store__store-name']}>{rowData.store.id} - {rowData.store.name}</BaseCell.MainContainer>
            <BaseCell.SubContainer className={styles['store__address']}>{rowData.store.address}</BaseCell.SubContainer>
        </>
    );
};
