import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectorDemandForecastOutputArea } from '../../../../../../../reducers/previsaoDemanda';
import { generateElasticity } from '../../utils/generateElasticity';

import styles from './Elasticity.module.scss';

export const Elasticity = () => {
    const outputArea = useSelector(selectorDemandForecastOutputArea);

    const { elasticity } = outputArea.output[0];

    if (!elasticity)
        return (
            <div className={styles['empty-data']}>
                <h3>Elasticidade do produto</h3>
                <WarningRoundedIcon style={{ fontSize: '24px', color: '#e34850' }} />
                <span>Gráfico indisponível.</span>
                <p>Não temos dados suficientes para calcular a elasticidade desse produto.</p>
            </div>
        );

    const dataElasticity = {
        exporting: {
            enabled: false,
        },
        chart: {
            type: 'gauge',
            backgroundColor: 'transparent',
            height: 210,
        },
        title: {
            text: '',
        },
        pane: {
            startAngle: -90,
            endAngle: 89.9,
            background: null,
            center: ['50%', '75%'],
            size: '100%',
        },
        yAxis: {
            min: 0,
            max: 4,
            tickPixelInterval: 0,
            tickPosition: 'inside',
            tickColor: Highcharts?.defaultOptions?.chart?.backgroundColor || '#FFFFFF',
            tickLength: 20,
            tickWidth: 2,
            minorTickInterval: null,
            labels: {
                formatter: () => null,
            },
            plotBands: [
                {
                    from: 0,
                    to: 1,
                    color: '#F5F5F5',
                    thickness: 15,
                },
                {
                    from: 1,
                    to: 2,
                    color: '#CCE9FA',
                    thickness: 15,
                },
                {
                    from: 2,
                    to: 3,
                    color: '#559FF2',
                    thickness: 15,
                },
                {
                    from: 3,
                    to: 4,
                    color: '#0D66D0',
                    thickness: 15,
                },
            ],
        },
        tooltip: {
            enabled: false,
        },
        series: [
            {
                name: 'Elasticidade',
                data: [elasticity],
                dataLabels: {
                    y: 100,
                    borderWidth: 0,
                    color: '#323232',
                    style: {
                        fontSize: '24px',
                        fontWeight: '300',
                        fontFamily: 'Open Sans',
                        fontStyle: 'normal',
                    },
                },
                dial: {
                    radius: '65%',
                    backgroundColor: '#505050',
                    baseWidth: 12,
                    baseLength: '0%',
                    rearLength: '0%',
                },
                pivot: {
                    backgroundColor: '#505050',
                    radius: 6,
                },
            },
        ],
    };

    return (
        <div className={`${styles['product-elasticity-box']}`}>
            <p className={styles.title}>Elasticidade do produto</p>
            <div className={styles['gauge-chart-container']}>
                <HighchartsReact highcharts={Highcharts} options={dataElasticity} />
            </div>
            <div className={styles['bottom-text-wrapper']}>
                <p className={styles['main-text']}>{generateElasticity(elasticity)?.classification}</p>
                <p className={styles['regular-text']}>{generateElasticity(elasticity)?.description}</p>
            </div>
        </div>
    );
};
