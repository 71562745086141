import React from 'react';
import { IAComponent } from '../../../OtimizacaoPrecos/components/CoordinateSection/ia';
import styles from './GridMessage.module.scss';
export const GridMessage = () => {
    return (
        <div className={styles.container}>
            <IAComponent />
            <p>
                A Otimização de Preços com IA analisa seus dados de preços e vendas para definir automaticamente os preços ideais, maximizando lucro e
                faturamento de forma estratégica.
            </p>
        </div>
    );
};
