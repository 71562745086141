import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsExport from 'highcharts/modules/export-data';
import HighchartsExporting from 'highcharts/modules/exporting';
import React from 'react';

import iconLowQuartile from '../../../../../../../../assets/icons/icon_boxplot_low-quartile.svg';
import iconMax from '../../../../../../../../assets/icons/icon_boxplot_max.svg';
import iconMedian from '../../../../../../../../assets/icons/icon_boxplot_median.svg';
import iconMin from '../../../../../../../../assets/icons/icon_boxplot_min.svg';
import iconUpperQuartile from '../../../../../../../../assets/icons/icon_boxplot_upper-quartile.svg';
import { ChartForecast } from '../../../../../../../../reducers/OtimizacaoPrecos/optimizationPricesResult';
import { formatNumbersToPtBr } from '../../../../../../../../utils/FormatNumbers';

HighchartsMore(Highcharts);
HighchartsExporting(Highcharts);
HighchartsExport(Highcharts);

function formatNumber(value: number) {
    const defaultValues = {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
        showSuffix: false,
    };
    return formatNumbersToPtBr(value, 'currency', defaultValues);
}

export const options: Highcharts.Options = {
    chart: {
        type: 'boxplot',
        height: 400,
        inverted: true,
        showAxes: true,
    },
    title: {
        text: '',
    },
    legend: {
        enabled: false,
    },
    xAxis: {
        lineWidth: 0,
        categories: ['Projeção Otimizada', 'Projeção Base'],
        title: {
            text: 'PROJEÇÕES',
            style: {
                fontWeight: 'bold',
            },
        },
    },
    yAxis: [
        {
            gridLineWidth: 0,
            title: {
                text: '',
            },
            labels: {
                formatter() {
                    let price = this.axis.defaultLabelFormatter.call(this);
                    if (price.indexOf(',') !== -1) {
                        price = price.replace(',', '.');
                    }
                    return price;
                },
            },
        },
        {
            gridLineWidth: 0,
            title: {
                text: '',
            },
            linkedTo: 0,
            labels: {
                formatter() {
                    let price = this.axis.defaultLabelFormatter.call(this);
                    if (price.includes('.')) {
                        price = price.replace('.', '');
                    }
                    if (price.indexOf(',') !== -1) {
                        price = price.replace(',', '.');
                    }
                    return price;
                },
            },
            opposite: true,
        },
    ],
    tooltip: {
        useHTML: true,
        formatter() {
            const { q1, median, q3, low, high } = this.point as any;

            const style = "display: flex; gap: 8px; color: #959595; font-family: 'Open Sans'; font-size: 12px; align-items: center;";
            const styleValue = 'font-weight: 600; color: #505050;';

            if (this.point.category === 'Projeção Base') {
                return (
                    `<p style="${style}">${this.x}</p>` +
                    `<p style="${style}"><img src="${iconMax}" />Máximo: <b style="${styleValue}">${formatNumber(high)}</b></p>` +
                    `<p style="${style}"><img src="${iconUpperQuartile}" />Quartil superior: <b style="${styleValue}">${formatNumber(q3)}</b></p>` +
                    `<p style="${style}"><img src="${iconMedian}" />Mediana: <b style="${styleValue}">${formatNumber(median)}</b></p>` +
                    `<p style="${style}"><img src="${iconLowQuartile}" />Quartil inferior: <b style="${styleValue}">${formatNumber(q1)}</b></p>` +
                    `<p style="${style}"><img src="${iconMin}" />Mínimo: <b style="${styleValue}">${formatNumber(low)}</b></p>`
                );
            }

            return (
                `<p style="${style}">${this.x}</p>` +
                `<p style="${style}"><img src="${iconMax}" />Erro estendido superior: <b style="${styleValue}">${formatNumber(high)}</b></p>` +
                `<p style="${style}"><img src="${iconUpperQuartile}" />Erro superior: <b style="${styleValue}">${formatNumber(q3)}</b></p>` +
                `<p style="${style}"><img src="${iconMedian}" />Previsão: <b style="${styleValue}">${formatNumber(median)}</b></p>` +
                `<p style="${style}"><img src="${iconLowQuartile}" />Erro inferior: <b style="${styleValue}">${formatNumber(q1)}</b></p>` +
                `<p style="${style}"><img src="${iconMin}" />Erro estendido inferior: <b style="${styleValue}">${formatNumber(low)}</b></p>`
            );
        },
        backgroundColor: 'rgba(255, 255, 255, 0.90)',
        borderRadius: 4,
        borderColor: '#505050',
        padding: 15,
    },
    exporting: {
        enabled: false,
    },
    plotOptions: {
        series: {
            color: '#979797',
            // @ts-ignore
            pointWidth: 30,
            lineWidth: 1,
            stickyTracking: true,
        },
        boxplot: {
            whiskerWidth: 1, // Remove as linhas externas
            medianColor: 'white',
            lineWidth: 0,
            stemWidth: 1,
            medianWidth: 3,
        },
    },
    series: [],
};

type BoxplotSeriesData = {
    min: number;
    q1: number;
    median: number;
    q3: number;
    max: number;
};

export const ChartBoxplot = ({ chartRef, data }: { chartRef: React.MutableRefObject<HighchartsReact.RefObject | null>; data: ChartForecast }) => {
    function generateSeriesData({ base, optimized }: { base: BoxplotSeriesData; optimized: BoxplotSeriesData }) {
        return [
            {
                name: 'Dados',
                type: 'boxplot',
                data: [
                    { low: base.min, q1: base.q1, median: base.median, q3: base.q3, high: base.max, color: '#747474', fillColor: '#E663C9' },
                    {
                        low: optimized.min,
                        q1: optimized.q1,
                        median: optimized.median,
                        q3: optimized.q3,
                        high: optimized.max,
                        color: '#747474',
                        fillColor: '#0D66D0',
                    },
                ],
            },
        ];
    }

    const chartData = {
        ...options,
        series: generateSeriesData({ base: data.base, optimized: data.optimized }),
    };

    return <HighchartsReact ref={chartRef} highcharts={Highcharts} options={chartData} />;
};
