import classNames from 'classnames';
import React, { ComponentProps, forwardRef } from 'react';
import { FilterButton, InnerFilter } from '../../../../../components';
import { SegmentsPlaceholder } from '../../../../../components/NewFilterBox/Components';
import styles from './Filters.module.scss';
import { useFilters } from './useFilters';

export type FiltersProps = ComponentProps<'section'>;

const Filters = forwardRef<HTMLElement, FiltersProps>(({ className, ...props }, ref) => {
    const { data, value, loading, cattegories, actions } = useFilters();

    return (
        <section {...props} ref={ref} className={classNames(styles.wrapper, className)}>
            <div>
                <InnerFilter
                    type="check"
                    placeholder="Produtos"
                    data={data?.productIds ?? []}
                    value={value?.productIds ?? []}
                    isLoading={loading?.productIds}
                    onChange={(value) => actions.handleChange('productIds', value)}
                    onClean={() => actions.handleClean('productIds')}
                    onClose={() => actions.handleSearch('productIds', '')}
                    onSearch={(query) => actions.handleSearch('productIds', query)}
                />
                <InnerFilter
                    type="check"
                    placeholder="Família"
                    data={data?.productFamilyIds ?? []}
                    value={value?.productFamilyIds ?? []}
                    isLoading={loading?.productFamilyIds}
                    onChange={(value) => actions.handleChange('productFamilyIds', value)}
                    onClean={() => actions.handleClean('productFamilyIds')}
                    onClose={() => actions.handleSearch('productFamilyIds', '')}
                    onSearch={(query) => actions.handleSearch('productFamilyIds', query)}
                />
                <InnerFilter
                    type="check"
                    placeholder="Lojas"
                    data={data?.storeIds ?? []}
                    value={value?.storeIds ?? []}
                    isLoading={loading?.storeIds}
                    onChange={(value) => actions.handleChange('storeIds', value)}
                    onClean={() => actions.handleClean('storeIds')}
                    onClose={() => actions.handleSearch('storeIds', '')}
                    onSearch={(query) => actions.handleSearch('storeIds', query)}
                    selectAll={{
                        onSelectAll: (checked) => {
                            actions.handleSelectAll('storeIds', checked);
                        },
                    }}
                />
                <InnerFilter
                    type="check"
                    placeholder="Cluster"
                    data={data?.clusters ?? []}
                    value={value?.clusters ?? []}
                    isLoading={loading?.clusters}
                    onChange={(value) => {
                        actions.handleChange('clusters', value)
                    }}
                    onClean={() => actions.handleClean('clusters')}
                    onClose={() => actions.handleSearch('clusters', '')}
                    onSearch={(query) => actions.handleSearch('clusters', query)}
                />
                <InnerFilter
                    type="check"
                    placeholder="Segmentação"
                    data={data.segmentos ?? []}
                    value={value.segmentos}
                    isLoading={loading.segmentos}
                    onSearch={(query) => actions.handleSearch('segmentos', query)}
                    onChange={(value) => actions.handleChange('segmentos', value)}
                    onClose={() => actions.handleSearch('segmentos', '')}
                    onClean={() => actions.handleClean('segmentos')}
                    groupBy="role"
                    searchable={false}
                />
                <FilterButton onClick={actions.handleCleanAll}>Limpar Filtros</FilterButton>
            </div>
            <div>
                {cattegories.map(({ value: key, level, name }) => (
                    <InnerFilter
                        key={key}
                        type="check"
                        data={data[key] ?? []}
                        value={value[key]}
                        onSearch={(query) => actions.handleSearch(key, query)}
                        onChange={(value) => actions.handleChange(key, value)}
                        onClose={() => actions.handleSearch(key, '')}
                        onClean={() => actions.handleClean(key)}
                        isLoading={loading.cattegories}
                        // @ts-expect-error
                        placeholder={<SegmentsPlaceholder level={level} placeholder={name} />}
                    />
                ))}
            </div>
        </section>
    );
});

Filters.displayName = 'Filters';

export { Filters };
