import { CustosVendaDataType, DespesaVendaDataType, EditBaseTableItemDataType, ImpostosDataType } from "./pages/EditBaseTable/types"
import { ManageComercialTableTypes } from "./pages/ManageComercialTable/types";

export const priceAndPercentageCalc = (data, percentageValue) => {
    const calc = data?.produtoComercial?.produtoBase?.parametros?.precoTabela * (percentageValue / 100);
    // const calc = data?.parametros?.novoPrecoTabela.valorNovo * (percentageValue / 100);
    return calc
}

export const statusPriceLimit = (product, limit) => {
    const min = Number(priceAndPercentageCalc(product, limit?.percentualMinimo))
    const max = Number(priceAndPercentageCalc(product, limit?.percentualMaximo))
    const value = product?.parametros?.novoPrecoTabela.valorNovo
    // const value = product?.produtoBase?.parametros?.novoPrecoTabela

    if (value >= min && value <= max) {
        return false;
    } else {
        return true;
    }
}

export const totalImpostos = (impostos: ImpostosDataType) => {
    const total = impostos?.ipi + impostos?.icms + impostos?.pis + impostos?.cofins + impostos?.outros
    return total
}

export const totalCustoVenda = (data: EditBaseTableItemDataType) => {
    const total = data?.custosVenda.freteMedio
        + data?.custosVenda.comissao
        + data?.custosVenda.trocasOuDevolucao
        + data?.custosVenda.gratificacao
        + data?.custosVenda.outros

    return total
}

export const totalDespesaVenda = (data: EditBaseTableItemDataType) => {
    const total = data?.despesaVenda.comerciais + data?.despesaVenda.administrativas + data?.despesaVenda.outras

    return total
}

export const outrosCustosCalc = (data: EditBaseTableItemDataType) => {
    const impostos = totalImpostos(data.impostos)
    const custoVenda = totalCustoVenda(data)
    const despesaVenda = totalDespesaVenda(data)
    const total = ((impostos + custoVenda + despesaVenda) * 0.01) * data.parametros.precoMedio?.valorAtual

    return total
}

export const outrosCustosConsultaCalc = (data: EditBaseTableItemDataType) => {
    const impostos = totalImpostos(data.impostos)
    const custoVenda = totalCustoVenda(data)
    const despesaVenda = totalDespesaVenda(data)
    const total = ((impostos + custoVenda + despesaVenda) * 0.01) * data.parametros.precoTabela

    return total
}

export const PMZCalc = (data: EditBaseTableItemDataType) => {
    return outrosCustosCalc(data) + data.parametros.custoFabricacao
}

export const PMZConsultaTabelaBaseCalc = (data: EditBaseTableItemDataType) => {
    return outrosCustosConsultaCalc(data) + data.parametros.custoFabricacao
}

export const totalCustosVenda = (custosVenda: CustosVendaDataType) => {
    const total = custosVenda?.freteMedio +
        custosVenda?.comissao +
        custosVenda?.trocasOuDevolucao +
        custosVenda?.gratificacao +
        custosVenda?.outros

    return total.toFixed(2).toString().replace('.', ',')
}

export const totalOutrasDespesas = (outras: DespesaVendaDataType) => {
    const total = outras?.comerciais + outras?.administrativas + outras?.outras

    return total.toFixed(2).toString().replace('.', ',')
}

export const tabelaAtualPercentage = (data: EditBaseTableItemDataType) => {
    const value = data.tabelaAtual.valorTabelaAtual
    const calc = ((value - PMZCalc(data)) / value) * 100

    return calc.toFixed(2).toString().replace('.', ',')
}

export const precoMedioMargemOperacionalCalc = (data: EditBaseTableItemDataType) => {
    const value = data.parametros.precoMedio.valorAtual
    if (value) {
        const calc = ((value - PMZCalc(data)) / value) * 100
        return Number(calc.toFixed(2))
    }
    return 0
}

export const precoMedioDiffCalc = (data: EditBaseTableItemDataType) => {
    const precoMedioAtual = data.parametros.precoMedio.valorAtual
    const tabelaAtual = data.tabelaAtual.valorTabelaAtual
    const calc = ((precoMedioAtual - tabelaAtual) / tabelaAtual) * 100
    return calc
}

export const precoEditadoEPrecoTabelaAtualDiffCalc = (data: EditBaseTableItemDataType) => {
    const novoPrecoTabela = data.parametros.novoPrecoTabela.valorNovo
    const tabelaAtual = data.tabelaAtual.valorTabelaAtual
    const calc = ((novoPrecoTabela - tabelaAtual) / tabelaAtual) * 100
    return calc
}

// PREÇO MEDIO PROJETADO
export const precoMedioProjetadoCalc = (data: EditBaseTableItemDataType) => {
    const novoPrecoTabela = data.parametros.novoPrecoTabela.valorNovo
    const toPercent = precoMedioDiffCalc(data) * 0.01
    const calc = (1 + toPercent) * novoPrecoTabela

    return calc
}

export const margemOperacionalPrecoMedioCalc = (data: EditBaseTableItemDataType) => {
    const calc = (data.parametros.precoMedio.valorAtual - PMZCalc(data)) / data.parametros.precoMedio.valorAtual * 100;
    return calc;
}

// MARGEM OPERACIONAL
export const margemOperacionalCalc = (data: EditBaseTableItemDataType) => {
    const calc = (precoMedioProjetadoCalc(data) - PMZCalc(data)) / precoMedioProjetadoCalc(data) * 100;

    if (calc === Infinity || calc === -Infinity) {
        return 0
    }
    return calc;
}

// (id32)
export const precoMedioMargemBrutaCalc = (data: EditBaseTableItemDataType) => {
    const precoMedio = data.parametros.precoMedio.valorAtual
    const custoFabricacao = data.parametros.custoFabricacao
    const imposto = totalImpostos(data.impostos) * 0.01
    const impostosPrecoMedio = imposto * precoMedio // id33A
    const calc = ((precoMedio - custoFabricacao - impostosPrecoMedio) / precoMedio) * 100

    return calc
}

// MARGEM BRUTA (id30)
export const margemBrutaCalc = (data: EditBaseTableItemDataType) => {
    const imposto = totalImpostos(data.impostos) * 0.01
    const margemOperacionalVariation = imposto * precoMedioProjetadoCalc(data)
    const calc = (
        (precoMedioProjetadoCalc(data) - data.parametros.custoFabricacao - margemOperacionalVariation) / precoMedioProjetadoCalc(data)
    ) * 100

    if (calc === Infinity || calc === -Infinity) {
        return 0
    }
    return calc
}

// MARGEM BRUTA PERCENTUAL (id31)
export const margemBrutaPercentVariationCalc = (data: EditBaseTableItemDataType) => {
    const calc = margemBrutaCalc(data) - precoMedioMargemBrutaCalc(data);

    if (calc === Infinity || calc === -Infinity) {
        return 0
    }
    return calc
}

// MARGEM OPERACIONAL PERCENTUAL
export const margemOperacionalPercentVariationCalc = (data: EditBaseTableItemDataType) => {
    const calc = margemOperacionalCalc(data) - margemOperacionalPrecoMedioCalc(data);

    if (calc === Infinity || calc === -Infinity) {
        return 0
    }
    return calc
}

// ################################### TABELA COMERCIAL ###################################

export const outrosCustosComercialCalc = (data) => {
    const impostos = totalImpostos(data.impostos)
    const custoVenda = totalCustoVenda(data)
    const despesaVenda = totalDespesaVenda(data)
    const total = ((impostos + custoVenda + despesaVenda) * 0.01) * data.produtoComercial.precoMedioUfCanal

    return total
}

export const PMZComercialCalc = (data) => {
    return outrosCustosComercialCalc(data) + data.produtoComercial.parametros.custoFabricacao
}

export const comercialPrecoEditadoEPrecoTabelaAtualDiffCalc = (data) => {
    const novoPrecoTabela = data.parametros.novoPrecoTabela.valorNovo
    const tabelaAtual = data.tabelaAtual.tabelaAtual
    const calc = (novoPrecoTabela - tabelaAtual) / tabelaAtual * 100
    return calc
}

// TABELA COMERCIAL - TABELA BASE (PERCENTUAL)
export const tabelaBaseComercialPercentage = (data) => {
    const value = data.tabelaAtual.tabelaBase
    const calc = (value - PMZComercialCalc(data)) / value * 100

    return calc.toFixed(2).toString().replace('.', ',')
}

// TABELA COMERCIAL - TABELA ATUAL (PERCENTUAL)
export const tabelaAtualComercialPercentage = (data) => {
    const value = data.tabelaAtual.tabelaAtual
    const calc = (value - PMZComercialCalc(data)) / value * 100

    return calc.toFixed(2).toString().replace('.', ',')
}

// id40
export const diffTabelaComercialPrecoMedioCalc = (data) => {
    const precoMedioAtual = data.produtoComercial.precoMedioUfCanal //id39
    const tabelaComercialAtual = data.tabelaAtual.tabelaAtual //id37
    const calc = (precoMedioAtual - tabelaComercialAtual) / precoMedioAtual * 100

    return calc
}

// TABELA COMERCIAL - PREÇO MEDIO PROJETADO (id42)
export const comercialPrecoMedioProjetadoCalc = (data) => {
    const novoPrecoTabela = data.parametros.novoPrecoTabela.valorNovo
    const calc = (1 + (diffTabelaComercialPrecoMedioCalc(data) * 0.01)) * novoPrecoTabela

    return calc
}

// TABELA COMERCIAL - MARGEM LUCRO BRUTA PROJETADO (id46)
export const comercialMargemBrutaCalc = (data) => {
    const custoFabricacao = data.produtoComercial.parametros.custoFabricacao //id1
    const impostos = totalImpostos(data.produtoComercial.impostos) * 0.01 //id8
    const precoMedioImpostos = impostos * comercialPrecoMedioProjetadoCalc(data);
    const minus = comercialPrecoMedioProjetadoCalc(data) - custoFabricacao - precoMedioImpostos
    const calc = minus / comercialPrecoMedioProjetadoCalc(data) * 100

    return calc
}

// (id50)
export const variacaoMargemBruta = (data) => {
    const precoMedioAtual = data.produtoComercial.precoMedioUfCanal
    const custoFabricacao = data.produtoComercial.parametros.custoFabricacao
    const impostos = totalImpostos(data.produtoComercial.impostos) * 0.01
    const precoMedioImpostos = impostos * precoMedioAtual;
    const minus = precoMedioAtual - custoFabricacao - precoMedioImpostos
    const margemBrutaAtual = minus / precoMedioAtual * 100

    const calc = comercialMargemBrutaCalc(data) - margemBrutaAtual

    return calc
}

// TABELA COMERCIAL - MARGEM LUCRO OPERACIONAL (id47)
export const comercialMargemOperacionalCalc = (data) => {
    const PMZ = PMZComercialCalc(data)
    const minus = comercialPrecoMedioProjetadoCalc(data) - PMZ // id42 - id43

    const calc = minus / comercialPrecoMedioProjetadoCalc(data) * 100

    return calc
}

// (id51)
export const variacaoMargemOperacionalCalc = (data: ManageComercialTableTypes) => {
    const precoMedioAtual = data.produtoComercial.precoMedioUfCanal //id39
    const PMZ = data.produtoComercial.parametros.precoMargemZero //id43
    const margemOperacional = (precoMedioAtual - PMZ) / precoMedioAtual * 100

    const calc = comercialMargemOperacionalCalc(data) - margemOperacional

    return calc
}

export const precoAutoComercialCalc = (data) => {
    const value = data.parametros?.novoPrecoTabela.valorNovo;
    const percentage = data.percentualIdeal / 100;
    const calc = value * percentage;

    return calc
}

export const descontoMaximoTagCalc = (desconto, preco) => {
    const calc = (1 - (desconto / 100)) * preco
    return calc || 0
}

export const margemOperacionalTagCalc = (PMZ, preco) => {
    const calc = (preco - PMZ) / preco * 100
    return calc || 0
}

export const applyDiscount = (desconto, preco) => {
    return preco - (preco * (desconto / 100));
}
