import React from 'react';
import { Button, Modal } from 'rsuite';
import searchStatsIcon from '../../assets/icons/icon_search_stats.svg';
import styles from './ModalSpeakWithSpecialist.module.scss';

type ModalSpeakWithSpecialistProps = {
    show: boolean;
    title: string;
    subtitle: string;
    onClick: () => void;
    onCancel: () => void;
};

export const ModalSpeakWithSpecialist = ({ show, title, subtitle, onClick, onCancel }: ModalSpeakWithSpecialistProps) => {
    return (
        <Modal
            show={show}
            size="md"
            className={styles["modal-speak-with-specialist"]}
        >
            <Modal.Header onHide={onCancel} />
            <Modal.Body className={styles.content}>
                <div className={styles['icon-container']}>
                    <img src={searchStatsIcon} className={styles.icon} />
                </div>
                <h6 className={styles.title}>
                    {title}
                </h6>
                <p className={styles.subtitle}>
                    {subtitle}
                </p>
            </Modal.Body>
            <Modal.Footer className={styles.footer}>
                <Button
                    appearance="subtle"
                    className={styles['btn-cancel']}
                    onClick={onCancel}
                >
                    Cancelar
                </Button>
                <Button
                    id="upsell"
                    appearance="primary"
                    className={[styles['btn-action'], 'upsell'].join(' ')}
                    onClick={onClick}
                >
                    Fale com um especialista
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
