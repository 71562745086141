import React from 'react';
import { Container } from 'rsuite';
import { PageProps } from '../../../../../@types';
import { useCheckUserAllowAccess } from '../../hooks/useCheckUserAllowAccess';
import styles from './OtimizacaoPrecos.module.scss';
import { GridFilters } from './components/Filters';
import { GridHeader } from './components/Header';
import { GridMessage } from './components/Message/GridMessage';
import { ModalConfirmAction } from './components/ModalConfirmAction';
import { GridTable } from './components/Table';

export type CriarOtimizacaoProps = React.HTMLAttributes<HTMLElement> & PageProps;

export const OtimizacaoPrecosLista = ({ usuario }: CriarOtimizacaoProps) => {
    useCheckUserAllowAccess({ usuario });

    const MemoHeader = React.memo(GridHeader);
    return (
        <Container className="main-container ">
            <div className={styles.container}>
                <MemoHeader />
                <GridMessage />
                <GridFilters />
                <GridTable />
                <ModalConfirmAction />
            </div>
        </Container>
    );
};
