import { combineReducers } from '@reduxjs/toolkit';
import optimizationPrices, { OptimizationPricesProps } from './optimizationPrices';
import optimizationPricesList, { OptimizationPricesListProps } from './optimizationPricesList';
import optimizationPricesResult, { OptimizationPricesResultProps } from './optimizationPricesResult';

export type OptimizationPricesStateProps = {
    optimizationPrices: OptimizationPricesProps;
    optimizationPricesList: OptimizationPricesListProps;
    optimizationPricesResult: OptimizationPricesResultProps;
};

const rootReducer = combineReducers({
    optimizationPrices: optimizationPrices,
    optimizationPricesList: optimizationPricesList,
    optimizationPricesResult: optimizationPricesResult,
});

export const optimizationPricesSelector = (state: Record<'optimizationPricesReducer', OptimizationPricesStateProps>) =>
    state.optimizationPricesReducer.optimizationPrices;

export const optimizationPricesListSelector = (state: Record<'optimizationPricesReducer', OptimizationPricesStateProps>) =>
    state.optimizationPricesReducer.optimizationPricesList;

export const optimizationPricesResultSelector = (state: Record<'optimizationPricesReducer', OptimizationPricesStateProps>) =>
    state.optimizationPricesReducer.optimizationPricesResult;

export default rootReducer;
