import classNames from 'classnames';
import React from 'react';
import { Table } from 'rsuite';
import { formatNumbersToPtBr } from '../../../../../../../../utils/FormatNumbers';

import { ChartForecast } from '../../../../../../../../reducers/OtimizacaoPrecos/optimizationPricesResult';
import styles from './Table.module.scss';

export function formatNumberWithComma(value: number) {
    if (value) {
        return value.toFixed(2).replace('.', ',');
    }
    return '-';
}

type RowData = {
    type: string;
    min: number;
    q1: number;
    median: number;
    q3: number;
    max: number;
};

interface TableProjectionProps {
    data: ChartForecast;
}

export const TableProjection = ({ data: optimizationData }: TableProjectionProps) => {
    const { Column, HeaderCell, Cell } = Table;

    const [data, setData] = React.useState([
        {
            type: 'Otimizada',
            ...optimizationData.optimized,
        },
        {
            type: 'Base',
            ...optimizationData.base,
        },
    ]);

    const [sortColumn, setSortColumn] = React.useState<keyof RowData>('min');
    const [sortType, setSortType] = React.useState<'asc' | 'desc'>('asc');
    const handleSortColumn = (sortColumn: string, sortType: 'asc' | 'desc') => {
        setSortColumn(sortColumn as keyof RowData);
        setSortType(sortType);
        ordenarDados(sortColumn as keyof RowData, sortType);
    };

    function ordenarDados(column: keyof RowData, ordem = 'asc') {
        setData((data) =>
            [...data].sort((a, b) => {
                if (a[column] < b[column]) return ordem === 'asc' ? -1 : 1;
                if (a[column] > b[column]) return ordem === 'asc' ? 1 : -1;
                return 0;
            }),
        );
    }

    function formatNumber(value: number) {
        const defaultValues = {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
            showSuffix: false,
        };
        return formatNumbersToPtBr(value, 'currency', defaultValues);
    }

    return (
        <div>
            <Table
                className={classNames('second-table', styles.table)}
                height={400}
                data={data}
                sortColumn={sortColumn}
                sortType={sortType}
                onSortColumn={handleSortColumn}
            >
                {/* @ts-ignore */}
                <Column sortable width={200}>
                    <HeaderCell>Tipo de Projeção</HeaderCell>
                    <Cell dataKey="type">{(rowData: RowData) => <span style={{ color: '#747474' }}>{rowData.type}</span>}</Cell>
                </Column>

                {/* @ts-ignore */}
                <Column sortable width={200}>
                    <HeaderCell>Min.</HeaderCell>
                    <Cell dataKey="min">{(rowData: RowData) => <span>{formatNumber(rowData.min)}</span>}</Cell>
                </Column>

                {/* @ts-ignore */}
                <Column sortable width={200}>
                    <HeaderCell>Q1</HeaderCell>
                    <Cell dataKey="q1">{(rowData: RowData) => <span>{formatNumber(rowData.q1)}</span>}</Cell>
                </Column>

                {/* @ts-ignore */}
                <Column sortable width={200}>
                    <HeaderCell>Mediana</HeaderCell>
                    <Cell dataKey="median">{(rowData: RowData) => <span>{formatNumber(rowData.median)}</span>}</Cell>
                </Column>

                {/* @ts-ignore */}
                <Column sortable width={200}>
                    <HeaderCell>Q3</HeaderCell>
                    <Cell dataKey="q3">{(rowData: RowData) => <span>{formatNumber(rowData.q3)}</span>}</Cell>
                </Column>

                {/* @ts-ignore */}
                <Column sortable width={200}>
                    <HeaderCell>Máx.</HeaderCell>
                    <Cell dataKey="max">{(rowData: RowData) => <span>{formatNumber(rowData.max)}</span>}</Cell>
                </Column>
            </Table>
        </div>
    );
};
