import React from 'react';
import { MdArrowBack, MdWarning } from 'react-icons/md';
import { Link, useParams } from 'react-router-dom';
import styles from './Header.module.scss';

interface HeaderProps {
    title?: string;
    badge?: {
        label: string;
        active: boolean;
    };
    children?: React.ReactNode;
    showAlert?: boolean;
}
export const Header = ({ badge, title = 'Otimização de preços', children, showAlert = true }: HeaderProps) => {
    const { id } = useParams() as Record<'id', string>;

    return (
        <header className={styles.container}>
            <Link className={styles.back} to="/ipa/otimizacao-de-precos/lista">
                <MdArrowBack style={{ height: '24px', width: '24px' }} color="#505050" />
                <span>Voltar para lista de otimizações</span>
            </Link>

            {id && showAlert && (
                <div className={styles.alert}>
                    <span>
                        <MdWarning size={24} color="#FFC107" />
                    </span>
                    <div>
                        <span>Atenção!</span>
                        <p>
                            Ao editar esta otimização, a versão atual será inativada e uma nova otimização será criada. As configurações anteriores não poderão
                            ser alteradas, mas ficarão disponíveis para consulta.
                        </p>
                    </div>
                </div>
            )}

            <section className={styles.content}>
                <div className={styles.title}>
                    <p>{title}</p>
                    {badge?.label ? (
                        <span className={styles.badge} data-active={badge?.active}>
                            {badge?.label}
                        </span>
                    ) : null}
                </div>
                {children}
            </section>
        </header>
    );
};
