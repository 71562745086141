import React from 'react';
import { Button, Modal, Panel } from 'rsuite';
import HowItWorksDescription from './HowItWorksDescription';
import AvailableUfTable from './AvailableUfTable';
import styles from './ModalHowItWorks.module.scss';

export interface IModalHowItWorks {
    onCancel():void;
    show: boolean;
}

const ModalHowItWorks = ({ show, onCancel }: IModalHowItWorks) => (
    <Modal
        overflow={false}
        backdrop="static"
        show={show}
        className={styles['modal-understand-better']}
        onHide={onCancel}
        size="md"
    >
        <div className={styles['title-wrapper']}>
            <h6 className={styles['bold-title']}>Entenda o que é o OnDemand</h6>
        </div>
        <Modal.Body className={styles.body} data-testid="modal-understand-better">
            <Panel
                header="Entenda o que é o OnDemand"
                collapsible
                bordered
                className={styles.panel}
            >
                <HowItWorksDescription />
            </Panel>
            <Panel
                header="Disponibilidade do OnDemand por UF"
                collapsible
                bordered
                className={styles.panel}
                style={{ marginTop: '12px' }}
            >
                <AvailableUfTable />
            </Panel>
        </Modal.Body>
        <Modal.Footer>
            <Button onClick={onCancel} appearance="primary">
                Fechar
            </Button>
        </Modal.Footer>
    </Modal>
);

export default ModalHowItWorks;
