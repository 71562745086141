import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import * as service from '../services';
import { useManageComercialTableStore } from '../ManageComercialTable.store';

export const useComercialTableList = (size = 20) => {
    const [ serviceEditParamsComercialTableList, setServiceEditParamsComercialTableList ] = useState({ page: 0, size })
    const [ comercialTableMutateData, setComercialTableMutateData ] = useState({})
    const [ comercialTableHeaderData, setComercialTableHeaderData ] = useState([])
    const {
        comercialTableItem,
        setManageComercialTableState,
    } = useManageComercialTableStore()

    const getComercialTableList = async (data) => {
        const resp = await service.getComercialTableList(data);
        return resp;
    };

    const querySuccess = (data: AxiosResponse<any>) => {
        const firstItem = data.data.content[0]
        const currentTableItem = data.data.content.find((item) => item.id === comercialTableItem?.id) // tabela que esta selecionada no header
        const hasItem = comercialTableItem?.id ? currentTableItem : firstItem

        const headerList = data.data.content.map((item) => (
            {
                label: item.nome,
                value: item.id
            }
        ))

        setComercialTableHeaderData(headerList)
        setComercialTableMutateData(data)
        setManageComercialTableState('comercialTableItem', hasItem)
    }

    const { data, refetch } = useQuery(
        {
            queryKey: ['edit-comercial-table-list', serviceEditParamsComercialTableList],
            queryFn: () => getComercialTableList(serviceEditParamsComercialTableList),
            retry: false,
            onSuccess: (data) => querySuccess(data),
        },
    );

    const handleChangeComercialTableListPage = (value: number) => {
        setServiceEditParamsComercialTableList({...serviceEditParamsComercialTableList, page: value - 1})
    }

    const handleChangeComercialTableListLength = (value: number) => {
        setServiceEditParamsComercialTableList({page: 0, size: value})
    }

    return {
        comercialTableData: data,
        comercialTableMutateData,
        comercialTableHeaderData,
        setComercialTableMutateData,
        handleGetEditTableList: refetch,
        setServiceEditParamsComercialTableList,
        handleChangeComercialTableListPage,
        handleChangeComercialTableListLength
    };
};
