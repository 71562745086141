import React from 'react';
import ModalConfirm from '../../../../../../../components/ModalConfirm';
import { CustosVendaBox, ImpostosBox } from '../../../../components';
import { OutrasDespesasBox } from '../OutrasDespesasBox';
import styles from './ModalCustosProduto.module.scss';
import { EditBaseTableItemDataType } from '../../types';
import { CustoFabricacaoBox } from '../../../../components/CustoFabricacaoBox';
import { LoadingSpinerArea } from '../../../../../../../components';

type ModalCustosProdutoProps = {
    show?: boolean,
    title?: string,
    subtitle?: string,
    data: EditBaseTableItemDataType,
    disabledImpostos?: boolean,
    disabledCustosVenda?: boolean,
    disabledOutrasDespesas?: boolean,
    disabledCustoFabricacao?: boolean,
    hasCustoFabricacao?: boolean,
    confirmButton?: string,
    cancelButton?: string,
    loadingArea?: string,
    onCancel?: () => void,
    onConfirm?: () => void,
    onChangeCustosProduto?: (type: string, name: string, value: number) => void,
}

export const ModalCustosProduto = ({
    show,
    title,
    subtitle,
    data,
    disabledImpostos,
    disabledCustosVenda,
    disabledOutrasDespesas,
    disabledCustoFabricacao,
    cancelButton,
    confirmButton,
    hasCustoFabricacao,
    loadingArea,
    onCancel,
    onConfirm,
    onChangeCustosProduto
}: ModalCustosProdutoProps) => {

    const custoFabricacao = data?.produtoComercial?.parametros.custoFabricacao / data.produtoComercial?.parametros.precoTabela * 100

    return (
        <ModalConfirm
            show={show}
            className={styles['modal-custos-gerais-tabela-base']}
            modalWidth="735px"
            titleFontSize="16px"
            footerAlign="end"
            title={title}
            upMessage={subtitle}
            confirmButton={confirmButton}
            cancelButton={cancelButton}
            onCancel={onCancel}
            onConfirm={onConfirm}
            onHideHeaderButton={onCancel}
        >
            {loadingArea ? <LoadingSpinerArea area={loadingArea} /> : null}
            <div className={styles.container}>
                <ImpostosBox
                    disabled={disabledImpostos}
                    impostosData={data?.impostos}
                    onChange={(name, value) => onChangeCustosProduto('impostos', name, value)}
                />
                <CustosVendaBox
                    disabled={disabledCustosVenda}
                    custosVendaData={data?.custosVenda}
                    onChange={(name, value) => onChangeCustosProduto('custos', name, value)}
                />
                <div>
                    <OutrasDespesasBox
                        disabled={disabledOutrasDespesas}
                        outrasDespesasData={data?.despesaVenda}
                        onChange={(name, value) => onChangeCustosProduto('despesas', name, value)}
                    />
                    {hasCustoFabricacao ? (
                        <CustoFabricacaoBox
                            disabled={disabledCustoFabricacao}
                            custoFabricacaoData={data?.parametros?.custoFabricacao}
                            totalCustoFabricacao={custoFabricacao}
                            onChange={(name, value) => onChangeCustosProduto('custoFabricacao', name, value)}
                        />
                    ) : null}
                </div>
            </div>
        </ModalConfirm>
    );
};
