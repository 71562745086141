import React from 'react';
import { useSelector } from 'react-redux';
import { optimizationPricesResultSelector } from '../../../../../../../reducers/OtimizacaoPrecos';
import { useResult } from '../../../../hooks/useResult';
import { CardResult } from '../CardResult/CardResult';
import styles from './ResultGroup.module.scss';
export const ResultGroup = () => {
    const { isLoadingResult } = useResult();
    const { result } = useSelector(optimizationPricesResultSelector);
    const { category, stores, totalProducts, period, lastOptimization } = result;
    const storesString = React.useMemo(() => {
        return stores.length > 1 ? stores?.map((item) => item.storeId).join(', ') : stores?.map((item) => item.storeName).join(', ');
    }, [stores]);

    return (
        <section className={styles.container}>
            <CardResult isLoading={isLoadingResult} label={`${category.level} - ${category?.name}`} content={category?.value} />
            <CardResult isLoading={isLoadingResult} label="Loja" content={storesString} />
            <CardResult isLoading={isLoadingResult} label="Total de produtos" content={totalProducts} />
            <CardResult isLoading={isLoadingResult} label="Frequência" content={period.label} />
            <CardResult isLoading={isLoadingResult} label="Última atualização" content={lastOptimization} />
        </section>
    );
};
