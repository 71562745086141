import { trackPromise } from 'react-promise-tracker';
import api from '../../../../../utils/API';

const URL = process.env.REACT_APP_IPA_PRICE_TABLES_MANAGER_URL

// ########## - CONSULTAR TABELA COMERCIAL - ##########

export const getProdutoComercialList = (data) => trackPromise(
    api({
        url: `${URL}/rascunho-produto-comercial`,
        method: 'GET',
        expectToken: true,
        params: {
            page: data.page,
            size: data.size,
            idTabelaComercial: data.idTabelaComercial
        },
    }).then((res) => res),
    'consult-comercial-table'
);

export const downloadTabelaComercialAtual = (id) => trackPromise(
    api({
        url: `${URL}/tabela-comercial/download/${id}`,
        method: 'POST',
        responseType: 'arraybuffer',
        expectToken: true,
    })
        .then((res) => res)
        .then((download) => {
            if (download.status === 200) {
                const blob = new Blob([download.data]);
                const hiddenElement = document.createElement('a');
                hiddenElement.href = window.URL.createObjectURL(blob);
                hiddenElement.target = '_blank';
                hiddenElement.download = `tabela_comercial.csv`;
                hiddenElement.click();
            } else {
                return download;
            }
        }),
);
