import React from 'react'
import { Button } from 'rsuite';
import LockIcon from '@material-ui/icons/Lock';
import styles from './PageBlocker.module.scss'

type PageBlockerProps = {
    title?: string,
    subtitle?: string,
    buttonText?: string,
    style?: React.CSSProperties,
    onClick?: () => void,
}

export const PageBlocker = ({title, subtitle, buttonText, style, onClick}: PageBlockerProps) => {
    return (
        <div className={styles['block-page']} style={style}>
            <LockIcon className={styles['lock-icon']} />
            <p className={styles.title}>
                {title}
            </p>
            <p className={styles.subtitle}>
                {subtitle}
            </p>
            <Button
                className={styles['btn-action']}
                onClick={onClick}
            >
                {buttonText}
            </Button>
        </div>
    )
}

