import { useQuery } from '@tanstack/react-query';
import { flexRender, getCoreRowModel, getPaginationRowModel, PaginationState, RowData, useReactTable } from '@tanstack/react-table';
import classNames from 'classnames';
import React, { ComponentProps, forwardRef, memo, useCallback, useState } from 'react';
import { Loader, Table } from 'rsuite';
import { getSetupIpaStoresAndClustersData } from '../../service';
import columns from './columns';
import { HeaderHeightFeature, RowHeightFeature } from './Features';
import styles from './Table.module.scss';

declare module '@tanstack/table-core' {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    interface TableMeta<TData extends RowData> {
        onCloseAddNewClusterModal: () => void;
    }
}

const Pagination = memo(Table.Pagination);

const paginationLengthMenu = [
    { label: '10', value: 10 },
    { label: '20', value: 20 },
    { label: '30', value: 30 },
    { label: '40', value: 40 },
    { label: '50', value: 50 },
];

export type SetupIpaStoresAndClustersTableProps = Omit<ComponentProps<'table'>, 'children'> & {
    onCloseAddNewClusterModal: () => void;
};

const SetupIpaStoresAndClustersTable = forwardRef<HTMLTableElement, SetupIpaStoresAndClustersTableProps>(
    ({ className, onCloseAddNewClusterModal, ...props }, ref) => {
        const [pagination, setPagination] = useState<PaginationState>({
            pageIndex: 0,
            pageSize: 10,
        });

        const handleGetData = useCallback(async () => {
            const getModel = () => ({ page: pagination.pageIndex, size: pagination.pageSize });
            return await getSetupIpaStoresAndClustersData(getModel());
        }, [pagination]);

        const { data, isFetching } = useQuery({
            queryKey: ['table-data', pagination],
            queryFn: handleGetData,
            staleTime: 0,
        });

        const table = useReactTable({
            _features: [HeaderHeightFeature, RowHeightFeature],
            data: data?.content ?? [],
            columns,
            state: { pagination },
            getPaginationRowModel: getPaginationRowModel(),
            getCoreRowModel: getCoreRowModel(),
            onPaginationChange: setPagination,
            manualPagination: true,
            headerHeight: 34,
            rowHeight: 54,
            meta: {
                onCloseAddNewClusterModal,
            },
        });

        const handleChangePage = (pageIndex: number) => {
            table.setPagination((prev) => ({ ...prev, pageIndex: pageIndex - 1 }));
        };

        const handleChangeLength = (pageSize: number) => {
            table.setPagination((prev) => ({ ...prev, pageSize }));
        };

        return (
            <div className={styles['wrapper']}>
                <table {...props} ref={ref} className={classNames(styles['table'], className)}>
                    <thead style={{ ['--table-header-height' as string]: table.options?.headerHeight ? table.options?.headerHeight + 'px' : 'auto' }}>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <tr key={headerGroup.id}>
                                {headerGroup.headers.map((header) => (
                                    <th
                                        key={header.id}
                                        style={{ minWidth: header.column.columnDef.minSize + 'px', maxWidth: header.column.columnDef.maxSize + 'px' }}
                                    >
                                        {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody>
                        {isFetching ? (
                            <tr>
                                <td colSpan={table.getAllColumns().length}>
                                    <Loader center />
                                </td>
                            </tr>
                        ) : (
                            table.getRowModel().rows.map((row) => (
                                <tr
                                    key={row.id}
                                    style={{ ['--table-row-height' as string]: table.options?.rowHeight ? table.options?.rowHeight + 'px' : 'auto' }}
                                >
                                    {row.getVisibleCells().map((cell) => (
                                        <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                                    ))}
                                </tr>
                            ))
                        )}
                    </tbody>
                    <tfoot>
                        {table.getFooterGroups().map((footerGroup) => (
                            <tr key={footerGroup.id}>
                                {footerGroup.headers.map((header) => (
                                    <th key={header.id}>{header.isPlaceholder ? null : flexRender(header.column.columnDef.footer, header.getContext())}</th>
                                ))}
                            </tr>
                        ))}
                    </tfoot>
                </table>
                <Pagination
                    className={styles['table__pagination']}
                    total={data?.totalElements}
                    displayLength={pagination.pageSize}
                    activePage={pagination.pageIndex + 1}
                    onChangePage={handleChangePage}
                    onChangeLength={handleChangeLength}
                    lengthMenu={paginationLengthMenu}
                />
            </div>
        );
    },
);

SetupIpaStoresAndClustersTable.displayName = 'SetupIpaStoresAndClustersTable';

export { SetupIpaStoresAndClustersTable };
