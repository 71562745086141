import React from 'react';
import { Checkbox, CheckPicker } from 'rsuite';

import { useDispatch, useSelector } from 'react-redux';
import { optimizationPricesResultSelector } from '../../../../../../../reducers/OtimizacaoPrecos';
import { setSelectedStores } from '../../../../../../../reducers/OtimizacaoPrecos/optimizationPricesResult';
import styles from './FilterStore.module.scss';
export const FilterStore = () => {
    const { result } = useSelector(optimizationPricesResultSelector);
    const dispatch = useDispatch();

    const checkedStores = result.selectedStoresId?.length > 0 && result.selectedStoresId?.length === result.stores?.length;

    const indeterminateStores = result.selectedStoresId?.length > 0 && !checkedStores;

    const itemsDisabled = () => {
        if (checkedStores) {
            return result.stores.map((item) => item.storeId);
        }
        const selectedId = result?.selectedStoresId?.[0];
        return selectedId ? result.stores.filter((item) => item.storeId !== selectedId).map((item) => item.storeId) : ([] as any);
    };
    return (
        <>
            <CheckPicker
                id={styles['select-picker']}
                style={{ maxWidth: '300px' }}
                disabledItemValues={itemsDisabled()}
                cleanable
                appearance="default"
                searchable
                placeholder={'Selecione as lojas'}
                type="select"
                name="loja"
                filter={false}
                value={result.selectedStoresId}
                defaultValue={[1]}
                data={result.stores.map((item) => ({ label: item.storeName, value: item.storeId }))}
                onSelect={(value) => dispatch(setSelectedStores({ id: value }))}
                onClean={() => dispatch(setSelectedStores({ id: [] }))}
                locale={{
                    noResultsText: result.stores.length === 0 ? 'Carregando lojas...' : 'Nenhum item encontrado',
                    searchPlaceholder: 'Pesquisar lojas',
                }}
                renderValue={(selected: any) => (
                    <span>
                        Lojas selecionadas <span className={styles.badge}>{selected.length}</span>
                    </span>
                )}
                renderExtraFooter={() => (
                    <div className={styles['render-extra-footer']}>
                        <Checkbox
                            block
                            indeterminate={indeterminateStores}
                            checked={checkedStores}
                            onChange={(value: any, checked: boolean) => {
                                const nextValue = checked ? result.stores.map((item) => item.storeId) : [];
                                dispatch(setSelectedStores({ id: nextValue }));
                            }}
                        >
                            Selecionar todas as lojas
                        </Checkbox>
                    </div>
                )}
            />
        </>
    );
};
