import React from 'react';
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';
import { ButtonLeftIcon } from '../../../../../../components';
import styles from './ButtonVideo.module.scss';

type ButtonVideoProps = {
    onClick?: () => void,
}

export const ButtonVideo = ({ onClick }: ButtonVideoProps) => {
    return (
        <div className={styles['button-video-wrapper']}>
            <ButtonLeftIcon
                text='Veja como usar'
                icon={<OndemandVideoIcon className={styles['video-icon']}/>}
                onClick={onClick}
            />
        </div>
    )
}
