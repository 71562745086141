import { useQueries, useQuery } from '@tanstack/react-query';
import { AxiosPromise } from 'axios';
import _ from 'lodash';
import { ReactNode, useMemo, useRef, useState } from 'react';
import { Notification } from 'rsuite';
import { PageOptions } from '../../../@types/FiltersTypes';
import { queryClient } from '../../../App';
import { PostSavedFitersDataProps, deleteSavedFilter, postSavedFilter, putSavedFilter } from '../../../pages/IPA/RevisaoPrecos/services_ts';
import { SAVED_FILTER_ITEM } from '../../../pages/IPA/RevisaoPrecos/utils';
import { listSegmentsData, listaSegmentos } from '../services';
import { useFilterData } from './useFilterData';
import { FilterSavedItem, useFilterSaved } from './useFilterSaved';
import { useFilterValue } from './useFilterValue';
import { useModal } from './useModal';

type IDataItem = {
    label: string;
    value: string;
};

export type IFilterItem = {
    key: string;
    queryFn?: (query: string) => Promise<Record<'label' | 'value', string>[]>;
    selectAll?: boolean;
    type?: 'check' | 'select';
    placeholder?: ReactNode;
    textSearch?: string;
    textLoading?: string;
} & Record<string, unknown>;

export type IUseFilterOptions = {
    page: PageOptions;
    filters: IFilterItem[];
};

export type IUseFilter = {
    data: Partial<Record<string, IDataItem[]>>;
    values: Partial<Record<string, string[]>>;
    filters: IFilterItem[];
    savedFilter: SAVED_FILTER_ITEM;
    modals: Partial<Record<string, boolean>>;
    isEmpty: boolean;
    loading: Partial<Record<string, boolean>>;
    onSearch: (key: string, query: string) => void;
    onSelect: (key: string, value: string[]) => void;
    onClearAll: () => void;
    onSelectAll: (key: string, checked: boolean) => void;
    onOpenModal: (key: string) => void;
    onCloseModal: (key: string) => void;
    onSaveFilter: (data: SAVED_FILTER_ITEM, option: 'edit' | 'save') => Promise<AxiosPromise<any>>;
    onDeleteFilter: (id: string) => Promise<AxiosPromise<any>>;
    onSelectSavedFilter: (item: SAVED_FILTER_ITEM) => void;
};

const index = {
    save: {
        queryFn: postSavedFilter,
        description: 'Filtro salvo com sucesso.',
    },
    edit: {
        queryFn: putSavedFilter,
        description: 'Filtro editado com sucesso.',
    },
    exclude: {
        queryFn: deleteSavedFilter,
        description: 'Filtro excluido com sucesso.',
    },
} as const;

const queryKey = 'get-filter-data-';

export const useFilterSection = ({ page, filters }: IUseFilterOptions): IUseFilter => {
    const [data, { add: addData, set: setData }] = useFilterData();

    const [cache, { set: setCache }] = useFilterData();

    const [values, { set: setValues, reset: resetValues, resetByKey: resetValuesByKey }] = useFilterValue();

    const [modals, { onOpen: onOpenModal, onHide: onCloseModal }] = useModal();

    const [savedFilter, { onClean: onCleanSavedFilter, onSelect: selectSavedFilter, onSet: setSavedFilter }] = useFilterSaved();

    const [search, setSearch] = useState<Partial<Record<string, string>>>({});

    const [filtersWithSegments, setUpdatedFilterList] = useState<IFilterItem[]>(filters);

    const timer = useRef<NodeJS.Timeout>();

    const onSearch = (key: string, query: string) => {
        clearTimeout(timer.current);
        const newTimer = setTimeout(() => {
            setSearch((oldValue) => ({ ...oldValue, [key]: query }));
        }, 500);
        timer.current = newTimer;
    };

    const onSelect = (key: string, value: string[]) => {
        setValues(key, value);
        const updatedList = data[key]?.filter((item) => value.includes(item.value)) ?? [];
        setCache(key, updatedList);
    };

    const onClearAll = () => {
        onCleanSavedFilter();
        resetValues();
    };

    const onSelectAll = (key: string, checked: boolean) => {
        if (!checked) {
            return resetValuesByKey(key);
        }
        const allData = data[key]?.map(({ value }) => value) ?? [];
        return setValues(key, allData);
    };

    const onSelectSavedFilter = (item: FilterSavedItem) => {
        onClearAll();
        selectSavedFilter(item);

        const { filters } = item;

        Object.keys(filters).forEach((k) => {
            const _k = k as keyof typeof filters;

            const isIgnoredKeys = _k.match(/productIds/) || _k.match(/storeIds/) || _k.match(/savedFilters/);

            if (isIgnoredKeys) return;

            const currentFilter = filters[_k] as any[];

            if (!currentFilter) return;

            if (_k.match(/products/)) {
                currentFilter.forEach((filter) =>
                    addData('productIds', {
                        label: filter.description,
                        value: filter.id,
                    }),
                );
                const values = currentFilter.map((filter) => filter.id);
                setValues('productIds', values);
                return;
            }

            if (_k.match(/stores/)) {
                currentFilter.forEach((filter) =>
                    addData('productIds', {
                        label: filter.description,
                        value: filter.id,
                    }),
                );
                const values = currentFilter.map((filter) => filter.id);
                setValues('storeIds', values);
                return;
            }

            if (_k.match(/clusters/)) {
                currentFilter.forEach(({ label, id }) => addData('clusters', { label, value: id }));
                const values = currentFilter.map((filter) => filter.id);
                setValues('clusters', values);
                return;
            }

            currentFilter.forEach((item) =>
                addData(_k, {
                    label: item,
                    value: item,
                }),
            );

            setValues(_k, currentFilter);
        });
    };

    const isEmpty = (() => {
        let isEmpty = true;
        Object.values(values).forEach((v) => {
            if (Array.isArray(v) && v.length > 0) {
                isEmpty = false;
            }
        });
        return isEmpty;
    })();

    const loading = (() => {
        const loadingObject: IUseFilter['loading'] = {};
        Object.keys(data).forEach((key) => {
            loadingObject[key] = !!queryClient.isFetching([queryKey + key]);
        });
        return loadingObject;
    })();

    useQueries({
        queries: filtersWithSegments.map(({ key, queryFn }) => ({
            queryKey: [queryKey + key, search[key]],
            queryFn: () => queryFn?.(search[key] ?? ''),
            onSuccess: (data: IDataItem[]) => {
                setData(key, _.unionBy([...(cache[key] ?? []), ...(data ?? [])], 'value'));
            },
            retry: false,
        })),
    });

    const refetchByKey = async (key: string): Promise<IFilterItem[]> => {
        const queryKeyToRefetch = [queryKey + key, search[key]];
        await queryClient.invalidateQueries(queryKeyToRefetch);
        const data = (await queryClient.getQueryData(queryKeyToRefetch)) as IFilterItem[];
        return data;
    };

    const onSuccess = (modal: string, option: keyof typeof index) => {
        onCloseModal(modal);
        Notification.success({
            description: index[option].description,
        });
    };

    const handleUpdateSavedFilterAfterSave = async (name: string) => {
        let resultValue = {};
        refetchByKey('savedFilters').then((data) => {
            if (data) {
                resultValue = data?.find((item) => item.label === name) ?? {};
                setSavedFilter(resultValue as SAVED_FILTER_ITEM);
            }
        });
    };

    const onSaveFilter = async ({ name, value }: SAVED_FILTER_ITEM, option: 'edit' | 'save'): Promise<AxiosPromise<any>> => {
        const model: PostSavedFitersDataProps = {
            screen: page,
            name,
            id: value,
            filters: values,
        };
        const res = await index[option].queryFn(model);
        if (res.status === 200 || res.status === 201) {
            onSuccess('salvarFiltros', option);
            handleUpdateSavedFilterAfterSave(name);
        }
        return res;
    };

    const onDeleteFilter = async (id: string): Promise<AxiosPromise<any>> => {
        const res = await deleteSavedFilter(id);
        if (res.status === 200) {
            onSuccess('excluirFiltro', 'exclude');
            onClearAll();
            refetchByKey('savedFilters');
        }
        return res;
    };

    useQuery({
        queryKey: ['get-segmentos'],
        initialData: [],
        queryFn: listaSegmentos,
        onSuccess: (data) => {
            const updatedList: IFilterItem[] = [];
            data.forEach(({ field, name, level }) => {
                const data: IFilterItem = {
                    key: field,
                    placeholder: name,
                    level,
                    textLoading: 'Carregando produtos...',
                    textSearch: `Buscar por ${name}`,
                    queryFn: (query) => listSegmentsData(field, { query }),
                };
                updatedList.push(data);
            });
            setUpdatedFilterList((oldValue) => [...oldValue, ...updatedList]);
        },
    });

    return {
        data: useMemo(() => data, [data]),
        values: useMemo(() => values, [values]),
        filters: filtersWithSegments,
        modals,
        savedFilter,
        isEmpty,
        loading,
        onSearch,
        onSelect,
        onClearAll,
        onSelectAll,
        onOpenModal,
        onCloseModal,
        onSaveFilter,
        onDeleteFilter,
        onSelectSavedFilter,
    };
};
