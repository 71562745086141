import React from 'react';
import {
    ButtonToolbar,
    Icon,
    IconButton,
    Whisper,
} from 'rsuite';
import Speaker from './Speaker';

export const AddFiltro = () => (
    <Whisper
        trigger="click"
        placement="bottomStart"
        speaker={<Speaker className="popover-filtro-dinamico" />}
    >
        <ButtonToolbar>
            <IconButton icon={<Icon icon="plus" />}>
                Adicionar Filtros
            </IconButton>
        </ButtonToolbar>
    </Whisper>
);
