import moment from 'moment';
import React, { ReactNode } from 'react'
import { DateRangePicker } from 'rsuite';
import DateRangePickerPill from '../../../../../../components/DateRangePickerPill';
import { locale } from '../../utils';
import calendarIcon from '../../../../../../assets/icons/icon_calendar.svg';

const maxDate = moment().toDate();
const minDate = moment().subtract(89, 'days').toDate();
const { combine, after, before } = DateRangePicker;

type SelectDateRangePillProps = {
    classNameValue: string,
    value: string,
    disabledDate: boolean,
    rightIcon: ReactNode,
    onChange: (value: string) => void,
    onClean: () => void,
    onOk: () => void,
}

export const SelectDateRangePill = ({
    classNameValue,
    value,
    disabledDate,
    rightIcon,
    onChange,
    onClean,
    onOk,
}: SelectDateRangePillProps) => {
    return (
        <DateRangePickerPill
            block
            name="date-range"
            placement="bottomEnd"
            cleanable
            classNameValue={classNameValue}
            value={value}
            onChange={onChange}
            onClean={onClean}
            onOk={onOk}
            disabledDate={maxDate || minDate ? combine(before(minDate), after(maxDate)) : null}
            appearance="default"
            placeholder="Selecione datas específicas"
            format="DD/MM/YYYY"
            locale={locale}
            ranges={[]}
            leftIcon={<img src={calendarIcon} alt="" style={{ height: '12px' }} />}
            rightIcon={rightIcon}
        />
    )
}
