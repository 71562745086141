import { z } from 'zod';

export const setupBasicConfigSchema = z.object({
    id: z.string().optional(),
    pricing_preferences: z.enum(['STORE', 'CLUSTER']),
    cost_field: z.string(),
});

export type SetupBasicConfigSchema = z.infer<typeof setupBasicConfigSchema>;

