import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type FiltersNameProps = 'productId' | 'storesId' | 'dateRange';

export type FilterDataProps = Record<string, unknown> & {
    label: string;
    value: string;
    description: string;
};

export type ProductsProps = FilterDataProps & {
    gtin: string;
    productId: string;
};

export type StoresProps = FilterDataProps & { store_id: string };

type SelectedFilters = {
    productId: string;
    storesId: any[];
    dateRange: Date[];
};

type List = {
    products: ProductsProps[];
    stores: StoresProps[];
};

type Search = {
    stores: string;
};

export type ActiveFilter = {
    productName: string | null;
    productLabel: string | null;
    storeOrClusterLabel: string | null;
    date: string | null;
};

export type FiltersStateProps = {
    selectedFilters: SelectedFilters;
    isCompleted: boolean;
    isCanGetInput: boolean;
    list: List;
    search: Search;
    activeFilter: ActiveFilter;
    buttons: {
        getOutput: {
            isDisabled: boolean;
            isLoading: boolean;
        };
    };
};

const initialState: FiltersStateProps = {
    selectedFilters: {
        productId: '',
        storesId: [],
        dateRange: [],
    },
    isCompleted: false,
    isCanGetInput: false,
    activeFilter: {
        productName: null,
        productLabel: null,
        storeOrClusterLabel: null,
        date: null,
    },
    list: {
        products: [],
        stores: [],
    },
    search: {
        stores: '',
    },
    buttons: {
        getOutput: {
            isDisabled: false,
            isLoading: false,
        },
    },
};

const slice = createSlice({
    name: 'demand-forecast-filter-area',
    initialState,
    reducers: {
        setProducts(state, { payload }: PayloadAction<{ products: ProductsProps[] }>) {
            state.list.products = payload.products;
        },

        setStores(state, { payload }: PayloadAction<{ stores: StoresProps[] }>) {
            state.list.stores = payload.stores;
        },

        setFilter(
            state,
            {
                payload,
            }: PayloadAction<{
                name: FiltersNameProps;
                filterValue: string | any | Date[];
            }>,
        ) {
            state.selectedFilters[payload.name] = payload.filterValue;

            const isCompleted = Object.values(state.selectedFilters).every((item) => item.length > 0);
            const isCanGetInput = state.selectedFilters.productId !== '' && state.selectedFilters.storesId.length > 0;

            state.isCompleted = isCompleted;
            state.isCanGetInput = isCanGetInput;
        },
        setSearchStores(state, { payload }: PayloadAction<string>) {
            state.search.stores = payload;
        },

        resetFilters(state) {
            state.selectedFilters = initialState.selectedFilters;
            state.isCompleted = initialState.isCompleted;
            state.isCanGetInput = initialState.isCanGetInput;
            state.activeFilter = initialState.activeFilter;
        },

        setActiveFilter(state, { payload }: PayloadAction<{ activeFilter: ActiveFilter }>) {
            state.activeFilter = payload.activeFilter;
        },

        handleEnableButtonMain: (
            state,
            {
                payload,
            }: PayloadAction<{
                buttonName: keyof FiltersStateProps['buttons'];
                isDisabled: boolean;
            }>,
        ) => {
            state.buttons[payload.buttonName].isDisabled = payload.isDisabled;
        },
        handleLoadingButtonMain: (
            state,
            {
                payload,
            }: PayloadAction<{
                buttonName: keyof FiltersStateProps['buttons'];
                isLoading: boolean;
            }>,
        ) => {
            state.buttons[payload.buttonName].isLoading = payload.isLoading;
        },
    },
});

export const { setProducts, setStores, setFilter, resetFilters, setActiveFilter, handleEnableButtonMain, setSearchStores, handleLoadingButtonMain } =
    slice.actions;

export default slice.reducer;
