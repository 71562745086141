import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'rsuite';

import { FaSave } from 'react-icons/fa';
import { MdDownload } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import { useSearchParams } from '../../../../../../../hooks/useSearchParams';
import {
    selectorDemandForecastFiltersArea,
    selectorDemandForecastOutputArea,
    selectorDemandForecastOutputSavedSimulations,
    selectorDemandForecastSimulatorArea,
} from '../../../../../../../reducers/previsaoDemanda';
import { setInputsValueByGerenciador } from '../../../../../../../reducers/previsaoDemanda/inputArea';
import { useHandleScenarios } from '../../hooks/useHandleScenarios';
import { ModalSaveScenario } from '../ModalSaveScenario';
import { ModalUpdateScenario } from '../ModalUpdateScenario';
import styles from './ProductDetails.module.scss';
export const ProductDetails = () => {
    const filtersArea = useSelector(selectorDemandForecastFiltersArea);
    const outputArea = useSelector(selectorDemandForecastOutputArea);
    const outputSavedSimulations = useSelector(selectorDemandForecastOutputSavedSimulations);
    const simulatorArea = useSelector(selectorDemandForecastSimulatorArea);
    const { inputs } = simulatorArea;

    const params = useParams() as Record<'id', string>;
    const dispatch = useDispatch();

    const { getParam, deleteParam } = useSearchParams();
    const isGerenciatorSource = getParam('source') === 'gerenciador';

    const { isDisabled: buttonDisable, isLoading } = filtersArea.buttons.getOutput;

    const { showModalSave, showModalUpdate } = outputSavedSimulations.modal;

    const {
        handleGetDemandForecastSimulate,
        closeAllModals,
        handleShowModal,
        handleSaveScenario,
        handleDownloadScenario,
        handleUpdateScenario,
        handleOpenModalSave,
    } = useHandleScenarios();

    const isDisabled = !outputArea.calculations.hasSimulation;

    useEffect(() => {
        if (!isGerenciatorSource) return;

        const inputMapped = localStorage.getItem('setInputsValueByGerenciador');
        if (!inputMapped) return;

        const existsNewPrice = inputs[0]?.newPrice;
        const canGetDemandForecast = filtersArea.isCanGetInput && filtersArea.isCompleted && existsNewPrice;

        dispatch(setInputsValueByGerenciador({ inputs: JSON.parse(inputMapped), index: 0 }));
        if (canGetDemandForecast) {
            handleGetDemandForecastSimulate().then(() => {
                deleteParam('source');
                localStorage.removeItem('setInputsValueByGerenciador');
            });
        }
    }, [isGerenciatorSource, filtersArea.isCanGetInput, filtersArea.isCompleted]);

    return (
        <main className={styles.simulation}>
            <div className={styles['simulation-selected-items']}>
                {filtersArea?.isCompleted && (
                    <>
                        <h3>{filtersArea?.activeFilter?.productName}</h3>
                        <p>
                            <span>{filtersArea?.activeFilter?.storeOrClusterLabel}</span>
                            <span>{filtersArea?.activeFilter?.date}</span>
                        </p>
                    </>
                )}
            </div>

            <div className={styles['simulation-buttons']}>
                <div className={styles['action-buttons']}>
                    <Button disabled={isDisabled} className={styles['icon-button']} onClick={handleDownloadScenario}>
                        <MdDownload size={20} />
                    </Button>
                    <Button disabled={isDisabled} className={styles['icon-button']} onClick={() => handleShowModal(params?.id)}>
                        <FaSave size={20} />
                        {/* <span className={styles['save-icon']}>
                            <FaSave />
                            <FaChevronDown />
                        </span> */}
                    </Button>
                </div>

                <Button
                    size="md"
                    className={styles['main-button']}
                    appearance="primary"
                    onClick={handleGetDemandForecastSimulate}
                    disabled={!filtersArea.isCompleted || buttonDisable || isLoading}
                    loading={isLoading}
                    id="product-details-view-results-btn"
                >
                    VISUALIZAR RESULTADOS
                </Button>
            </div>

            {showModalSave && <ModalSaveScenario show={showModalSave} title={'Salvar'} onHide={closeAllModals} onClickSave={handleSaveScenario} />}
            {showModalUpdate && (
                <ModalUpdateScenario show={showModalUpdate} onClickSave={handleUpdateScenario} onClickSaveNew={handleOpenModalSave} onHide={closeAllModals} />
            )}
        </main>
    );
};
