import { format } from 'date-fns';

import pinpoint1 from '../../../../assets/pinpoints/storeCompetitors/pinpoint_concorrentes-1.svg';
import pinpoint10 from '../../../../assets/pinpoints/storeCompetitors/pinpoint_concorrentes-10.svg';
import pinpoint2 from '../../../../assets/pinpoints/storeCompetitors/pinpoint_concorrentes-2.svg';
import pinpoint3 from '../../../../assets/pinpoints/storeCompetitors/pinpoint_concorrentes-3.svg';
import pinpoint4 from '../../../../assets/pinpoints/storeCompetitors/pinpoint_concorrentes-4.svg';
import pinpoint5 from '../../../../assets/pinpoints/storeCompetitors/pinpoint_concorrentes-5.svg';
import pinpoint6 from '../../../../assets/pinpoints/storeCompetitors/pinpoint_concorrentes-6.svg';
import pinpoint7 from '../../../../assets/pinpoints/storeCompetitors/pinpoint_concorrentes-7.svg';
import pinpoint8 from '../../../../assets/pinpoints/storeCompetitors/pinpoint_concorrentes-8.svg';
import pinpoint9 from '../../../../assets/pinpoints/storeCompetitors/pinpoint_concorrentes-9.svg';

export const storeCompetitorsPinpoints = [
    pinpoint1,
    pinpoint2,
    pinpoint3,
    pinpoint4,
    pinpoint5,
    pinpoint6,
    pinpoint7,
    pinpoint8,
    pinpoint9,
    pinpoint10,
];

export function getModelFilters(filters) {
    return {
        rede: filters.rede || null,
        tipoLoja: filters.tipoLoja || null,
        uf: filters.uf || null,
        cep: null,
        latitude: filters.endereco?.latitude || null,
        longitude: filters.endereco?.longitude || null,
        cidade: filters.cidade || null,
    };
}

export function maskCnpj(cnpj) {
    const cleanedCnpj = cnpj.replace(/\D/g, '');
    if (cleanedCnpj.length !== 14) {
        throw new Error('Invalid CNPJ. CNPJ must contain 14 digits.');
    }
    const maskedCnpj = `${cleanedCnpj.substr(0, 2)}.${cleanedCnpj.substr(
        2,
        3,
    )}.${cleanedCnpj.substr(5, 3)}/${cleanedCnpj.substr(
        8,
        4,
    )}-${cleanedCnpj.substr(12, 2)}`;
    return maskedCnpj;
}

export const topTenStoreCompetitorsTableColumn = [
    {
        width: 60,
        header: 'Posição',
        dataKey: 'position',
        cellContent: null,
        tooltip: null,
    },
    {
        flexGrow: 2,
        header: 'Loja Concorrente',
        dataKey: 'store_brand_competitor',
        cellContent: null,
        tooltip: null,
    },
    {
        flexGrow: 4,
        header: 'Endereço',
        dataKey: 'endereco',
        cellContent: null,
        tooltip: null,
    },
    {
        flexGrow: 2,
        header: 'CNPJ',
        dataKey: 'cnpj_competitor',
        cellContent: (
            (rowData) => (
                <div>
                    {rowData?.cnpj_competitor ? maskCnpj(rowData.cnpj_competitor) : null}
                </div>
            )
        ),
        tooltip: null,
    },
    {
        flexGrow: 2,
        header: 'Tipo de loja',
        dataKey: 'store_type_competitor',
        cellContent: null,
        tooltip: null,
    },
    {
        flexGrow: 2,
        header: 'Índice de competitividade',
        dataKey: 'cpi',
        cellContent: (
            (rowData) => (
                <div>
                    {rowData?.cpi?.toFixed(2).replace('.', ',') || null}
                    %
                </div>
            )
        ),
        tooltip:
            'Maior que 100: a Loja Concorrente é mais competitiva (mais barata) que a loja selecionada. '
            + 'Menor que 100: a Loja Concorrente é menos competitiva (mais cara) que a loja selecionada',
    },
    {
        flexGrow: 2,
        header: 'Itens em comum considerados',
        dataKey: 'common_products',
        cellContent: null,
        tooltip:
            'Quantidade de itens em comum considerados para o cálculo de competitividade',
    },
    {
        flexGrow: 1,
        header: 'Distância (km)',
        dataKey: 'distance_km',
        cellContent: (
            (rowData) => (
                <div>
                    {rowData?.distance_km?.toFixed(2).replace('.', ',') || null}
                </div>
            )
        ),
        tooltip: null,
    },
];

export const topTenChainCompetitorsTableColumn = [
    {
        flexGrow: 3,
        header: 'Rede Concorrente',
        dataKey: 'store_brand_competitor',
        cellContent: null,
        tooltip: null,
    },
    {
        flexGrow: 2,
        header: 'Qtd de lojas concorrentes',
        dataKey: 'competitor_stores',
        cellContent: null,
        tooltip: 'Versus todas as lojas da Rede selecionada na UF',
    },
    {
        flexGrow: 3,
        header: 'Índice de competitividade',
        dataKey: 'average_cpi',
        cellContent: (
            (rowData) => (
                <div>
                    {rowData?.average_cpi?.toFixed(2).replace('.', ',') || null}
                    %
                </div>
            )
        ),
        tooltip:
            'Maior que 100: a Rede Concorrente é mais competitiva (mais barata) que a rede da loja selecionada. '
            + 'Menor que 100: a Rede Concorrente é menos competitiva (mais cara) que a rede da loja selecionada',
    },
    {
        flexGrow: 3,
        header: 'Distância média das lojas concorrentes (km)',
        dataKey: 'average_distance',
        cellContent: null,
        tooltip: null,
    },
];
export function getModel(props, query, name, identificadores) {
    return {
        query: query || '',
        field: name || null,
        login: JSON.parse(localStorage.getItem('usuario'))?.email,
        cliente: JSON.parse(localStorage.getItem('cliente'))?.codigo,
        dataInicio: props.dataInicio
            ? format(props.dataInicio, 'yyyy-MM-dd')
            : null,
        dataFim: props?.dataFim ? format(props.dataFim, 'yyyy-MM-dd') : null,
        rede:
            name && name === 'rede'
                ? null
                : props.rede?.value?.length
                    ? props.rede.value
                    : null,
        tipoLoja:
            name && name === 'tipoLoja'
                ? null
                : props?.tipoLoja?.value?.length
                    ? props.tipoLoja.value
                    : null,
        uf:
            name && name === 'uf'
                ? null
                : props?.uf?.value?.length
                    ? props.uf.value
                    : null,
        cidade:
            name && name === 'cidade'
                ? null
                : props?.cidade?.value?.length
                    ? props.cidade.value
                    : null,
        categoria:
            name && name === 'categoria'
                ? null
                : props?.categoria?.value?.length
                    ? props.categoria.value
                    : null,
        secao:
            name && name === 'secao'
                ? null
                : props?.secao?.value?.length
                    ? props.secao.value
                    : null,
        gtin:
            name && name === 'produto'
                ? null
                : props.produto?.cache?.length
                    ? props.produto.cache.map((item) => item.identificador)
                    : null,
        tipoPreco:
            name && name === 'tipoPreco'
                ? null
                : props?.tipoPreco?.value?.length
                    ? props.tipoPreco.value
                    : null,
        tipoProduto:
            name && name === 'tipoProduto'
                ? null
                : props?.tipoProduto?.value?.length
                    ? props.tipoProduto.value
                    : null,
        fabricante:
            name && name === 'fabricante'
                ? null
                : props?.fabricante?.value?.length
                    ? props?.fabricante?.value
                    : null,
        origemPreco:
            name && name === 'origemPreco'
                ? null
                : props?.origemPreco?.value?.length
                    ? props?.origemPreco?.value
                    : null,
        latitude: props?.endereco?.latitude ? props.endereco.latitude : null,
        longitude: props?.endereco?.longitude ? props.endereco.longitude : null,
        raio: props?.raio?.value ? props.raio.value : null,
        preco: props?.preco?.name ? props.preco.name : 'PRECO_PAGO',
        limitProducts: props.produto?.limitProducts || null,
        identificadores: identificadores || null,
        addNotFound: true,
        canal: props?.canal || ['Canal físico', 'Canal digital'],
        anuncios: props?.ad,
        anuncioMidia:
            name && name === 'midia'
                ? null
                : props?.midia?.value?.length
                    ? props?.midia?.value
                    : null,
        anuncioMecanica:
            name && name === 'mecanica'
                ? null
                : props?.mecanica?.value?.length
                    ? props?.mecanica?.value
                    : null,
        anuncioGrupoDeMidia:
            name && name === 'grupoMidia'
                ? null
                : props?.grupoMidia?.value?.length
                    ? props?.grupoMidia?.value
                    : null,
    };
}

export const dataVideoLojasConcorrentes = [
    {
        link: (
            <iframe
                width="100%"
                height="524"
                src="https://www.youtube.com/embed/d3CV6_7yW9M?&autoplay=1"
                title="Conheça o Módulo de Lojas Concorrentes"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
            />
        ),
    },
];