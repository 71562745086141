import React from 'react';

import { MdCalendarToday } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { Skeleton } from '../../../../../../../../../components';
import { optimizationPricesResultSelector } from '../../../../../../../../../reducers/OtimizacaoPrecos';
import { styleFormat } from '../../../../../../../../../utils/FormatNumbers';
import { useResult } from '../../../../../../hooks/useResult';
import { IAComponent } from '../../../../../OtimizacaoPrecos/components/CoordinateSection/ia';
import { BigNumbers } from '../../../BigNumbers';
import { ChartProjectionOptmizated } from '../../../ChartProjectionOptmizated';
import { FilterStore } from '../../../FilterStore/FilterStore';
import styles from './OptimizationForecastTab.module.scss';

const optionsPercent = {
    style: 'percent' as styleFormat,
    options: {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
        showSuffix: true,
    },
};

const optionsDecimal = {
    style: 'decimal' as styleFormat,
    options: {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
        showSuffix: false,
    },
};

const optionsCurrency = {
    style: 'currency' as styleFormat,
    options: {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
        showSuffix: true,
    },
};
export const OptimizationForecastTab = () => {
    const { tabs } = useSelector(optimizationPricesResultSelector);
    const { isLoadingResultBigNumbers } = useResult();

    const { margin, competitiveness, salesVolume, revenue, profitForecast, endDate, startDate } = tabs.forecast.bigNumbers;

    return (
        <main className={styles.container}>
            <section className={styles['big-numbers-container']}>
                <p className={styles.title}>Previsão de preço otimizado</p>
                <div className={styles.period}>
                    <div>
                        <span className={styles.label}>Período: </span>

                        {isLoadingResultBigNumbers ? (
                            <Skeleton width={140} height={20} />
                        ) : (
                            <span className={styles.date}>
                                <MdCalendarToday />
                                {startDate} ~ {endDate}
                            </span>
                        )}
                    </div>

                    <div>
                        <span className={styles.label}>Loja otimização: </span>
                        <FilterStore />
                    </div>
                </div>
                <div className={styles['big-numbers']}>
                    <BigNumbers
                        isLoading={isLoadingResultBigNumbers}
                        styleNumber={optionsPercent}
                        mainValue={margin.optimizedValue}
                        variation={margin.differencePercentage}
                        description="Margem otimizada"
                        oldValue={margin.value}
                        positiveVariation={margin.differencePercentage >= 0}
                    />
                    <BigNumbers
                        isLoading={isLoadingResultBigNumbers}
                        styleNumber={optionsPercent}
                        mainValue={competitiveness.optimizedValue}
                        variation={competitiveness.differencePercentage}
                        description="Competitividade otimizada"
                        oldValue={competitiveness.value}
                        positiveVariation={competitiveness.differencePercentage >= 0}
                    />
                    <BigNumbers
                        isLoading={isLoadingResultBigNumbers}
                        styleNumber={optionsDecimal}
                        mainValue={salesVolume.optimizedValue}
                        variation={salesVolume.differencePercentage}
                        description="Volume venda otimizado"
                        oldValue={salesVolume.value}
                        positiveVariation={salesVolume.differencePercentage >= 0}
                    />
                    <BigNumbers
                        isLoading={isLoadingResultBigNumbers}
                        styleNumber={optionsCurrency}
                        mainValue={revenue.optimizedValue}
                        variation={revenue.differencePercentage}
                        description="Receita otimizada"
                        oldValue={revenue.value}
                        positiveVariation={revenue.differencePercentage >= 0}
                    />
                    <BigNumbers
                        isLoading={isLoadingResultBigNumbers}
                        styleNumber={optionsCurrency}
                        mainValue={profitForecast.optimizedValue}
                        variation={profitForecast.differencePercentage}
                        description="Previsão de lucro otimizado"
                        oldValue={profitForecast.value}
                        positiveVariation={profitForecast.differencePercentage >= 0}
                        highlight
                    />
                </div>
            </section>

            <div className={styles['ia-container']}>
                <IAComponent />
                <p>
                    Nossa projeção usa inteligência artificial e dados estratégicos para oferecer o melhor cenário de precificação, podendo haver pequenas
                    variações.
                </p>
            </div>

            <div>
                <ChartProjectionOptmizated />
            </div>
        </main>
    );
};
