import React from 'react';
import { useHistory } from 'react-router-dom';
import { MainContainer } from '../../components';
import { HeaderPriceTableList, TablePriceTableList } from './components';
import { useComercialTableList } from '../ManageComercialTable/hooks/useComercialTableList';
import { downloadTabelaComercialAtual } from '../ConsultComercialTable/services';
import { getConsultComercialTable, removeComercialTable } from '../ManageComercialTable/services';
import { LoadingSpinerArea, NotificationMessage } from '../../../../../components';
import { useCreateComercialTableStore } from '../CreateComercialTable/CreateComercialTable.store';
import { editComercialTableObj } from '../../utils';

export const PriceTableList = () => {
    const history = useHistory();
    const { setCreateComercialTableState } = useCreateComercialTableStore();

    const {
        comercialTableData,
        handleChangeComercialTableListPage,
        handleChangeComercialTableListLength,
        handleGetEditTableList,
    } = useComercialTableList();

    const handleRemoveComercialTable = async (id: number) => {
        const resp = await removeComercialTable(id)
        if (resp.status === 200) {
            NotificationMessage(
                'success',
                5000,
                'Item removido com sucesso',
            );
            handleGetEditTableList()
        }
    }

    const handleEditTableRules = async (id: number) => {
        const resp = await getConsultComercialTable(id)
        if (resp.status === 200) {
            const data = resp.data
            setCreateComercialTableState('createComercialTableBody', editComercialTableObj(data))
            setCreateComercialTableState('isCreateComercialTable', false)
            history.push(`/ipa/criar-tabela-comercial/${id}`)
        }
    }

    const handleTableIndividualItem = (value, rowData) => {
        const type = {
            1: () => history.push('gerenciar-tabela-comercial'),
            2: () => downloadTabelaComercialAtual(rowData?.id),
            3: () => handleEditTableRules(rowData?.id),
            4: () => handleRemoveComercialTable(rowData?.id),
        };

        type[value]();
    };

    return (
        <MainContainer>
            <LoadingSpinerArea area="edit-comercial-table" />
            <HeaderPriceTableList />
            <TablePriceTableList
                data={comercialTableData?.data}
                onChangePage={handleChangeComercialTableListPage}
                onChangeLength={handleChangeComercialTableListLength}
                onSelectThreeDotButtonOption={handleTableIndividualItem}
            />
        </MainContainer>
    );
};
