import React, { memo } from 'react';
import { QuickActionFilter } from '../QuickActionFilter';
import { CompetitivenessFilter } from './CompetitivenessFilter';
import { CpiDaysFilter } from './CpiDaysFilter';
import { MarginFilter } from './MarginFilter';
import { PriceVariationFilter } from './PriceVariationFilter';
import useGerenciadorQuickActionFilters from './useGerenciadorQuickActionFilters';

const { Select, Button } = QuickActionFilter;

const MemoCpiDaysFilter = memo(CpiDaysFilter);
const MemoMarginFilter = memo(MarginFilter);
const MemoPriceVariationFilter = memo(PriceVariationFilter);
const MemoCompetitivenessFilter = memo(CompetitivenessFilter);

const GerenciadorQuickActionFilters = () => {
    const filters = useGerenciadorQuickActionFilters();

    return (
        <QuickActionFilter>
            <Button {...filters.isFilterVisible} />
            <MemoCpiDaysFilter />
            <MemoPriceVariationFilter />
            <MemoCompetitivenessFilter />
            <MemoMarginFilter />
            {/* <Button {...filters.changedPrices} forceDisabled={false} /> */}
            <Select {...filters.selectLimits} />
            <Button {...filters.derrivedProducts} forceDisabled />
        </QuickActionFilter>
    );
};

export default memo(GerenciadorQuickActionFilters);
