import React, { useEffect } from 'react';
import { MdPriceChange } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../@types/RootState';
import { PriceVariationFilterProps, setPriceVariationFilter } from '../../../../../reducers/gerenciadorPrecos/quickActionsFilter';
import { QuickActionFilter } from '../QuickActionFilter';

const { FilterPriceVariation } = QuickActionFilter;

const MENU_STYLE = { width: '380px', height: '180px' };

export const PriceVariationFilter = () => {
    const dispatch = useDispatch();

    const marginFilterState = useSelector((state: RootState) => {
        return state.gerenciadorPrecosReducer.quickActionsFilters.priceVariationRange;
    });

    const handleToggleFilter = () => {
        const obj = {
            ...marginFilterState,
            isActive: !marginFilterState.isActive,
        };
        dispatch(setPriceVariationFilter(obj));
    };

    const handleCloseFilter = () => {
        const obj = {
            ...marginFilterState,
            isActive: true,
        };

        dispatch(setPriceVariationFilter(obj));
    };

    const handleChange = (paramName: keyof PriceVariationFilterProps, value: any) => {
        const obj = { ...marginFilterState, [paramName]: value, isActive: false };
        dispatch(setPriceVariationFilter(obj));
    };

    const handleOpen = () => {
        const margin = { ...marginFilterState, isActive: false };
        dispatch(setPriceVariationFilter(margin));
    };

    useEffect(() => {
        const marginFilterState = sessionStorage.getItem('gerenciador-price-variation-filter');

        if (marginFilterState) {
            const marginFilterParsed = JSON.parse(marginFilterState);
            dispatch(setPriceVariationFilter(marginFilterParsed));
        }
    }, []);

    return (
        <FilterPriceVariation
            placeholder="Filtrar por Alteração de Preços"
            icon={<MdPriceChange />}
            menuStyle={MENU_STYLE}
            onClick={handleToggleFilter}
            onChangeValue={handleChange}
            onClose={handleCloseFilter}
            onOpen={handleOpen}
            state={marginFilterState}
        />
    );
};
