import { useQuery } from '@tanstack/react-query';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AsyncCheckPillProps } from '../../../../../../../components';
import * as services from '../../../../../../../components/FilterSection/services';
import { optimizationPricesListSelector } from '../../../../../../../reducers/OtimizacaoPrecos';
import { FILTER_KEYS, setFiltersValues, setSearch, setSearchInputValue } from '../../../../../../../reducers/OtimizacaoPrecos/optimizationPricesList';
import { checkIfFiltersValuesIsEmpty } from '../../../../../../../utils';
import { getMargemObjetivaSegmento } from '../../../../../Estrategia/pages/MargemObjetiva/services';

export type FilterObject<K extends string = string> = {
    dataKey: K;
} & AsyncCheckPillProps;

type Queries = FilterObject<FILTER_KEYS>[];

export const useGridFilters = () => {
    const dispatch = useDispatch();

    const values = useSelector(optimizationPricesListSelector);

    const showOnlyDefaultCategoryLevel = true;

    const set = useCallback(
        (key: FILTER_KEYS, value: string[]) => {
            dispatch(setFiltersValues({ key, value }));
        },
        [dispatch],
    );

    const handleClean = useCallback(
        (key: FILTER_KEYS) => {
            dispatch(setFiltersValues({ key, value: [] }));
            dispatch(setSearch({ value: '' }));
            dispatch(setSearchInputValue({ value: '' }));
        },
        [dispatch],
    );

    const listStatus = async (): Promise<DataItem[]> => {
        return new Promise((resolve) => {
            resolve([
                { value: 'ativa', label: 'Ativa' },
                { value: 'inativa', label: 'Expirada' },
            ]);
        });
    };

    const queries: Queries = React.useMemo(
        () => [
            {
                dataKey: 'productIds',
                placeholder: 'Produto',
                queryFn: services.listProducts,
                value: values.filters.productIds,
                onSelect: (value) => set('productIds', value),
                onClean: () => handleClean('productIds'),
            },
            {
                dataKey: 'productFamilyIds',
                placeholder: 'Família',
                queryFn: services.listFamilies,
                value: values.filters.productFamilyIds,
                onSelect: (value) => set('productFamilyIds', value),
                onClean: () => handleClean('productFamilyIds'),
            },
            {
                dataKey: 'storeIds',
                placeholder: 'Loja',
                queryFn: services.listStores,
                value: values.filters.storeIds,
                selectAll: true,
                onSelect: (value) => set('storeIds', value),
                onClean: () => handleClean('storeIds'),
            },
            {
                dataKey: 'status',
                placeholder: 'Status',
                queryFn: listStatus,
                value: values.filters.status,
                onSelect: (value) => set('status', value),
                onClean: () => handleClean('status'),
                type: 'select',
                searchable: false,
            },
            {
                dataKey: 'responsible',
                placeholder: 'Responsável',
                queryFn: services.listOwners,
                value: values.filters.responsible,
                onSelect: (value) => set('responsible', value),
                onClean: () => handleClean('responsible'),
            },
        ],
        [values, set, handleClean],
    );

    const { data: defaultSegment } = useQuery({
        // @ts-ignore
        queryKey: ['list-segments-default'] as const,
        queryFn: getMargemObjetivaSegmento,
        initialData: [],
        enabled: showOnlyDefaultCategoryLevel,
    });

    const { data: segments } = useQuery({
        queryKey: ['list-segments'],
        queryFn: services.listaSegmentos,
        initialData: [],
        enabled: !showOnlyDefaultCategoryLevel,
    });

    const segmentsQueriesByDefaultLevel: Queries = React.useMemo(() => {
        if (!defaultSegment?.field) {
            return [];
        }
        return [defaultSegment].map((segment) => ({
            dataKey: segment.field as FILTER_KEYS,
            placeholder: segment.name,
            value: values.filters[segment.field as FILTER_KEYS],
            queryFn: (query) => services.listSegment(query, segment.level),
            level: segment.level,
            onSelect: (value) => set(segment.field as FILTER_KEYS, value),
            onClean: () => handleClean(segment.field as FILTER_KEYS),
        }));
    }, [values, set, handleClean, defaultSegment]);

    const segmentsQueries: Queries = React.useMemo(() => {
        return segments.map((segment) => ({
            dataKey: segment.field as FILTER_KEYS,
            placeholder: segment.name,
            value: values.filters[segment.field as FILTER_KEYS],
            queryFn: (query) => services.listSegment(query, segment.level),
            level: segment.level,
            onSelect: (value) => set(segment.field as FILTER_KEYS, value),
            onClean: () => handleClean(segment.field as FILTER_KEYS),
        }));
    }, [segments, values, set, handleClean]);

    const filtersWithSegments = React.useMemo(() => [...queries, ...segmentsQueries], [queries, segmentsQueries]);

    const memoValues = React.useMemo(() => values, [values]);

    const isEmpty = checkIfFiltersValuesIsEmpty(values.filters);

    return {
        list: filtersWithSegments,
        values: memoValues,
        segmentsQueriesByDefaultLevel,
        showOnlyDefaultCategoryLevel,
        isEmpty,
    } as const;
};
