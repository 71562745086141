import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../@types/RootState';
import { SetupIPAConfiguracoesBasicas } from '../../../pages/Administrador/Produtos/Components/SetupIPAConfiguracoesBasicas/SetupIPAConfiguracoesBasicas.types';

type State = SetupIPAConfiguracoesBasicas | undefined;

const initialState: State = {} as State;

interface UpdateBasicConfigPayload {
    pricing_preferences: string;
    cost_field: string;
}

const slice = createSlice({
    name: 'ipa.setup.basicConfig.competitorsDays',
    initialState,
    reducers: {
        SET_IPA_SETUP_COMPETITORS_DAYS: (_state, action) => action.payload,
        RESET_IPA_SETUP_COMPETITORS_DAYS: () => initialState,
        UPDATE_BASIC_CONFIG: (state, action: PayloadAction<UpdateBasicConfigPayload>) => {
            state.pricing_preferences = action.payload.pricing_preferences;
            state.cost_field = action.payload.cost_field;
        }
    },
});

export const {
    SET_IPA_SETUP_COMPETITORS_DAYS,
    RESET_IPA_SETUP_COMPETITORS_DAYS,
    UPDATE_BASIC_CONFIG
} = slice.actions;

export const selectorIpaSetupCompetitorsDays = (state: RootState) => {
    return state.reducerIPA.basicConfig.competitorsDays;
};

export default slice.reducer;
