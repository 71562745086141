import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { snakeCaseToCamelCase } from '../../utils/snakeCaseToCamelCase';

export type Input = {
    competitiveness: number;
    margin: number;
    value: number;
    newPrice: number;
    priceCanGetNewInput: boolean;
};

export type InputStateProps = {
    inputs: Input[];
    limitInput: number;
};

const initialState: InputStateProps = {
    inputs: [
        {
            competitiveness: 0,
            margin: 0,
            value: 0,
            newPrice: 0,
            priceCanGetNewInput: true,
        },
    ],
    limitInput: 3,
};

const slice = createSlice({
    name: 'demand-forecast-input-area',
    initialState,
    reducers: {
        setInputsValue(
            state,
            {
                payload,
            }: PayloadAction<{
                inputs: Input;
                index: number;
            }>,
        ) {
            const inputs = snakeCaseToCamelCase(payload.inputs);
            state.inputs[payload.index] = inputs;
        },
        setInputList(state, { payload }: PayloadAction<{ inputs: Input[] }>) {
            state.inputs = payload.inputs;
        },
        setNewValue(
            state,
            {
                payload,
            }: PayloadAction<{
                newprice: number;
                index: number;
            }>,
        ) {
            state.inputs[payload.index] = {
                ...state.inputs[payload.index],
                newPrice: payload.newprice,
                priceCanGetNewInput: true,
            };
        },
        setInputsValueByGerenciador(
            state,
            {
                payload,
            }: PayloadAction<{
                inputs: Input;
                index: number;
            }>,
        ) {
            const inputs = snakeCaseToCamelCase(payload.inputs);
            state.inputs[payload.index] = inputs;
        },
        setNewInputList(state) {
            if (state.inputs.length >= state.limitInput) return;

            const basePrice = state.inputs[0].value;

            const input = {
                competitiveness: 0,
                margin: 0,
                value: basePrice || 0,
                newPrice: 0,
                priceCanGetNewInput: true,
            };

            state.inputs.push(input);
        },
        removeInputList(state, { payload }: PayloadAction<{ index: number }>) {
            state.inputs.splice(payload.index, 1);
        },
        setInputIsMatchOpenedScenario(state, { payload }: PayloadAction<{ price: string }>) {
            const input = state.inputs.find((input) => input.newPrice.toString() === payload.price);

            if (input) {
                state.inputs = [input];
            }
        },
        clearInputData: (state) => {
            return {
                ...initialState,
                inputs: state.inputs.map(() => {
                    return {
                        competitiveness: 0,
                        margin: 0,
                        value: 0,
                        newPrice: 0,
                        priceCanGetNewInput: true,
                    };
                }),
            };
        },
        clearInputDataAndResetList: () => initialState,
    },
});

export const {
    setInputsValue,
    setNewValue,
    clearInputData,
    setNewInputList,
    removeInputList,
    setInputIsMatchOpenedScenario,
    setInputList,
    clearInputDataAndResetList,
    setInputsValueByGerenciador,
} = slice.actions;

export default slice.reducer;
