import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsExport from 'highcharts/modules/export-data';
import HighchartsExporting from 'highcharts/modules/exporting';
import React, { useEffect, useRef, useState } from 'react';

import iconEllipsis from '../../../../../../../assets/icons/icon_ellipsis-v.svg';
import iconMaximize from '../../../../../../../assets/icons/icon_maximize.svg';
import iconMinimize from '../../../../../../../assets/icons/icon_minimize.svg';

import { useSelector } from 'react-redux';
import { Button, Dropdown, IconButton } from 'rsuite';
import { Skeleton } from '../../../../../../../components';
import { optimizationPricesResultSelector } from '../../../../../../../reducers/OtimizacaoPrecos';
import { useResult } from '../../../../hooks/useResult';
import styles from './ChartProjectionOptmizated.module.scss';
import { ChartBoxplot, options } from './components/Chart';
import { ModalDescriptionOptmization } from './components/Modal';
import { TableProjection } from './components/Table';
import { BoxplotIcon } from './icons/BoxplotIcon';
import { TableIcon } from './icons/TableIcon';

HighchartsMore(Highcharts);
HighchartsExporting(Highcharts);
HighchartsExport(Highcharts);

const SIZE_BORDER_LESS_PAD = 50; // 100px

export const ChartProjectionOptmizated = () => {
    const [view, setView] = useState<'table' | 'chart'>('chart');
    const [expand, setExpand] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const { isLoadingResultChartData } = useResult();

    const { tabs } = useSelector(optimizationPricesResultSelector);
    const { forecast } = tabs;
    const { chart } = forecast;

    const chartRef = useRef<HighchartsReact.RefObject>(null);
    const divRefWrapper = useRef<HTMLDivElement>(null);
    const ref = useRef<{ defaultWidth: number }>();

    function exportPNG() {
        if (chartRef?.current?.chart) {
            chartRef?.current.chart.exportChart({ type: 'image/png' }, options);
        }
    }

    function print() {
        if (chartRef?.current?.chart) {
            chartRef?.current.chart.print();
        }
    }

    function viewFullScreen() {
        if (chartRef?.current?.chart) {
            chartRef?.current.chart.fullscreen.toggle();
        }
    }

    const exportCSV = () => {
        if (chartRef?.current?.chart) {
            chartRef.current.chart.downloadCSV();
        }
    };

    const exportXLS = () => {
        if (chartRef?.current?.chart) {
            chartRef.current.chart.downloadXLS();
        }
    };

    useEffect(() => {
        const handleFullscreenChange = () => {
            const isFullscreen = !!document.fullscreenElement;
            setExpand(isFullscreen);
        };

        if (divRefWrapper.current) {
            ref.current = { defaultWidth: divRefWrapper.current.clientWidth - SIZE_BORDER_LESS_PAD };
        }

        document.addEventListener('fullscreenchange', handleFullscreenChange);

        return () => {
            document.removeEventListener('fullscreenchange', handleFullscreenChange);
        };
    }, []);

    return (
        <div className={styles.container} ref={divRefWrapper}>
            <div>
                <div className={styles['title-container']}>
                    <div className={styles['title-wrapper']}>
                        <p className={styles.title}>Faixa de projeção otimizada</p>
                        <div className={styles.subtitle}>
                            <p>Análise comparativa entre a projeção otimizada e a base, considerando variações e margens de incerteza no lucro.</p>
                            <Button onClick={() => setShowModal(true)} appearance="link">
                                Saiba mais
                            </Button>
                        </div>
                    </div>
                    <div>
                        <Button
                            id="boxplot-expand-btn"
                            appearance="subtle"
                            disabled={view === 'table'}
                            onClick={() => {
                                setExpand((prev) => !prev);
                                viewFullScreen();
                            }}
                        >
                            {expand ? <img alt="Maximizar" src={iconMinimize} /> : <img alt="Minimizar" src={iconMaximize} />}
                        </Button>

                        <Dropdown
                            placement="bottomEnd"
                            className={styles['more-options']}
                            renderTitle={() => <IconButton appearance="subtle" icon={<img alt="Mais opções" src={iconEllipsis} />} />}
                        >
                            {view === 'chart' ? (
                                <>
                                    <Dropdown.Item onClick={viewFullScreen} id="btn-boxplot-full-screen">
                                        Ver em tela cheia
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={print} id="btn-boxplot-print">
                                        Imprimir
                                    </Dropdown.Item>
                                    <Dropdown.Item divider />
                                    <Dropdown.Item onClick={exportPNG} id="btn-boxplot-download-png">
                                        Download em PNG
                                    </Dropdown.Item>
                                </>
                            ) : null}
                            <Dropdown.Item onClick={() => exportCSV()} id="btn-boxplot-download-csv">
                                Download em CSV
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => exportXLS()} id="btn-boxplot-download-xls">
                                Download em XLSX
                            </Dropdown.Item>
                        </Dropdown>
                    </div>
                </div>
                <div className={styles['view-container']}>
                    <p>Visualizar por:</p>
                    <div className={styles['buttons-container']}>
                        <Button
                            data-active={view === 'chart' ? 'true' : 'false'}
                            appearance={view === 'chart' ? 'primary' : 'subtle'}
                            onClick={() => setView('chart')}
                        >
                            <BoxplotIcon defaultColor={view === 'chart' ? 'white' : '#505050'} />
                        </Button>
                        <Button
                            data-active={view === 'table' ? 'true' : 'false'}
                            appearance={view === 'table' ? 'primary' : 'subtle'}
                            onClick={() => setView('table')}
                        >
                            <TableIcon defaultColor={view === 'table' ? 'white' : '#378EF0'} />
                        </Button>
                    </div>
                </div>
            </div>

            {divRefWrapper?.current?.clientWidth ? (
                <div
                    style={{
                        width: view === 'chart' ? divRefWrapper?.current?.clientWidth - SIZE_BORDER_LESS_PAD : '100%',
                        maxWidth: ref.current?.defaultWidth,
                    }}
                >
                    {isLoadingResultChartData ? (
                        <Skeleton height={400} />
                    ) : view === 'table' ? (
                        <TableProjection data={chart} />
                    ) : (
                        <ChartBoxplot data={chart} chartRef={chartRef} />
                    )}
                </div>
            ) : null}

            {showModal && <ModalDescriptionOptmization showModal={showModal} setShowModal={setShowModal} />}
        </div>
    );
};
