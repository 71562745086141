import classNames from 'classnames';
import Decimal from 'decimal.js';
import React, { ComponentProps, forwardRef, useCallback, useMemo } from 'react';
import CurrencyFormat from 'react-currency-format';
import { MdOutlineClose } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { Divider } from 'rsuite';
import { GerenciadorPrecos } from '../../../../../@types/GerenciadorPrecos';
import { BaseCell, InputAddon, InputCurrency, InputGroup, QtdeCell } from '../../../../../components';
import { AlertWithTimestamp } from '../../../../../components/AlertWithTimestamp';
import ExceptionSaveDatapoint from '../../../../../data/ExceptionSaveDatapoint';
import { IPAMaths } from '../../../../../lib';
import { selectorDatapoints, SET_GERENCIADOR_DATAPOINT_BY_INDEX } from '../../../../../reducers/gerenciadorPrecos/datapoints';
import { REMOVE_GERENCIADOR_EXPANDED_ROW_OPTION } from '../../../../../reducers/gerenciadorPrecos/expandedRowKey';
import { CompetitivenessPriceIndicator, MoneyPercentageIndicator, VariationBoxIndicator } from '../../../Negociacoes/pages/NegociacaoFornecedor/components';
import {
    SaveEditedOfferDatapoint,
    saveEditedOfferDatapoint
} from '../../services';
import { CompetitorPriceOfferCell } from '../TableCells/CompetitorPriceOfferCell';
import { ExpandOfferCell } from '../TableCells/ExpandOfferCell';
import styles from './ExpandedRowOffer.module.scss';
import * as calc from './utils';
type RowData = GerenciadorPrecos.RowData;

export type ExpandedRowOfferProps = ComponentProps<'tr'> & {
    rowData: RowData;
};

const ExpandedRowOffer = forwardRef<HTMLTableRowElement, ExpandedRowOfferProps>(({ rowData, className, children, ...props }, ref) => {
    const dispatch = useDispatch();
    const datapoints = useSelector(selectorDatapoints);

    const { productsToBePricedId, offer } = rowData;

    const competitorsPrice = offer?.competitorsPrice || 0;

    const retailPrice = offer?.retailPrice || 0;
    const originalMargin = offer?.originalMargin || 0;
    const price = offer?.price || 0;
    const discountPercentage = offer?.discountPercentage || 0;
    const newCompetitivenessPrice = offer?.newCompetitivenessPrice || 0;
    const newMargin = offer?.newMargin || 0;
    const competitivenessValue = useMemo(() => {
        if (!competitorsPrice) return 0;
        return IPAMaths.novaCompetitividade(price, competitorsPrice);
    }, [competitorsPrice, price]);
    const index = useMemo(() => datapoints.findIndex((item) => item.productsToBePricedId === productsToBePricedId), [datapoints, productsToBePricedId]);

    const isCpiDisabled = useMemo(() => {
        if (!offer?.price || !offer?.competitorsPrice) return true;
        return false;
    }, [offer?.price, offer?.competitorsPrice]);

    const handleClose = useCallback(() => {
        dispatch(REMOVE_GERENCIADOR_EXPANDED_ROW_OPTION('OFFER'));
        dispatch(REMOVE_GERENCIADOR_EXPANDED_ROW_OPTION('OFFERPRICE_COMPETITORS'));
    }, [dispatch]);

    const handleUpdateCampo = useCallback(
        (name: string, value: number, rowData: RowData, index: number) => {
            const { offer, price: basePrice } = rowData;

            if (!offer) return;

            let updatedOffer = { ...offer };

            if (value <= 0) {
                updatedOffer = {
                    ...updatedOffer,
                    price: value,
                    newCompetitivenessPrice: 0,
                    newMargin: 0,
                    discountPercentage: 100
                };

                dispatch(
                    SET_GERENCIADOR_DATAPOINT_BY_INDEX({
                        index,
                        rowData: { ...rowData, offer: updatedOffer },
                    })
                );
                return;
            }

            switch (name) {
                case 'price': {
                    const newValues = calc.handleCalcOfferPrice(value, rowData);

                    const cpi = competitorsPrice
                        ? IPAMaths.novaCompetitividade(value, competitorsPrice)
                        : 0;

                    const discountPercentage = basePrice
                        ? IPAMaths.calcDiscountPercentage(basePrice, value)
                        : 0;

                    updatedOffer = {
                        ...updatedOffer,
                        ...newValues,
                        discountPercentage: Math.max(0, Math.min(100, discountPercentage || 0)),
                        newCompetitivenessPrice: cpi
                    };
                    break;
                }

                case 'CPI': {
                    const newValues = calc.handleCalcOfferCPI(value, rowData);
                    const discountPercentage = basePrice && newValues.price !== null

                        ? Math.max(0, Math.min(100, IPAMaths.calcDiscountPercentage(basePrice, newValues.price) as number))
                        : 0;

                    updatedOffer = {
                        ...updatedOffer,
                        ...newValues,
                        discountPercentage: Math.max(0, Math.min(100, discountPercentage))
                    };
                    break;
                }

                case 'margin': {
                    const newValues = calc.handleCalcOfferMargin(value, rowData);

                    if (basePrice && newValues.price) {
                        const discountPercentage = IPAMaths.calcDiscountPercentage(basePrice, newValues.price);
                        (newValues as any).discountPercentage = Math.max(0, Math.min(100, (discountPercentage as any)));
                    }

                    updatedOffer = {
                        ...updatedOffer,
                        ...newValues
                    };
                    break;
                }

                case 'discountPercentage': {
                    const limitedDiscount = Math.max(0, Math.min(100, value));

                    const newPrice = basePrice
                        ? IPAMaths.calcPrecoWithDiscount(basePrice, limitedDiscount)
                        : 0;

                    const newValues = calc.handleCalcOfferPrice(newPrice, rowData);



                    const newCPI = competitorsPrice
                        ? IPAMaths.novaCompetitividade(newPrice, competitorsPrice)
                        : 0;

                    updatedOffer = {
                        ...updatedOffer,
                        ...newValues,
                        discountPercentage: limitedDiscount,
                        newCompetitivenessPrice: newCPI
                    };

                    if (new Decimal(newPrice || 0).lt(0.02)) {
                        updatedOffer.newMargin = 0;
                        updatedOffer.newCompetitivenessPrice = 0;
                    }
                    break;
                }
            }

            if (
                rowData?.offer?.price !== updatedOffer.price ||
                rowData?.offer.discountPercentage !== updatedOffer.discountPercentage
            ) {
                dispatch(
                    SET_GERENCIADOR_DATAPOINT_BY_INDEX({
                        index,
                        rowData: { ...rowData, offer: updatedOffer },
                    })
                );
            }
        },
        [dispatch]
    );


    const handleBlur = useCallback(
        async (rowData: RowData) => {
            try {
                const { offer } = rowData;

                if (!offer) throw new Error('offer data not found');

                const model: SaveEditedOfferDatapoint.Data = {
                    price: offer.price,
                    productId: rowData.productId,
                    storeId: rowData.storeId,
                };

                const res = await saveEditedOfferDatapoint(model);

                return res;
            } catch {
                const error = new ExceptionSaveDatapoint({
                    productId: rowData.productId,
                    storeId: rowData.storeId,
                    storeName: rowData.storeName,
                });
                AlertWithTimestamp({ message: error.message });
            }
        },
        [dispatch],
    );
    return (
        <tr {...props} ref={ref}>
            {/* blank column to fix layout */}
            <td style={{ width: '60px' }}>
                <ExpandOfferCell rowData={rowData} theme="blue">
                    3
                </ExpandOfferCell>
            </td>
            <td style={{ minWidth: '200px' }}>
                <BaseCell>
                    <BaseCell.MainContainer>Oferta</BaseCell.MainContainer>
                </BaseCell>
            </td>
            {/* store column blank to fix layout */}
            <td style={{ width: '80px' }}></td>
            {/* pmz column blank to fix layout */}
            <td style={{ width: '78px' }}></td>
            {/* margem objetiva column blank to fix layout */}
            <td style={{ width: '78px' }}></td>
            <td style={{ width: '64px' }}>
                <QtdeCell />
            </td>
            {/* pmc column blank to fix layout */}
            <td style={{ width: '124px' }}></td>
            <td style={{ width: '124px' }}>
                {retailPrice ? (
                    <BaseCell>
                        <BaseCell.MainContainer>
                            <CurrencyFormat
                                fixedDecimalScale
                                decimalScale={2}
                                value={retailPrice}
                                displayType="text"
                                prefix="R$ "
                                decimalSeparator=","
                                thousandSeparator="."
                            />
                        </BaseCell.MainContainer>
                        <BaseCell.SubContainer>
                            <MoneyPercentageIndicator value={originalMargin} tooltip="Margem do preço vigente" />
                            <Divider vertical />
                            <CompetitivenessPriceIndicator value={competitivenessValue} />
                        </BaseCell.SubContainer>
                    </BaseCell>
                ) : (
                    <>--</>
                )}
            </td>
            <td style={{ width: '127px' }}>
                <CompetitorPriceOfferCell
                    rowData={rowData}
                />
            </td>
            <td style={{ width: '132px' }}>
                <BaseCell>
                    <BaseCell.MainContainer>
                        <InputGroup>
                            <InputAddon>R$</InputAddon>
                            <InputCurrency
                                value={price}
                                name="price"
                                decimalSeparator=","
                                thousandSeparator="."
                                precision="2"
                                allowEmpty
                                allowNegative={false}
                                onChangeEvent={(_e, _m, value) => {
                                    handleUpdateCampo('price', value, rowData, index);
                                }}
                                onBlur={(_e, _v, { hasChanges }) => {
                                    if (hasChanges) handleBlur(rowData);
                                }}
                            />
                        </InputGroup>
                    </BaseCell.MainContainer>
                    <BaseCell.SubContainer>
                        <VariationBoxIndicator value={IPAMaths.variation.price(price, retailPrice)} suffix="%" />
                    </BaseCell.SubContainer>
                </BaseCell>
            </td>
            <td style={{ width: '116px' }}>
                <BaseCell>
                    <BaseCell.MainContainer>
                        <InputGroup>
                            <InputCurrency
                                name="CPI"
                                decimalSeparator=","
                                thousandSeparator="."
                                precision="1"
                                allowEmpty
                                allowNegative={false}
                                value={newCompetitivenessPrice || null}
                                onChangeEvent={(_e, _m, value) => {
                                    handleUpdateCampo('CPI', value, rowData, index);
                                }}
                                onBlur={(_e, _v, { hasChanges }) => {
                                    if (hasChanges) handleBlur(rowData);
                                }}
                                disabled={isCpiDisabled}
                            />
                            <InputAddon>%</InputAddon>
                        </InputGroup>
                    </BaseCell.MainContainer>
                    <BaseCell.SubContainer>
                        <VariationBoxIndicator value={IPAMaths.variation.cpi(newCompetitivenessPrice, competitorsPrice)} />
                    </BaseCell.SubContainer>
                </BaseCell>
            </td>
            <td style={{ width: '116px' }}>
                <BaseCell>
                    <BaseCell.MainContainer>
                        <InputGroup>
                            <InputCurrency
                                name="margin"
                                decimalSeparator=","
                                thousandSeparator="."
                                precision="1"
                                allowEmpty
                                allowNegative
                                value={newMargin}
                                onChangeEvent={(_e, _m, value) => {
                                    handleUpdateCampo('margin', value, rowData, index);
                                }}
                                onBlur={(_e, _v, { hasChanges }) => {
                                    if (hasChanges) handleBlur(rowData);
                                }}
                            />
                            <InputAddon>%</InputAddon>
                        </InputGroup>
                    </BaseCell.MainContainer>
                    <BaseCell.SubContainer>
                        <VariationBoxIndicator value={IPAMaths.variation.margem(newMargin, originalMargin)} />
                    </BaseCell.SubContainer>
                </BaseCell>
            </td>
            <td style={{ width: '140px' }}>
                <BaseCell>
                    <BaseCell.MainContainer>
                        <InputGroup>
                            <InputCurrency
                                name="discountPercentage"
                                decimalSeparator=","
                                thousandSeparator="."
                                precision="1"
                                allowEmpty
                                allowNegative
                                value={discountPercentage}
                                onChangeEvent={(_e, _m, value) => {
                                    handleUpdateCampo(
                                        'discountPercentage',
                                        value,
                                        rowData,
                                        index,
                                    );
                                }}
                                onBlur={(_e, _v, { hasChanges }) => {
                                    if (hasChanges) handleBlur(rowData);
                                }}
                            />
                            <InputAddon className={styles.addonoffer}>% off</InputAddon>
                        </InputGroup>
                    </BaseCell.MainContainer>
                    <BaseCell.SubContainer>
                        <div className={classNames(styles.box, className)}>DESCONTO</div>
                    </BaseCell.SubContainer>
                </BaseCell>
            </td>
            <td style={{ width: '26px' }}>
                <button className={styles.closeButton} onClick={handleClose}>
                    <MdOutlineClose size={16} />
                </button>
            </td>
        </tr>
    );
});

ExpandedRowOffer.displayName = 'ExpandedRowOffer';

export default ExpandedRowOffer;
