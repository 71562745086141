import classNames from 'classnames';
import React from 'react';
import styles from './CellWrapper.module.scss';

interface CellWrapperProps {
    children: React.ReactNode;
    className?: string;
}
export const CellWrapper = ({ children, className }: CellWrapperProps) => {
    return <div className={classNames(styles.container, className)}>{children}</div>;
};
