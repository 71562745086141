import qs from 'qs';
import { trackPromise } from 'react-promise-tracker';

import api from '../../../utils/API';

export const getRegras = (params) =>
    trackPromise(
        api({
            url: `${process.env.REACT_APP_IPA_URL}/rules/search/page`,
            method: 'GET',
            expectToken: true,
            params: {
                clientId: JSON.parse(localStorage.getItem('cliente'))?.codigo,
                size: params?.size || 20,
                page: params?.page || 0,
                ...(params.type && { type: params.type }),
                ...(params.name && { name: params.name }),
                ...(params.mechanismType && {
                    searchMechanismType: params.mechanismType,
                }),
                ...(params.author && { searchAuthors: params.author }),
                ...(params.category && { 'segment.value': params.category }),
                ...(params.productDescription && {
                    productId: params.productDescription,
                }),
                ...(params.searchStoreIds && {
                    searchStoreIds: params.searchStoreIds,
                }),
                ...(params.searchCluster && {
                    searchCluster: params.searchCluster,
                }),
                ...(params.ruleStatus && { ruleStatus: params.ruleStatus }),
                ...(params.sort && { sort: params.sort }),
            },
            paramsSerializer(params) {
                return qs.stringify(params, { arrayFormat: 'repeat' });
            },
        }).then((resp) => resp.data),
    );

export const getAuthors = (params) =>
    api({
        url: `${process.env.REACT_APP_IPA_URL}/rules/authors`,
        method: 'GET',
        expectToken: true,
        params: {
            clientId: JSON.parse(localStorage.getItem('cliente'))?.codigo,
            size: params.size || 20,
            page: params.page || 0,
            ...(params.type && { type: params.type }),
            ...(params.author && { author: params.author }),
        },
        paramsSerializer(params) {
            return qs.stringify(params, { arrayFormat: 'repeat' });
        },
    }).then((resp) => resp.data);

export const getMechanisms = () =>
    api({
        url: `${process.env.REACT_APP_IPA_URL}/rules/mechanisms`,
        method: 'GET',
        expectToken: true,
    }).then((resp) => resp.data);

export const getLojas = () =>
    api({
        url: `${process.env.REACT_APP_IPA_URL}/setup/stores`,
        method: 'GET',
        expectToken: true,
        params: {
            clientId: JSON.parse(localStorage.getItem('cliente'))?.codigo,
        },
        paramsSerializer(params) {
            return qs.stringify(params, { arrayFormat: 'repeat' });
        },
    }).then((resp) => resp.data);

export const getClusters = async (query = '') => {
    try {
        const response = await api.get(`${process.env.REACT_APP_IPA_URL}/products/clusters/search`, {
            expectToken: true,
            params: { query },
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: 'repeat' });
            },
        });

        if (!response.data) {
            console.warn('Nenhum cluster encontrado');
            return [];
        }

        return response.data.map((item) => ({
            label: item.name || item.label,
            value: item.id || item.value,
        }));
    } catch (error) {
        console.error('Erro ao buscar clusters:', error);
        return [];
    }
};
