import { createColumnHelper } from '@tanstack/react-table';
import { SetupIpaStoresAndClustersRowData } from '../../service';
import * as Cells from './Cells';

const columnHelper = createColumnHelper<SetupIpaStoresAndClustersRowData>();

const columns = [
    columnHelper.accessor('store', {
        cell: Cells.Store,
        header: 'Loja',
        size: Number.MAX_SAFE_INTEGER,
        minSize: 200,
    }),
    columnHelper.accessor('storeType', {
        cell: Cells.StoreType,
        header: 'Tipo de loja',
        maxSize: 240,
        minSize: 120,
    }),
    columnHelper.accessor('cluster', {
        cell: Cells.Cluster,
        header: 'Cluster',
        maxSize: 240,
        minSize: 120,
    }),
];

export default columns;
