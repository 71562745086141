import { addDays, format } from 'date-fns';
import qs from 'qs';
import { trackPromise } from 'react-promise-tracker';
import { IGerenciadorPrecosDatapoint } from '../../../../../@types';
import { IPAMaths, api } from '../../../../../lib';
import {
    GET_ITEMS_CLUSTER_STORE_MODAL_PARAMS,
    GET_ITEMS_CLUSTER_STORE_MODAL_RESPONSE,
    UpdateProductDataDTO
} from './types';

export const transformItem = (item: IGerenciadorPrecosDatapoint): IGerenciadorPrecosDatapoint => {
    const { price, competitorsPrice, retailPrice, newMargin } = item;

    const novaCompetitividade = IPAMaths.novaCompetitividade(
        price,
        competitorsPrice as number,
    );

    const retailCpi = IPAMaths.novaCompetitividade(
        retailPrice,
        competitorsPrice as number,
    );

    return {
        ...item,
        expirationDate: format(addDays(new Date(), 1), 'yyyy-MM-dd'),
        revenueForecastSevenDays: 13000,
        novaMargem: newMargin,
        novaCompetitividade,
        retailCpi,
    };
};

export const GET_ITEMS_CLUSTER_STORE_MODAL = async (
    params: GET_ITEMS_CLUSTER_STORE_MODAL_PARAMS = {},
) => {
    const { page, size, sort, clusters, productIds } = params;
    const { data } = await trackPromise(
        api.get<GET_ITEMS_CLUSTER_STORE_MODAL_RESPONSE>('/all-products', {
            expectToken: true,
            params: {
                page,
                size,
                sort,
                productIds,
                clusters,            },
            paramsSerializer(params) {
                return qs.stringify(params, { arrayFormat: 'repeat' });
            },
        }),
    );
    return {
        ...data,
        content: data.content.map(transformItem),
    };


};

export const UPDATE_PRODUCT_CLUSTER_PRICES = async (data: UpdateProductDataDTO) => {
    return trackPromise(
        api({
            url: `${process.env.REACT_APP_IPA_URL}/all-products/update/price/modal-products`,
            method: 'POST',
            expectToken: true,
            data,
        }).then((resp) => resp),
        'update-product-price-family-modal',
    );
};
