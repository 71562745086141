import React, { useEffect } from 'react';
import { MdEmojiEvents } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../@types/RootState';
import { CompetitivenessFilterProps, setCompetitivenessFilter } from '../../../../../reducers/gerenciadorPrecos/quickActionsFilter';
import { QuickActionFilter } from '../QuickActionFilter';

const { FilterCompetitiveness } = QuickActionFilter;

const MENU_STYLE = { width: '380px', height: '180px' };

export const CompetitivenessFilter = () => {
    const dispatch = useDispatch();

    const competitivenessFilterState = useSelector((state: RootState) => {
        return state.gerenciadorPrecosReducer.quickActionsFilters.cpiRange;
    });

    const handleToggleFilter = () => {
        const obj = {
            ...competitivenessFilterState,
            isActive: !competitivenessFilterState.isActive,
        };
        dispatch(setCompetitivenessFilter(obj));
    };

    const handleCloseFilter = () => {
        const obj = {
            ...competitivenessFilterState,
            isActive: true,
        };

        dispatch(setCompetitivenessFilter(obj));
    };

    const handleChange = (paramName: keyof CompetitivenessFilterProps, value: any) => {
        const obj = { ...competitivenessFilterState, [paramName]: value };
        dispatch(setCompetitivenessFilter(obj));
    };

    const handleOpen = () => {
        const margin = { ...competitivenessFilterState, isActive: false };
        dispatch(setCompetitivenessFilter(margin));
    };

    useEffect(() => {
        const competitivenessFilterState = sessionStorage.getItem('gerenciador-competitiveness-filter');

        if (competitivenessFilterState) {
            const competitivenessFilterParsed = JSON.parse(competitivenessFilterState);
            dispatch(setCompetitivenessFilter(competitivenessFilterParsed));
        }
    }, []);

    return (
        <FilterCompetitiveness
            placeholder="Filtrar por Competitividade"
            icon={<MdEmojiEvents />}
            menuStyle={MENU_STYLE}
            onClick={handleToggleFilter}
            onChangeValue={handleChange}
            onClose={handleCloseFilter}
            onOpen={handleOpen}
            state={competitivenessFilterState}
        />
    );
};
