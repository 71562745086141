import * as React from 'react';

interface TableIconProps extends React.SVGAttributes<SVGElement> {
    defaultColor: string;
}
export const TableIcon = ({ defaultColor, ...props }: TableIconProps) => {
    return (
        <svg width={12} height={13} viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M9.063 3.5H2.936A.939.939 0 002 4.438V9c0 .517.42.938.938.938h6.124C9.58 9.938 10 9.517 10 9V4.437a.939.939 0 00-.938-.937zM4.436 9.313h-1.5A.313.313 0 012.626 9v-.969h1.813v1.281zm0-1.907H2.625V6.031h1.813v1.375zm0-2H2.625v-.968c0-.173.14-.313.313-.313h1.5v1.281zm2.516 3.907h-1.89V8.03h1.89v1.281zm0-1.907h-1.89V6.031h1.89v1.375zm0-2h-1.89V4.125h1.89v1.281zM9.375 9c0 .172-.14.313-.313.313H7.579V8.03h1.797V9zm0-1.594H7.578V6.031h1.797v1.375zm0-2H7.578V4.125h1.484c.173 0 .313.14.313.313v.968z"
                fill={defaultColor || props.fill}
            />
        </svg>
    );
};
