import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import GetAppIcon from '@material-ui/icons/GetApp';
import React, { useEffect, useState } from 'react';
import { useParams, withRouter } from 'react-router-dom';
import { Button, Container, Content, FlexboxGrid } from 'rsuite';
import { LoadingSpiner } from '../../../../../components/LoadingSpinner';
import { listBlocos } from '../BlocoDeOferta/services';
import Header from '../Components/Header';
import Steps from '../Components/Steps';
import { getLojasParticipantes } from '../LojasParticipantes/services';
import { getOfertas } from '../Ofertas/services';
import { getCampaign } from '../services';
import { extracaoPrecosCampanha } from './services';

const Extracao = ({ history }) => {
    const { id } = useParams();
    const [campanha, setCampanha] = useState({});
    const [lojasParticipantes, setLojasParticipantes] = useState({
        content: [],
    });
    const [blocosOfertas, setBlocosOfertas] = useState({});
    const [ofertas, setOfertas] = useState({ content: [] });

    const extrair = () => {
        extracaoPrecosCampanha(id).then(() => {
            console.log('ok');
        });
    };

    const back = () => {
        history.push(`/ipa/campanhas/cadastro/dados-campanha/${id}`);
    };

    const goToOfertas = () => {
        history.push(`/ipa/campanhas/cadastro/ofertas/${id}`);
    };

    const listCampaign = async () => {
        const response = await getCampaign(id);
        if (response) {
            setCampanha(response.data);
        }
    };

    const listLojasParticipantes = async () => {
        const response = await getLojasParticipantes({
            id,
        });
        if (response) setLojasParticipantes(response);
    };

    const listBlocosOfertas = async () => {
        const response = await listBlocos(id);
        if (response) setBlocosOfertas(response);
    };

    const listOfertas = async () => {
        const response = await getOfertas({
            id,
        });
        if (response) {
            setOfertas(response);
        }
    };

    useEffect(() => {
        listCampaign();
        listLojasParticipantes();
        listBlocosOfertas();
        listOfertas();
    }, []);

    return (
        <Container className="main-container cadastro-campanhas-extracao-page">
            <Steps rota={history.location.pathname} campanhaId={id} status={campanha?.status} lojas={lojasParticipantes?.content?.length} />
            <LoadingSpiner size="md" />
            <Header
                history={history}
                id={id}
                name={campanha?.name}
                status={campanha?.status}
                backToCampaign={back}
                startingDate={campanha?.starting_date}
                expirationDate={campanha?.expiration_date}
                blocosOfertas={blocosOfertas?.totalElements}
                lojasParticipantes={lojasParticipantes?.totalElements}
                ofertas={ofertas?.totalElements}
            />
            <Content className="navegacao-extracao">
                <FlexboxGrid justify="space-between" colSpan={24}>
                    <FlexboxGrid.Item>
                        <Button appearance="link" onClick={goToOfertas}>
                            <ArrowBackIcon /> Voltar para Ofertas
                        </Button>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item>
                        <FlexboxGrid>
                            <FlexboxGrid.Item>
                                <Button appearance="primary" onClick={extrair} id="campaign-download-btn">
                                    <GetAppIcon /> DOWNLOAD PREÇOS
                                </Button>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </Content>
        </Container>
    );
};

export default withRouter(Extracao);
