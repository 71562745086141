import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import * as service from '../services';
import { useConsultComercialTableStore } from '../ConsultComercialTable.store';

export const useConsultComercialTableProductList = () => {
    const [ consultComercialTableParams, setConsultComercialTableParams ] = useState({page: 0, size: 20, idTabelaComercial: null})
    const {
        selectAllConsultComercialTable,
        setConsultComercialTableState,
    } = useConsultComercialTableStore();

    const getComercialTableProductList = async (params) => {
        if (params.idTabelaComercial) {
            const resp = await service.getProdutoComercialList(params);
            return resp;
        }
    };

    const {
        isLoading, isError, data, error, isFetching, refetch,
    } = useQuery(
        {
            queryKey: ['consult-comercial-table-product-list', consultComercialTableParams],
            queryFn: () => getComercialTableProductList(consultComercialTableParams),
            retry: false,
            onSuccess: (data) => {
                if (selectAllConsultComercialTable) {
                    setConsultComercialTableState('consultComercialProductItemSelectedList', data.data.content)
                }
            },
        },
    );

    const handleChangeConsultarTabelaComercialPage = (value: number) => {
        setConsultComercialTableParams({...consultComercialTableParams, page: value - 1})
    }

    const handleChangeConsultarTabelaComercialLength = (value: number) => {
        setConsultComercialTableParams({...consultComercialTableParams, page: 0, size: value})
    }

    return {
        isLoading,
        isError,
        consultComercialTableProductData: data,
        error,
        isFetching,
        consultComercialTableParams,
        setConsultComercialTableParams,
        handleChangeConsultarTabelaComercialPage,
        handleChangeConsultarTabelaComercialLength,
    };
};
