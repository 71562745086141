import InfoIcon from '@material-ui/icons/Info';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button, InputNumber, Tooltip, Whisper } from 'rsuite';
import { setGenerateReportData, setIsSpecificDates, setStateValue, setDateTypesRecorrencia } from '../../../../../actions/actionsModuloRelatorio';
import infoIcon from '../../../../../assets/icons/icon_info-gray.svg';
import SelectPill from '../../../../../components/SelectPill';
import { SelectDateRangePill } from '../Components/SelectDateRangePill';
import { brokenPeriodRange, brokenPeriodRangeEnumType, getDateIntervals, parseDateInTimezone, periodRange, setLocalDateISOFormat } from '../utils';

const DateIntervals = () => {
    const [active, setActive] = useState('7');
    const [intervalDays, setIntervalDays] = useState('0');
    const [activeBrokenPeriod, setActiveBrokenPeriod] = useState('Dias');
    const [dateRangeValue, setDateRangeValue] = useState([]);
    const [isEditSpecificDate, setIsEditSpecificDate] = useState(false);

    const { id } = useParams();

    const dispatch = useDispatch();

    const { moduloRelatorioData, editSpecificDateRangeValue } = useSelector(
        (state) => ({
            moduloRelatorioData: state.moduloRelatorioDataReducer?.moduloRelatorioData,
            editSpecificDateRangeValue: state.moduloRelatorioDataReducer?.editSpecificDateRangeValue,
        }),
        shallowEqual,
    );

    const handleDaysRange = (number) => {
        const yesterday = new Date(Date.now() - 24 * 60 * 60 * 1000);
        const day = new Date(yesterday - (+number - 1) * 24 * 60 * 60 * 1000);
        const activePill = number !== '7' && number !== '15' && number !== '30' && number !== '60' && number !== '90' ? '1' : number;
        const data = {
            dataInicio: setLocalDateISOFormat(day),
            dataFim: setLocalDateISOFormat(yesterday),
            dataEspecifica: false,
            recorrencia: true,
            isSpecificDates: false,
            editSpecificDateRangeValue: [],
        }

        setActive(activePill);
        setDateRangeValue([]);
        dispatch(setDateTypesRecorrencia(data));
        setIsEditSpecificDate(false);
    };

    const handleDateRangePicker = (value) => {
        setActive('');
        setDateRangeValue(value);
        dispatch(setGenerateReportData('dataInicio', setLocalDateISOFormat(value[0])));
        dispatch(setGenerateReportData('dataFim', setLocalDateISOFormat(value[1])));
        dispatch(setIsSpecificDates(true));
    };

    const handleEditSpecificDateRangePicker = (value) => {
        const data = {
            dataInicio: setLocalDateISOFormat(value[0]),
            dataFim: setLocalDateISOFormat(value[1]),
            dataEspecifica: true,
            recorrencia: false,
            isSpecificDates: true,
            editSpecificDateRangeValue: value,
        }
        dispatch(setDateTypesRecorrencia(data));
        setIntervalDays('0');
        setActive('');
        setIsEditSpecificDate(true);
    };

    const handleBrokenPeriod = (period) => {
        setActiveBrokenPeriod(period);
        dispatch(setGenerateReportData('quebraPeriodo', period === 'Todo período' ? 'TODO_PERIODO' : period.toUpperCase()));
    };

    const setIntervalDates = (dateA, dateB) => {
        const { isSpecificDates, diffDays } = getDateIntervals(dateA, dateB);

        if (isSpecificDates) {
            setActive('1');
            setIntervalDays(diffDays);
        } else {
            setActive(diffDays.toString());
            setDateRangeValue([]);
        }
    };

    const handleIntervalDays = (number) => {
        if (isEditSpecificDate) {
            return;
        }

        if (number > 0) {
            handleDaysRange(number);
        } else {
            setIntervalDates(moduloRelatorioData.dataInicio, moduloRelatorioData.dataFim);
        }
    };

    const handleCustomDateInterval = (value) => {
        setIntervalDays(value);
        setIsEditSpecificDate(false);
        dispatch(setStateValue('editSpecificDateRangeValue', []));
        dispatch(setGenerateReportData('recorrencia', true));
        dispatch(setGenerateReportData('dataEspecifica', false));
    };

    useEffect(() => {
        if (typeof id !== 'string') {
            handleDaysRange('7');
        }
    }, []);

    useEffect(() => {
        if (typeof id === 'string' && !isEditSpecificDate) {
            setActiveBrokenPeriod(brokenPeriodRangeEnumType[moduloRelatorioData.quebraPeriodo]);

            if (!moduloRelatorioData.dataEspecifica) {
                setIntervalDates(moduloRelatorioData.dataInicio, moduloRelatorioData.dataFim);
                dispatch(setStateValue('editSpecificDateRangeValue', []));
            } else {
                dispatch(
                    setStateValue('editSpecificDateRangeValue', [
                        parseDateInTimezone(moduloRelatorioData.dataInicio),
                        parseDateInTimezone(moduloRelatorioData.dataFim),
                    ]),
                );
                setActive('');
            }
        }
    }, [id, moduloRelatorioData, active]);

    return (
        <>
            <div className="section-period">
                <div className="section-title-wrapper">
                    <p>Período</p>
                    <Whisper placement="right" speaker={<Tooltip>Que será considerado para gerar o relatório sendo 90 dias o máximo possível</Tooltip>}>
                        <img src={infoIcon} alt="" />
                    </Whisper>
                </div>
                <div className="buttons-wrapper">
                    {periodRange.map((item) => (
                        <Button className={`inf-pill-button ${active === item ? 'active' : ''}`} key={item} onClick={() => handleDaysRange(item)}>
                            Últimos {item} dias
                        </Button>
                    ))}
                    {typeof id === 'string' ? (
                        <SelectPill
                            id="select-picker-pill"
                            className={`select-interval ${active === '1' ? 'active' : ''}`}
                            menuClassName="select-interval-menu"
                            placeholder="Intervalo personalizado"
                            searchable={false}
                            onClose={() => handleIntervalDays(intervalDays)}
                            renderExtraFooter={() => (
                                <div className="select-interval-input-wrapper">
                                    <p>Últimos</p>
                                    <InputNumber min={0} value={intervalDays} onChange={handleCustomDateInterval} />
                                    <p>dias</p>
                                </div>
                            )}
                        />
                    ) : (
                        <SelectDateRangePill
                            classNameValue={dateRangeValue.length ? 'date-range-has-value' : ''}
                            value={dateRangeValue}
                            onChange={(value) => handleDateRangePicker(value)}
                            onClean={() => handleDaysRange('7')}
                        />
                    )}
                    {typeof id === 'string' ? (
                        <SelectDateRangePill
                            classNameValue={editSpecificDateRangeValue.length ? 'date-range-has-value' : ''}
                            value={editSpecificDateRangeValue}
                            // onClean={() => handleDaysRange('7')}
                            onOk={(value) => handleEditSpecificDateRangePicker(value)}
                            rightIcon={
                                <Whisper placement="right" speaker={<Tooltip>Apenas datas dos últimos 90 dias estão disponíveis para seleção.</Tooltip>}>
                                    <InfoIcon
                                        style={{
                                            width: '16px',
                                            color: editSpecificDateRangeValue.length ? '#378EF0' : '#959595',
                                        }}
                                    />
                                </Whisper>
                            }
                        />
                    ) : null}
                </div>
            </div>
            <div>
                <div className="section-title-wrapper">
                    <p>Quebra de período</p>
                    <Whisper
                        placement="right"
                        speaker={<Tooltip>Como a coluna Período virá no relatório: se por dias ou agrupado por semanas ou meses</Tooltip>}
                    >
                        <img src={infoIcon} alt="" />
                    </Whisper>
                </div>
                <div className="buttons-wrapper">
                    {brokenPeriodRange.map((item) => (
                        <Button
                            className={`inf-pill-button ${activeBrokenPeriod === item ? 'active' : ''}`}
                            key={item}
                            onClick={() => handleBrokenPeriod(item)}
                        >
                            {item}
                        </Button>
                    ))}
                </div>
            </div>
        </>
    );
};

export default DateIntervals;
