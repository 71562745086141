import React from 'react';
import { Dropdown, Popover } from 'rsuite';

export enum DropdownEventKey {
    'edit-optimization' = 'edit-optimization',
    'delete-optimization' = 'delete-optimization',
    'result-optimization' = 'result-optimization',
}

interface MenuProps {
    onSelect: (eventKey: string, event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}
const Menu = ({ onSelect }: MenuProps) => (
    <Dropdown.Menu onSelect={onSelect}>
        <Dropdown.Item eventKey={DropdownEventKey['result-optimization']}>Acompanhar resultado</Dropdown.Item>
        <Dropdown.Item eventKey={DropdownEventKey['edit-optimization']}>Editar otimização</Dropdown.Item>
        <Dropdown.Item eventKey={DropdownEventKey['delete-optimization']}>Excluir</Dropdown.Item>
    </Dropdown.Menu>
);

interface TableOptionsProps {
    onSelect: (eventKey: string, event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

export const TableOptions = ({ onSelect, ...rest }: TableOptionsProps) => (
    <Popover {...rest} full>
        <Menu onSelect={onSelect} />
    </Popover>
);
