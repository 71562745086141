import classNames from 'classnames';
import { addDays, format } from 'date-fns';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Alert, Notification } from 'rsuite';
import { PageProps } from '../../../@types';
import { GerenciadorPrecos } from '../../../@types/GerenciadorPrecos';
import { RootState } from '../../../@types/RootState';
import { Sort } from '../../../@types/sort';
import { searchSegmentos } from '../../../actions/actionsRevisao';
import { ContextMenu, ContextMenuContent, ContextMenuItem, ContextMenuSeparator, ContextMenuTrigger, ModalPriceEventHistory } from '../../../components';
import { AlertWithTimestamp } from '../../../components/AlertWithTimestamp';
import NewFilterBox from '../../../components/NewFilterBox/NewFilterBox';
import { Exception } from '../../../data';
import { RESET_SAVED_FILTERS, closeModal, selectorDataPointsExhibitionType, setSavedFilterInputName } from '../../../reducers/gerenciadorPrecos';
import { ContextMenuState } from '../../../reducers/gerenciadorPrecos/contextMenu';
import { UPDATE_GERENCIADOR_DATAPOINT_BY_ID, UPDATE_GERENCIADOR_DATAPOINT_BY_INDEX } from '../../../reducers/gerenciadorPrecos/datapoints';
import { RESET_DATAPOINTS_CACHE } from '../../../reducers/gerenciadorPrecos/datapointsCache';
import { RESET_FILTERS_VALUE } from '../../../reducers/gerenciadorPrecos/filters/filtersValues';
import { selectorIsLayoutFixed } from '../../../reducers/gerenciadorPrecos/isLayoutFixed';
import { RESET_QUICK_ACTION_FILTER } from '../../../reducers/gerenciadorPrecos/quickActionsFilter';
import { RESET_SELECTED_DATAPOINTS } from '../../../reducers/gerenciadorPrecos/selectedDatapoints';
import { SetupFarmaFormValues } from '../../Administrador/Produtos/Components/SetupFarma/hooks/useFarma';
import { getDemandForecastInputs, getDemandForecastSimpleOutputs } from '../SimulacoesAnalises/PrevisaoDemanda/v2/services';
import {
    AlterarPrecosSchema,
    ComposicaoPrecoModal,
    CompositionRuleModal,
    DropDownOptions,
    ExcluirFiltroModal,
    ExpandedRowWrapper,
    ModalAlterarPrecos,
    ModalPrevisaoDemanda,
    RadioOptionColumns,
    SalvarFiltrosModal,
    TablePagination,
    TitleDropDownOptions,
    useCompositionRuleModal,
    usePriceCompositionModal,
} from './Components';
import { BigNumbersArea } from './Components/BigNumbersArea';
import EngineWarning from './Components/EngineWarning/EngineWarning';
import GerenciadorHeader from './Components/GerenciadorHeader/GerenciadorHeader';
import GerenciadorTableHeading from './Components/GerenciadorTableHeading/GerenciadorTableHeading';
import TableGerenciador from './TableGerenciador';
import styles from './gerenciadorPrecos.module.scss';
import { useGerenciadorPrecos, useSalvarFiltros } from './hooks';
import { onSuccessGetDatapointsRevisaoItem, salvarSimulacao, undoEditedDatapoint } from './services';
import { ChangeMassicePrice, changeMassivePrice, getPriceEventHistory } from './services_ts';
import { CREATE_MODEL_UNDO_DATAPOINT_PRICE_CHANGE, handleMoveFocusToNextRowInput } from './utils';

const MemoBigNumbers = memo(BigNumbersArea);
const MemoModalPrevisaoDemanda = memo(ModalPrevisaoDemanda);
const MemoExcluirFiltroModal = memo(ExcluirFiltroModal);
const MemoCompositionRuleModal = memo(CompositionRuleModal);
const MemoRowExpanded = memo(ExpandedRowWrapper);
const MemoPagination = memo(TablePagination);
const MemoModalAlterarPrecos = memo(ModalAlterarPrecos);
const MemoModalPriceEventHistory = memo(ModalPriceEventHistory);
const MemoSalvarFiltrosModal = memo(SalvarFiltrosModal);
const MemoComposicaoPrecoModal = memo(ComposicaoPrecoModal);

type RowData = GerenciadorPrecos.RowData;

const RevisaoPrecos = ({ history }: PageProps) => {
    const dispatch = useDispatch();

    const isLayoutFixed = useSelector(selectorIsLayoutFixed);

    const formMethodsIpaFarma = useForm<SetupFarmaFormValues>({
        defaultValues: {
            usePMCForIPAPricing: false,
        },
    });

    const datapointsExibitionType = useSelector(selectorDataPointsExhibitionType);

    const {
        modals: [modals, { onOpen: onOpenModal, onHide: onCloseModal }],
        table: {
            data: content,
            expandedRowKeys,
            dataKeyProduto,
            onChangeCheckAll,
            handleContextMenu,
            handleBlur,
            handleUpdateCampo,
            setDataKeyProduto,
            onSortColumn,
            selectedDatapoints: {
                data: selectedDatapoints,
                isAllChecked,
                isIndeterminate,
                handleCheck,
                isToggleChecked,
                handleResetState: resetSelectedDatapointsState,
            },
            ...table
        },
        bigNumbers: { variationPriceData, variationPriceLoading, totalPriceData, totalPriceLoading, updateBigNumbers, handleUpdateBigNumbers },
        contextMenuDatapoint,
        refetch,
        models,
    } = useGerenciadorPrecos();

    const reduxModals = useSelector((state: RootState) => {
        return state.gerenciadorPrecosReducer.modals;
    });

    const [showModalPrevisaoDemanda, setShowModalPrevisaoDemanda] = useState(false);

    const [previsao, setPrevisao] = useState<RowData['demandForecast']>({} as RowData['demandForecast']);

    const [previsaoSelecionada, setPrevisaoSelecionada] = useState<number | null>(null);

    const {
        isOpen: isPriceCompositionModalOpen,
        setIsOpen: setIsPriceCompositionModalOpen,
        handleOpen: handleOpenPriceCompositionModal,
        data: ipaPriceComposition,
    } = usePriceCompositionModal();

    const {
        isOpen: isCompositionRuleModalOpen,
        handleOpen: handleOpenCompositionRuleModal,
        handleClose: handleCloseCompositionRuleModal,
        data: ipaRuleComposition,
    } = useCompositionRuleModal();

    const handleSalvarSimulacao = useCallback(async () => {
        if (!previsaoSelecionada) return;
        const dataContent = content;
        const dataSimulacao = {
            product: {
                id: dataContent[previsaoSelecionada].productId,
                description: dataContent[previsaoSelecionada].description,
                store: {
                    id: dataContent[previsaoSelecionada].storeId,
                    description: dataContent[previsaoSelecionada].storeName,
                },
            },
            ...dataContent[previsaoSelecionada].demandForecast,
        };
        try {
            const resp = await salvarSimulacao(dataSimulacao);
            if (resp.status !== 201) return;
            Notification.success({
                title: 'Notificação',
                duration: 6000,
                description: (
                    <div>
                        <p>Simulação salva com sucesso.</p>
                    </div>
                ),
            });
            setShowModalPrevisaoDemanda(false);
        } catch {
            const { message } = new Exception('Erro ao salvar simulação');
            AlertWithTimestamp({ message });
        }
    }, [previsaoSelecionada, content]);

    const handleOpenModalPrevisao = useCallback(
        (previsao: RowData['demandForecast'], index: number) => {
            setPrevisao(previsao);
            setPrevisaoSelecionada(index);
            setShowModalPrevisaoDemanda(!showModalPrevisaoDemanda);
        },
        [showModalPrevisaoDemanda],
    );

    const handleDatakeysTable = useCallback(
        (dataKey: Sort['type'], sortType: Sort['orderBy']) => {
            if (dataKey) setDataKeyProduto(dataKey);
            onSortColumn(dataKey, sortType);
        },
        [setDataKeyProduto, onSortColumn],
    );

    const calcularRevisao = useCallback(
        async (rowData: RowData, index: number) => {
            const updatedRowData: Partial<RowData> = {
                loadingCalc: true,
            };

            dispatch(
                UPDATE_GERENCIADOR_DATAPOINT_BY_INDEX({
                    index,
                    data: updatedRowData,
                }),
            );

            try {
                const date = [format(new Date(), 'yyyy/MM/dd'), format(addDays(new Date(), 7), 'yyyy/MM/dd')];

                const query = {
                    product_id: rowData.productId,
                    product_name: rowData.description,
                    store_ids: [rowData.storeId],
                    from_date: new Date(date[0]),
                    to_date: new Date(date[1]),
                    new_price: rowData.price,
                };
                const input = {
                    base_price: rowData.retailPrice,
                    competitiveness: 0,
                    margin: 0,
                    new_price: rowData.price,
                };
                const data = await getDemandForecastSimpleOutputs({
                    input: [input],
                    query,
                });

                if (data) {
                    updatedRowData.demandForecast = data;
                    updatedRowData.novoCalculo = false;
                }

                updatedRowData.loadingCalc = false;

                dispatch(
                    UPDATE_GERENCIADOR_DATAPOINT_BY_INDEX({
                        index,
                        data: updatedRowData,
                    }),
                );
            } catch {
                const { message } = new Exception('Erro ao calcular revisão');
                AlertWithTimestamp({ message });
            }
        },
        [dispatch],
    );

    const goToPrevisao = useCallback(async () => {
        if (!previsaoSelecionada) return;
        const item = content[previsaoSelecionada];

        const date = [format(new Date(), 'yyyy/MM/dd'), format(addDays(new Date(), 7), 'yyyy/MM/dd')];

        const query = {
            productId: item.productId,
            productName: item.description,
            storeIds: [item.storeId],
            fromDate: new Date(date[0]),
            toDate: new Date(date[1]),
            newPrice: item.demandForecast?.new_price_scenario?.price || 0,
        };

        const { input } = await getDemandForecastInputs({
            query,
        });

        const inputMapped = {
            value: item?.demandForecast?.base_price_scenario?.price || input.base_price,
            newPrice: item?.demandForecast?.new_price_scenario?.price || input.new_price,
            priceCanGetNewInput: false,
            margin: input.margin,
            competitiveness: input.competitiveness,
        };

        localStorage.setItem('setInputsValueByGerenciador', JSON.stringify(inputMapped));

        const baseURL = `/ipa/simulacoes-analises/previsao-demanda-v2`;

        const url = `${baseURL}?productId=${item.productId}&storesId=${item.storeId}&price=${item.price}&filterDate=${date[0]}-${date[1]}&source=gerenciador`;
        window.open(url, '_blank');
    }, [previsaoSelecionada, content, dispatch, history]);

    const handleSuccessUndoEditedDatapoint = useCallback(
        (rowData: RowData) => {
            const data = onSuccessGetDatapointsRevisaoItem(rowData);
            const { productsToBePricedId } = data;

            const payload = {
                id: productsToBePricedId,
                datapointExhibitionType: datapointsExibitionType ?? "PRODUCT",
                itemsCountFamily: rowData.itemsCountFamily,
                data,
            };
            dispatch(UPDATE_GERENCIADOR_DATAPOINT_BY_ID(payload));
            Alert.success('Alteração de preço desfeita com sucesso');
        },
        [dispatch],
    );

    const handleUndoEditedDatapoint = useCallback(
        async (contextMenu: ContextMenuState) => {
            if (!contextMenu) return;
            const model = CREATE_MODEL_UNDO_DATAPOINT_PRICE_CHANGE(contextMenu, contextMenu.itemsCountFamily ?? 0, datapointsExibitionType);
            try {
                const { status, data } = await undoEditedDatapoint(model);
                if (status !== 200) return;
                handleSuccessUndoEditedDatapoint(data);
            } catch {
                const { message } = new Exception('Erro ao desfazer alteração');
                AlertWithTimestamp({ message });
            }
        },
        [handleSuccessUndoEditedDatapoint, datapointsExibitionType],
    );

    useEffect(() => {
        searchSegmentos();
    }, []);

    const { salvarFiltros, editarFiltros, excluirFiltroSalvo } = useSalvarFiltros(models);

    const handleLimparFiltros = useCallback(() => {
        dispatch(RESET_QUICK_ACTION_FILTER());
        dispatch(RESET_SELECTED_DATAPOINTS());
        dispatch(RESET_DATAPOINTS_CACHE());
        dispatch(RESET_FILTERS_VALUE());
        dispatch(RESET_SAVED_FILTERS());
    }, [dispatch]);

    useEffect(() => {
        const tableContainer = document.getElementById('table-revisao-precos');
        if (tableContainer) {
            const handleKeyDown = (e: KeyboardEvent) => {
                handleMoveFocusToNextRowInput(e);
            };

            tableContainer.addEventListener('keydown', handleKeyDown);

            return () => {
                tableContainer.removeEventListener('keydown', handleKeyDown);
            };
        }
    }, []);

    const handleChangeMassivePrice = useCallback(
        async (formData: AlterarPrecosSchema) => {
            const filters = models as unknown as ChangeMassicePrice['filters'];

            const model: ChangeMassicePrice = {
                ...formData,
                filters,
                products: {
                    selectedIds: selectedDatapoints.selectedIds,
                    excludedIds: selectedDatapoints.excludedIds,
                },
                selectedAll: selectedDatapoints.selectedAll,
            };

            try {
                const data = await changeMassivePrice(model);

                if (data) {
                    onCloseModal('alterar-precos');

                    resetSelectedDatapointsState();

                    refetch();

                    if (data.productsFailed) {
                        const { message } = new Exception(
                            `Houve uma falha na aplicação de preços de ${data.productsFailed} produtos. Os demais foram aplicados normalmente.`,
                        );
                        AlertWithTimestamp({ message });
                        return;
                    }

                    Notification.success({
                        description: 'Preços alterados!',
                    });
                }
            } catch (err) {
                const { message } = new Exception('Erro durante a alteração de preços');
                AlertWithTimestamp({ message });
            }
        },
        [models, selectedDatapoints, onCloseModal, resetSelectedDatapointsState, refetch],
    );

    return (
        <>
            <main className={classNames('revisao-page', styles['layout'], isLayoutFixed && styles['layout-fixed'], styles['gerenciador-page'])}>
                <section className={styles['layout__fixed']}>
                    <GerenciadorHeader />
                    <div className={styles['layout__fixed__content']}>
                        <EngineWarning />
                        <MemoBigNumbers
                            variationPriceData={variationPriceData}
                            totalPriceData={totalPriceData}
                            variationLoading={variationPriceLoading}
                            totalLoading={totalPriceLoading}
                            update={updateBigNumbers}
                            onClickUpdate={handleUpdateBigNumbers}
                            className={styles['gerenciador-page__big-numbers']}
                        />
                        <NewFilterBox />
                        <GerenciadorTableHeading />
                    </div>
                </section>

                <section className={classNames(isLayoutFixed && 'hide-scroll', isLayoutFixed && styles['layout__scroll'])}>
                    <div style={{ height: 0 }}>
                        <DropDownOptions styleClass="dropdown-option-revisao" title={<TitleDropDownOptions />}>
                            <RadioOptionColumns sortColumn={dataKeyProduto} sort={handleDatakeysTable} />
                        </DropDownOptions>
                    </div>
                    <FormProvider {...formMethodsIpaFarma}>
                        <ContextMenu>
                            <ContextMenuTrigger>
                                <TableGerenciador
                                    data={content}
                                    isAllChecked={isAllChecked}
                                    isIndeterminate={isIndeterminate}
                                    dataKeyProduto={dataKeyProduto}
                                    handleContextMenu={handleContextMenu}
                                    onChangeCheckAll={onChangeCheckAll}
                                    handleBlur={handleBlur}
                                    handleCheck={handleCheck}
                                    handleUpdateCampo={handleUpdateCampo}
                                    isToggleChecked={isToggleChecked}
                                    expandedRowKeys={expandedRowKeys}
                                    calcularRevisao={calcularRevisao}
                                    onSortColumn={onSortColumn}
                                    handleOpenModalPrevisao={handleOpenModalPrevisao}
                                    renderRowExpanded={(rowData) => <MemoRowExpanded rowData={rowData} />}
                                    {...table}
                                />
                            </ContextMenuTrigger>
                            {contextMenuDatapoint && (
                                <ContextMenuContent>
                                    {contextMenuDatapoint?.priceType?.match(/EDITADO/) && (
                                        <>
                                            <ContextMenuItem
                                                onClick={() => {
                                                    handleUndoEditedDatapoint(contextMenuDatapoint);
                                                }}
                                            >
                                                Desfazer alteração de preço
                                            </ContextMenuItem>
                                            <ContextMenuSeparator />
                                        </>
                                    )}
                                    <ContextMenuItem
                                        onClick={() => {
                                            handleOpenPriceCompositionModal(
                                                contextMenuDatapoint.productId,
                                                contextMenuDatapoint.storeId,
                                                contextMenuDatapoint.clusterId ?? undefined
                                            );
                                        }}
                                    >
                                        Composição do preço
                                    </ContextMenuItem>
                                    {contextMenuDatapoint?.ruleId && (
                                        <ContextMenuItem
                                            onClick={() => {
                                                handleOpenCompositionRuleModal(contextMenuDatapoint.ruleId, contextMenuDatapoint.productsToBePricedId);
                                            }}
                                        >
                                            Regra aplicada ao produto
                                        </ContextMenuItem>
                                    )}
                                    <ContextMenuSeparator />
                                    <ContextMenuItem
                                        onClick={() => {
                                            onOpenModal('price-event-history');
                                        }}
                                    >
                                        Resumo de alteração de preço
                                    </ContextMenuItem>
                                </ContextMenuContent>
                            )}
                        </ContextMenu>
                    </FormProvider>
                    <MemoPagination />
                </section>
            </main>

            <MemoModalPrevisaoDemanda
                handleSalvarSimulacao={handleSalvarSimulacao}
                goToPrevisao={goToPrevisao}
                show={showModalPrevisaoDemanda}
                closeModal={() => setShowModalPrevisaoDemanda(false)}
                previsao={previsao}
            />
            <MemoComposicaoPrecoModal
                show={isPriceCompositionModalOpen}
                onHide={() => setIsPriceCompositionModalOpen(false)}
                priceComposition={ipaPriceComposition}
            />
            <MemoSalvarFiltrosModal
                show={reduxModals.salvarFiltros.isOpen}
                actions={{
                    POST: salvarFiltros,
                    PUT: editarFiltros,
                }}
                onHide={() => {
                    dispatch(
                        closeModal({
                            name: 'salvarFiltros',
                        }),
                    );
                }}
                onChange={(value) => {
                    dispatch(
                        setSavedFilterInputName({
                            value: value as string,
                        }),
                    );
                }}
            />
            <MemoExcluirFiltroModal
                show={reduxModals.excluirFiltro.isOpen}
                onConfirm={() => {
                    excluirFiltroSalvo(handleLimparFiltros);
                }}
                onHide={() => {
                    dispatch(
                        closeModal({
                            name: 'excluirFiltro',
                        }),
                    );
                }}
            />
            <MemoCompositionRuleModal
                show={isCompositionRuleModalOpen}
                onHide={handleCloseCompositionRuleModal}
                ruleComposition={ipaRuleComposition}
                history={history}
            />
            <MemoModalAlterarPrecos show={modals['alterar-precos']} onHide={() => onCloseModal('alterar-precos')} onSubmit={handleChangeMassivePrice} />
            <MemoModalPriceEventHistory
                show={modals['price-event-history']}
                onHide={() => onCloseModal('price-event-history')}
                queryFn={
                    contextMenuDatapoint?.productsToBePricedId
                        ? () => {
                            return getPriceEventHistory(contextMenuDatapoint.productsToBePricedId);
                        }
                        : undefined
                }
            />
        </>
    );
};

export default withRouter(RevisaoPrecos);
