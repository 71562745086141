import { Slot } from '@radix-ui/react-slot';
import cn from 'classnames';
import React from 'react';
import styles from './BaseCell.module.scss';

export type BaseCellProps = React.ComponentPropsWithoutRef<'div'> & { asChild?: boolean };

const BaseCell = ({ asChild, className, ...props }: BaseCellProps) => {
    const Comp = asChild ? Slot : 'td';
    return <Comp className={cn(styles.container, className)} {...props} />;
};

const MainContainer = ({ className, ...props }: React.HtmlHTMLAttributes<HTMLDivElement>) => {
    return <div className={cn(styles.container__main, className)} {...props} />;
};

const SubContainer = ({ className, ...props }: React.HtmlHTMLAttributes<HTMLDivElement>) => {
    return <div className={cn(styles.container__sub, className)} {...props} />;
};

BaseCell.MainContainer = MainContainer;
BaseCell.SubContainer = SubContainer;

export default BaseCell;
