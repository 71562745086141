import React from 'react';
import { InputGroup, Input } from 'rsuite';
import styles from './LabelInputGroup.module.scss';
import { InputCurrency } from '../../../../../components';

type LabelInputGroupProps = {
    label?: string,
    subLabel?: string,
    symbol?: string,
    value?: number | null,
    disabled?: boolean,
    onChange?: (arg0: string) => void,
}

export const LabelInputGroup = ({
    label,
    subLabel,
    value = null,
    onChange,
    disabled,
    symbol,
    ...props
}: LabelInputGroupProps) => {
    return (
        <div className={styles.row}>
            <div className={styles['label-wrapper']}>
                <span className={styles.label}>{label}</span>
                {subLabel ? <span className={styles.label}>{subLabel}</span> : null}
            </div>
            <InputGroup>
                <InputCurrency
                    value={value}
                    className={styles['currency-input']}
                    disabled={disabled}
                    {...props}
                />
                <InputGroup.Addon>{symbol}</InputGroup.Addon>
            </InputGroup>
        </div>
    );
};
