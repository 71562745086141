import React from 'react';
import { Button, Divider, Modal, Panel } from 'rsuite';

import './style.scss';

export const ModalVideos = ({ show, onHide, videosData }) => (
    <Modal show={show} onHide={onHide} className="modal-videos">
        <Modal.Header className="modal-videos-title">
            Entenda como funciona o Módulo de Competitividade
        </Modal.Header>
        {videosData.map((item, index) => (
            <div className="modal-panel" key={index}>
                <Panel
                    className="modal-content-panel"
                    header={item.title}
                    collapsible
                    bordered
                    defaultExpanded={index === 0}
                >
                    {item.links}
                </Panel>
            </div>
        ))}
        <Divider className="divider-modal-videos" />
        <Button
            className="button-modal-videos"
            appearance="primary"
            onClick={onHide}
        >
            FECHAR
        </Button>
    </Modal>
);

export default ModalVideos;
