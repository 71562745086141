import React from 'react';
import { Alert, Button, Modal } from 'rsuite';

import { useQueryClient } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';
import { optimizationPricesListSelector } from '../../../../../../../reducers/OtimizacaoPrecos';
import { toggleModal } from '../../../../../../../reducers/OtimizacaoPrecos/optimizationPricesList';
import { deleteOptimization } from '../../../../services';
import styles from './ModalConfirmAction.module.scss';

const { Header, Title, Body, Footer } = Modal;

type ModalConfirmActionProps = {
    title?: string;
    description?: string;
};

export const ModalConfirmAction = ({
    title = 'Excluir otimização',
    description = 'Excluir a otimização irá interromper o cálculo de preços otimizados. Você confirma essa ação?',
}: ModalConfirmActionProps) => {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const { modals } = useSelector(optimizationPricesListSelector);

    const onHide = () => {
        dispatch(toggleModal({ name: 'delete' }));
    };

    const handleConfirme = () => {
        onHide();
        if (!modals.delete.id) return;
        deleteOptimization(modals.delete.id).then(() => {
            Alert.success('Otimização excluída com sucesso');
            queryClient.invalidateQueries({ exact: false, queryKey: ['optimization-prices-grid'] });
        });
    };

    return (
        <Modal className={styles.modal} show={modals.delete.isOpen}>
            <Header onHide={onHide}>
                <Title>{title}</Title>
            </Header>
            <Body>
                <p>{description}</p>
            </Body>
            <Footer>
                <Button className={styles['btn-cancel']} appearance="ghost" onClick={onHide}>
                    Cancelar
                </Button>
                <Button id="btn-save-scenario" className={styles['btn-main']} appearance="primary" onClick={handleConfirme}>
                    Confirmar
                </Button>
            </Footer>
        </Modal>
    );
};
