import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { enterAdminArea } from '../actions/actionsAdmin';
import { changeMenuActiveKey, changeSideMenu } from '../actions/actionsMenu';
import { changeActiveKey } from '../actions/actionsNavbar';
import { changeActiveKeyAdmin } from '../actions/actionsNavbarAdmin';

const RouteChange = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    const menuChanged = (pathname) => {
        if (pathname === '/isa/historico-preco') {
            dispatch(enterAdminArea(false));
            dispatch(changeActiveKey('1'));
            dispatch(changeSideMenu('1'));
            dispatch(changeMenuActiveKey('1'));
        }
        if (pathname === '/isa/extracao-dados') {
            dispatch(enterAdminArea(false));
            dispatch(changeActiveKey('1'));
            dispatch(changeSideMenu('1'));
            dispatch(changeMenuActiveKey('3'));
        }
        if (pathname === '/isa/download-relatorio') {
            dispatch(enterAdminArea(false));
            dispatch(changeActiveKey('1'));
            dispatch(changeSideMenu('1'));
            dispatch(changeMenuActiveKey('2'));
        }
        if (pathname === '/isa/gestao-retorno') {
            dispatch(enterAdminArea(false));
            dispatch(changeActiveKey('1'));
            dispatch(changeSideMenu('1'));
            dispatch(changeMenuActiveKey('4'));
        }
        if (pathname === '/isa/revisao-lista') {
            dispatch(enterAdminArea(false));
            dispatch(changeActiveKey('1'));
            dispatch(changeSideMenu('1'));
            dispatch(changeMenuActiveKey('6'));
        }
        if (pathname === '/isa/lojas-bloqueadas') {
            dispatch(enterAdminArea(false));
            dispatch(changeActiveKey('1'));
            dispatch(changeSideMenu('1'));
            dispatch(changeMenuActiveKey('7'));
        }
        if (pathname && pathname.includes('/acompanhamento')) {
            dispatch(enterAdminArea(false));
            dispatch(changeActiveKey('3'));
            dispatch(changeSideMenu('3'));
        }
        if (pathname === '/isa/acompanhamento-coletas') {
            dispatch(enterAdminArea(false));
            dispatch(changeActiveKey('1'));
            dispatch(changeSideMenu('1'));
            dispatch(changeMenuActiveKey('5'));
        }
        if (pathname && pathname.includes('/isa/acompanhamento-coletas/detalhe')) {
            dispatch(enterAdminArea(false));
            dispatch(changeActiveKey('1'));
            dispatch(changeSideMenu('1'));
            dispatch(changeMenuActiveKey('5'));
        }
        if (pathname && pathname.includes('/analise')) {
            dispatch(enterAdminArea(false));
            dispatch(changeActiveKey('3'));
            dispatch(changeSideMenu('3'));
        }
        if (pathname === '/isa/painel-geral') {
            dispatch(enterAdminArea(false));
            dispatch(changeActiveKey('4'));
            dispatch(changeSideMenu('4'));
            dispatch(changeMenuActiveKey('1'));
        }
        if (pathname === '/isa/painel-geral-free') {
            dispatch(enterAdminArea(false));
            dispatch(changeActiveKey('4'));
            dispatch(changeSideMenu('4'));
            dispatch(changeMenuActiveKey('1'));
        }
        if (pathname === '/isa/solicitacao-ondemand') {
            dispatch(enterAdminArea(false));
            dispatch(changeActiveKey('4'));
            dispatch(changeSideMenu('4'));
            dispatch(changeMenuActiveKey('2'));
        }
        if (pathname === '/isa/solicitacao-ondemand/sistema') {
            dispatch(enterAdminArea(false));
            dispatch(changeActiveKey('4'));
            dispatch(changeSideMenu('4'));
            dispatch(changeMenuActiveKey('2'));
        }
        if (pathname === '/isa/modulo-relatorio') {
            dispatch(enterAdminArea(false));
            dispatch(changeActiveKey('4'));
            dispatch(changeSideMenu('4'));
            dispatch(changeMenuActiveKey('3'));
        }
        if (pathname === '/isa/analise-competitividade/varejo') {
            dispatch(enterAdminArea(false));
            dispatch(changeActiveKey('4'));
            dispatch(changeSideMenu('4'));
            dispatch(changeMenuActiveKey('4'));
        }
        if (pathname === '/isa/analise-competitividade/produtos') {
            dispatch(enterAdminArea(false));
            dispatch(changeActiveKey('4'));
            dispatch(changeSideMenu('4'));
            dispatch(changeMenuActiveKey('4'));
        }
        if (pathname === '/isa/lojas-concorrentes') {
            dispatch(enterAdminArea(false));
            dispatch(changeActiveKey('4'));
            dispatch(changeSideMenu('4'));
            dispatch(changeMenuActiveKey('5'));
        }
        if (pathname && pathname.includes('/ipa/precificacao-produto')) {
            dispatch(enterAdminArea(false));
            dispatch(changeActiveKey('5'));
            dispatch(changeSideMenu('6'));
            dispatch(changeMenuActiveKey('2-1'));
        }
        if (pathname === '/ipa/historico-preco') {
            dispatch(enterAdminArea(false));
            dispatch(changeActiveKey('5'));
            dispatch(changeSideMenu('6'));
            dispatch(changeMenuActiveKey('6'));
        }
        if (pathname === '/ipa/extracao-preco') {
            dispatch(enterAdminArea(false));
            dispatch(changeActiveKey('5'));
            dispatch(changeSideMenu('6'));
            dispatch(changeMenuActiveKey('5'));
        }
        if (pathname && pathname.includes('/ipa/regras-preco')) {
            dispatch(enterAdminArea(false));
            dispatch(changeActiveKey('5'));
            dispatch(changeSideMenu('6'));
            dispatch(changeMenuActiveKey('1-1'));
        }
        if (pathname && pathname.includes('/ipa/limites-preco')) {
            dispatch(enterAdminArea(false));
            dispatch(changeActiveKey('5'));
            dispatch(changeSideMenu('6'));
            dispatch(changeMenuActiveKey('1-2'));
        }
        if (pathname && pathname.includes('/ipa/regras-atacado')) {
            dispatch(enterAdminArea(false));
            dispatch(changeActiveKey('5'));
            dispatch(changeSideMenu('6'));
            dispatch(changeMenuActiveKey('1-3'));
        }
        if (pathname && pathname.includes('/ipa/gerenciador-de-precos')) {
            dispatch(enterAdminArea(false));
            dispatch(changeActiveKey('5'));
            dispatch(changeSideMenu('6'));
            dispatch(changeMenuActiveKey('4'));
        }
        if (pathname && pathname.includes('/ipa/otimizacao-de-precos/lista')) {
            dispatch(enterAdminArea(false));
            dispatch(changeActiveKey('5'));
            dispatch(changeSideMenu('6'));
            dispatch(changeMenuActiveKey('10'));
        }
        if (pathname && pathname.includes('/ipa/otimizacao-de-precos')) {
            dispatch(enterAdminArea(false));
            dispatch(changeActiveKey('5'));
            dispatch(changeSideMenu('6'));
            dispatch(changeMenuActiveKey('10'));
        }
        if (pathname && pathname.includes('/ipa/simulacoes-analises')) {
            dispatch(enterAdminArea(false));
            dispatch(changeActiveKey('5'));
            dispatch(changeSideMenu('6'));
            dispatch(changeMenuActiveKey('9'));
        }
        if (pathname && pathname.includes('/ipa/campanhas')) {
            dispatch(enterAdminArea(false));
            dispatch(changeActiveKey('5'));
            dispatch(changeSideMenu('6'));
            dispatch(changeMenuActiveKey('8'));
        }
        if (pathname && pathname.includes('/ipa/preco-psicologico')) {
            dispatch(enterAdminArea(false));
            dispatch(changeActiveKey('5'));
            dispatch(changeSideMenu('6'));
            dispatch(changeMenuActiveKey('1-3'));
        }
        if (pathname && pathname.includes('/ipa/tabela-precos')) {
            dispatch(enterAdminArea(false));
            dispatch(changeActiveKey('5'));
            dispatch(changeSideMenu('6'));
            dispatch(changeMenuActiveKey('1-4'));
        }
        if (pathname === '/ipa/estrategia/margem-objetiva') {
            dispatch(enterAdminArea(false));
            dispatch(changeActiveKey('5'));
            dispatch(changeSideMenu('6'));
            dispatch(changeMenuActiveKey('1-5'));
        }
        if (pathname === '/ipa/estrategia/preco-atacado') {
            dispatch(enterAdminArea(false));
            dispatch(changeActiveKey('5'));
            dispatch(changeSideMenu('6'));
            dispatch(changeMenuActiveKey('1-6'));
        }
        if (pathname && pathname.includes('/ipa/negociacoes/fornecedor')) {
            dispatch(enterAdminArea(false));
            dispatch(changeActiveKey('5'));
            dispatch(changeSideMenu('6'));
            dispatch(changeMenuActiveKey('3-1'));
        }
        if (pathname && pathname.includes('/ipa/negociacoes/salvas')) {
            dispatch(enterAdminArea(false));
            dispatch(changeActiveKey('5'));
            dispatch(changeSideMenu('6'));
            dispatch(changeMenuActiveKey('3-2'));
        }
        if (pathname && pathname.includes('/ipa/lite/gerenciador-de-precos')) {
            dispatch(enterAdminArea(false));
            dispatch(changeActiveKey('5'));
            dispatch(changeSideMenu('8'));
            dispatch(changeMenuActiveKey('4'));
        }
        if (pathname && pathname.includes('/painel-abras')) {
            dispatch(enterAdminArea(false));
            dispatch(changeActiveKey('6'));
            dispatch(changeSideMenu('5'));
        }
        if (pathname && pathname.includes('/administrador/pessoas')) {
            dispatch(enterAdminArea(true));
            dispatch(changeActiveKeyAdmin('1'));
        }
        if (pathname && pathname.includes('/administrador/produtos')) {
            dispatch(enterAdminArea(true));
            dispatch(changeActiveKeyAdmin('2'));
        }
        if (pathname && pathname.includes('/upload')) {
            dispatch(enterAdminArea(false));
        }

        if (pathname === '/ira') {
            dispatch(enterAdminArea(false));
            dispatch(changeActiveKey('2'));
            dispatch(changeSideMenu('7'));
            dispatch(changeMenuActiveKey('1'));
        }
        if (pathname === '/ira/competitividade-geral') {
            dispatch(enterAdminArea(false));
            dispatch(changeActiveKey('2'));
            dispatch(changeSideMenu('7'));
            dispatch(changeMenuActiveKey('2'));
        }
        if (pathname === '/ira/margem-praticada-geral') {
            dispatch(enterAdminArea(false));
            dispatch(changeActiveKey('2'));
            dispatch(changeSideMenu('7'));
            dispatch(changeMenuActiveKey('3'));
        }
        if (pathname === '/ira/vendas-gerais') {
            dispatch(enterAdminArea(false));
            dispatch(changeActiveKey('2'));
            dispatch(changeSideMenu('7'));
            dispatch(changeMenuActiveKey('4'));
        }
        if (pathname === '/ipa/calculadora-demanda') {
            dispatch(enterAdminArea(false));
            dispatch(changeActiveKey('7'));
            dispatch(changeSideMenu('9'));
            dispatch(changeMenuActiveKey('1'));
        }
        if (pathname === '/ipa/cenarios-salvos') {
            dispatch(enterAdminArea(false));
            dispatch(changeActiveKey('7'));
            dispatch(changeSideMenu('9'));
            dispatch(changeMenuActiveKey('2'));
        }
        if (pathname === '/ipa/comparacao-cenarios') {
            dispatch(enterAdminArea(false));
            dispatch(changeActiveKey('7'));
            dispatch(changeSideMenu('9'));
            dispatch(changeMenuActiveKey('2'));
        }
        if (pathname === '/ipa/plataforma-editar-tabela-base') {
            dispatch(enterAdminArea(false));
            dispatch(changeActiveKey('7'));
            dispatch(changeSideMenu('10'));
            dispatch(changeMenuActiveKey('2'));
        }
        if (pathname === '/ipa/criar-tabela-comercial') {
            dispatch(enterAdminArea(false));
            dispatch(changeActiveKey('7'));
            dispatch(changeSideMenu('10'));
            dispatch(changeMenuActiveKey('3'));
        }
        if (pathname === '/ipa/tabela-base-atual') {
            dispatch(enterAdminArea(false));
            dispatch(changeActiveKey('7'));
            dispatch(changeSideMenu('10'));
            dispatch(changeMenuActiveKey('4'));
        }
        if (pathname === '/ipa/lista-tabelas-preco') {
            dispatch(enterAdminArea(false));
            dispatch(changeActiveKey('7'));
            dispatch(changeSideMenu('10'));
            dispatch(changeMenuActiveKey('3'));
        }
        if (pathname === '/ipa/gerenciar-tabela-comercial') {
            dispatch(enterAdminArea(false));
            dispatch(changeActiveKey('7'));
            dispatch(changeSideMenu('10'));
            dispatch(changeMenuActiveKey('1'));
        }
        if (pathname === '/ipa/consultar-tabela-comercial') {
            dispatch(enterAdminArea(false));
            dispatch(changeActiveKey('7'));
            dispatch(changeSideMenu('10'));
            dispatch(changeMenuActiveKey('7'));
        }
    };

    useEffect(() => {
        menuChanged(location.pathname);
    });

    useEffect(() => {
        history.listen((l) => {
            menuChanged(l.pathname);
        });
    }, [history]);

    return null;
};

export default RouteChange;
