import React from 'react';

import { MdArrowBack } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Button } from 'rsuite';
import { clearSavedScenarios } from '../../../../../../../../reducers/previsaoDemanda/demandForecastSimulations';
import { resetFilters } from '../../../../../../../../reducers/previsaoDemanda/filterArea';
import { clearInputDataAndResetList } from '../../../../../../../../reducers/previsaoDemanda/inputArea';
import { clearOutputData } from '../../../../../../../../reducers/previsaoDemanda/outputArea';
import styles from './HeaderSavedSimulations.module.scss';

interface HeaderSavedSimulationsProps {
    labelToBack: string;
    urlToBack: string;
    title: string;
    subtitle?: string;
}
export const HeaderSavedSimulations = ({ title, subtitle, urlToBack, labelToBack }: HeaderSavedSimulationsProps) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const goBack = () => {
        dispatch(clearInputDataAndResetList());
        dispatch(resetFilters());
        dispatch(clearOutputData());
        dispatch(clearSavedScenarios());
        history.push('/ipa/simulacoes-analises/previsao-demanda-v2/');
    };

    return (
        <>
            <div className={styles['header-saved-simulation']}>
                <Link to={urlToBack} className={styles.back}>
                    <MdArrowBack className={styles.icon} />
                    <span>{labelToBack}</span>
                </Link>
                <Button id="simulate-scenario-results" appearance="primary" className={styles.button} onClick={goBack}>
                    fazer nova simulação
                </Button>
            </div>

            <header className={styles.header}>
                <h1>{title}</h1>
                <p>{subtitle}</p>
            </header>
        </>
    );
};
