import React from 'react';

import { IoMdTrash } from 'react-icons/io';
import { MdDownload } from 'react-icons/md';

import { Button } from 'rsuite';
import { useHandleScenarios } from '../../../hooks/useHandleScenarios';
import { ModalConfirmAction } from '../../ModalConfirmAction';
import styles from './ResultAndActionArea.module.scss';
export const ResultAndActionArea = () => {
    const { handleShowConfirmActionModal, closeAllModals, handleDeleteScenario, handleDownloadSelectedScenarios, handleCompareScenarios, props } =
        useHandleScenarios();

    const { disabled, disabledMainButton, simulationsCount, simulationsSelected, modal } = props;

    const showTotalSimulations = simulationsCount > 0 && simulationsSelected === 0;
    const showSelectedSimulations = simulationsSelected > 0;

    const descriptionModal =
        simulationsSelected > 1
            ? `Você está deletando ${simulationsSelected} cenários. Confirma esta ação?`
            : 'Você está deletando um cenário. Confirma essa ação?';

    const titleModal = simulationsSelected > 1 ? 'Deletar cenários' : 'Deletar cenário';

    const handleMessageTotalSimulations = () => {
        if (simulationsCount > 1) {
            return (
                <>
                    Foram encontrados <strong>{simulationsCount} resultados</strong> no total.
                </>
            );
        }
        return (
            <>
                Foi encontrado <strong>{simulationsCount} resultado</strong> no total.
            </>
        );
    };

    const handleMessageSelectedSimulations = () => {
        if (simulationsSelected > 1) {
            return (
                <>
                    <strong>{simulationsSelected} resultados </strong> selecionados.
                </>
            );
        }
        return (
            <>
                <strong>{simulationsSelected} resultado </strong> selecionado.
            </>
        );
    };

    return (
        <main className={styles.container}>
            <p>
                {showTotalSimulations && handleMessageTotalSimulations()}

                {showSelectedSimulations && handleMessageSelectedSimulations()}
            </p>

            <div className={styles.buttons}>
                <Button disabled={disabled} className={styles['icon-button']} onClick={handleShowConfirmActionModal}>
                    <IoMdTrash />
                </Button>
                <Button disabled={disabled} className={styles['icon-button']} onClick={handleDownloadSelectedScenarios}>
                    <MdDownload />
                </Button>

                <Button disabled={disabledMainButton} appearance="primary" className={styles['main-button']} onClick={handleCompareScenarios}>
                    Comparar
                </Button>
            </div>
            {modal.showModalConfirmAction && (
                <ModalConfirmAction
                    show={modal.showModalConfirmAction}
                    onClickAction={handleDeleteScenario}
                    onHide={closeAllModals}
                    title={titleModal}
                    description={descriptionModal}
                />
            )}
        </main>
    );
};
