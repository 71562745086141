import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams, withRouter } from 'react-router-dom';
import { Alert, Button, Content, Notification, Panel, Toggle } from 'rsuite';
import { trackEvent } from '../../../../../utils/MatomoConfig';

import { LoadingSpiner } from '../../../../../components/LoadingSpinner';
import ModalConfirm from '../../../../../components/ModalConfirm';
import ConsolidateItems from '../Components/ConsolidateItems';
import DateIntervals from '../Components/DateIntervals';
import DndComponent from '../Components/DnD/DndComponent';
import ModalSaveReport from '../Components/ModalSaveReport';
import ModalUnderstandBetter from '../Components/ModalUnderstandBetter';

import {
    setGenerateReportData,
    setGenerateReportResponse,
    setModuloRelatorioDataToInitialData,
    setStateValue,
    setTipoPromocaoData,
} from '../../../../../actions/actionsModuloRelatorio';
import {
    changeValue,
    clearFiltros,
    setFiltrosSalvos,
} from '../../../../../actions/actionsPainelGeral';
import {
    checkWithin90Days,
    dimensionsSelectionMissingText,
    dndLocalItems,
    dndPriceItems,
    dndProductItems,
    dndQuantityItems,
    dndWithFiltersItems,
    moduleReportReverseModelType,
    reportNameAndInitialDateMissingText
} from '../utils';

import iconCheckGreen from '../../../../../assets/icons/icon_check_circle_green.svg';
import saveIcon from '../../../../../assets/icons/icon_save_blue.svg';
import useProducts from '../../../../../hooks/useProduct';
import {
    useDndComponentToEditItems,
    useGenerateReport,
    useSaveReportCounter,
    useSelectEndereco,
} from '../Hooks';
import { fileDownloadStatus } from '../data';

const ColumnSectionTitle = ({ title }) => (
    <div className="text-border-wrapper">
        <p className="text">{title}</p>
        <div className="side-border" />
    </div>
);

const PanelHeader = ({ title, subtitle }) => (
    <div className="panel-header-title-wrapper">
        <p>{title}</p>
        <p className="panel-header-subtitle">{subtitle}</p>
    </div>
);

const DndComponentMemo = React.memo(DndComponent);

const CreateReportContent = ({ goToContent, onClickGoBack }) => {
    const [showModalUnderstandBetter, setShowModalUnderstandBetter] =
        useState(false);
    const [priceType, setPriceType] = useState({
        precoPago: true,
        precoRegular: false,
    });
    const [showModalSaveReport, setShowModalSaveReport] = useState(false);

    const productsEditList = useDndComponentToEditItems(dndProductItems);
    const localEditList = useDndComponentToEditItems(dndLocalItems);
    const priceEditList = useDndComponentToEditItems(dndPriceItems);
    const quantityEditList = useDndComponentToEditItems(dndQuantityItems);
    const withFiltersEditList = useDndComponentToEditItems(dndWithFiltersItems);

    const {
        setDimensoesList,
        validateConsolidadoData,
        consolidateSelectedList,
        setConsolidateSelectedList,
    } = useGenerateReport();

    const {
        products,
        onSearchProducts,
        handleAddProduct,
        handleRemoveProduct,
        handleCleanProduct,
        setProductsForEdition,
    } = useProducts();

    const { enderecoHandlers } = useSelectEndereco();
    const { refetchCounter, dataCounter, isFetching } = useSaveReportCounter();

    const dispatch = useDispatch();

    const {
        consolidadoData,
        moduloRelatorioData,
        showModalNoDownload,
        reportSaveSuccess,
        saveReportItemData,
        reportLineQuantity,
        showModalConfirmDownload,
        dateIntervalKey,
    } = useSelector(
        (state) => ({
            consolidadoData: state.moduloRelatorioDataReducer?.consolidadoData,
            moduloRelatorioData:
                state.moduloRelatorioDataReducer?.moduloRelatorioData,
            showModalConfirmDownload:
                state.moduloRelatorioDataReducer?.showModalConfirmDownload,
            showModalNoDownload:
                state.moduloRelatorioDataReducer?.showModalNoDownload,
            reportSaveSuccess:
                state.moduloRelatorioDataReducer?.reportSaveSuccess,
            saveReportItemData:
                state.moduloRelatorioDataReducer?.saveReportItemData,
            reportLineQuantity:
                state.moduloRelatorioDataReducer?.reportLineQuantity,
            dateIntervalKey:
                state.moduloRelatorioDataReducer?.dateIntervalKey,
        }),
        shallowEqual,
    );

    const functions = {
        onSearchProducts,
        handleAddProduct,
        handleRemoveProduct,
        handleCleanProduct,
    };

    const { id } = useParams();

    const handlePriceType = (checked, name) => {
        setPriceType((prevState) => ({ ...prevState, [name]: checked }));

        if (
            (priceType.precoPago && name === 'precoRegular') ||
            (priceType.precoRegular && name === 'precoPago')
        ) {
            dispatch(
                setGenerateReportData('precoEscolhido', [
                    'PRECO_PAGO',
                    'PRECO_REGULAR',
                ]),
            );
        }

        if (
            (priceType.precoPago &&
                priceType.precoRegular &&
                name === 'precoRegular') ||
            (priceType.precoPago &&
                priceType.precoRegular &&
                name === 'precoPago')
        ) {
            dispatch(
                setGenerateReportData('precoEscolhido', [
                    name === 'precoPago' ? 'PRECO_REGULAR' : 'PRECO_PAGO',
                ]),
            );
        }

        if (
            !priceType.precoPago &&
            priceType.precoRegular &&
            name === 'precoRegular'
        ) {
            setPriceType((prevState) => ({
                ...prevState,
                precoRegular: true,
            }));
        }

        if (
            priceType.precoPago &&
            !priceType.precoRegular &&
            name === 'precoPago'
        ) {
            setPriceType((prevState) => ({
                ...prevState,
                precoPago: true,
            }));
        }
    };

    const handleGenerateReport = () => {
        setDimensoesList();
        validateConsolidadoData('generate');
    };

    const validateEnderecoAndGenerateReport = (type) => {
        const enderecoInColumn2 = consolidadoData.local
            .filter((item) => item.name === 'Endereço')
            .some((item) => item.column === '2');

        const enderecoDataHasBeenFilled =
            typeof moduloRelatorioData.enderecoDTO.latitude === 'number' &&
            typeof moduloRelatorioData.enderecoDTO.raio === 'number';

        const enderecoIsEmpty =
            moduloRelatorioData.enderecoDTO.latitude === null &&
            moduloRelatorioData.enderecoDTO.raio === null;

        if (enderecoInColumn2) {
            // conferindo se o endereço está na coluna 2
            if (enderecoDataHasBeenFilled || enderecoIsEmpty) {
                // conferindo se os dados do endereço foram preenchidos ou se estão totalmente vazios
                type === 'generate'
                    ? goGenerateReport()
                    : setShowModalSaveReport(true);
            } else {
                Alert.warning('Endereço ou raio não foram selecionados', 5000);
            }
        } else {
            type === 'generate'
                ? goGenerateReport()
                : setShowModalSaveReport(true);
        }
    };

    const handleSaveReport = () => {
        if (
            consolidadoData.produto.length &&
            consolidadoData.local.length &&
            (consolidadoData.preco.length ||
                consolidadoData.quantidade.length ||
                consolidadoData.comFiltro.length)
        ) {
            refetchCounter();
            validateEnderecoAndGenerateReport('save');
        } else {
            Alert.warning(dimensionsSelectionMissingText, 5000);
        }
    };

    const handleOpenModalGenerate = () => {
        if (
            consolidadoData.produto.length &&
            consolidadoData.local.length &&
            (consolidadoData.preco.length ||
                consolidadoData.quantidade.length ||
                consolidadoData.comFiltro.length)
        ) {
            // abre a modal de Processar Relatório quando a chamada do endpoint abaixo for 200
            handleGenerateReport();
            trackEvent('Módulo de relatórios', 'Gerar relatório');
        } else {
            Alert.warning(dimensionsSelectionMissingText, 5000);
        }
    };

    const handleSelectGenerateReportModal = () => {
        dispatch(setGenerateReportResponse(false));
        goToContent('HISTORICO_RELATORIOS');
    };

    const setSelectSavedReportOptions = (savedData) => {
        // fixa os valores nos filtros e coluna2 para edição
        setPriceType({
            precoPago: savedData?.precoEscolhido?.some(
                (item) => item === 'PRECO_PAGO',
            ),
            precoRegular: savedData?.precoEscolhido?.some(
                (item) => item === 'PRECO_REGULAR',
            ),
        });
        savedData?.dimensoes?.map((item) => {
            if (item.dimensao.name === 'GTIN' && savedData?.produto?.length) {
                const arrayToString = savedData.produto
                    .toString()
                    .replaceAll(',', ' ');

                setProductsForEdition(arrayToString);
            }

            if (item.dimensao.name === 'TIPO_PROMOCAO') {
                dispatch(
                    setTipoPromocaoData(
                        'edit',
                        savedData[
                            moduleReportReverseModelType[item.dimensao.name]
                        ],
                    ),
                );
            }

            if (item.dimensao.name === 'LOGRADOURO') {
                const enderecoItem = {
                    label: savedData.enderecoDTO.endereco,
                    value: savedData.enderecoDTO.endereco,
                };

                enderecoHandlers.handleSetValuesForEditEndereco(enderecoItem);
            }

            if (
                item.dimensao.name !== 'GTIN' &&
                item.dimensao.name !== 'REGIAO' &&
                item.dimensao.name !== 'LOJA' &&
                item.dimensao.name !== 'LOGRADOURO' &&
                item.dimensao.name !== 'PRECO_MINIMO' &&
                item.dimensao.name !== 'PRECO_MAXIMO' &&
                item.dimensao.name !== 'PRECO_MAIS_FREQUENTE' &&
                item.dimensao.name !== 'PRECO_MEDIO' &&
                item.dimensao.name !== 'QUANTIDADE_DADOS' &&
                item.dimensao.name !== 'QUANTIDADE_LOJAS' &&
                item.dimensao.name !== 'QUANTIDADE_REDES' &&
                item.dimensao.name !== 'TIPO_PROMOCAO' &&
                item.dimensao.name !== 'ANUNCIO_FLAG' &&
                item.dimensao.name !== 'CONTEUDO_GRAMATURA' &&
                item.dimensao.name !== 'ULTIMO_PRECO'
            ) {
                savedData[
                    moduleReportReverseModelType[item.dimensao.name]
                ]?.forEach((filtro) => {
                    dispatch(
                        setFiltrosSalvos(
                            moduleReportReverseModelType[item.dimensao.name],
                            filtro,
                        ),
                    );
                });
                dispatch(
                    changeValue(
                        savedData[
                            moduleReportReverseModelType[item.dimensao.name]
                        ],
                        moduleReportReverseModelType[item.dimensao.name],
                    ),
                );
            }
        });
    };

    const handleSaveEditedReport = () => {
        const isWithin90Days = checkWithin90Days(moduloRelatorioData.dataInicio) && checkWithin90Days(moduloRelatorioData.dataFim)
        if (!isWithin90Days) {
            dispatch(setStateValue('openModalGenerateReportError', true));
            return;
        }
        
        if (moduloRelatorioData?.recorrencia) {
            if (
                moduloRelatorioData?.fileName === '' ||
                moduloRelatorioData?.reportEngineRecorrenciaDTO
                    ?.dataInicioEnvio === '' ||
                moduloRelatorioData?.reportEngineRecorrenciaDTO
                    ?.dataInicioEnvio === null
            ) {
                Alert.warning(reportNameAndInitialDateMissingText, 5000);
                return;
            }
            validateConsolidadoData('update');
        } else {
            if (moduloRelatorioData?.fileName === '') {
                Alert.warning(reportNameAndInitialDateMissingText, 5000);
                return;
            }
            validateConsolidadoData('update');
        }
    };

    const setModalMessage = () => {
        const completeMessage = `
            Esse relatório irá gerar mais de 1 milhão de linhas.
        `;

        return completeMessage;
    };

    useEffect(() => {
        setDimensoesList();
    }, [consolidadoData]);

    useEffect(() => {
        if (showModalConfirmDownload) {
            setPriceType({
                precoPago: true,
                precoRegular: false,
            });
        }

        if (reportSaveSuccess) {
            Notification.open({
                className: 'product-config-success-notification',
                description: (
                    <>
                        <img src={iconCheckGreen} alt="" />
                        <p>Relatório salvo com sucesso.</p>
                    </>
                ),
            });
            setShowModalSaveReport(false);
            dispatch(setStateValue('reportSaveSuccess', false));
            dispatch(setStateValue('saveReportItemData', {}));
            onClickGoBack();
        }
    }, [showModalConfirmDownload, reportSaveSuccess]);

    useEffect(() => {
        if (moduloRelatorioData?.produto?.length !== products?.cache?.length) {
            dispatch(setGenerateReportData('produto', products.value));
        }
    }, [products?.cache]);

    useEffect(() => {
        if (typeof id === 'string' && id === saveReportItemData?.id) {
            setSelectSavedReportOptions(saveReportItemData?.reportEngineDTO);
        }

        if (typeof id !== 'string') {
            onSearchProducts();
            dispatch(clearFiltros());
            dispatch(setModuloRelatorioDataToInitialData());
        }
    }, [
        id,
        saveReportItemData?.id,
        saveReportItemData?.reportEngineDTO?.produto?.length,
    ]);

    return (
        <>
            <Content>
                <LoadingSpiner size="md" />
                {typeof id === 'string' ? null : (
                    <p className="content-title">Criar relatório</p>
                )}

                <p className="content-subtitle">
                    <span className="bold-text">DEFINIÇÃO DE COLUNAS</span>
                    <span className="regular-text">
                        Defina pelo menos um elemento (coluna) para cada
                        dimensão.
                    </span>{' '}
                    <span className="link-text">
                        <button
                            type="button"
                            onClick={() => setShowModalUnderstandBetter(true)}
                        >
                            Entenda melhor
                        </button>
                    </span>
                </p>
                <Panel
                    header={
                        <PanelHeader
                            title="Intervalo de datas"
                            subtitle="Escolha um período e uma quebra de período para o relatório"
                        />
                    }
                    collapsible
                    bordered
                    className="date-interval-panel"
                    defaultExpanded
                >
                    <DateIntervals key={dateIntervalKey} />
                </Panel>

                <Panel
                    header={
                        <PanelHeader
                            title="Produto"
                            subtitle="Escolha quais colunas de produto devem aparecer no relatório arrastando o elemento desejado abaixo"
                        />
                    }
                    collapsible
                    bordered
                    className="product-panel"
                    defaultExpanded
                >
                    <DndComponentMemo
                        key="produto"
                        dndName="produto"
                        dndItems={
                            typeof id === 'string'
                                ? productsEditList.editItems
                                : dndProductItems
                        }
                        products={products}
                        functions={functions}
                        columnTitles={[
                            'Elementos disponíveis',
                            'Elementos selecionados (colunas)',
                        ]}
                    />
                </Panel>
                <Panel
                    header={
                        <PanelHeader
                            title="Local"
                            subtitle="Escolha quais colunas de local devem aparecer no relatório arrastando o elemento desejado abaixo"
                        />
                    }
                    collapsible
                    bordered
                    className="product-panel"
                    defaultExpanded
                >
                    <DndComponentMemo
                        key="local"
                        dndName="local"
                        dndItems={
                            typeof id === 'string'
                                ? localEditList.editItems
                                : dndLocalItems
                        }
                        columnTitles={[
                            'Elementos disponíveis',
                            'Elementos selecionados (colunas)',
                        ]}
                        enderecoHandlers={enderecoHandlers}
                    />
                </Panel>
                <Panel
                    header={
                        <PanelHeader
                            title="Métricas"
                            dataLength={
                                consolidadoData.preco.length ||
                                consolidadoData.quantidade.length ||
                                consolidadoData.comFiltro.length
                            }
                            subtitle="Defina quais são as métricas que serão analisadas no relatório arrastando o elemento desejado abaixo"
                        />
                    }
                    collapsible
                    bordered
                    className="metricas-panel"
                    defaultExpanded
                >
                    <DndComponentMemo
                        key="preco"
                        dndName="preco"
                        dndItems={
                            typeof id === 'string'
                                ? priceEditList.editItems
                                : dndPriceItems
                        }
                        columnTitles={[
                            'Elementos disponíveis',
                            'Elementos selecionados (colunas)',
                        ]}
                        elementLeft={<ColumnSectionTitle title="Preços" />}
                        elementRight={
                            <div className="text-border-wrapper">
                                <span className="text">Preços</span>
                                <div className="toggle-wrapper">
                                    <Toggle
                                        disabled={!consolidadoData.preco.length}
                                        size="sm"
                                        name="pago"
                                        checked={priceType.precoPago}
                                        onChange={(checked) =>
                                            handlePriceType(
                                                checked,
                                                'precoPago',
                                            )
                                        }
                                    />
                                    <label htmlFor="pago">Pago</label>
                                    <Toggle
                                        disabled={!consolidadoData.preco.length}
                                        size="sm"
                                        name="regular"
                                        checked={priceType.precoRegular}
                                        onChange={(checked) =>
                                            handlePriceType(
                                                checked,
                                                'precoRegular',
                                            )
                                        }
                                    />
                                    <label htmlFor="regular">Regular</label>
                                </div>
                                <div className="side-border" />
                            </div>
                        }
                    />
                    <DndComponentMemo
                        key="quantidade"
                        dndName="quantidade"
                        dndItems={
                            typeof id === 'string'
                                ? quantityEditList.editItems
                                : dndQuantityItems
                        }
                        elementLeft={<ColumnSectionTitle title="Quantidade" />}
                        elementRight={<ColumnSectionTitle title="Quantidade" />}
                    />
                    <DndComponentMemo
                        key="comFiltro"
                        dndName="comFiltro"
                        dndItems={
                            typeof id === 'string'
                                ? withFiltersEditList.editItems
                                : dndWithFiltersItems
                        }
                        elementLeft={<ColumnSectionTitle title="Promoção" />}
                        elementRight={<ColumnSectionTitle title="Promoção" />}
                    />
                </Panel>
            </Content>
            <ConsolidateItems
                editId={id}
                consolidateSelectedList={consolidateSelectedList}
                setConsolidateSelectedList={setConsolidateSelectedList}
            />
            <Content className="generate-report-wrapper">
                {typeof id === 'string' ? (
                    <>
                        <Button
                            id="back-report-button"
                            className="edit-go-back-button"
                            appearance="ghost"
                            onClick={onClickGoBack}
                        >
                            Voltar
                        </Button>
                        <Button
                            id="save-edit-report"
                            className="edit-save-report-button"
                            appearance="primary"
                            onClick={handleSaveEditedReport}
                        >
                            <img src={saveIcon} alt="" />
                            Salvar relatório
                        </Button>
                    </>
                ) : (
                    <>
                        <Button
                            id="save-report"
                            className="save-report-button"
                            appearance="ghost"
                            onClick={handleSaveReport}
                        >
                            <img src={saveIcon} alt="" />
                            Salvar relatório
                        </Button>
                        <Button
                            id="generate-report"
                            className="generate-report-button"
                            appearance="primary"
                            onClick={handleOpenModalGenerate}
                        >
                            Gerar relatório
                        </Button>
                    </>
                )}
            </Content>
            {showModalUnderstandBetter ? (
                <ModalUnderstandBetter
                    show={showModalUnderstandBetter}
                    onCancel={() => setShowModalUnderstandBetter(false)}
                />
            ) : null}
            <ModalConfirm
                show={showModalConfirmDownload}
                modalWidth="500px"
                titleFontSize="16px"
                textAlign="center"
                footerAlign="center"
                closeButton={false}
                icon={fileDownloadStatus.showModalConfirmDownload?.icon}
                title={fileDownloadStatus.showModalConfirmDownload?.title}
                message={fileDownloadStatus.showModalConfirmDownload?.message}
                confirmButton="salvar relatório"
                cancelButton={
                    fileDownloadStatus.showModalConfirmDownload?.confirmButton
                }
                onConfirm={handleSaveReport}
                onCancel={handleSelectGenerateReportModal}
            />
            <ModalSaveReport
                show={showModalSaveReport}
                dataCounter={dataCounter}
                loadingCounter={isFetching}
                onCancel={() => setShowModalSaveReport(false)}
            />
        </>
    );
};
export default withRouter(CreateReportContent);
