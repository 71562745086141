import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
    Button, Col,
    Icon,
    Nav,
    Notification,
} from 'rsuite';
import iconArrowUpDown from '../assets/icons/icon_arrow-updown.svg';
import iconInfo from '../assets/icons/icon_info.svg';
import iconMenuBars from '../assets/icons/icon_menu_bars.svg';
import iconClose from '../assets/icons/icon_x_close.svg';
import { productsNav, sidebarNav } from '../data/dataNavbarMobile';
import { setClickToLocalStorage } from './NavBar';
import NavFullscreenMobile from './NavFullscreenMobile';
import SidebarMenuMobile from './SidebarMenuMobile';

const NavBarMobile = ({ url, usuario }) => {
    const [selected, setSelected] = useState('infopanel');
    const [openDialogMenu, setOpenDialogMenu] = useState(false);
    const [openSidebarMenu, setOpenSidebarMenu] = useState(false);
    const sidebarMenu = useSelector((state) => state.menuDataReducer);
    const productMenu = useSelector((state) => state.navbarDataReducer);
    const buttonProductLogo = productsNav.find(
        (product) => product.value === selected,
    );

    const userType = {
        infopanel: !usuario?.servicos.some((item) => item.name === 'PAN'),
        mpdv: !usuario?.servicos.some((item) => item.name === 'COL'),
        ipa: true,
    };

    const handleToggleSidebarMenu = () => {
        setOpenSidebarMenu(!openSidebarMenu);
    };

    const centralAjuda = () => {
        setClickToLocalStorage('centralAjuda', 'botao-ajuda', usuario);
        window.open('https://infoprice-support.freshdesk.com/support/home');
    };

    const disabledClickAlert = (disabled) => {
        if (disabled) {
            Notification.open({
                className: 'no-mobile-nav-notification',
                duration: 4000,
                description: (
                    <>
                        <img src={iconInfo} alt="" />
                        <p>
                            Essa visualização ainda não está disponível para
                            celular
                        </p>
                    </>
                ),
            });
        }
    };

    useEffect(() => {
        if (url.pathname === '/isa/painel-geral' || url.pathname === '/isa/painel-geral-free') {
            setSelected('infopanel');
        } else {
            setSelected('mpdv');
        }
    }, [url]);

    return (
        <div className="navbar-mobile-container">
            <Button
                className="sidebar-button"
                appearance="primary"
                onClick={handleToggleSidebarMenu}
            >
                <img src={openSidebarMenu ? iconClose : iconMenuBars} alt="" />
            </Button>
            <Button
                className="logo-button"
                onClick={() => setOpenDialogMenu(true)}
            >
                <img src={buttonProductLogo.logo} alt="" />
                <img src={iconArrowUpDown} alt="" />
            </Button>
            <NavFullscreenMobile
                open={openDialogMenu}
                onClose={() => setOpenDialogMenu(false)}
                activeKey={productMenu?.activeKey}
            >
                {productsNav.map((item) => (
                    <Nav.Item
                        eventKey={item.key}
                        key={item.value}
                        componentClass={Col}
                        xs={24}
                        className="primary-nav-item"
                        disabled={userType[item.value]}
                        title={item.label}
                        onClick={() => disabledClickAlert(userType[item.value])}
                    >
                        {userType[item.value] ? (
                            <>
                                <Icon icon="lock" className="mg-left-7" />
                                <span>{item.label}</span>
                            </>
                        ) : (
                            <Link to={item.link}>{item.label}</Link>
                        )}
                    </Nav.Item>
                ))}
                <div className="secondary-nav-item-wrapper">
                    {usuario?.usuarioAdministrador && (
                        <Nav.Item
                            componentClass={Col}
                            xs={24}
                            className="secondary-nav-item"
                        >
                            <Link to="/administrador/pessoas">
                                Administrador
                            </Link>
                        </Nav.Item>
                    )}
                    <Nav.Item
                        componentClass={Col}
                        xs={24}
                        className="secondary-nav-item"
                        onClick={centralAjuda}
                    >
                        Central de Ajuda
                    </Nav.Item>
                </div>
            </NavFullscreenMobile>
            <SidebarMenuMobile
                appearance="subtle"
                activeKey={sidebarMenu?.activeKeyMenu}
                open={openSidebarMenu}
            >
                {sidebarNav[selected].map((item) => (
                    <Nav.Item
                        componentClass={Link}
                        eventKey={item.key}
                        key={item.value}
                        value={item.value}
                        to={item.disabled ? null : item.link}
                        disabled={item.disabled}
                        icon={<Icon icon={item.icon} />}
                        onClick={() => disabledClickAlert(item.disabled)}
                    >
                        {item.label}
                    </Nav.Item>
                ))}
            </SidebarMenuMobile>
        </div>
    );
};

export default NavBarMobile;
