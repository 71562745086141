import React from 'react';
import { useSelector } from 'react-redux';
import { FlexboxGrid } from 'rsuite';
import { InfoTable, Layout } from '../../../../../../../components';
import { selectorDemandForecastFiltersArea, selectorDemandForecastOutputArea } from '../../../../../../../reducers/previsaoDemanda';
import { OutputProps, Query } from '../../../../../../../reducers/previsaoDemanda/outputArea';
import { formatNumbersToPtBr } from '../../../../../../../utils/FormatNumbers';
import { generateElasticity, generateElasticityValue } from '../../utils/generateElasticity';
import { normalizeCompetitiveness, normalizeMargin } from '../../utils/normalizeValue';
import { CellWrapper } from './Components/CustomCell/CellWrapper';
import { Highlight } from './Components/Highlight/Highlight';
import styles from './TableArea.module.scss';

const DATA_KEY = 'productsToBePricedId';

type QueryProps = Query & {
    storeId: string;
    store: string;
};

type Simulation = {
    output: OutputProps;
    query: QueryProps;
};

export const TableArea = () => {
    const filtersArea = useSelector(selectorDemandForecastFiltersArea, (prev, next) => prev.isCompleted === next.isCompleted);

    const outputArea = useSelector(selectorDemandForecastOutputArea);
    const getStore = (storeId: string) => {
        const store = filtersArea.list.stores.find((store) => store.store_id === storeId);

        return store;
    };

    const sort = {
        type: DATA_KEY,
        orderBy: 'asc',
    };

    const { Column, HeaderCell, Cell } = InfoTable;

    if (!(filtersArea.isCompleted && outputArea.calculations.hasSimulation)) {
        return null;
    }

    const defaultOptionsFormat = {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
    };

    const formatCompetitiveness = (value: number) => {
        return formatNumbersToPtBr(normalizeCompetitiveness(value), 'decimal', {
            maximumFractionDigits: 1,
            minimumFractionDigits: 1,
            showSuffix: false,
        });
    };

    const formatMargin = (value: number) => {
        return formatNumbersToPtBr(normalizeMargin(value), 'decimal', {
            maximumFractionDigits: 1,
            minimumFractionDigits: 1,
            showSuffix: false,
        });
    };

    return (
        <Layout.Section className={styles['section-table']}>
            <FlexboxGrid className={styles['text-header']}>
                <h3>Resultado separado por loja</h3>
                <div>
                    <p>Veja o resultado da simulação para cada uma das lojas selecionadas</p>
                </div>
            </FlexboxGrid>

            <InfoTable
                data={outputArea?.simulation[0]?.priceSimulations || []}
                dataKey={DATA_KEY}
                className={styles.table}
                loading={false}
                minHeight={50}
                sortColumn={sort.type}
                headerHeight={44}
                rowHeight={62}
            >
                {/* @ts-ignore */}
                <Column flexGrow={2} resizable>
                    <HeaderCell>Loja</HeaderCell>
                    <Cell className={styles['padding-inline-xxs']} dataKey="query">
                        {(rowData: Simulation) => {
                            return (
                                <CellWrapper className={styles['cell-wrapper']}>
                                    <div className={styles['main-content']}>{rowData.query?.storeId}</div>
                                    <div className={styles['secondary-content']}>
                                        <span>{rowData.query?.store || getStore(rowData.query?.storeId)?.label}</span>
                                    </div>
                                </CellWrapper>
                            );
                        }}
                    </Cell>
                </Column>

                {/* @ts-ignore */}
                <Column width={120} resizable>
                    <HeaderCell>Elasticidade</HeaderCell>
                    <Cell className={styles['padding-inline-xxs']} dataKey="elasticity">
                        {(rowData: Simulation) => (
                            <CellWrapper className={styles['cell-wrapper']}>
                                <div className={styles['main-content']}>{generateElasticityValue(rowData.output?.elasticity)}</div>
                                <div className={styles['secondary-content']}>
                                    <span>{generateElasticity(rowData.output.elasticity)?.classification}</span>
                                </div>
                            </CellWrapper>
                        )}
                    </Cell>
                </Column>

                {/* @ts-ignore */}
                <Column width={120} resizable>
                    <HeaderCell>Preço</HeaderCell>
                    <Cell className={styles['padding-inline-xxs']} dataKey="price">
                        {(rowData: Simulation) => (
                            <CellWrapper className={styles['cell-wrapper']}>
                                <div className={styles['main-content']}>
                                    {formatNumbersToPtBr(rowData.output?.newPriceScenario?.price, 'currency', defaultOptionsFormat)}
                                </div>
                                <div className={styles['secondary-content']}>
                                    <div>{formatNumbersToPtBr(rowData.output?.basePriceScenario?.price, 'currency', defaultOptionsFormat)}</div>
                                </div>
                            </CellWrapper>
                        )}
                    </Cell>
                </Column>

                {/* @ts-ignore */}
                <Column width={120} resizable>
                    <HeaderCell>CPI</HeaderCell>
                    <Cell className={styles['padding-inline-xxs']} dataKey="competitiveness">
                        {(rowData: Simulation) => (
                            <CellWrapper className={styles['cell-wrapper']}>
                                <div className={styles['main-content']}>{`${formatCompetitiveness(rowData.output?.newPriceScenario?.competitiveness)} %`}</div>
                                <div className={styles['secondary-content']}>
                                    <div>{`${formatCompetitiveness(rowData.output?.basePriceScenario?.competitiveness)} %`}</div>
                                </div>
                            </CellWrapper>
                        )}
                    </Cell>
                </Column>

                {/* @ts-ignore */}
                <Column width={110} resizable>
                    <HeaderCell>Margem</HeaderCell>
                    <Cell className={styles['padding-inline-xxs']} dataKey="margin">
                        {(rowData: Simulation) => (
                            <CellWrapper className={styles['cell-wrapper']}>
                                <div className={styles['main-content']}>{`${formatMargin(rowData.output?.newPriceScenario?.margin)} %`}</div>
                                <div className={styles['secondary-content']}>
                                    <div>{`${formatMargin(rowData.output?.basePriceScenario?.margin)} %`}</div>
                                </div>
                            </CellWrapper>
                        )}
                    </Cell>
                </Column>

                {/* @ts-ignore */}
                <Column width={110} resizable>
                    <HeaderCell>
                        Margem
                        <br /> Produto
                    </HeaderCell>
                    <Cell className={styles['padding-inline-xxs']} dataKey="marginProduct">
                        {(rowData: Simulation) => (
                            <CellWrapper className={styles['cell-wrapper']}>
                                <div className={styles['main-content']}>{`${formatMargin(rowData.output?.newPriceScenario?.marginLevel1)} %`}</div>
                                <div className={styles['secondary-content']}>
                                    <div>{`${formatMargin(rowData.output?.basePriceScenario?.marginLevel1)} %`}</div>
                                </div>
                            </CellWrapper>
                        )}
                    </Cell>
                </Column>

                {/* @ts-ignore */}
                <Column width={110} resizable>
                    <HeaderCell>
                        Margem
                        <br /> Departamento
                    </HeaderCell>
                    <Cell className={styles['padding-inline-xxs']} dataKey="marginDepartment">
                        {(rowData: Simulation) => (
                            <CellWrapper className={styles['cell-wrapper']}>
                                <div className={styles['main-content']}>{`${formatMargin(rowData.output?.newPriceScenario?.marginLevel2)} %`}</div>
                                <div className={styles['secondary-content']}>
                                    <div>{`${formatMargin(rowData.output?.basePriceScenario?.marginLevel2)} %`}</div>
                                </div>
                            </CellWrapper>
                        )}
                    </Cell>
                </Column>

                {/* @ts-ignore */}
                <Column width={150} resizable>
                    <HeaderCell>Vendas (un)</HeaderCell>
                    <Cell className={styles['padding-inline-xxs']} dataKey="demandForecast">
                        {(rowData: Simulation) => (
                            <CellWrapper className={styles['cell-wrapper']}>
                                <div className={styles['main-content']}>{formatNumbersToPtBr(rowData.output?.newPriceScenario?.demandForecast, 'decimal')}</div>
                                <div className={styles['secondary-content']}>
                                    <span>{formatNumbersToPtBr(rowData.output?.basePriceScenario?.demandForecast, 'decimal')}</span>
                                    <Highlight value={rowData.output?.scenariosVariation?.demandForecast} />
                                </div>
                            </CellWrapper>
                        )}
                    </Cell>
                </Column>

                {/* @ts-ignore */}
                <Column width={150} resizable>
                    <HeaderCell>Receita (R$)</HeaderCell>
                    <Cell className={styles['padding-inline-xxs']} dataKey="revenueForecast">
                        {(rowData: Simulation) => (
                            <CellWrapper className={styles['cell-wrapper']}>
                                <div className={styles['main-content']}>
                                    {formatNumbersToPtBr(rowData.output?.newPriceScenario?.revenueForecast, 'currency', defaultOptionsFormat)}
                                </div>
                                <div className={styles['secondary-content']}>
                                    <span>{formatNumbersToPtBr(rowData.output?.basePriceScenario?.revenueForecast, 'currency', defaultOptionsFormat)}</span>
                                    <Highlight value={rowData.output?.scenariosVariation?.revenueForecast} />
                                </div>
                            </CellWrapper>
                        )}
                    </Cell>
                </Column>

                {/* @ts-ignore */}
                <Column width={150} resizable>
                    <HeaderCell>Lucro (R$)</HeaderCell>
                    <Cell className={styles['padding-inline-xxs']} dataKey="profitForecast">
                        {(rowData: Simulation) => (
                            <CellWrapper className={styles['cell-wrapper']}>
                                <div className={styles['main-content']}>
                                    {formatNumbersToPtBr(rowData.output?.newPriceScenario?.profitForecast, 'currency', defaultOptionsFormat)}
                                </div>
                                <div className={styles['secondary-content']}>
                                    <span>{formatNumbersToPtBr(rowData.output?.basePriceScenario?.profitForecast, 'currency', defaultOptionsFormat)}</span>
                                    <Highlight value={rowData.output?.scenariosVariation?.profitForecast} />
                                </div>
                            </CellWrapper>
                        )}
                    </Cell>
                </Column>

                {/* @ts-ignore */}
                {/* <Column width={50}>
                    <HeaderCell />
                    <Cell>
                        {(rowData: Simulation) => (
                            <Button
                                // disabled={!rowData.checked}
                                className={styles['icon-button']}
                                onClick={() => {}}
                            >
                                <MdQueryStats />
                            </Button>
                        )}
                    </Cell>
                </Column> */}
            </InfoTable>
        </Layout.Section>
    );
};
