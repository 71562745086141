import React from 'react';

import SimulacaoData from '../../../../../../../../assets/image/simulacao-data.svg';

import styles from './MessageSimulation.module.scss';
export const MessageSimulation = () => {
    return (
        <main className={styles.container}>
            <div className={styles['data-info']}>
                <div className={styles['data-info-content']}>
                    <img src={SimulacaoData} alt="Simulação de dados" />
                    <p>
                        Para ver os resultados da simulação,
                        <br /> preencha os dados acima
                    </p>
                </div>
            </div>
        </main>
    );
};
