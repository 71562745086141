/* eslint-disable react/display-name */
import React, { HtmlHTMLAttributes, Ref } from 'react';
import { useSelector } from 'react-redux';
import { IconButton } from '.';
import { FilterButtonProps } from '..';
import { RootState } from '../../../../../../@types/RootState';

export const FilterButton = React.forwardRef(
    (
        { icon, skin = 'neutral', forceDisabled = false, ...props }: FilterButtonProps & { forceDisabled?: boolean },
        ref: Ref<HtmlHTMLAttributes<HTMLButtonElement>>,
    ) => {
        const datapointExhibitionType = useSelector((state: RootState) => {
            return state.datapointExhibitionType.datapointExhibitionType;
        });

        const isFamilyExhibition = datapointExhibitionType === 'FAMILY';

        return (
            <IconButton
                ref={ref}
                icon={icon}
                data-testid="filter-button"
                skin={skin}
                disabled={isFamilyExhibition && forceDisabled}
                {...props}
            />
        );
    },
);
