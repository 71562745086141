import React from 'react';

const useDebounce = (delay = 500) => {
    const timer = React.useRef<NodeJS.Timeout>();

    const debounce = React.useCallback(
        (callback: () => void, optionalDelay?: number) => {
            clearTimeout(timer.current);
            const newTimer = setTimeout(() => {
                callback();
            }, optionalDelay || delay);

            timer.current = newTimer;
        },
        [delay],
    );

    return debounce;
};

export default useDebounce;
