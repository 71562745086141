// src/reducers/gerenciadorPrecos/dataPointsExhibitionType.ts
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../@types/RootState';

const initialState = {
    datapointExhibitionType: 'PRODUCT' as 'PRODUCT' | 'FAMILY' | 'PRODUCT_CLUSTER',
    getFamilyModalIsOpen: false,
    getClusterStoreModalIsOpen: false,
};

const slice = createSlice({
    name: 'gerenciadorPrecos/dataPointsExhibitionType',
    initialState,
    reducers: {
        SET_DATA_POINTS_EXHIBITION_TYPE: (
            state,
            { payload }: PayloadAction<'PRODUCT' | 'FAMILY' | 'PRODUCT_CLUSTER'>,
        ) => {
            state.datapointExhibitionType = payload;
        },
        SET_GET_FAMILY_MODAL_IS_OPEN: (
            state,
            { payload }: PayloadAction<boolean>,
        ) => {
            state.getFamilyModalIsOpen = payload;
        },
        SET_GET_CLUSTER_STORE_MODAL_IS_OPEN: (
            state,
            { payload }: PayloadAction<boolean>,
        ) => {
            state.getClusterStoreModalIsOpen = payload;
        },
    },
});

const selectorDataPointsExhibitionType = (state: RootState) => {
    return state.datapointExhibitionType.datapointExhibitionType;
};

const selectorGetFamilyModalIsOpen = (state: RootState) => {
    return state.datapointExhibitionType.getFamilyModalIsOpen;
};

const selectorGetClusterStoreModalIsOpen = (state: RootState) => {
    return state.datapointExhibitionType.getClusterStoreModalIsOpen;
};

export const { SET_DATA_POINTS_EXHIBITION_TYPE, SET_GET_FAMILY_MODAL_IS_OPEN, SET_GET_CLUSTER_STORE_MODAL_IS_OPEN } =
    slice.actions;

export { selectorDataPointsExhibitionType, selectorGetClusterStoreModalIsOpen, selectorGetFamilyModalIsOpen };

export default slice.reducer;
