/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-interface */
import { RowData, TableFeature } from '@tanstack/react-table';

export type HeaderHeight = number;

export interface HeaderHeightOptions {
    headerHeight?: number;
}

declare module '@tanstack/react-table' {
    interface TableOptionsResolved<TData extends RowData> extends HeaderHeightOptions {}
}

export const HeaderHeightFeature: TableFeature<HeaderHeightOptions> = {
    getDefaultOptions: (): HeaderHeightOptions => {
        return {
            headerHeight: undefined,
        } as HeaderHeightOptions;
    },
};
