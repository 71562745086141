import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Notification } from 'rsuite';
import {
    reducerOnDemandSelector,
    setSingleRequestData,
    resetSingleRequestData,
} from '../../../../../../reducers/reducerOnDemand';
import * as service from '../../services';
import useOnDemandTableRequest from '../useOnDemandTableRequest';
import useOnDemandGetAddressFromCep from '../useOnDemandGetAddressFromCep';
import iconCheckGreen from '../../../../../../assets/icons/icon_check_circle_green.svg';
import { NotificationMessage } from '../../../../../../components';

export const useOnDemandEditRequest = () => {
    const [modalEditContent, setModalEditContent] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);

    const dispatch = useDispatch();

    const reducer = useSelector(reducerOnDemandSelector);

    const { refetch } = useOnDemandTableRequest();

    const {
        isError, isFetching, setCep, handleGetCepRequest,
    } = useOnDemandGetAddressFromCep();

    const handleChangeSingleRequestData = (name, value) => {
        const newObj = {
            ...reducer.singleRequestData,
            [name]: value,
        };

        dispatch(
            setSingleRequestData({
                name: 'singleRequestData',
                data: newObj,
            }),
        );
    };

    const handleSaveEditRequest = async () => {
        setOpenEditModal(false);
        setModalEditContent(false);
        
        try {
            const res = await service.saveEditRequest(reducer.singleRequestData);

            if (res) {
                Notification.open({
                    className: 'request-edit-success-notification',
                    description: (
                        <>
                            <img src={iconCheckGreen} alt="" />
                            <p>As edições foram salvas com sucesso.</p>
                        </>
                    ),
                });
                refetch();
            }
        } catch (error) {
            NotificationMessage(
                'error',
                7000,
                error.response.data[0].mensagem,
            );
        }
    };

    const handleEditConfirm = () => {
        if (!modalEditContent) {
            setModalEditContent(true);
        } else {
            handleSaveEditRequest();
        }
    };

    const handleOnChangeCep = (value) => {
        const newObj = { ...reducer.singleRequestData, cep: value };
        dispatch(
            setSingleRequestData({
                name: 'singleRequestData',
                data: newObj,
            }),
        );
        handleGetCepRequest(value);
    };

    const handleOnCancelEditModal = () => {
        setOpenEditModal(false);
        setModalEditContent(false);
        dispatch(resetSingleRequestData());
        setCep('');
    };

    const handleChangeProductType = (value) => {
        if (value === 'gtin') {
            const newObj = {
                ...reducer.singleRequestData,
                palavraChave: '',
            };
            dispatch(
                setSingleRequestData({
                    name: 'singleRequestData',
                    data: newObj,
                }),
            );
        } else {
            const newObj = {
                ...reducer.singleRequestData,
                gtin: '',
            };
            dispatch(
                setSingleRequestData({
                    name: 'singleRequestData',
                    data: newObj,
                }),
            );
        }
    };

    return {
        editHandlers: {
            handleChangeSingleRequestData,
            handleEditConfirm,
            handleOnChangeCep,
            handleOnCancelEditModal,
            handleChangeProductType,
        },
        editState: {
            openEditModal,
            setOpenEditModal,
            modalEditContent,
            setModalEditContent,
        },
        editCep: {
            isError,
            isFetching,
            setCep,
        },
    };
};
