import { useMutation, useQueryClient } from '@tanstack/react-query';
import * as service from '../services';
import { useManageComercialTableStore } from '../ManageComercialTable.store';
import { handleDateFormat } from '../../../utils';
import { AtivarPromoDataTypes } from '../types';

export const useAtivarOferta = () => {
    const queryClient = useQueryClient();

    const {
        ativarPromoData,
        setOpenModalActivateProductOffer,
        setManageComercialTableState,
    } = useManageComercialTableStore();

    const successAction = () => {
        queryClient.invalidateQueries(['comercial-table-product-list']);
        queryClient.invalidateQueries(['edit-comercial-table-list']);
        setOpenModalActivateProductOffer(false, false, null);
    }

    const ativarOfertaMutation = useMutation({
        mutationFn: (payload: AtivarPromoDataTypes) => service.saveOfertaPromo(payload),
        onSuccess: successAction,
    });

    const cancelarOfertaMutation = useMutation({
        mutationFn: (payload: number) => service.cancelOfertaPromo(payload),
        onSuccess: successAction,
    });

    const handleClickAtivarPromo = () => {
        const promoData = {
            ...ativarPromoData,
            inicioVigencia: handleDateFormat(ativarPromoData.inicioVigencia),
            terminoVigencia: handleDateFormat(ativarPromoData.terminoVigencia)
        }
        ativarOfertaMutation.mutateAsync(promoData);
    }

    const handleClickCancelarPromo = () => {
        cancelarOfertaMutation.mutateAsync(ativarPromoData.idRascunhoProduto);
    }

    const handleBlurPrecoOferta = (value: string | undefined) => {
        const price = {...ativarPromoData, precoOferta: Number(value?.replace(',', '.'))}
        setManageComercialTableState('ativarPromoData', price)
    }

    const handleChangeDateVigencia = (dateType: string, value: string) => {
        const price = {...ativarPromoData, [dateType]: handleDateFormat(value)}
        setManageComercialTableState('ativarPromoData', price)
    }

    return {
        handleClickAtivarPromo,
        handleClickCancelarPromo,
        handleBlurPrecoOferta,
        handleChangeDateVigencia,
    };
};
