/* eslint-disable react/jsx-indent */

import ArrowDownwardRoundedIcon from '@material-ui/icons/ArrowDownwardRounded';
import ArrowUpwardRoundedIcon from '@material-ui/icons/ArrowUpwardRounded';
import classNames from 'classnames';
import React, { memo, useState } from 'react';
import CurrencyFormat from 'react-currency-format';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Checkbox, Icon, Loader, Table, Tooltip, Whisper } from 'rsuite';
import { TableProps } from 'rsuite/lib/Table';
import { GerenciadorPrecos } from '../../../@types/GerenciadorPrecos';
import { RootState } from '../../../@types/RootState';
import { CustomHeaderCell, QtdeCell } from '../../../components';
import { GerenciadorDatakeysState, SET_GET_CLUSTER_STORE_MODAL_IS_OPEN } from '../../../reducers';
import { useFarma } from '../../Administrador/Produtos/Components/SetupFarma/hooks/useFarma';
import {
    BaseCell,
    CompetitorPriceCell,
    ExpandOfferCell,
    HoverNomeColuna,
    MargemCell,
    ObjectiveMarginCell,
    PMZCell,
    PrecoSugeridoCell,
    PrecoVigenteCell,
    ProductCell,
} from './Components';
import { GetClusterListStoreModal } from './Components/GetClusterStoreListModal';
import { CpiCell } from './Components/TableCells/CpiCell';
import { ExpandWholesaleCell } from './Components/TableCells/ExpandWholesaleCell';
import PmcPriceTag from './Components/TableCells/PMCCell/PMCCel';
import styles from './gerenciadorPrecos.module.scss';
import sortOptions from './sortOptions';


const MemoCheckbox = memo(Checkbox);
const MemoCompetitorPriceCell = memo(CompetitorPriceCell);
const MemoExpandWholesaleCell = memo(ExpandWholesaleCell);
const MemoExpandOfferCell = memo(ExpandOfferCell);
const MemoProductCell = memo(ProductCell);
const MemoPmcPriceTag = memo(PmcPriceTag);
const { Column, HeaderCell, Cell } = Table;
type RowData = GerenciadorPrecos.RowData;
type DataKey = GerenciadorPrecos.DataKey;

type TableGerenciadorProps = TableProps<string, RowData> & {
    datakeys: GerenciadorDatakeysState;
    isAllChecked: boolean;
    isIndeterminate: boolean;
    dataKeyProduto: string;
    expandedRowKeys: string[];
    onChangeCheckAll: (checked: boolean) => void;
    handleCheck: (value: DataKey, checked: boolean) => void;
    handleContextMenu: (rowData: RowData) => void;
    handleBlur: (rowData: RowData, index: number) => void;
    handleUpdateCampo: (name: GerenciadorPrecos.Inputs, value: number, rowData: RowData, index: number) => void;
    isToggleChecked: (productsToBePricedId: string) => boolean;
    calcularRevisao: (rowData: RowData, index: number) => void;
    handleOpenModalPrevisao: (demandForecast: RowData['demandForecast'], index: number) => void;
    onUpdateSortColumn?: (dataKey: string, value: string) => void;
};

const TableGerenciador = ({
    datakeys,
    isAllChecked,
    isIndeterminate,
    dataKeyProduto,
    expandedRowKeys,
    sortColumn,
    handleContextMenu,
    onChangeCheckAll,
    handleBlur,
    handleCheck,
    handleUpdateCampo,
    isToggleChecked,
    calcularRevisao,
    handleOpenModalPrevisao,
    onSortColumn,
    onUpdateSortColumn,
    ...props
}: TableGerenciadorProps) => {
    const { configFarma } = useFarma();

    const datapointExhibitionType = useSelector((state: RootState) => {
        return state.datapointExhibitionType.datapointExhibitionType;
    });

    const dispatch = useDispatch();

    const isShowCountStoreCluster = datapointExhibitionType === 'PRODUCT_CLUSTER';

    const [showModalClusterStore, setShowModalClusterStore] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState<RowData | null>(null);

    const handleOpenModalClusterStore = (rowData: RowData) => {
        if (datapointExhibitionType === 'PRODUCT_CLUSTER') {
            setSelectedRowData(rowData);
            setShowModalClusterStore(true);
            dispatch(SET_GET_CLUSTER_STORE_MODAL_IS_OPEN(true));
        }
    };

    return (
        <>
            <Table
                id="table-revisao-precos"
                className={classNames('table', styles['gerenciador-page__table'])}
                rowClassName={(rowData) =>
                    classNames('revisao-row', styles['gerenciador-page__table__row'], {
                        [styles['gerenciador-page__table__row--red']]:
                            rowData?.pmc !== null && rowData?.price !== null && rowData?.pmc < rowData?.price && configFarma?.usePMCForIPAPricing,
                    })
                }
                expandedRowKeys={expandedRowKeys}
                autoHeight
                onSortColumn={onSortColumn}
                sortColumn={sortColumn}
                {...props}
            >
                {/* @ts-expect-error coluna não pode receber children */}
                <Column align="center" width={60}>
                    <HeaderCell>
                        <MemoCheckbox
                            checked={isAllChecked}
                            indeterminate={isIndeterminate}
                            onChange={(_, checked) => {
                                onChangeCheckAll(checked);
                            }}
                        />
                    </HeaderCell>
                    <Cell>
                        {(rowData: RowData) => (
                            <BaseCell className={styles['gerenciador-page__check-cell']} rowData={rowData} handleContextMenu={handleContextMenu}>
                                <MemoCheckbox
                                    value={rowData.productsToBePricedId}
                                    onChange={(value: DataKey, checked) => {
                                        handleCheck(value, checked);
                                    }}
                                    checked={isToggleChecked(rowData.productsToBePricedId)}
                                />
                            </BaseCell>
                        )}
                    </Cell>
                </Column>
                {/* @ts-expect-error coluna não pode receber children */}
                <Column sortable flexGrow={2} minWidth={140}>
                    <HeaderCell
                        className={classNames('product-dropdown')}
                        style={{
                            paddingLeft: 14,
                        }}
                    >
                        Produto
                    </HeaderCell>
                    <Cell dataKey={dataKeyProduto}>{(rowData: RowData) => <MemoProductCell handleContextMenu={handleContextMenu} rowData={rowData} />}</Cell>
                </Column>
                {/* @ts-expect-error coluna não pode receber children */}
                <Column sortable width={100}>
                    <HeaderCell>Loja</HeaderCell>
                    <Cell dataKey="storeId">
                        {(rowData: RowData) => (
                            <BaseCell rowData={rowData} handleContextMenu={handleContextMenu}>
                                {rowData.storeName ? (
                                    <Whisper
                                        delayShow={0.2}
                                        trigger="hover"
                                        placement="right"
                                        speaker={
                                            <Tooltip>
                                                {datapointExhibitionType === 'PRODUCT_CLUSTER' ? rowData?.clusterName : rowData?.storeName}
                                            </Tooltip>}>
                                        <span className="font-size-100-semibold">
                                            {datapointExhibitionType === 'PRODUCT_CLUSTER'
                                                ? (rowData?.clusterItemsCount ? rowData?.clusterName : rowData?.storeId)
                                                : rowData?.storeId}
                                            <br />
                                            {isShowCountStoreCluster && rowData?.clusterItemsCount && (
                                                <span className={styles['store-cluster-count']} onClick={() => handleOpenModalClusterStore(rowData)}>
                                                    {rowData?.clusterItemsCount} {rowData?.clusterItemsCount === 1 ? 'LOJA' : 'LOJAS'}
                                                    {rowData.hasEditedProducts === true && (
                                                        <Whisper
                                                            placement="bottom"
                                                            speaker={
                                                                <Tooltip className={styles['custom-tooltip']}>
                                                                    Algumas lojas deste cluster tiveram preços editados e podem estar diferentes do esperado
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <Icon className={styles['store-cluster-count-warning']} icon="exclamation-triangle" />
                                                        </Whisper>
                                                    )}
                                                </span>
                                            )}
                                        </span>
                                    </Whisper>
                                ) : null}
                            </BaseCell>
                        )}
                    </Cell>
                </Column>
                {/* @ts-expect-error coluna não pode receber children */}
                <Column sortable width={78}>
                    <HeaderCell>PMZ</HeaderCell>
                    <Cell dataKey="pmz">{(rowData: RowData) => <PMZCell rowData={rowData} handleContextMenu={handleContextMenu} />}</Cell>
                </Column>
                {/* @ts-expect-error coluna não pode receber children */}
                <Column sortable width={78}>
                    <HeaderCell className={styles['gerenciador-page__table__header-cell']}>
                        <div>
                            <p>Margem</p>
                            <p>Objetiva</p>
                        </div>
                    </HeaderCell>
                    <Cell dataKey="objectiveMargin">
                        {(rowData: RowData) => {
                            return <ObjectiveMarginCell rowData={rowData} handleContextMenu={handleContextMenu} />;
                        }}
                    </Cell>
                </Column>
                {/* @ts-expect-error coluna não pode receber children */}
                <Column width={64}>
                    <HeaderCell className={styles['gerenciador-page__table__header-cell']}>Emb...</HeaderCell>
                    <Cell>
                        <QtdeCell />
                    </Cell>
                </Column>
                {configFarma?.usePMCForIPAPricing && (
                    <Column sortable width={124}>
                        <HeaderCell className={styles['gerenciador-page__table__header-cell']}>
                            <div>
                                <p>PMC</p>
                            </div>
                        </HeaderCell>
                        <Cell dataKey="pmc">
                            {(rowData: RowData) => (
                                <MemoPmcPriceTag
                                    className={classNames({
                                        [styles.redBackground]: rowData?.pmc !== null && rowData?.price !== null && rowData?.pmc < rowData?.price,
                                    })}
                                    pmcPrice={rowData?.pmc}
                                    date={rowData?.pmcLastDate ?? ''}
                                    rowData={rowData}
                                />
                            )}
                        </Cell>
                    </Column>
                )}

                {/* @ts-expect-error coluna não pode receber children */}
                <Column sortable width={124}>
                    <HeaderCell className={styles['gerenciador-page__table__header-cell']}>
                        <div>
                            <p>Preço</p>
                            <p>vigente</p>
                        </div>
                    </HeaderCell>
                    <Cell dataKey="priceRetail">{(rowData: RowData) => <PrecoVigenteCell rowData={rowData} handleContextMenu={handleContextMenu} />}</Cell>
                </Column>
                {/* @ts-expect-error coluna não pode receber children */}
                <Column sortable width={127}>
                    <HeaderCell className={styles['gerenciador-page__table__header-cell']}>
                        <div>
                            <p>Preço</p>
                            <p>concorrente</p>
                        </div>
                    </HeaderCell>
                    <Cell dataKey="competitorsPrice">
                        {(rowData: RowData) => <MemoCompetitorPriceCell rowData={rowData} handleContextMenu={handleContextMenu} />}
                    </Cell>
                </Column>
                {/* @ts-expect-error coluna não pode receber children */}
                <Column sortable width={132}>
                    <HeaderCell>
                        <CustomHeaderCell dataKey={datakeys.price} value={sortColumn} data={sortOptions.price} onChangeSort={onUpdateSortColumn}>
                            Preço sugerido
                        </CustomHeaderCell>
                    </HeaderCell>
                    <Cell className="table__input-group" dataKey={datakeys.price}>
                        {(rowData: RowData, index: number) => (
                            <PrecoSugeridoCell
                                dataKey="novoPreco"
                                rowData={rowData}
                                index={index}
                                handleUpdateCampo={handleUpdateCampo}
                                handleBlur={handleBlur}
                                handleContextMenu={handleContextMenu}
                            />
                        )}
                    </Cell>
                </Column>
                {/* @ts-expect-error coluna não pode receber children */}
                <Column sortable width={116}>
                    <HeaderCell>
                        <CustomHeaderCell dataKey={datakeys.cpi} value={sortColumn} data={sortOptions.cpi} onChangeSort={onUpdateSortColumn}>
                            <HoverNomeColuna placement="bottom" text="CPI" tooltip="Competitividade" />
                        </CustomHeaderCell>
                    </HeaderCell>
                    <Cell dataKey={datakeys.cpi}>
                        {(rowData: RowData, index: number) => (
                            <CpiCell
                                dataKey="novaCompetitividade"
                                rowData={rowData}
                                index={index}
                                handleContextMenu={handleContextMenu}
                                handleUpdateCampo={handleUpdateCampo}
                                handleBlur={handleBlur}
                            />
                        )}
                    </Cell>
                </Column>
                {/* @ts-expect-error coluna não pode receber children */}
                <Column sortable width={116}>
                    <HeaderCell className={styles['gerenciador-page__table__header-cell']}>
                        <CustomHeaderCell dataKey={datakeys.newMargin} value={sortColumn} data={sortOptions.newMargin} onChangeSort={onUpdateSortColumn}>
                            Margem
                        </CustomHeaderCell>
                    </HeaderCell>
                    <Cell className="table__input-group" dataKey={datakeys.newMargin}>
                        {(rowData: RowData, index: number) => (
                            <MargemCell
                                dataKey="novaMargem"
                                rowData={rowData}
                                index={index}
                                handleUpdateCampo={handleUpdateCampo}
                                handleContextMenu={handleContextMenu}
                                handleBlur={handleBlur}
                            />
                        )}
                    </Cell>
                </Column>
                {/* @ts-expect-error coluna não pode receber children */}
                <Column width={140}>
                    <HeaderCell className={styles['gerenciador-page__table__header-cell']}>
                        Previsão
                        <Whisper placement="bottom" speaker={<Tooltip>Previsão de receita para 7 dias</Tooltip>}>
                            <span>
                                <Icon
                                    icon="info"
                                    style={{
                                        fontSize: '12px',
                                        marginLeft: '2px',
                                    }}
                                />
                            </span>
                        </Whisper>
                    </HeaderCell>
                    <Cell dataKey="demandForecast">
                        {(rowData: RowData, index: number) => (
                            <BaseCell
                                rowData={rowData}
                                handleContextMenu={handleContextMenu}
                                onClick={() => {
                                    if (!rowData.novoCalculo && rowData?.demandForecast) {
                                        handleOpenModalPrevisao(rowData.demandForecast, index);
                                    }
                                }}
                            >
                                <div className="previsao-demanda">
                                    {rowData.novoCalculo ? (
                                        <span className="calculo">
                                            {rowData.loadingCalc ? (
                                                <Loader content="Carregando..." />
                                            ) : (
                                                <Button
                                                    title="Calcular"
                                                    appearance="primary"
                                                    block
                                                    onClick={() => {
                                                        calcularRevisao(rowData, index);
                                                    }}
                                                >
                                                    Calcular
                                                </Button>
                                            )}
                                        </span>
                                    ) : rowData.demandForecast?.new_price_scenario?.revenue_forecast && !rowData.novoCalculo ? (
                                        <>
                                            <CurrencyFormat
                                                className="currency"
                                                fixedDecimalScale
                                                decimalScale={2}
                                                value={rowData?.demandForecast?.new_price_scenario?.revenue_forecast}
                                                displayType="text"
                                                prefix="R$"
                                                decimalSeparator=","
                                                thousandSeparator="."
                                            />
                                            {rowData?.demandForecast?.new_price_scenario?.revenue_forecast >
                                                rowData.demandForecast?.base_price_scenario?.revenue_forecast ? (
                                                <ArrowUpwardRoundedIcon
                                                    className="positive"
                                                    style={{
                                                        fontSize: 16,
                                                    }}
                                                />
                                            ) : rowData?.demandForecast?.new_price_scenario?.revenue_forecast <
                                                rowData.demandForecast?.base_price_scenario?.revenue_forecast ? (
                                                <ArrowDownwardRoundedIcon
                                                    className="negative"
                                                    style={{
                                                        fontSize: 16,
                                                    }}
                                                />
                                            ) : null}
                                        </>
                                    ) : (
                                        '--'
                                    )}
                                </div>
                            </BaseCell>
                        )}
                    </Cell>
                </Column>
                {/* @ts-expect-error coluna não pode receber children */}
                <Column width={26}>
                    <HeaderCell />
                    <Cell dataKey="wholesale">
                        {(rowData: RowData) => (
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                {/* TODO: arrumar os estilos com css */}
                                <MemoExpandWholesaleCell rowData={rowData} handleContextMenu={handleContextMenu}>
                                    2
                                </MemoExpandWholesaleCell>
                                <MemoExpandOfferCell rowData={rowData} handleContextMenu={handleContextMenu}>
                                    3
                                </MemoExpandOfferCell>
                            </div>
                        )}
                    </Cell>
                </Column>
            </Table>

            {selectedRowData && (
                <GetClusterListStoreModal
                    rowData={selectedRowData}
                    show={showModalClusterStore}
                    onHide={() => {
                        setShowModalClusterStore(false);
                        setSelectedRowData(null);
                    }}
                />
            )}
        </>
    );
};

export default memo(TableGerenciador);
