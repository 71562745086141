import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../@types/RootState';

type Modals = 'ADD_CLUSTER';

type State = Record<Modals, boolean>;

const initialState: State = {
    ADD_CLUSTER: false,
};

const slice = createSlice({
    name: 'ipa/setup-form/stores-and-clusters/modals',
    initialState,
    reducers: {
        OPEN_MODAL: (state, action: PayloadAction<Modals>) => {
            console.log({ action });
            state[action.payload] = true;
        },
        CLOSE_MODAL: (state, action: PayloadAction<Modals>) => {
            state[action.payload] = false;
        },
    },
});

export const { OPEN_MODAL, CLOSE_MODAL } = slice.actions;

export default slice.reducer;

export const selectorIpaFormStoresAndClustersModals = (state: RootState) => {
    return state.reducerIPA.storesAndClusters.modals;
};
