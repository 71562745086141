import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { getProdutosMenorMargemAplicada } from '../services';
// @ts-ignore
import { format } from 'date-fns';
import { FiltrosContextMargem } from '../Pages/MargemPraticadaGeral';

export const useProdutosMenorMargemPraticada = () => {
    const filtros = useContext(FiltrosContextMargem);

    const listarProdutosMenorMargemPraticada = async () => {
        const params = {
            filters: [
                {
                    filter: 'STOREID',
                    values: filtros?.lojasSelecionadas || null,
                },
                {
                    filter: 'PRODUCTID',
                    values: filtros?.produtosSelecionados || null,
                },
                {
                    filter: 'STARTDATE',
                    values: filtros?.periodoSelecionado?.length
                        ? [format(filtros.periodoSelecionado[0], 'yyyy-MM-dd')]
                        : null,
                },
                {
                    filter: 'ENDDATE',
                    values: filtros?.periodoSelecionado?.length
                        ? [format(filtros.periodoSelecionado[1], 'yyyy-MM-dd')]
                        : null,
                },
                {
                    filter: 'STORETYPE',
                    values: filtros?.tipoLojasSelecionadas || null,
                },
                {
                    filter: 'CATEGORYLEVEL1',
                    values: filtros?.categoriaNivel1Selecionadas || null,
                },
                {
                    filter: 'CATEGORYLEVEL2',
                    values: filtros?.categoriaNivel2Selecionadas || null,
                },
            ],
        };

        const resp = await getProdutosMenorMargemAplicada(params);
        if (resp.length) {
            const productId = Object.keys(resp[0])[0]?.split('.');
            const description = Object.keys(resp[0])[1]?.split('.');
            const sumSalesAmount = Object.keys(resp[0])[4]?.split('.');
            const realizedMargin = Object.keys(resp[0])[2]?.split('.');
            const marginGain = Object.keys(resp[0])[3]?.split('.');
            return resp.map((item: { [x: string]: any }) => ({
                [productId[1]]: item[Object.keys(item)[0]],
                [description[1]]: item[Object.keys(item)[1]],
                [realizedMargin[1]]: parseFloat(item[Object.keys(item)[2]])
                    .toFixed(2)
                    .replace('.', ','),
                [sumSalesAmount[1]]: item[Object.keys(item)[4]],
                [marginGain[1]]: parseFloat(item[Object.keys(item)[3]])?.toLocaleString(
                    'pt-BR',
                    {
                        style: 'currency',
                        currency: 'BRL',
                    }
                ),
            }));
        }
        return [];
    };

    const { isLoading, isError, data, error } = useQuery(
        ['listaProdutosMenorMargemPraticada', filtros],
        listarProdutosMenorMargemPraticada,
        {
            retry: false,
            cacheTime: 6 * 180 * 1000,
            staleTime: 6 * 180 * 1000,
        }
    );

    return {
        isLoading,
        isError,
        data,
        error,
    };
};
