import React from 'react';
import { Table } from 'rsuite';
import CurrencyFormat from 'react-currency-format';
import ModalConfirm from '../../../../../../../components/ModalConfirm';
import { Alert } from '../../../../components';
import { PriceAnalysisComercialTableDataTypes } from '../../../ManageComercialTable/types';
import styles from './ModalPriceAnalysis.module.scss';

const {
    Column, HeaderCell, Cell,
} = Table;

type ModalPriceAnalysisProps = {
    show?: boolean,
    isAlertMessage?: boolean,
    title?: string,
    subtitle?: string,
    className?: string,
    data?: PriceAnalysisComercialTableDataTypes[],
    tableHeight?: number,
    onCancel?: () => void,
    onConfirm?: () => void,
}

export const ModalPriceAnalysis = ({
    show,
    isAlertMessage,
    title,
    subtitle,
    data,
    tableHeight,
    className,
    onCancel,
    onConfirm,
}: ModalPriceAnalysisProps) => {
    return (
        <ModalConfirm
            show={show}
            className={[styles['modal-price-analysis'], className === 'base' ? styles.base : styles.comercial].join(' ')}
            modalWidth="600px"
            titleFontSize="16px"
            footerAlign="end"
            title={title}
            upMessage={subtitle}
            cancelButton="Fechar"
            onCancel={onCancel}
            onConfirm={onConfirm}
            onHideHeaderButton={onCancel}
        >
            <div className={styles.container}>
                <Table
                    data={data}
                    // autoHeight
                    bordered
                    id="table-price-analysis"
                    className={styles['table-price-analysis']}
                    height={tableHeight}
                    // rowClassName={styles['table-scenario-row']}
                    // rowHeight={52}
                    headerHeight={42}
                    // sortColumn={tableState.sortColumn}
                    // sortType={tableState.sortType}
                    // onSortColumn={tableHandlers.handleSortColumns}
                >
                    <Column flexGrow={1}>
                        <HeaderCell />
                        <Cell dataKey="names">
                            {(rowData) => (
                                    <p className={styles['main-bold-text']}>
                                        {rowData.name}
                                    </p>
                                )}
                        </Cell>
                    </Column>
                    <Column sortable>
                        <HeaderCell>
                            Preço
                        </HeaderCell>
                        <Cell dataKey="price">
                            {(rowData) => (
                                    <p className={styles['main-bold-text']}>
                                        <CurrencyFormat
                                            fixedDecimalScale={true}
                                            decimalScale={2}
                                            value={rowData.price}
                                            displayType={'text'}
                                            prefix={'R$ '}
                                            decimalSeparator={','}
                                            thousandSeparator={'.'}
                                        />
                                    </p>
                                )}
                        </Cell>
                    </Column>
                    <Column sortable>
                        <HeaderCell>
                            Diferença
                        </HeaderCell>
                        <Cell dataKey="diff">
                            {(rowData) => (
                                    <p className={[styles['main-bold-text'], styles.diff].join(' ')}>
                                        <CurrencyFormat
                                            fixedDecimalScale={true}
                                            decimalScale={2}
                                            value={rowData?.diff || ''}
                                            displayType={'text'}
                                            suffix={'%'}
                                            decimalSeparator={','}
                                            thousandSeparator={'.'}
                                        />
                                    </p>
                                )}
                        </Cell>
                    </Column>
                </Table>
            </div>
            {isAlertMessage ? (
                <Alert
                    title="Atenção!"
                    message="Desde a última aplicação do preço na tabela base não foi adicionado um novo Preço médio atual"
                    className={styles.alert}
                />
            ) : null}
        </ModalConfirm>
    );
};
