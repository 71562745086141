import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'rsuite';
import { UpdatedHeader } from '../../../../../../../components/UpdatedHeader';
import { LinkButton } from '../../../../../../../components';
import styles from './HeaderEditBaseTable.module.scss';
import { TabelaBase } from '../../../ManageComercialTable/types';

type HeaderEditBaseTableTypes = {
    updateData?: Omit<TabelaBase, 'deletadoEm'>,
    onClickEdit?: () => void,
}

export const HeaderEditBaseTable = ({ updateData, onClickEdit }: HeaderEditBaseTableTypes) => {
    const history = useHistory();

    return (
        <div className={styles['flex-between']}>
            <div>
                <UpdatedHeader
                    title="Editar tabela base"
                    date={updateData?.atualizadoEm}
                />
                <div className={styles['subtitle-wrapper']}>
                    <p className={styles.subtitle}>
                        As alterações são salvas automaticamente
                    </p>
                    <LinkButton
                        fontSize="12px"
                        onClick={onClickEdit}
                    >
                        Editar custos gerais da tabela
                    </LinkButton>
                </div>
            </div>
            <Button
                className={styles['btn-consultar-tabela-base']}
                onClick={() => history.push('/ipa/tabela-base-atual')}
            >
                Consultar tabela base atual
            </Button>
        </div>
    )
}
