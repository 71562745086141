import React, { useEffect, useState } from 'react';
import CurrencyFormat from 'react-currency-format';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import {
    Alert,
    Button,
    CheckPicker,
    Col,
    Container,
    Content,
    FlexboxGrid,
    Icon,
    IconButton,
    Input,
    InputGroup,
    SelectPicker,
    Table,
} from 'rsuite';
import { LoadingSpiner } from '../../../components/LoadingSpinner';
import ModalConfirmacao from '../../../components/ModalConfirmacao';
import { getLojas } from '../../../services/LojaService';
import { deleteLimite, getAuthors, getLimitType, getLimites } from './services';

const { Column, HeaderCell, Cell, Pagination } = Table;

const LimitesPreco = ({ history }) => {
    const [name, setName] = useState('');
    const [sort, setSort] = useState({});
    const [limitType, setLimitType] = useState('');
    const [page, setPage] = useState(0);
    const [size, setSize] = useState(20);
    const [data, setData] = useState([]);
    const [tiposLimite, setTiposLimite] = useState([]);
    const [authors, setAuthors] = useState([]);
    const [showModalConfirmacaoExclusao, setShowModalConfirmacaoExclusao] =
        useState(false);
    const [deleteMessage, setDeleteMessage] = useState('');
    const [limiteToDelete, setLimiteToDelete] = useState(null);
    const [tipoDescricao, setTipoDescricao] = useState('S');
    const [description, setDescription] = useState('');
    const [criador, setCriador] = useState({ value: [], cache: [] });
    const [lojas, setLojas] = useState([]);
    const [loadingLojas, setLoadingLojas] = useState(false);
    const [lojasSelecionadas, setLojasSelecionadas] = useState({
        value: [],
        cache: [],
    });
    const [lojasImmutable, setLojasImmutable] = useState([]);

    const getModelFiltros = () => ({
        name: name || null,
        limitType: limitType || null,
        author: criador.value ? criador.value : null,
        storeIds: lojasSelecionadas.value ? lojasSelecionadas.value : null,
        productDescription:
            tipoDescricao === 'P' && description ? description : null,
        category: tipoDescricao === 'S' && description ? description : null,
        page: page || 0,
        size: size || 20,
        sort:
            sort?.type && sort?.orderBy ? `${sort.type},${sort.orderBy}` : null,
    });

    const listLimites = async () => {
        const data = getModelFiltros();
        const response = await getLimites(data);
        if (response) {
            setData(response);
        }
    };

    const listLojas = async (query) => {
        setLoadingLojas(true);
        const response = await getLojas({ query });
        if (response) {
            setLojas(
                response?.map((item) => ({
                    value: item.store_id,
                    label: `${item.store_id} - ${item.store}`,
                    store_id: item.store_id,
                })),
            );
            setLojasImmutable(
                response?.map((item) => ({
                    value: item.store_id,
                    label: `${item.store_id} - ${item.store}`,
                })),
            );
        }
        setLoadingLojas(false);
    };

    const searchCriador = async (query) => {
        const param = { author: query || null };

        const response = await getAuthors(param);
        if (response) {
            setAuthors(
                response.content
                    .map((item) => ({
                        value: item,
                        label: item,
                    }))
                    .concat(
                        criador.cache.filter(
                            (itemCache) =>
                                response.content.findIndex(
                                    (itemLista) =>
                                        itemLista === itemCache.label,
                                ) === -1,
                        ),
                    ),
            );
        }
    };

    const getTypes = async () => {
        const response = await getLimitType();
        if (response) {
            setTiposLimite(
                response.map((item) => ({
                    value: item,
                    label: item,
                })),
            );
        }
    };

    const handleEdit = (item) => {
        history.push(`/ipa/limites-preco/${item.limitId}`);
    };

    const handleChangeFiltroCriador = (value) => {
        setCriador((prevState) => ({ ...prevState, value }));

        if (value === null) {
            setCriador((prevState) => ({ ...prevState, cache: [] }));
            searchCriador('');
        }
        if (value !== null && value.length !== criador.cache.length) {
            const newCacheProduto = criador.cache.filter(
                (item) => value.indexOf(item.value) !== -1,
            );

            setCriador((prevState) => ({
                ...prevState,
                cache: newCacheProduto,
            }));
        }
    };

    const handleChangeFiltroLojas = (value) => {
        setLojasSelecionadas((prevState) => ({ ...prevState, value }));

        if (value === null) {
            setLojasSelecionadas((prevState) => ({ ...prevState, cache: [] }));
        }
        if (value !== null && value.length !== lojasSelecionadas.cache.length) {
            const newCacheLojas = lojasSelecionadas.cache.filter(
                (item) => value.indexOf(item.value) !== -1,
            );

            setLojasSelecionadas((prevState) => ({
                ...prevState,
                cache: newCacheLojas,
            }));
        }
    };

    const handleSelectCriador = (value, item) => {
        const cacheCriador = criador.cache;
        cacheCriador.push(item);
        setCriador((prevState) => ({ ...prevState, cache: cacheCriador }));
    };

    const handleSelectLojas = (value, item) => {
        const cacheLojas = lojasSelecionadas.cache;
        cacheLojas.push(item);
        setLojasSelecionadas((prevState) => ({
            ...prevState,
            cache: cacheLojas,
        }));
    };

    const handleDeleteLimite = (limite) => {
        setDeleteMessage('Deseja mesmo deletar esse limite?');
        setShowModalConfirmacaoExclusao(true);
        setLimiteToDelete(limite);
    };

    const goNovoLimite = () => {
        history.push('/ipa/limites-preco/cadastro');
    };

    const cancelExclusao = () => {
        setDeleteMessage('');
        setShowModalConfirmacaoExclusao(false);
        setLimiteToDelete(null);
    };

    const confirmExclusao = async () => {
        const response = await deleteLimite(limiteToDelete.limitId);
        if (response) {
            Alert.success('Limite deletado com sucesso');
            setDeleteMessage('');
            setShowModalConfirmacaoExclusao(false);
            setLimiteToDelete(null);
            listLimites();
        }
    };

    useEffect(() => {
        listLimites();
        searchCriador();
        getTypes();
        listLojas();
    }, []);

    useEffect(() => {
        listLimites();
    }, [
        page,
        size,
        criador,
        lojasSelecionadas,
        description,
        name,
        sort,
        limitType,
    ]);


    return (
        <Container className="main-container limite-preco-page">
            <Content className="secao-filtros">
                <FlexboxGrid justify="space-between">
                    <FlexboxGrid.Item>
                        <h4 className="limites-title">Limites de Preço</h4>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item>
                        <Button
                            title="Novo limite"
                            block
                            onClick={goNovoLimite}
                            appearance="primary"
                        >
                            Novo limite
                        </Button>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
                <FlexboxGrid>
                    <FlexboxGrid.Item
                        componentClass={Col}
                        lg={8}
                        md={8}
                        sm={24}
                        xs={24}
                    >
                        <InputGroup>
                            <InputGroup.Button
                                appearance={
                                    tipoDescricao === 'S' ? 'primary' : 'ghost'
                                }
                                onClick={() => setTipoDescricao('S')}
                            >
                                Segmento
                            </InputGroup.Button>
                            <InputGroup.Button
                                appearance={
                                    tipoDescricao === 'P' ? 'primary' : 'ghost'
                                }
                                onClick={() => setTipoDescricao('P')}
                            >
                                Produto
                            </InputGroup.Button>
                            <Input
                                placeholder="Descrição"
                                name="descricaco"
                                onChange={(value) => setDescription(value)}
                            />
                        </InputGroup>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item
                        componentClass={Col}
                        lg={4}
                        md={4}
                        sm={24}
                        xs={24}
                        style={{ marginTop: '2px' }}
                    >
                        <Input
                            placeholder="Nome do limite"
                            className="filtros__input"
                            onChange={(value) => setName(value)}
                        />
                    </FlexboxGrid.Item>
                </FlexboxGrid>
                <FlexboxGrid>
                    <FlexboxGrid.Item
                        componentClass={Col}
                        lg={4}
                        md={4}
                        sm={24}
                        xs={24}
                        style={{ marginTop: '2px' }}
                    >
                        <SelectPicker
                            className="inf-picker"
                            block
                            cleanable
                            searchable={false}
                            data={tiposLimite}
                            name="tipoLimite"
                            placeholder="Tipo do limite"
                            onSelect={(value) => setLimitType(value)}
                            onClean={() => setLimitType('')}
                        />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item
                        componentClass={Col}
                        lg={4}
                        md={4}
                        sm={24}
                        xs={24}
                        style={{ marginTop: '2px' }}
                    >
                        <CheckPicker
                            className="inf-picker"
                            cleanable
                            searchable
                            data={authors}
                            name="author"
                            placeholder="Criador"
                            locale={{
                                noResultsText: 'Nenhum item encontrado',
                                searchPlaceholder: 'Busque o criador desejado',
                            }}
                            onSelect={handleSelectCriador}
                            onChange={(value) =>
                                handleChangeFiltroCriador(value)
                            }
                            onSearch={(search) => searchCriador(search)}
                        />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item
                        componentClass={Col}
                        lg={4}
                        md={4}
                        sm={24}
                        xs={24}
                        style={{ marginTop: '2px' }}
                    >
                        <CheckPicker
                            className="inf-picker"
                            cleanable
                            searchable
                            data={lojas}
                            name="lojas"
                            placeholder="Lojas"
                            locale={{
                                noResultsText: loadingLojas
                                    ? 'Carregando lojas...'
                                    : 'Nenhum item encontrado',
                                searchPlaceholder: 'Pesquisar',
                            }}
                            onSelect={handleSelectLojas}
                            onChange={(value) => handleChangeFiltroLojas(value)}
                            onClean={() =>
                                setLojasSelecionadas({ value: [], cache: [] })
                            }
                            onSearch={(search) =>
                                setLojas(
                                    lojasImmutable.filter((loja) =>
                                        loja.label.includes(search),
                                    ),
                                )
                            }
                            onClose={() => setLojas(lojasImmutable)}
                        />
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </Content>
            <Content>
                <FlexboxGrid>
                    <FlexboxGrid.Item colspan={24}>
                        <LoadingSpiner size="md" />

                        <FlexboxGrid>
                            <FlexboxGrid.Item colspan={24}>
                                <Table
                                    data={data.content}
                                    autoHeight
                                    className="table"
                                    sortColumn={sort.type}
                                    sortType={sort.orderBy}
                                    onSortColumn={(sortColumn, sortType) =>
                                        setSort({
                                            type: sortColumn,
                                            orderBy: sortType,
                                        })
                                    }
                                >
                                    <Column sortable flexGrow={2}>
                                        <HeaderCell>Nome do limite</HeaderCell>
                                        <Cell dataKey="name" />
                                    </Column>
                                    <Column flexGrow={2} align="left">
                                        <HeaderCell>
                                            Produto ou categoria
                                        </HeaderCell>
                                        <Cell>
                                            {(rowData) => {
                                                const segmentos =
                                                    rowData.segments
                                                        ? rowData.segments
                                                              .map((item) =>
                                                                  item.level ===
                                                                  'productId'
                                                                      ? item.value +
                                                                        (item.description
                                                                            ? ` - ${item.description}`
                                                                            : '')
                                                                      : item.value,
                                                              )
                                                              .join('; ')
                                                        : null;
                                                return (
                                                    <span title={segmentos}>
                                                        {segmentos}
                                                    </span>
                                                );
                                            }}
                                        </Cell>
                                    </Column>
                                    <Column flexGrow={1}>
                                        <HeaderCell>Loja ou cluster</HeaderCell>
                                        <Cell>
                                            {(rowData) => (
                                                <span>
                                                    {rowData?.clusters?.map((cluster) => cluster.label).join(
                                                        ';',
                                                    ) || '--'}
                                                </span>
                                            )}
                                        </Cell>
                                    </Column>
                                    <Column flexGrow={1}>
                                        <HeaderCell>Segmentação</HeaderCell>
                                        <Cell>
                                            {(rowData) => {
                                                const sensibility = {
                                                    SENSITIVE: 'Sensível',
                                                    NOT_SENSITIVE:
                                                        'Não sensível',
                                                    SUPER_SENSITIVE:
                                                        'Super sensível',
                                                };
                                                const segmentacaoLimites = [
                                                    ...(rowData.sensibilityType
                                                        ?.length
                                                        ? [
                                                              rowData.sensibilityType,
                                                          ]
                                                        : []),
                                                    ...(rowData
                                                        .infoPriceSensibility
                                                        ?.length
                                                        ? [
                                                              sensibility[
                                                                  rowData
                                                                      .infoPriceSensibility
                                                              ],
                                                          ]
                                                        : []),
                                                    ...(rowData
                                                        .infoPriceGlobalAbcClass
                                                        ?.length
                                                        ? [
                                                              `Global ${rowData.infoPriceGlobalAbcClass}`,
                                                          ]
                                                        : []),
                                                    ...(rowData.abcClass
                                                        ? [rowData.abcClass]
                                                        : []),
                                                    ...(rowData
                                                        .infoPriceAbcClass
                                                        ?.length
                                                        ? [
                                                              `Local ${rowData.infoPriceAbcClass}`,
                                                          ]
                                                        : []),
                                                ];
                                                return (
                                                    <span
                                                        title={
                                                            segmentacaoLimites
                                                        }
                                                    >
                                                        {segmentacaoLimites?.length
                                                            ? segmentacaoLimites.join(
                                                                  '; ',
                                                              )
                                                            : '--'}
                                                    </span>
                                                );
                                            }}
                                        </Cell>
                                    </Column>
                                    <Column flexGrow={2}>
                                        <HeaderCell>Margem</HeaderCell>
                                        <Cell>
                                            {(rowData) => {
                                                const maxMargem =
                                                    rowData.maxMargin
                                                        ? rowData.maxMargin *
                                                          100
                                                        : '';
                                                const minMargem =
                                                    rowData.minMargin
                                                        ? rowData.minMargin *
                                                          100
                                                        : '';
                                                return (
                                                    <p>
                                                        {!minMargem &&
                                                        !maxMargem
                                                            ? '--'
                                                            : null}
                                                        {minMargem ? (
                                                            <span>
                                                                Mín:
                                                                <CurrencyFormat
                                                                    fixedDecimalScale
                                                                    decimalScale={
                                                                        2
                                                                    }
                                                                    value={
                                                                        minMargem
                                                                    }
                                                                    displayType="text"
                                                                    suffix="%"
                                                                    decimalSeparator=","
                                                                    thousandSeparator="."
                                                                />
                                                            </span>
                                                        ) : (
                                                            ''
                                                        )}
                                                        {minMargem &&
                                                        maxMargem ? (
                                                            <span> | </span>
                                                        ) : (
                                                            ''
                                                        )}
                                                        {maxMargem ? (
                                                            <span>
                                                                Max:
                                                                <CurrencyFormat
                                                                    fixedDecimalScale
                                                                    decimalScale={
                                                                        2
                                                                    }
                                                                    value={
                                                                        maxMargem
                                                                    }
                                                                    displayType="text"
                                                                    suffix="%"
                                                                    decimalSeparator=","
                                                                    thousandSeparator="."
                                                                />
                                                            </span>
                                                        ) : (
                                                            ''
                                                        )}
                                                    </p>
                                                );
                                            }}
                                        </Cell>
                                    </Column>
                                    <Column flexGrow={2}>
                                        <HeaderCell>Competitividade</HeaderCell>
                                        <Cell>
                                            {(rowData) => {
                                                const maxCpi = rowData.maxCpi
                                                    ? rowData.maxCpi * 100
                                                    : '';
                                                const minCpi = rowData.minCpi
                                                    ? rowData.minCpi * 100
                                                    : '';
                                                return (
                                                    <p>
                                                        {!minCpi && !maxCpi
                                                            ? '--'
                                                            : null}
                                                        {minCpi ? (
                                                            <span>
                                                                Mín:
                                                                <CurrencyFormat
                                                                    fixedDecimalScale
                                                                    decimalScale={
                                                                        2
                                                                    }
                                                                    value={
                                                                        minCpi
                                                                    }
                                                                    displayType="text"
                                                                    suffix="%"
                                                                    decimalSeparator=","
                                                                    thousandSeparator="."
                                                                />
                                                            </span>
                                                        ) : (
                                                            ''
                                                        )}
                                                        {minCpi && maxCpi ? (
                                                            <span> | </span>
                                                        ) : (
                                                            ''
                                                        )}
                                                        {maxCpi ? (
                                                            <span>
                                                                Max:
                                                                <CurrencyFormat
                                                                    fixedDecimalScale
                                                                    decimalScale={
                                                                        2
                                                                    }
                                                                    value={
                                                                        maxCpi
                                                                    }
                                                                    displayType="text"
                                                                    suffix="%"
                                                                    decimalSeparator=","
                                                                    thousandSeparator="."
                                                                />
                                                            </span>
                                                        ) : (
                                                            ''
                                                        )}
                                                    </p>
                                                );
                                            }}
                                        </Cell>
                                    </Column>
                                    <Column sortable flexGrow={1}>
                                        <HeaderCell>Criador</HeaderCell>
                                        <Cell dataKey="author" />
                                    </Column>
                                    <Column flexGrow={1}>
                                        <HeaderCell>Status</HeaderCell>
                                        <Cell dataKey="status" />
                                    </Column>

                                    <Column flexGrow={1} fixed="right">
                                        <HeaderCell />

                                        <Cell className="table__edit-button">
                                            {(rowData) => (
                                                <span>
                                                    <IconButton
                                                        title="Editar"
                                                        onClick={() =>
                                                            handleEdit(rowData)
                                                        }
                                                        size="md"
                                                        icon={
                                                            <Icon icon="edit2" />
                                                        }
                                                    />
                                                    <IconButton
                                                        onClick={() =>
                                                            handleDeleteLimite(
                                                                rowData,
                                                            )
                                                        }
                                                        title="Deletar"
                                                        size="md"
                                                        icon={
                                                            <Icon icon="trash2" />
                                                        }
                                                    />
                                                </span>
                                            )}
                                        </Cell>
                                    </Column>
                                </Table>
                                <Pagination
                                    lengthMenu={[
                                        {
                                            value: 20,
                                            label: 20,
                                        },
                                        {
                                            value: 40,
                                            label: 40,
                                        },
                                    ]}
                                    activePage={data.number + 1}
                                    displayLength={data.size}
                                    total={data.totalElements}
                                    onChangePage={(value) => setPage(value - 1)}
                                    onChangeLength={(value) => setSize(value)}
                                />
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </Content>
            <ModalConfirmacao
                show={showModalConfirmacaoExclusao}
                onConfirm={confirmExclusao}
                onCancel={cancelExclusao}
                title="Deletar limite"
                message={deleteMessage}
                confirmButton="Confirmar"
                cancelButton="Cancelar"
            />
        </Container>
    );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(LimitesPreco),
);
