import { z } from 'zod';

export const schema = z.object({
    filters: z
        .object({
            categories: z.array(z.string()).default([]),
            storesId: z.array(z.string()).default([]),
            controlStoreId: z.string().default(''),
        })
        .superRefine((data, ctx) => {
            if (data.categories.length === 0) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: 'Pelo menos uma categoria deve ser selecionada.',
                    path: ['categories'],
                });
            }
            if (data.storesId.length === 0) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: 'Pelo menos uma loja deve ser selecionada.',
                    path: ['storesId'],
                });
            }
            if (!data.controlStoreId) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: 'Uma loja de controle deve ser selecionada.',
                    path: ['controlStoreId'],
                });
            }
        }),
    coordinates: z
        .object({
            objective: z.enum(['profit', 'revenue']).default('profit'),
            margin: z
                .object({
                    active: z.boolean().default(false),
                    min: z.coerce.number().min(-80, 'O valor mínimo é de -80').max(79.99, 'A margem não pode ser maior ou igual a 80').nullable().default(null),
                    max: z.coerce.number().min(-80, 'O valor mínimo é de -80').max(79.99, 'A margem não pode ser maior ou igual a 80').nullable().default(null),
                })
                .superRefine((data, ctx) => {
                    if (!data.active) return z.NEVER;

                    const min = data.min;
                    const max = data.max;

                    if (min === null) {
                        ctx.addIssue({
                            code: z.ZodIssueCode.custom,
                            message: 'O campo % Mínimo deve ser informado.',
                            path: ['min'],
                        });
                    } else if (max === null) {
                        ctx.addIssue({
                            code: z.ZodIssueCode.custom,
                            message: 'O campo % Máximo deve ser informado.',
                            path: ['max'],
                        });
                    } else if (min === max) {
                        ctx.addIssue({
                            code: z.ZodIssueCode.custom,
                            message: 'Os campos % Mínimo e % Máximo devem ter valores diferentes.',
                            path: [''],
                        });
                    } else if (min > max) {
                        ctx.addIssue({
                            code: z.ZodIssueCode.custom,
                            message: 'O campo % Mínimo não pode ser maior que o % Máximo.',
                            path: [''],
                        });
                    }
                }),
            competitiveness: z
                .object({
                    active: z.boolean().default(false),
                    min: z.coerce.number().nonnegative('Não é permitido valor negativo').nullable().default(null),
                    max: z.coerce.number().nonnegative('Não é permitido valor negativo').nullable().default(null),
                })
                .superRefine((data, ctx) => {
                    if (!data.active) return z.NEVER;

                    const min = data.min;
                    const max = data.max;

                    if (min === null || min < 50) {
                        ctx.addIssue({
                            code: z.ZodIssueCode.too_small,
                            message: 'O valor mínimo de competitividade deve ser 50.',
                            inclusive: true,
                            minimum: 50,
                            type: 'number',
                            path: ['min'],
                        });
                    } else if (max === null || max < 50) {
                        ctx.addIssue({
                            code: z.ZodIssueCode.too_small,
                            message: 'O valor mínimo de competitividade deve ser 50.',
                            inclusive: true,
                            minimum: 50,
                            type: 'number',
                            path: ['max'],
                        });
                    } else if (min === max) {
                        ctx.addIssue({
                            code: z.ZodIssueCode.custom,
                            message: 'Os campos % Mínimo e % Máximo devem ter valores diferentes.',
                            path: [''],
                        });
                    } else if (min > max) {
                        ctx.addIssue({
                            code: z.ZodIssueCode.custom,
                            message: 'O campo % Mínimo não pode ser maior que o % Máximo.',
                            path: ['/'],
                        });
                    }
                }),
            minVariationToChangePrice: z.coerce.number().min(0.01, 'Variação deve ser maior que 0').max(100, 'Variação deve ser menor que 100').default(0),
        })
        .superRefine(({ competitiveness, margin }, ctx) => {
            if (!competitiveness.active && !margin.active) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: 'Margem e competitividade desabilitados, selecione pelo menos um.',
                    path: ['margin', 'active'],
                });
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: 'Competitividade e margem desabilitados, selecione pelo menos um.',
                    path: ['competitiveness', 'active'],
                });
            }
        }),
    scheduleOptimizationPrices: z.object({
        period: z
            .object({
                value: z.enum(['weekly', 'monthly']).default('weekly'),
                options: z.array(z.string()).default([]),
            })
            .superRefine(({ value, options }, ctx) => {
                if (value === 'weekly' && options.length === 0) {
                    ctx.addIssue({
                        code: z.ZodIssueCode.custom,
                        message: 'Selecione pelo menos um dia da semana.',
                        path: ['options'],
                    });
                }
            }),
        validity: z
            .object({
                start: z.object({
                    active: z.boolean().default(true),
                    startDate: z.date().default(new Date()),
                }),
                end: z
                    .object({
                        active: z.boolean().default(false),
                        endDate: z.date().default(new Date()).optional(),
                    })
                    .optional(),
            })
            .superRefine((data, ctx) => {
                if (data.end?.active && !data.end.endDate) {
                    ctx.addIssue({
                        code: z.ZodIssueCode.invalid_type,
                        message: 'O campo Data Final deve ser preenchido.',
                        expected: 'date',
                        received: 'undefined',
                        path: ['end.endDate'],
                    });
                }

                if (data?.end?.endDate && data.start.startDate > data.end.endDate) {
                    ctx.addIssue({
                        code: z.ZodIssueCode.invalid_date,
                        message: 'A data final deve ser maior que a data inicial.',
                        path: ['start.startDate'],
                    });
                }
            }),
    }),
});

export type FormOptimizationPrices = z.infer<typeof schema>;
