/* eslint-disable max-len */
import CryptoJS from 'crypto-js';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import {
    Button,
    ButtonToolbar,
    Col,
    Container,
    Content,
    ControlLabel,
    FlexboxGrid,
    Form,
    FormControl,
    FormGroup,
    Icon,
    Input,
    InputGroup
} from 'rsuite';

import { HomeCarouselComponent } from '../../components/HomeCarouselComponent';
import { LoadingSpiner } from '../../components/LoadingSpinner';

import { setAdministrador, setAdministradores } from '../../actions/actionsAdmin';
import { setCliente, setServicosCliente } from '../../actions/actionsCliente';
import { setPermissoesUsuario } from '../../actions/actionsUsuario';
import * as UsuarioService from '../../services/UsuarioService';
import * as LoginService from './services';

import { AlertWithTimestamp } from '../../components/AlertWithTimestamp';
import { Exception } from '../../data';
import { setPropertiesHeap } from '../../utils/HeapConfig';
import { setPropertiesTagManager } from '../../utils/TagManagerProperties';

const Login = ({ history }) => {
    const dispatch = useDispatch();
    const [email, setEmail] = useState('');
    const [senha, setSenha] = useState('');

    const setLogProperties = (userClientAllData) => {
        setPropertiesTagManager(userClientAllData);
        setPropertiesHeap(userClientAllData);
    };

    const setDataReducer = (clientId, userClientAllData) => {
        dispatch(setCliente(clientId));
        dispatch(setAdministrador(userClientAllData.usuarioAdministrador));
        setPermissoesUsuario(userClientAllData.servicos, userClientAllData.servicoBi, userClientAllData.servicoPanel, userClientAllData.servicoIra);
        dispatch(setServicosCliente(userClientAllData.servicosCliente));
        dispatch(setAdministradores(userClientAllData.administradores));
    };

    const setPaineis = (user) => {
        if (user.servicoBi.analises.length || user.servicoBi.graficos.length) {
            const paineis = user.servicoBi.analises.concat(user.servicoBi.graficos);
            history.push(paineis[0].nome ? `/analise/${paineis[0].codigo}` : `/acompanhamento/${paineis[0].codigo}`);
        } else {
            const { message } = new Exception('Não existem painéis customizados associados a sua conta. Entre em contato com o seu gerente de contas da InfoPrice para mais informações.');
            AlertWithTimestamp({ message });
        }
    };

    const mapDataUsuario = (userData) => ({
        email: userData.usuario.login,
        nome: userData.usuario.pessoaFisica.nome,
    });

    const mapDataCliente = (clientData, user) => ({
        codigo: clientData.codigoCliente,
        nome: clientData.cliente,
        organizacao: user.usuario.organizacao.nome,
    });

    const redirectToPage = (userClientAllData) => {
        setLogProperties(userClientAllData);
        if (history?.location?.state?.from) {
            history.replace(history.location.state.from);
            return;
        }
        if (userClientAllData.servicos.some((item) => item.name === 'IPA')) {
            if (userClientAllData.servicoIpa.pacoteIpa === 'LITE') {
                history.push('/ipa/lite/onboarding');
            } else {
                history.push('/ipa/gerenciador-de-precos');
            }
            return;
        }
        if (userClientAllData.servicos.some((item) => item.name === 'IPA') && userClientAllData.servicos.some((item) => item.name === 'IRA')) {
            history.push('/ipa/gerenciador-de-precos');
            return;
        }
        if (userClientAllData.servicos.some((item) => item.name === 'SDD')) {
            history.push('/ipa/calculadora-demanda');
            return;
        }
        if (userClientAllData.servicos.some((item) => item.name === 'PAN')) {
            if (userClientAllData?.servicoPanel?.pacotePainel?.name === 'FREE') {
                history.push('/isa/painel-geral-free');
                
            } else {
                history.push('/isa/painel-geral');
            }
            return;
        }
        if (userClientAllData.servicos.some((item) => item.name === 'COL')) {
            history.push('/isa/historico-preco');
            return;
        }
        if (userClientAllData.servicos.some((item) => item.name === 'IRA')) {
            history.push('/ira');
            return;
        }
        if (userClientAllData.servicos.some((item) => item.name === 'BI')) {
            setPaineis(userClientAllData);
            return;
        }
    };

    const showPassword = () => {
        const passwordInput = document.getElementById('senha');

        if (passwordInput.type === 'password') {
            passwordInput.type = 'text';
        } else {
            passwordInput.type = 'password';
        }
    };

    const newAccount = () => {
        history.push('/free/email');
    };

    const setLocalStorageUserData = (userData, userClientAllData, token) => {
        localStorage.setItem('usuario', JSON.stringify(mapDataUsuario(userData)));
        localStorage.setItem('cliente', JSON.stringify(mapDataCliente(userClientAllData, userData)));
        localStorage.setItem('autenticacao.token', token);
    };

    const setLocalStorageUserToken = (token, email) => {
        localStorage.setItem('autenticacao.token', token);
        localStorage.setItem('userEmail', email);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const model = {
            grant_type: 'password',
            username: email,
            password: senha ? CryptoJS.SHA512(senha).toString() : null,
        };

        LoginService.getToken(model).then((response) => {
            if (response) {
                setLocalStorageUserToken(response.access_token, email);
                UsuarioService.getUsuarioInformation(response.access_token)
                    .then((data) => {
                        UsuarioService.getClienteCompletoByToken(data.data.usuario.login, response.access_token).then(async (dataUsuarioCliente) => {
                            if (dataUsuarioCliente) {
                                await setLocalStorageUserData(data.data, dataUsuarioCliente, response.access_token);

                                await setDataReducer(dataUsuarioCliente.codigoCliente, dataUsuarioCliente);

                                redirectToPage(dataUsuarioCliente);
                            } else {
                                const { message } = new Exception('Você não está associado a nenhum de nossos produtos. Por favor, entre em contato com o responsável pelo seu acesso para mais informações.');
                                AlertWithTimestamp({ message });
                            }
                        });
                    })
                    .catch((err) => {
                        if (err.response.status === 412) {
                            history.push('/two-factor-auth');
                        }
                    });
            }
        });
    };

    return (
        <div className="login-page">
            <LoadingSpiner size="md" />
            <Container>
                <FlexboxGrid>
                    <Col md={10} lg={10} xsHidden smHidden>
                        <Content>
                            <HomeCarouselComponent />
                        </Content>
                    </Col>
                    <Col md={14} lg={14} xs={24} className="form-area">
                        <Content className="login-form">
                            <h4>Login</h4>
                            <Form fluid onSubmit={handleSubmit}>
                                <FormGroup>
                                    <ControlLabel>E-mail</ControlLabel>
                                    <FormControl id="email" name="email" value={email} onChange={(value) => setEmail(value)} type="email" />
                                </FormGroup>
                                <FormGroup>
                                    <ControlLabel>Senha</ControlLabel>
                                    <InputGroup>
                                        <Input id="senha" name="senha" value={senha} onChange={(value) => setSenha(value)} type="password" />
                                        <InputGroup.Button onClick={showPassword}>
                                            <Icon icon="eye" />
                                        </InputGroup.Button>
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup>
                                    <ButtonToolbar>
                                        <Button block type="submit" appearance="primary">
                                            Entrar
                                        </Button>
                                    </ButtonToolbar>
                                </FormGroup>
                            </Form>
                            <div className="forgot-password">
                                <Link
                                    to={{
                                        pathname: '/recuperar-senha',
                                    }}
                                >
                                    Esqueci minha senha
                                </Link>
                            </div>
                            <div className="new-account">
                                <Button block onClick={newAccount} appearance="ghost" className="new-account-button">
                                    Criar conta gratuita
                                </Button>
                                <div className="new-account-text-wrapper">
                                    <p className="new-account-text">
                                        Este é um cadastro para <span className="new-account-text-bold">não</span> assinantes do{' '}
                                        <span className="new-account-text-bold">InfoPanel</span>
                                        .
                                        <br />
                                        Caso você seja de uma empresa contratante, contate-a ou
                                        <br />
                                        fale com o nosso time de atendimento através do e-mail <br />
                                        <span className="new-account-text-bold">atendimento@infoprice.co</span>
                                    </p>
                                </div>
                            </div>
                        </Content>
                    </Col>
                </FlexboxGrid>
            </Container>
        </div>
    );
};

export default withRouter(Login);
