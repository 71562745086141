import React from 'react';
import { FieldValues, SubmitHandler } from 'react-hook-form';
import { Button, Container } from 'rsuite';
import { PageProps } from '../../../../../@types';
import { useCheckUserAllowAccess } from '../../hooks/useCheckUserAllowAccess';
import { useFormOptimization } from '../../hooks/useFormOptimization';
import styles from './OtimizacaoPrecos.module.scss';
import { CoordinateSection } from './components/CoordinateSection';
import { FilterSection } from './components/FilterSection';
import { Header } from './components/Header';
import { PeriodSection } from './components/PeriodSection';

export type CriarOtimizacaoProps = React.HTMLAttributes<HTMLElement> & PageProps;

export const OtimizacaoPrecos = ({ usuario }: CriarOtimizacaoProps) => {
    useCheckUserAllowAccess({ usuario });
    const { handlers, states } = useFormOptimization();
    const { clearErrors, handleSubmit, onSubmit, setValue, watch } = handlers;
    const { control, errors } = states;

    return (
        <Container className="main-container ">
            <form
                className={styles.container}
                onSubmit={handleSubmit(onSubmit as SubmitHandler<FieldValues>, () => {
                    return { shouldFocusError: false };
                })}
            >
                <Header />
                <FilterSection control={control} watch={watch} errors={errors} />
                <CoordinateSection control={control} watch={watch} errors={errors} clearErrors={clearErrors} />
                <PeriodSection control={control} watch={watch} errors={errors} setValue={setValue} />
                <section>
                    <Button appearance="primary" type="submit">
                        Salvar Otimização
                    </Button>
                </section>
            </form>
        </Container>
    );
};
