/* eslint-disable react/jsx-indent */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'rsuite';
import { SET_GET_CLUSTER_STORE_MODAL_IS_OPEN, SET_GET_FAMILY_MODAL_IS_OPEN } from '../../../../../reducers/gerenciadorPrecos';
import { RowData } from '../../../../../reducers/gerenciadorPrecos/datapoints';
import { selectorGetClusterStoreModalTotalElements } from '../../../../../reducers/getClusterStoreModal/totalElements';
import { CLEAR_EDITED_DATAPOINTS } from '../../../../../reducers/getFamilyModal/editedDatapoints';
import styles from './GetClusterStoreListModal.module.scss';
import { GetClusterStoreTable } from './components/GetClusterStoreTable';

type GetClusterStoreModalProps = {
    rowData: RowData;
    show: boolean;
    onHide: () => void;
};

export const GetClusterListStoreModal = ({
    rowData,
    show,
    onHide,
}: GetClusterStoreModalProps) => {
    const { clusterId, objectiveMargin, clusterName, productId, description, productFamilyId } = rowData;

    const dispatch = useDispatch();

    const closeGetClusterStoreModal = () => {
        onHide();
        dispatch(SET_GET_FAMILY_MODAL_IS_OPEN(false));
        dispatch(SET_GET_CLUSTER_STORE_MODAL_IS_OPEN(false));
        dispatch(CLEAR_EDITED_DATAPOINTS());
    };

    const totalElements = useSelector(selectorGetClusterStoreModalTotalElements);

    return (
        <Modal show={show} onHide={closeGetClusterStoreModal} className={styles['family-modal']}>
            <Modal.Header>
                <Modal.Title className={styles['modal-title']}>
                    Cluster de lojas
                </Modal.Title>
                <div className={styles['header-container']}>
                    <div className={styles['header-container__row']}>
                        <div className={styles['header-container__content']}>
                            <p>PRODUTO</p>
                            <span>{productId} - <span className={styles['info']}>{description}</span></span>
                        </div>
                        <div className={styles['header-container__content']}>
                        </div>
                        <div className={styles['header-container__content']}>
                            <p>FAMÍLIA</p>
                            <span>{productFamilyId}</span>
                        </div>
                    </div>
                    <div className={styles['header-container__row']}>
                        <div className={styles['header-container__content']}>
                            <p>CLUSTER</p>
                            <span>{clusterName}</span>
                        </div>
                        <div className={styles['header-container__content']}>
                            <p>TOTAL DE LOJAS</p>
                            <span>{totalElements}</span>
                        </div>
                        <div className={styles['header-container__content']}>
                            <p>MARGEM OBJETIVA</p>
                            <span>{objectiveMargin?.toFixed(1)}%</span>
                        </div>
                    </div>
                </div>
            </Modal.Header>
            <Modal.Body>
                <GetClusterStoreTable onHide={onHide} clusterId={clusterId as string} productId={productId as string} />
            </Modal.Body>
        </Modal>
    );
};
