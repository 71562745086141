import { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import * as PainelGeralService from '../../pages/ISA/InfoPanel/InfoPanel/services';
import { getModelPanel } from '../../pages/ISA/InfoPanel/InfoPanel/utils';

export const useProduct = () => {
    const [products, setProducts] = useState({
        list: [],
        cache: [],
        value: [],
    });
    const [timer, setTimer] = useState(null);

    const { filtrosData } = useSelector(
        (state) => ({
            filtrosData: state.painelGeralDataReducer.filtrosData,
        }),
        shallowEqual
    );

    const setList = (list) => {
        const newList = list.map((item) => ({
            id: item.id,
            value: item.identificador,
            label: `${item.identificador} - ${item.descricao}`,
            identificador: item.identificador,
            descricao: item.descricao,
            secao: item.secao,
            categoria: item.categoria,
            datapoints: item.datapoints,
            limited: item.limited,
            foto: item.foto,
        }));

        const cacheList = products.cache;
        const removeDuplicate = newList.filter(
            (i) => !products.value.includes(i.value)
        );
        const concatList = products.value.length
            ? cacheList.concat(removeDuplicate)
            : newList;

        setProducts((prevState) => ({
            ...prevState,
            list: concatList,
        }));
    };

    const handleAddProduct = (item) => {
        const hasValue = products.value.includes(item.identificador);
        if (!hasValue) {
            const newList = products.value;
            const newValue = newList.concat(item.identificador);
            setProducts((prevState) => ({
                ...prevState,
                value: newValue,
                cache: item,
            }));
        }
    };

    const handleRemoveProduct = (item) => {
        const hasValue = products.value.includes(item.identificador);
        if (hasValue) {
            const removedCache = products.cache.filter(
                (i) => i.identificador !== item.identificador
            );
            const newValue = products.value.filter(
                (i) => i !== item.identificador
            );
            setProducts((prevState) => ({
                ...prevState,
                value: newValue,
                cache: removedCache,
            }));
        }
    };

    const handleCleanProduct = () => {
        setProducts((prevState) => ({ ...prevState, value: [], cache: [] }));
    };

    const searchProdutos = (data) => {
        PainelGeralService.getProduto(data).then((response) => {
            if (response) {
                setList(response);
            }
        });
    };

    const onSearchProducts = (inputValue) => {
        let filtros;

        if (
            inputValue &&
            typeof inputValue === 'string' &&
            inputValue.match(/^\d+ \d+[\d+ ]*$/)
        ) {
            const concatValue = products.value.concat(inputValue.split(' '));
            const newValue = [...new Set(concatValue)];
            filtros = { query: '', identificadores: newValue };

            setProducts((prevState) => ({
                ...prevState,
                value: newValue,
            }));
        } else {
            filtros = {
                query: typeof inputValue === 'string' ? inputValue : '',
            };
        }

        clearTimeout(timer);

        const newTimer = setTimeout(() => {
            searchProdutos(
                getModelPanel(
                    filtrosData,
                    filtros.query,
                    'produto',
                    filtros.identificadores ? filtros.identificadores : null
                )
            );
        }, 500);

        setTimer(newTimer);
    };

    const setProductsForEdition = (inputValue) => {
        const concatValue = products.value.concat(inputValue.split(' '));
        const newValue = [...new Set(concatValue)];
        const filtros = { query: '', identificadores: newValue };

        setProducts((prevState) => ({
            ...prevState,
            value: newValue,
        }));

        clearTimeout(timer);

        const newTimer = setTimeout(() => {
            searchProdutos(
                getModelPanel(
                    filtrosData,
                    filtros.query,
                    'produto',
                    filtros.identificadores ? filtros.identificadores : null
                )
            );
        }, 500);

        setTimer(newTimer);
    };

    useEffect(() => {
        if (products.value.length) {
            const filterList = products.list.filter((cache) =>
                products.value.some((i) => cache.identificador === i)
            );

            if (filterList.length) {
                setProducts((prevState) => ({
                    ...prevState,
                    cache: filterList,
                }));
            }
        }
    }, [products.value.length, products.list]);

    return {
        onSearchProducts,
        products,
        handleAddProduct,
        handleRemoveProduct,
        handleCleanProduct,
        setProductsForEdition,
    };
};
