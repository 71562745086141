import React, { useState } from 'react';
import { Nav } from 'rsuite';

import styles from './OptimizationTabs.module.scss';
import { OptimizationForecastTab } from './components/OptimizationForecastTab';

type TabType = 'FORECAST_OPTIMIZATION' | 'RESULT_ANALYSIS' | 'HISTORY_PRICES';
export const OptimizationTabs = () => {
    const [tab, setTab] = useState<TabType>('FORECAST_OPTIMIZATION');

    const handleContentChange = (value: TabType) => {
        setTab(value);
    };

    const setContent = {
        FORECAST_OPTIMIZATION: <OptimizationForecastTab />,
        RESULT_ANALYSIS: <div className={styles.container}>RESULT_ANALYSIS</div>,
        HISTORY_PRICES: <div className={styles.container}>HISTORY_PRICES</div>,
    };
    return (
        <main>
            <Nav activeKey={tab} appearance="subtle" onSelect={(eventKey) => handleContentChange(eventKey)}>
                <Nav.Item eventKey="FORECAST_OPTIMIZATION">Previsão de otimização</Nav.Item>
                {/* <Nav.Item eventKey="RESULT_ANALYSIS">Análise de resultados</Nav.Item>
                <Nav.Item eventKey="HISTORY_PRICES">Histórico de preços</Nav.Item> */}
            </Nav>
            {setContent[tab]}
        </main>
    );
};
