import { create } from 'zustand';
import { EditBaseTableItemDataType } from '../EditBaseTable/types';
import {
    AtivarPromoDataTypes,
    ComercialNewPriceBodyTypes,
    ManageComercialTableTypes,
    ComercialTablePriceLimitAndRulesTypes,
    CustosProdutoBodyTypes,
    PriceAnalysisComercialTableDataTypes,
    TabelaComercial
} from './types';
import { comercialPrecoMedioProjetadoCalc } from '../../calculos';

type State = {
    openModalPriceAnalysisComercial: boolean,
    openModalActivateProductOffer: boolean,
    openModalProductCostComercialTable: boolean,
    openModalPriceLimit: boolean,
    priceAnalysisComercialTableData: PriceAnalysisComercialTableDataTypes[],
    productItemData: EditBaseTableItemDataType,
    custosProdutoBody: CustosProdutoBodyTypes,
    comercialTableItem: TabelaComercial | undefined,
    comercialTablePriceLimitAndRules: ComercialTablePriceLimitAndRulesTypes,
    comercialNewPriceBody: ComercialNewPriceBodyTypes,
    comercialProductItemSelectedList: ManageComercialTableTypes[],
    comercialPriceApplyList: number[],
    selectAllTable: boolean,
    ativarPromoData: AtivarPromoDataTypes,
    hasOferta: boolean,
    contextMenuRowdata: ManageComercialTableTypes,
}

type Actions = {
    setManageComercialTableState: (name: string, data: any) => void,
    setOpenModalPriceAnalysisComercial: (open: boolean, data) => void,
    setOpenModalProductCostComercialTable: (open: boolean, data) => void,
    setOpenModalActivateProductOffer: (open: boolean, hasOferta: boolean, data: AtivarPromoDataTypes) => void,
    resetManageComercialTable: (name: string) => void,
    reset: () => void;
}

const initialState: State = {
    priceAnalysisComercialTableData: [],
    openModalPriceAnalysisComercial: false,
    openModalActivateProductOffer: false,
    openModalProductCostComercialTable: false,
    openModalPriceLimit: false,
    productItemData: {} as EditBaseTableItemDataType,
    custosProdutoBody: {} as CustosProdutoBodyTypes,
    comercialTableItem: {} as TabelaComercial,
    comercialTablePriceLimitAndRules: {} as ComercialTablePriceLimitAndRulesTypes,
    comercialNewPriceBody: {} as ComercialNewPriceBodyTypes,
    comercialProductItemSelectedList: [],
    comercialPriceApplyList: [],
    selectAllTable: false,
    ativarPromoData: {} as AtivarPromoDataTypes,
    hasOferta: false,
    contextMenuRowdata: {} as ManageComercialTableTypes,
};

export const useManageComercialTableStore = create<State & Actions>((set) => ({
    ...initialState,
    setManageComercialTableState: (name, data) => {
        set(() => ({
            [name]: data,
        }));
    },
    setOpenModalProductCostComercialTable: (open: boolean, data: EditBaseTableItemDataType) => {
        set(() => ({
            openModalProductCostComercialTable: open,
            productItemData: data || initialState.productItemData,
            custosProdutoBody: {
                idRascunhoProdutoComercial: data?.id || 0,
                custos: {
                    freteMedio: data?.custosVenda.freteMedio,
                    comissao: data?.custosVenda.comissao,
                    trocasOuDevolucao: data?.custosVenda.trocasOuDevolucao,
                    gratificacao: data?.custosVenda.gratificacao,
                    outros: data?.custosVenda.outros
                },
                despesas: {
                    comerciais: data?.despesaVenda.comerciais,
                    administrativas: data?.despesaVenda.administrativas,
                    outras: data?.despesaVenda.outras
                },
                custoFabricacao: 0,
            },
        }));
    },
    setOpenModalPriceAnalysisComercial: (open: boolean, data: EditBaseTableItemDataType) => {
        set(() => ({
            openModalPriceAnalysisComercial: open,
            priceAnalysisComercialTableData: [
                {
                    name: 'Tabela atual',
                    price: data ? data?.tabelaAtual?.tabelaAtual : 0,
                    diff: null
                },
                {
                    name: 'Preço médio atual',
                    price: data ? data?.produtoComercial?.precoMedioUfCanal : 0,
                    diff: null
                },
                {
                    name: 'Novo preço com desconto máximo',
                    price: 0,
                    diff: null
                },
                {
                    name: 'Novo preço de tabela',
                    price: data ? data?.parametros?.novoPrecoTabela?.valorNovo : 0,
                    diff: null
                },
                {
                    name: 'Preço médio projetado',
                    price: data ? comercialPrecoMedioProjetadoCalc(data) : 0,
                    diff: null
                },
            ]
        }));
    },
    setOpenModalActivateProductOffer: (open: boolean, hasOferta: boolean, data: AtivarPromoDataTypes) => {
        set(() => ({
            openModalActivateProductOffer: open,
            ativarPromoData: data,
            hasOferta,
        }));
    },
    resetManageComercialTable: (name: string) => {
        set(() => ({ [name]: initialState[name]}));
    },
    reset: () => {
        set(initialState);
    },
}));
