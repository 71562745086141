import React from 'react';
import { QuickActionFilterProps } from './QuickActionFilter.types';
import { FilterButton, FilterSelect, FilterSelectInterval } from './elements';
import { FilterCompetitiveness } from './elements/FilterCompetitiveness';
import { FilterMargin } from './elements/FilterMargin';
import { FilterPriceVariation } from './elements/FilterPriceVariation';
import './styles/index.scss';

const QuickActionFilter = ({ children, className = '', ...props }: QuickActionFilterProps) => (
    <div className={`quick-action-filter ${className}`} {...props}>
        {children}
    </div>
);

QuickActionFilter.Button = FilterButton;
QuickActionFilter.Select = FilterSelect;
QuickActionFilter.SelectInterval = FilterSelectInterval;
QuickActionFilter.FilterMargin = FilterMargin;
QuickActionFilter.FilterPriceVariation = FilterPriceVariation;
QuickActionFilter.FilterCompetitiveness = FilterCompetitiveness;

export { QuickActionFilter };
