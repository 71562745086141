import { shallowEqual, useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { getSaveReportLineCounter } from '../../services';

export const useSaveReportCounter = () => {
    const {
        moduloRelatorioData,
    } = useSelector(
        (state) => ({
            moduloRelatorioData:
                state.moduloRelatorioDataReducer?.moduloRelatorioData,
        }),
        shallowEqual,
    );

    const generateDataBody = {
        ...moduloRelatorioData,
        recorrencia: false,
        relatorioSalvo: false,
    };

    const counter = async () => {
        const resp = await getSaveReportLineCounter(generateDataBody);

        if (resp.status === 200) {
            return resp;
        } else {
            return {}
        }
    };

    const {
        isLoading, isError, data = {}, error, isFetching, isSuccess, refetch,
    } = useQuery(
        ['counter'],
        counter,
        {
            enabled: false,
            retry: false,
        },
    );

    return {
        isLoading,
        isError,
        dataCounter: data,
        error,
        isFetching,
        isSuccess,
        refetchCounter: refetch,
    };
};
