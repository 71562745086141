/* eslint-disable import/no-mutable-exports */
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Col, Popover, Row } from 'rsuite';
import {
    addSegmento,
    searchCluster,
    searchItensSegmento,
    searchProduto,
    searchSegmentos,
    searchSegmentosSensibilities,
    searchStore,
    selectValueSegmento,
} from '../../../../../actions/actionsLimitesPrecoCadastro';
import { selectSearch } from '../../../../../utils/Helpers';
import { CustomNav } from './CustomNav';

let Speaker = ({
    segmentosAdicionados,
    segmentos,
    addSegmento,
    tiposSegmento,
    onMouseEnter,
    onMouseLeave,
    className,
    style,
    searchSegmentos,
    searchItensSegmento,
    searchSegmentosSensibilities,
    searchProduto,
    searchStore,
    searchCluster,
}) => {
    const handleClick = (eventKey, b) => {
        const isNewSegmento = segmentosAdicionados.findIndex((item) => item.name === b.target.name) === -1;
        if (isNewSegmento) {
            const segmentoList = segmentos;
            const newSegmento = segmentoList[segmentoList.findIndex((item) => item.name === b.target.name)];
            if (newSegmento.onSearch) {
                const filtro =
                    newSegmento.name !== 'searchStoreIds' &&
                    newSegmento.name !== 'searchCluster' &&
                    newSegmento.name !== 'sensibilityType' &&
                    newSegmento.name !== 'productId'
                        ? { nivel: newSegmento.name, name: newSegmento.name }
                        : newSegmento;
                selectSearch(searchItensSegmento, searchProduto, searchStore, searchCluster, '', searchSegmentos, searchSegmentosSensibilities, '', filtro, {
                    query: '',
                });
            }
            addSegmento(newSegmento);
        }
    };

    return (
        <Popover onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} className={className} style={style}>
            <Row className="popover-filtro-dinamico__row">
                {tiposSegmento.map((item) => (
                    <Col md={12} key={item.title} className="popover-filtro-dinamico__col">
                        <CustomNav title={item.title} icon={item.icon} itens={item.itens} onSelect={handleClick} />
                    </Col>
                ))}
            </Row>
        </Popover>
    );
};

const mapStateToProps = (store) => ({
    tiposSegmento: store.limitesPrecoCadastroDataReducer.tiposSegmento,
    segmentos: store.limitesPrecoCadastroDataReducer.segmentos,
    segmentosAdicionados: store.limitesPrecoCadastroDataReducer.segmentosAdicionados,
    segmentosData: store.limitesPrecoCadastroDataReducer.segmentosData,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            addSegmento,
            selectValueSegmento,
            searchSegmentos,
            searchItensSegmento,
            searchSegmentosSensibilities,
            searchProduto,
            searchStore,
            searchCluster,
        },
        dispatch,
    );

Speaker = connect(mapStateToProps, mapDispatchToProps)(Speaker);

export default Speaker;
