export const gtinCheckSum = (code) => {
    const dv = parseInt(code.substring(code.length - 1, code.length), 10);
    const gtinWithoutDv = code.substring(0, code.length - 1);
    let total = 0;
    const gtinNumbers = gtinWithoutDv.split('');

    gtinNumbers.forEach((item, index) => {
        if (index % 2 === 1) {
            total += parseInt(item, 10);
        } else {
            total += (3 * parseInt(item, 10));
        }
    });
    const checkDigit = (10 - (total % 10)) % 10;
    return checkDigit === dv;
};

const generateGtinCheckSum = (code) => {
    let total = 0;
    const codeNumbers = code.padStart(13, 0).split('');

    codeNumbers.forEach((item, index) => {
        if (index % 2 === 1) {
            total += parseInt(item, 10);
        } else {
            total += (3 * parseInt(item, 10));
        }
    });

    const checkDigit = (10 - (total % 10)) % 10;
    return checkDigit.toString();
};

export const upceToUpca = (upceValue) => {
    let middleDigits;
    if (upceValue.length === 6) {
        middleDigits = upceValue;
    } else if (upceValue.length === 7) {
        middleDigits = upceValue.substring(0, 6);
    } else if (upceValue.length === 8) {
        middleDigits = upceValue.substring(1, 7);
    }

    if (middleDigits.length) {
        const digitList = middleDigits.split('');
        const d1 = digitList[0];
        const d2 = digitList[1];
        const d3 = digitList[2];
        const d4 = digitList[3];
        const d5 = digitList[4];
        const d6 = digitList[5];

        let mfrNum;
        let itemNum;
        if (d6 === ('0' || '1' || '2')) {
            mfrNum = `${d1 + d2 + d6}00`;
            itemNum = `00${d3}${d4}${d5}`;
        } else if (d6 === '3') {
            mfrNum = `${d1 + d2 + d3}00`;
            itemNum = `000${d4}${d5}`;
        } else if (d6 === '4') {
            mfrNum = `${d1 + d2 + d3 + d4}0`;
            itemNum = `0000${d5}`;
        } else {
            mfrNum = d1 + d2 + d3 + d4 + d5;
            itemNum = `0000${d6}`;
        }

        const newMsg = `0${mfrNum}${itemNum}`;
        const checkDigit = generateGtinCheckSum(newMsg);
        return newMsg + checkDigit.toString();
    }

    return false;
};

const checkInternal = (gtinNormalized) => {
    if (gtinNormalized.substring(0, 6) === '030000') {
        return true;
    } if (gtinNormalized.substring(0, 6) === '010000') {
        return true;
    }
    return false;
};

const getGtinPrefixName = (gtinPrefix) => {
    const codes = [
        {
            fxPrefixIni: 0, fxPrefixFim: 19, indEsp: 0, fxPais_Nome: 'GS1 US',
        },
        {
            fxPrefixIni: 20, fxPrefixFim: 29, indEsp: 1, fxPais_Nome: 'Números de circulação restrita dentro da região',
        },
        {
            fxPrefixIni: 30, fxPrefixFim: 39, indEsp: 0, fxPais_Nome: 'GS1 US',
        },
        {
            fxPrefixIni: 40, fxPrefixFim: 49, indEsp: 1, fxPais_Nome: 'GS1 Números de circulação restrita dentro da empresa',
        },
        {
            fxPrefixIni: 50, fxPrefixFim: 59, indEsp: 1, fxPais_Nome: 'GS1 US reserved for future use',
        },
        {
            fxPrefixIni: 60, fxPrefixFim: 139, indEsp: 0, fxPais_Nome: 'GS1 US',
        },
        {
            fxPrefixIni: 200, fxPrefixFim: 299, indEsp: 1, fxPais_Nome: 'GS1 Números de circulação restrita dentro da região',
        },
        {
            fxPrefixIni: 300, fxPrefixFim: 379, indEsp: 0, fxPais_Nome: 'GS1 France',
        },
        {
            fxPrefixIni: 380, fxPrefixFim: 380, indEsp: 0, fxPais_Nome: 'GS1 Bulgaria',
        },
        {
            fxPrefixIni: 383, fxPrefixFim: 383, indEsp: 0, fxPais_Nome: 'GS1 Slovenija',
        },
        {
            fxPrefixIni: 385, fxPrefixFim: 385, indEsp: 0, fxPais_Nome: 'GS1 Croatia',
        },
        {
            fxPrefixIni: 387, fxPrefixFim: 387, indEsp: 0, fxPais_Nome: 'GS1 BIH (Bosnia-Herzegovina)',
        },
        {
            fxPrefixIni: 389, fxPrefixFim: 389, indEsp: 0, fxPais_Nome: 'GS1 Montenegro',
        },
        {
            fxPrefixIni: 400, fxPrefixFim: 440, indEsp: 0, fxPais_Nome: 'GS1 Germany',
        },
        {
            fxPrefixIni: 450, fxPrefixFim: 459, indEsp: 0, fxPais_Nome: 'GS1 Japan',
        },
        {
            fxPrefixIni: 490, fxPrefixFim: 499, indEsp: 0, fxPais_Nome: 'GS1 Japan',
        },
        {
            fxPrefixIni: 460, fxPrefixFim: 469, indEsp: 0, fxPais_Nome: 'GS1 Russia',
        },
        {
            fxPrefixIni: 470, fxPrefixFim: 470, indEsp: 0, fxPais_Nome: 'GS1 Kyrgyzstan',
        },
        {
            fxPrefixIni: 471, fxPrefixFim: 471, indEsp: 0, fxPais_Nome: 'GS1 Taiwan',
        },
        {
            fxPrefixIni: 474, fxPrefixFim: 474, indEsp: 0, fxPais_Nome: 'GS1 Estonia',
        },
        {
            fxPrefixIni: 475, fxPrefixFim: 475, indEsp: 0, fxPais_Nome: 'GS1 Latvia',
        },
        {
            fxPrefixIni: 476, fxPrefixFim: 476, indEsp: 0, fxPais_Nome: 'GS1 Azerbaijan',
        },
        {
            fxPrefixIni: 477, fxPrefixFim: 477, indEsp: 0, fxPais_Nome: 'GS1 Lithuania',
        },
        {
            fxPrefixIni: 478, fxPrefixFim: 478, indEsp: 0, fxPais_Nome: 'GS1 Uzbekistan',
        },
        {
            fxPrefixIni: 479, fxPrefixFim: 479, indEsp: 0, fxPais_Nome: 'GS1 Sri Lanka',
        },
        {
            fxPrefixIni: 480, fxPrefixFim: 480, indEsp: 0, fxPais_Nome: 'GS1 Philippines',
        },
        {
            fxPrefixIni: 481, fxPrefixFim: 481, indEsp: 0, fxPais_Nome: 'GS1 Belarus',
        },
        {
            fxPrefixIni: 482, fxPrefixFim: 482, indEsp: 0, fxPais_Nome: 'GS1 Ukraine',
        },
        {
            fxPrefixIni: 483, fxPrefixFim: 483, indEsp: 0, fxPais_Nome: 'GS1 Turkmenistan',
        },
        {
            fxPrefixIni: 484, fxPrefixFim: 484, indEsp: 0, fxPais_Nome: 'GS1 Moldova',
        },
        {
            fxPrefixIni: 485, fxPrefixFim: 485, indEsp: 0, fxPais_Nome: 'GS1 Armenia',
        },
        {
            fxPrefixIni: 486, fxPrefixFim: 486, indEsp: 0, fxPais_Nome: 'GS1 Georgia',
        },
        {
            fxPrefixIni: 487, fxPrefixFim: 487, indEsp: 0, fxPais_Nome: 'GS1 Kazakstan',
        },
        {
            fxPrefixIni: 488, fxPrefixFim: 488, indEsp: 0, fxPais_Nome: 'GS1 Tajikistan',
        },
        {
            fxPrefixIni: 489, fxPrefixFim: 489, indEsp: 0, fxPais_Nome: 'GS1 Hong Kong',
        },
        {
            fxPrefixIni: 500, fxPrefixFim: 509, indEsp: 0, fxPais_Nome: 'GS1 UK',
        },
        {
            fxPrefixIni: 520, fxPrefixFim: 521, indEsp: 0, fxPais_Nome: 'GS1 Association Greece',
        },
        {
            fxPrefixIni: 528, fxPrefixFim: 528, indEsp: 0, fxPais_Nome: 'GS1 Lebanon',
        },
        {
            fxPrefixIni: 529, fxPrefixFim: 529, indEsp: 0, fxPais_Nome: 'GS1 Cyprus',
        },
        {
            fxPrefixIni: 530, fxPrefixFim: 530, indEsp: 0, fxPais_Nome: 'GS1 Albania',
        },
        {
            fxPrefixIni: 531, fxPrefixFim: 531, indEsp: 0, fxPais_Nome: 'GS1 Macedonia',
        },
        {
            fxPrefixIni: 535, fxPrefixFim: 535, indEsp: 0, fxPais_Nome: 'GS1 Malta',
        },
        {
            fxPrefixIni: 539, fxPrefixFim: 539, indEsp: 0, fxPais_Nome: 'GS1 Ireland',
        },
        {
            fxPrefixIni: 540, fxPrefixFim: 549, indEsp: 0, fxPais_Nome: 'GS1 Belgium & Luxembourg',
        },
        {
            fxPrefixIni: 560, fxPrefixFim: 560, indEsp: 0, fxPais_Nome: 'GS1 Portugal',
        },
        {
            fxPrefixIni: 569, fxPrefixFim: 569, indEsp: 0, fxPais_Nome: 'GS1 Iceland',
        },
        {
            fxPrefixIni: 570, fxPrefixFim: 579, indEsp: 0, fxPais_Nome: 'GS1 Denmark',
        },
        {
            fxPrefixIni: 590, fxPrefixFim: 590, indEsp: 0, fxPais_Nome: 'GS1 Poland',
        },
        {
            fxPrefixIni: 594, fxPrefixFim: 594, indEsp: 0, fxPais_Nome: 'GS1 Romania',
        },
        {
            fxPrefixIni: 599, fxPrefixFim: 599, indEsp: 0, fxPais_Nome: 'GS1 Hungary',
        },
        {
            fxPrefixIni: 600, fxPrefixFim: 601, indEsp: 0, fxPais_Nome: 'GS1 South Africa',
        },
        {
            fxPrefixIni: 603, fxPrefixFim: 603, indEsp: 0, fxPais_Nome: 'GS1 Ghana',
        },
        {
            fxPrefixIni: 604, fxPrefixFim: 604, indEsp: 0, fxPais_Nome: 'GS1 Senegal',
        },
        {
            fxPrefixIni: 608, fxPrefixFim: 608, indEsp: 0, fxPais_Nome: 'GS1 Bahrain',
        },
        {
            fxPrefixIni: 609, fxPrefixFim: 609, indEsp: 0, fxPais_Nome: 'GS1 Mauritius',
        },
        {
            fxPrefixIni: 611, fxPrefixFim: 611, indEsp: 0, fxPais_Nome: 'GS1 Morocco',
        },
        {
            fxPrefixIni: 613, fxPrefixFim: 613, indEsp: 0, fxPais_Nome: 'GS1 Algeria',
        },
        {
            fxPrefixIni: 615, fxPrefixFim: 615, indEsp: 0, fxPais_Nome: 'GS1 Nigeria',
        },
        {
            fxPrefixIni: 616, fxPrefixFim: 616, indEsp: 0, fxPais_Nome: 'GS1 Kenya',
        },
        {
            fxPrefixIni: 618, fxPrefixFim: 618, indEsp: 0, fxPais_Nome: 'GS1 Ivory Coast',
        },
        {
            fxPrefixIni: 619, fxPrefixFim: 619, indEsp: 0, fxPais_Nome: 'GS1 Tunisia',
        },
        {
            fxPrefixIni: 620, fxPrefixFim: 620, indEsp: 0, fxPais_Nome: 'GS1 Tanzania',
        },
        {
            fxPrefixIni: 621, fxPrefixFim: 621, indEsp: 0, fxPais_Nome: 'GS1 Syria',
        },
        {
            fxPrefixIni: 622, fxPrefixFim: 622, indEsp: 0, fxPais_Nome: 'GS1 Egypt',
        },
        {
            fxPrefixIni: 623, fxPrefixFim: 623, indEsp: 0, fxPais_Nome: 'GS1 Brunei',
        },
        {
            fxPrefixIni: 624, fxPrefixFim: 624, indEsp: 0, fxPais_Nome: 'GS1 Libya',
        },
        {
            fxPrefixIni: 625, fxPrefixFim: 625, indEsp: 0, fxPais_Nome: 'GS1 Jordan',
        },
        {
            fxPrefixIni: 626, fxPrefixFim: 626, indEsp: 0, fxPais_Nome: 'GS1 Iran',
        },
        {
            fxPrefixIni: 627, fxPrefixFim: 627, indEsp: 0, fxPais_Nome: 'GS1 Kuwait',
        },
        {
            fxPrefixIni: 628, fxPrefixFim: 628, indEsp: 0, fxPais_Nome: 'GS1 Saudi Arabia',
        },
        {
            fxPrefixIni: 629, fxPrefixFim: 629, indEsp: 0, fxPais_Nome: 'GS1 Emirates',
        },
        {
            fxPrefixIni: 640, fxPrefixFim: 649, indEsp: 0, fxPais_Nome: 'GS1 Finland',
        },
        {
            fxPrefixIni: 690, fxPrefixFim: 699, indEsp: 0, fxPais_Nome: 'GS1 China',
        },
        {
            fxPrefixIni: 700, fxPrefixFim: 709, indEsp: 0, fxPais_Nome: 'GS1 Norway',
        },
        {
            fxPrefixIni: 729, fxPrefixFim: 729, indEsp: 0, fxPais_Nome: 'GS1 Israel',
        },
        {
            fxPrefixIni: 730, fxPrefixFim: 739, indEsp: 0, fxPais_Nome: 'GS1 Sweden',
        },
        {
            fxPrefixIni: 740, fxPrefixFim: 740, indEsp: 0, fxPais_Nome: 'GS1 Guatemala',
        },
        {
            fxPrefixIni: 741, fxPrefixFim: 741, indEsp: 0, fxPais_Nome: 'GS1 El Salvador',
        },
        {
            fxPrefixIni: 742, fxPrefixFim: 742, indEsp: 0, fxPais_Nome: 'GS1 Honduras',
        },
        {
            fxPrefixIni: 743, fxPrefixFim: 743, indEsp: 0, fxPais_Nome: 'GS1 Nicaragua',
        },
        {
            fxPrefixIni: 744, fxPrefixFim: 744, indEsp: 0, fxPais_Nome: 'GS1 Costa Rica',
        },
        {
            fxPrefixIni: 745, fxPrefixFim: 745, indEsp: 0, fxPais_Nome: 'GS1 Panama',
        },
        {
            fxPrefixIni: 746, fxPrefixFim: 746, indEsp: 0, fxPais_Nome: 'GS1 Republica Dominicana',
        },
        {
            fxPrefixIni: 750, fxPrefixFim: 750, indEsp: 0, fxPais_Nome: 'GS1 Mexico',
        },
        {
            fxPrefixIni: 754, fxPrefixFim: 755, indEsp: 0, fxPais_Nome: 'GS1 Canada',
        },
        {
            fxPrefixIni: 759, fxPrefixFim: 759, indEsp: 0, fxPais_Nome: 'GS1 Venezuela',
        },
        {
            fxPrefixIni: 760, fxPrefixFim: 769, indEsp: 0, fxPais_Nome: 'GS1 Schweiz, Suisse, Svizzera',
        },
        {
            fxPrefixIni: 770, fxPrefixFim: 771, indEsp: 0, fxPais_Nome: 'GS1 Colombia',
        },
        {
            fxPrefixIni: 773, fxPrefixFim: 773, indEsp: 0, fxPais_Nome: 'GS1 Uruguay',
        },
        {
            fxPrefixIni: 775, fxPrefixFim: 775, indEsp: 0, fxPais_Nome: 'GS1 Peru',
        },
        {
            fxPrefixIni: 777, fxPrefixFim: 777, indEsp: 0, fxPais_Nome: 'GS1 Bolivia',
        },
        {
            fxPrefixIni: 778, fxPrefixFim: 779, indEsp: 0, fxPais_Nome: 'GS1 Argentina',
        },
        {
            fxPrefixIni: 780, fxPrefixFim: 780, indEsp: 0, fxPais_Nome: 'GS1 Chile',
        },
        {
            fxPrefixIni: 784, fxPrefixFim: 784, indEsp: 0, fxPais_Nome: 'GS1 Paraguay',
        },
        {
            fxPrefixIni: 786, fxPrefixFim: 786, indEsp: 0, fxPais_Nome: 'GS1 Ecuador',
        },
        {
            fxPrefixIni: 789, fxPrefixFim: 790, indEsp: 0, fxPais_Nome: 'GS1 Brasil',
        },
        {
            fxPrefixIni: 800, fxPrefixFim: 839, indEsp: 0, fxPais_Nome: 'GS1 Italy',
        },
        {
            fxPrefixIni: 840, fxPrefixFim: 849, indEsp: 0, fxPais_Nome: 'GS1 Spain',
        },
        {
            fxPrefixIni: 850, fxPrefixFim: 850, indEsp: 0, fxPais_Nome: 'GS1 Cuba',
        },
        {
            fxPrefixIni: 858, fxPrefixFim: 858, indEsp: 0, fxPais_Nome: 'GS1 Slovakia',
        },
        {
            fxPrefixIni: 859, fxPrefixFim: 859, indEsp: 0, fxPais_Nome: 'GS1 Czech',
        },
        {
            fxPrefixIni: 860, fxPrefixFim: 860, indEsp: 0, fxPais_Nome: 'GS1 Serbia',
        },
        {
            fxPrefixIni: 865, fxPrefixFim: 865, indEsp: 0, fxPais_Nome: 'GS1 Mongolia',
        },
        {
            fxPrefixIni: 867, fxPrefixFim: 867, indEsp: 0, fxPais_Nome: 'GS1 North Korea',
        },
        {
            fxPrefixIni: 868, fxPrefixFim: 869, indEsp: 0, fxPais_Nome: 'GS1 Turkey',
        },
        {
            fxPrefixIni: 870, fxPrefixFim: 879, indEsp: 0, fxPais_Nome: 'GS1 Netherlands',
        },
        {
            fxPrefixIni: 880, fxPrefixFim: 880, indEsp: 0, fxPais_Nome: 'GS1 South Korea',
        },
        {
            fxPrefixIni: 884, fxPrefixFim: 884, indEsp: 0, fxPais_Nome: 'GS1 Cambodia',
        },
        {
            fxPrefixIni: 885, fxPrefixFim: 885, indEsp: 0, fxPais_Nome: 'GS1 Thailand',
        },
        {
            fxPrefixIni: 888, fxPrefixFim: 888, indEsp: 0, fxPais_Nome: 'GS1 Singapore',
        },
        {
            fxPrefixIni: 890, fxPrefixFim: 890, indEsp: 0, fxPais_Nome: 'GS1 India',
        },
        {
            fxPrefixIni: 893, fxPrefixFim: 893, indEsp: 0, fxPais_Nome: 'GS1 Vietnam',
        },
        {
            fxPrefixIni: 896, fxPrefixFim: 896, indEsp: 0, fxPais_Nome: 'GS1 Pakistan',
        },
        {
            fxPrefixIni: 899, fxPrefixFim: 899, indEsp: 0, fxPais_Nome: 'GS1 Indonesia',
        },
        {
            fxPrefixIni: 900, fxPrefixFim: 919, indEsp: 0, fxPais_Nome: 'GS1 Austria',
        },
        {
            fxPrefixIni: 930, fxPrefixFim: 939, indEsp: 0, fxPais_Nome: 'GS1 Australia',
        },
        {
            fxPrefixIni: 940, fxPrefixFim: 949, indEsp: 0, fxPais_Nome: 'GS1 New Zealand',
        },
        {
            fxPrefixIni: 950, fxPrefixFim: 950, indEsp: 1, fxPais_Nome: 'GS1 Global Office',
        },
        {
            fxPrefixIni: 951, fxPrefixFim: 951, indEsp: 1, fxPais_Nome: 'Numera‡Æo para EPC Tag Data Standard',
        },
        {
            fxPrefixIni: 955, fxPrefixFim: 955, indEsp: 0, fxPais_Nome: 'GS1 Malaysia',
        },
        {
            fxPrefixIni: 958, fxPrefixFim: 958, indEsp: 0, fxPais_Nome: 'GS1 Macau',
        },
        {
            fxPrefixIni: 960, fxPrefixFim: 969, indEsp: 1, fxPais_Nome: 'Global Office (GTIN-8s)',
        },
        {
            fxPrefixIni: 977, fxPrefixFim: 977, indEsp: 1, fxPais_Nome: 'Serial publications (ISSN)',
        },
        {
            fxPrefixIni: 978, fxPrefixFim: 979, indEsp: 0, fxPais_Nome: 'Bookland (ISBN)',
        },
        {
            fxPrefixIni: 980, fxPrefixFim: 980, indEsp: 1, fxPais_Nome: 'Refund receipts',
        },
        {
            fxPrefixIni: 981, fxPrefixFim: 984, indEsp: 1, fxPais_Nome: 'GS1 Coupon identification for common currency areas',
        },
        {
            fxPrefixIni: 990, fxPrefixFim: 999, indEsp: 1, fxPais_Nome: 'GS1 Coupon identification',
        },
    ];
    const lengthCodes = codes.length;
    const prefix = parseInt(gtinPrefix, 10);
    let isGlobal = false;

    for (let i = 0; i < lengthCodes; i += 1) {
        if (prefix >= codes[i].fxPrefixIni && prefix <= codes[i].fxPrefixFim) {
            if (codes[i].indEsp === 0) {
                isGlobal = true;
            } else {
                isGlobal = false;
                break;
            }
        }
    }
    return isGlobal;
};

export const isGtinValid = (gtin) => {
    let gtinNormalized = gtin.toString();
    let isUpce = false;
    if (gtin.length !== 8 && gtin.length !== 11 && gtin.length !== 12 && gtin.length !== 13 && gtin.length !== 14) {
        return false;
    }
    if (gtinNormalized.length === 8 && gtinCheckSum(gtinNormalized.padStart(14, 0))) {
        gtinNormalized = upceToUpca(gtinNormalized);
        isUpce = true;
    }

    gtinNormalized = gtinNormalized.toString().padStart(14, 0);
    let gtinType = null;
    let gtinPrefix = null;
    let gtinOnly = null;
    const isValid = gtinCheckSum(gtinNormalized);
    const isInternal = checkInternal(gtinNormalized);

    if (!isValid) {
        return false;
    }
    if (isUpce) {
        gtinType = 'UPC-E';
        gtinPrefix = gtinNormalized.substring(1, 4);
        gtinOnly = gtin;
    } else if (gtinNormalized.substring(0, 6) === '000000') {
        gtinType = 'GTIN-8';
        gtinPrefix = gtinNormalized.substring(6, 9);
        gtinOnly = gtinNormalized.substring(6);
    } else if (gtinNormalized.substring(0, 2) === '00') {
        gtinType = 'GTIN-12';
        gtinPrefix = gtinNormalized.substring(1, 4);
        gtinOnly = gtinNormalized.substring(2);
    } else if (gtinNormalized.substring(0, 1) !== '0') {
        gtinType = 'GTIN-14';
        if (gtinNormalized.substring(1, 6) === '00000') {
            gtinPrefix = gtinNormalized.substring(6, 9);
        } else {
            gtinPrefix = gtinNormalized.substring(1, 4);
        }
        gtinOnly = gtinNormalized;
    } else {
        gtinType = 'GTIN-13';
        gtinPrefix = gtinNormalized.substring(1, 4);
        gtinOnly = gtinNormalized.substring(1);
    }

    const gs1Data = getGtinPrefixName(gtinPrefix);
    if (!gs1Data || isInternal) {
        return false;
    }

    return true;
};

export const mapFilterList = (name, item) => {
    switch (name) {
        default:
            return {
                label: item.label,
                value: item.value,
            };
    }
};

export const setFiltersModelListaCadastrada = (name, query, filters) => ({
    field: name,
    query,
    filters: {
        rede: name === 'rede' ? null : filters?.rede?.value,
        cidade: name === 'cidade' ? null : filters?.cidade?.value,
        uf: name === 'uf' ? null : filters?.uf?.value,
        categoria: name === 'categoria' ? null : filters?.categoria?.value,
        secao: name === 'secao' ? null : filters?.secao?.value,
        escopo: name === 'escopo' ? null : filters?.escopo?.value,
        tipoSolicitacao: name === 'tipoSolicitacao' ? null : filters?.tipoSolicitacao?.value,
        loja: name === 'loja' ? null : filters?.loja?.value,
    },
});
export const setFiltersModelNovaLista = ({ name, query, filters }) => {
    if (name === 'loja') {
        return {
            query: query || null,
            rede: filters?.rede?.value || null,
            cidade: filters?.cidade?.value || null,
            uf: filters?.uf?.value || null,
        };
    }
    return {
        field: name,
        query,
        rede: name === 'rede' ? null : filters?.rede?.value,
        cidade: name === 'cidade' ? null : filters?.cidade?.value,
        uf: name === 'uf' ? null : filters?.uf?.value,
    };
};

export const setDTO = (gtinList, filtros, paginacao) => ({
    lojas: filtros?.loja?.value || null,
    skus: gtinList || null,
    uf: filtros?.uf?.value || null,
    rede: filtros?.rede?.value || null,
    cidade: filtros?.cidade?.value || null,
    todasLojas: filtros?.todasLojas,
    quantityAcepted: false,
    size: paginacao.size,
    page: paginacao.page,
});

export const setPreviewListaCadastradaDTO = (filtros, paginacao) => ({
    produtos: filtros?.produto?.value || null,
    lojas: filtros?.loja?.value || null,
    nomeEscopo: filtros?.escopo?.value || null,
    tipoSolicitacao: filtros?.tipoSolicitacao?.value || null,
    secao: filtros?.secao?.value || null,
    categoria: filtros?.categoria?.value || null,
    uf: filtros?.uf?.value || null,
    cidade: filtros?.cidade?.value || null,
    rede: filtros?.rede?.value || null,
    filterRecommendation: filtros?.recommendation || false,
    size: paginacao.size,
    page: paginacao.page,
});

export const setDownloadDTONovaLista = (extensao, colunas) => ({
    extensao,
    dimensoes: colunas,
});

export const setDownloadDTOListaCadastrada = (extensao, filtros, colunas) => ({
    extensao,
    dimensoes: colunas,
    dto: setPreviewListaCadastradaDTO(filtros, { size: 20, page: 0 }),
});
