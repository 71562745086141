/* eslint-disable react/jsx-key */
import { format, parseISO } from 'date-fns';
import React, { useCallback, useMemo, useState } from 'react';
import CurrencyFormat from 'react-currency-format';
import { CompetitorPriceDTO } from '../../../../../../../@types/IPA/GerenciadorDePrecos';
import { Competitors, PhotoModal } from '../../../../../../../components';
import { LoadingSpinerArea } from '../../../../../../../components/LoadingSpinner';
import { parseStringToPlural } from '../../../../../../../utils';
import { NegotiationDataItemProps } from '../../NegociacaoFornecedor.types';

export interface IExpandedTableContent {
    rowData: NegotiationDataItemProps;
}

const originPrice = {
    'INFOPRICE - INFOPANEL': 'INFOPANEL',
    'INFOPRICE - MPDV': 'MPDV',
} as const;

export const ExpandedTableContent = ({ rowData }: IExpandedTableContent) => {
    const HAS_COMPETITORS_DTO =
        rowData.competitorsInfo?.competitorPriceDTO?.length;

    const CARD_RESUMO_CONCORRENTE_DATA =
        rowData.competitorsInfo?.competitor ??
        rowData.competitorsInfo?.competitorInfoPanel;

    const total = CARD_RESUMO_CONCORRENTE_DATA?.countCompetitorStore ?? 0;

    const [photoModal, setPhotoModal] =
    useState<CompetitorPriceDTO['photo']>(null);


    const showModal = useMemo(() => {
        return !!photoModal;
    }, [photoModal]);


    const handleHidePhotoModal = useCallback(() => {
        setPhotoModal(null);
    }, []);

    const handleClickPhotoModal = useCallback(
        (photo?: CompetitorPriceDTO['photo']) => {
            if (!photo) return;
            if (showModal) handleHidePhotoModal();
            else setPhotoModal(photo);
        },
        [showModal, handleHidePhotoModal],
    );

    return (
        <>
            <LoadingSpinerArea
                area={`competitor-row-${rowData.storeId}-${rowData.productId}`}
                height="100%"
                size="md"
            />
            <Competitors>
                {!CARD_RESUMO_CONCORRENTE_DATA && (
                    <p className="competitor-empty">
                        Informações de concorrência indisponíveis
                    </p>
                )}

                {CARD_RESUMO_CONCORRENTE_DATA && (
                    <>
                        <Competitors.Section>
                            <Competitors.Header>
                                <Competitors.Icon icon="MdDescInsights" />
                                <Competitors.Title>
                                    Concorrentes
                                </Competitors.Title>
                            </Competitors.Header>

                            <Competitors.Content>
                                <Competitors.Card>
                                    <Competitors.CardHeader>
                                        <Competitors.Title>
                                            {rowData.competitorsInfo?.competitor
                                                ? 'Concorrência direta'
                                                : 'Concorrência Infopanel'}
                                        </Competitors.Title>
                                        <Competitors.Subtitle>
                                            Produto encontrado em{' '}
                                            <strong>
                                                {total}{' '}
                                                {parseStringToPlural(
                                                    'loja',
                                                    total,
                                                )}
                                            </strong>
                                        </Competitors.Subtitle>
                                    </Competitors.CardHeader>
                                    <Competitors.CardContent>
                                        <Competitors.InfoBox
                                            isActive={
                                                rowData.competitivenessIndicator ===
                                                'RULE_CPI_AVG'
                                            }
                                        >
                                            <Competitors.Value>
                                                <CurrencyFormat
                                                    fixedDecimalScale
                                                    decimalScale={2}
                                                    value={
                                                        CARD_RESUMO_CONCORRENTE_DATA.competitorAverage
                                                    }
                                                    displayType="text"
                                                    prefix=" R$"
                                                    decimalSeparator=","
                                                    thousandSeparator="."
                                                />
                                            </Competitors.Value>
                                            <Competitors.Label>
                                                x̄ Preço
                                                <br />
                                                Médio
                                            </Competitors.Label>
                                        </Competitors.InfoBox>
                                        <Competitors.InfoBox
                                            isActive={
                                                rowData.competitivenessIndicator ===
                                                'RULE_CPI_MIN'
                                            }
                                        >
                                            <Competitors.Value>
                                                <CurrencyFormat
                                                    fixedDecimalScale
                                                    decimalScale={2}
                                                    value={
                                                        CARD_RESUMO_CONCORRENTE_DATA.competitorLower
                                                    }
                                                    displayType="text"
                                                    prefix=" R$"
                                                    decimalSeparator=","
                                                    thousandSeparator="."
                                                />
                                            </Competitors.Value>
                                            <Competitors.Label>
                                                {'<'} Preço
                                                <br />
                                                Mais Baixo
                                            </Competitors.Label>
                                        </Competitors.InfoBox>
                                        <Competitors.InfoBox
                                            isActive={
                                                rowData.competitivenessIndicator ===
                                                'RULE_CPI_MAX'
                                            }
                                        >
                                            <Competitors.Value>
                                                <CurrencyFormat
                                                    fixedDecimalScale
                                                    decimalScale={2}
                                                    value={
                                                        CARD_RESUMO_CONCORRENTE_DATA.competitorHigher
                                                    }
                                                    displayType="text"
                                                    prefix=" R$"
                                                    decimalSeparator=","
                                                    thousandSeparator="."
                                                />
                                            </Competitors.Value>
                                            <Competitors.Label>
                                                {'>'} Preço
                                                <br />
                                                Mais Alto
                                            </Competitors.Label>
                                        </Competitors.InfoBox>
                                    </Competitors.CardContent>
                                </Competitors.Card>
                            </Competitors.Content>
                        </Competitors.Section>

                        {HAS_COMPETITORS_DTO && (
                            <>
                                <Competitors.Separator />
                                <Competitors.Section>
                                    <Competitors.Header>
                                        <Competitors.Icon icon="DescArrowForward" />
                                        <Competitors.Title>
                                            Lojas
                                        </Competitors.Title>
                                    </Competitors.Header>

                                    <Competitors.Content>
                                        {rowData.competitorsInfo?.competitorPriceDTO.map(
                                            (competitor) => (
                                                <Competitors.Card>
                                                    <Competitors.CardHeader>
                                                        <Competitors.Title>
                                                            {
                                                                competitor.competitorStore
                                                            }
                                                        </Competitors.Title>
                                                        <Competitors.CardHeaderAction>
                                                            <Competitors.Tag>
                                                                {
                                                                    originPrice[
                                                                    competitor
                                                                        .origemPreco
                                                                    ]
                                                                }
                                                            </Competitors.Tag>
                                                            <Competitors.Label>
                                                                {
                                                                    competitor.competitorStoreType
                                                                }
                                                            </Competitors.Label>
                                                            {competitor.photo && (
                                                                <Competitors.PhotoIcon
                                                                    onClick={() => {
                                                                        handleClickPhotoModal(
                                                                            competitor.photo,
                                                                        );
                                                                    }}
                                                                />
                                                            )}

                                                        </Competitors.CardHeaderAction>
                                                    </Competitors.CardHeader>

                                                    <Competitors.CardContent>
                                                        <Competitors.InfoBox>
                                                            <Competitors.Label>
                                                                Preço
                                                            </Competitors.Label>
                                                            <Competitors.Value>
                                                                <CurrencyFormat
                                                                    fixedDecimalScale
                                                                    decimalScale={
                                                                        2
                                                                    }
                                                                    value={
                                                                        competitor?.retailPrice
                                                                    }
                                                                    displayType="text"
                                                                    prefix="R$"
                                                                    decimalSeparator=","
                                                                    thousandSeparator="."
                                                                />
                                                            </Competitors.Value>
                                                        </Competitors.InfoBox>

                                                        <Competitors.InfoBox>
                                                            <Competitors.Label>
                                                                Competitividade
                                                            </Competitors.Label>
                                                            <Competitors.Value>
                                                                <CurrencyFormat
                                                                    fixedDecimalScale
                                                                    decimalScale={
                                                                        2
                                                                    }
                                                                    value={
                                                                        rowData &&
                                                                            competitor &&
                                                                            'retailPrice' in
                                                                            rowData &&
                                                                            'retailPrice' in
                                                                            competitor &&
                                                                            rowData.retailPrice !==
                                                                            null && // Checking if rowData.retailPrice is not null
                                                                            competitor.retailPrice !==
                                                                            0 // Avoiding division by zero
                                                                            ? (rowData.retailPrice /
                                                                                competitor.retailPrice) *
                                                                            100
                                                                            : 0
                                                                    }
                                                                    displayType="text"
                                                                    suffix="%"
                                                                    decimalSeparator=","
                                                                    thousandSeparator="."
                                                                />
                                                            </Competitors.Value>
                                                        </Competitors.InfoBox>
                                                        <Competitors.InfoBox>
                                                            <Competitors.Label>
                                                                Data
                                                            </Competitors.Label>
                                                            <Competitors.Value>
                                                                {competitor.date
                                                                    ? format(
                                                                        parseISO(
                                                                            competitor.date,
                                                                        ),
                                                                        'dd/MM/yyyy',
                                                                    )
                                                                    : '--'}
                                                            </Competitors.Value>
                                                        </Competitors.InfoBox>
                                                    </Competitors.CardContent>
                                                </Competitors.Card>
                                            ),
                                        )}
                                    </Competitors.Content>
                                </Competitors.Section>
                            </>
                        )}
                    </>
                )}
            </Competitors>
            <PhotoModal
                show={true}
                onHide={handleHidePhotoModal}
                photo={photoModal}
            />
        </>
    );
};
