import { useDispatch } from 'react-redux';
import { resetOptimizationPrices, setOptions } from '../../../../reducers/OtimizacaoPrecos/optimizationPrices';

export function useScheduleOptimizationPrices() {
    const dispatch = useDispatch();

    function handleSetOptions(option: any[]) {
        dispatch(setOptions({ options: option }));
    }

    function handleReset() {
        dispatch(resetOptimizationPrices());
    }

    return { handleSetOptions, handleReset };
}
