import { Close, Search } from '@rsuite/icons';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button } from 'rsuite';
import { useDebounce } from '../../../../../../../hooks';
import { optimizationPricesListSelector } from '../../../../../../../reducers/OtimizacaoPrecos';
import { setSearch, setSearchInputValue } from '../../../../../../../reducers/OtimizacaoPrecos/optimizationPricesList';
import { useHandleSearchOptimization } from '../../../../hooks/useHandleSearchOptimization';
import styles from './GridHeader.module.scss';

export const GridHeader = () => {
    const inputRef = React.useRef<HTMLInputElement>(null);
    const history = useHistory();
    const debounce = useDebounce(400);

    const dispatch = useDispatch();
    useHandleSearchOptimization();

    const { searchInputValue } = useSelector(optimizationPricesListSelector);

    const searchList = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(setSearchInputValue({ value: e.target.value }));
        debounce(() => {
            dispatch(setSearch({ value: e.target.value }));
        });
    };

    const handleResetSearch = () => {
        dispatch(setSearch({ value: '' }));
        dispatch(setSearchInputValue({ value: '' }));
        inputRef.current?.focus();
    };

    return (
        <header className={styles.container}>
            <h1>Otimização de preços</h1>
            <div className={styles.search}>
                <span>
                    <Search color="#1473E6" style={{ height: '18px', width: '18px' }} />
                </span>
                <input type="text" ref={inputRef} placeholder="Buscar por uma categoria" value={searchInputValue} onChange={searchList} />
                <span onClick={handleResetSearch}>
                    <Close color="#747474" style={{ height: '18px', width: '18px', cursor: 'pointer' }} />
                </span>
            </div>
            <Button
                style={{
                    marginLeft: '8px',
                    borderRadius: '6px',
                }}
                appearance="primary"
                onClick={() => history.push('/ipa/otimizacao-de-precos')}
            >
                Nova Otimização
            </Button>
        </header>
    );
};
