import { useMutation, useQuery } from '@tanstack/react-query';
import { parseISO } from 'date-fns';
import { useParams } from 'react-router-dom';
import { Notification } from 'rsuite';
import { queryClient } from '../../../../../App';
import { useModal } from '../../../../../components';
import { AlertWithTimestamp } from '../../../../../components/AlertWithTimestamp';
import { Exception } from '../../../../../data';
import History from '../../../../../utils/History';
import { SalvarNegociacaoSchemaProps } from '../../components';
import { useNegotiationTable } from '../../hooks';
import {
    DELETENegotiation,
    DELETENegotiationDatapoint,
    EditarNegociacaoProps,
    GETDatapointsDetalhesDaNegociacao,
    GETNegotiationDetails,
    PATCHCloseNegotiation,
    PATCHReopenNegotiation,
    POSTDownloadNegotiation,
    editarNegociacao,
} from './DetalhesDaNegociacao.services';

const ROW_KEY = 'productsToBePricedId';

export const useDetalhesDaNegociacao = () => {
    const params = useParams() as Record<'id', string>;

    if (!params.id) History.push('/ipa/negociacoes/salvas');

    const handleNavBack = () => History.goBack();

    const [modals, { onHide: handleCloseModal, onOpen: handleOpenModal }] =
        useModal();

    const { data: negotiationData } = useQuery({
        queryKey: ['negotiation/get-details'],
        queryFn: () => GETNegotiationDetails(params.id ?? ''),
        initialData: {},
    });

    const table = useNegotiationTable({
        queryFn: (data) => {
            return GETDatapointsDetalhesDaNegociacao({
                ...data,
                ipaNegotiationId: params.id,
            });
        },
        rowKey: ROW_KEY,
        negotiationStatus: negotiationData.status,
    });

    const { mutate: handleCloseNegotiation } = useMutation({
        mutationFn: async (formData: Record<'id' | 'name', string>) => {
            const res = await PATCHCloseNegotiation(formData.id);
            return res.data;
        },
        onSuccess: (data) => {
            queryClient.setQueryData(['negotiation/get-details'], {
                ...negotiationData,
                status: data.status,
            });
            handleCloseModal('fecharNegocio');
            Notification.success({
                description: 'Negócio fechado.',
            });
        },
    });

    const { mutate: handleEditNegotiation } = useMutation({
        mutationFn: async (formData: SalvarNegociacaoSchemaProps) => {
            const model: EditarNegociacaoProps = {
                negotiationId: params.id,
                name: formData.name,
                supplier: formData.supplier ?? '',
                startDate: formData.startDate,
                endDate: formData.endDate,
            } as const;
            const res = await editarNegociacao(model);
            return res.data;
        },
        onSuccess: (data) => {
            handleCloseModal('editarNegociacao');
            Notification.success({
                description: 'Negociação salva!',
            });
            queryClient.setQueriesData(['negotiation/get-details'], data);
        },
    });

    const handleDeleteNegotiation = async () => {
        try {
            const response = await DELETENegotiation(params.id ?? '');
            if (response.status !== 204) return;

            handleCloseModal('excluirNegociacao');

            Notification.success({
                description: 'Negociação excluída!',
            });

            History.push('/ipa/negociacoes/salvas');
        } catch (err) {
            throw new Error('Erro durante a requisição!');
        }
    };

    const handleReopenNegotiation = async (
        formData: Record<'id' | 'name', string>,
    ) => {
        try {
            const response = await PATCHReopenNegotiation(formData.id);
            if (response.status !== 200) return;

            const newNegotiationId = response.data.id;
            History.replace(`/ipa/negociacoes/salvas/${newNegotiationId}`);

            handleCloseModal('reabrirNegocio');
            Notification.success({
                description: 'Negócio reaberto.',
            });

            window.location.reload();
        } catch (err) {
            throw new Error('Erro durante a requisição');
        }
    };

    const handleDownload = async () => {
        const res = await POSTDownloadNegotiation(params.id);
        return res;
    };

    const handleDeleteDatapoint = async () => {
        try {
            const res = await DELETENegotiationDatapoint({
                negotiationId: params.id,
                datapoints: {
                    excludedIds: table.selectedDatapoints.data.excluded,
                    selectedIds: table.selectedDatapoints.data.selected,
                },
            });
            if (res.status !== 204) return null;
            queryClient
                .refetchQueries(['get-negotiation-datapoints'])
                .then((res) => {
                    table.selectedDatapoints.reset();
                    Notification.success({
                        description: 'Produtos removidos!',
                    });
                    return res;
                });
            return res;
        } catch (err) {
            const { message } = new Exception('Erro genérico!');
            AlertWithTimestamp({ message });
        }
    };

    return {
        data: negotiationData,
        handleNavBack,
        table: {
            ...table,
            disableCheckBoxes: negotiationData.status === 'CLOSED',
            disableInputs: negotiationData.status === 'CLOSED',
        },
        heading: {
            name: negotiationData.name,
            status: negotiationData?.status,
            supplierId: negotiationData?.supplierId,
            supplierName: negotiationData?.supplierName,
            startDate: negotiationData?.startDate,
            endDate: negotiationData?.endDate,
            openModal: handleOpenModal,
        },
        tableHeading: {
            isSelected: !table.selectedDatapoints.isEmpty,
            openModal: handleOpenModal,
            hasSelectedDatapoints: !table.selectedDatapoints.isEmpty,
            selectedDatapointsLength:
                table.selectedDatapoints.currentState.length,
            totalElements: table.totalElements,
            status: negotiationData.status,
            onDownload: handleDownload,
            onDeleteDatapoints: handleDeleteDatapoint,
        },
        modals: {
            editarNegociacao: {
                show: modals.editarNegociacao,
                handleOpen: () => handleOpenModal('editarNegociacao'),
                onHide: () => handleCloseModal('editarNegociacao'),
                onSubmit: handleEditNegotiation,
                values: {
                    name: negotiationData.name ?? '--',
                    supplier: negotiationData.supplierName ?? '--',
                    startDate: parseISO(negotiationData.startDate as string),
                    endDate: negotiationData.endDate
                        ? parseISO(negotiationData.endDate)
                        : undefined,
                    enableFinalDate: !!negotiationData.endDate,
                },
            },
            excluirNegociacao: {
                show: modals.excluirNegociacao,
                name: negotiationData.name ?? '',
                onHide: () => handleCloseModal('excluirNegociacao'),
                onOpen: () => handleOpenModal('excluirNegociacao'),
                onSubmit: handleDeleteNegotiation,
            },
            fecharNegocio: {
                show: modals.fecharNegocio,
                data: { id: negotiationData.id, name: negotiationData.name },
                onHide: () => handleCloseModal('fecharNegocio'),
                onOpen: () => handleOpenModal('fecharNegocio'),
                onSubmit: handleCloseNegotiation,
            },
            reabrirNegocio: {
                show: modals.reabrirNegocio,
                data: { id: negotiationData.id, name: negotiationData.name },
                onHide: () => handleCloseModal('reabrirNegocio'),
                onOpen: () => handleOpenModal('reabrirNegocio'),
                onSubmit: handleReopenNegotiation,
            },
        },
    } as const;
};
