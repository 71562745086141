import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsExport from 'highcharts/modules/export-data';
import HighchartsExporting from 'highcharts/modules/exporting';
import React, { useRef } from 'react';
import { useSelector } from 'react-redux';

import { selectorDemandForecastOutputArea } from '../../../../../../../../reducers/previsaoDemanda';
import { formatNumbersToPtBr } from '../../../../../../../../utils/FormatNumbers';
import { CpiChartResponse } from '../../../@types/charts';

HighchartsExporting(Highcharts);
HighchartsExport(Highcharts);

export const HighchartsOptions = {
    lang: {
        decimalPoint: ',',
        thousandsSep: '.',
        exitFullscreen: 'Sair da tela cheia',
    },
};

Highcharts.setOptions(HighchartsOptions);

const yAxis = (name: 'demanda' | 'receita' | 'cpi', item: CpiChartResponse) => {
    const y = {
        demanda: item.demand,
        receita: parseInt(item.revenue.toFixed(0)),
        cpi: item.cpi,
    };

    return y[name];
};

const setDataChart = (name: 'demanda' | 'receita', data: CpiChartResponse[]) => {
    const newData = data.map((item) => ({
        name,
        x: item.cpi,
        y: yAxis(name, item),
        demand: item.demand,
        revenue: item.revenue,
        clientPreco: item.client_price,
        competitorPreco: item.competitor_price,
    }));

    return newData;
};

export const ElasticCrossChart = () => {
    const elasticCrossChartRef = useRef(null);

    const { cpiChartData } = useSelector(selectorDemandForecastOutputArea);

    const formatMoney = { maximumFractionDigits: 2, minimumFractionDigits: 2 };

    const defaultChartOption = {
        chart: {
            type: 'spline',
        },
        title: {
            text: '',
        },
        exporting: {
            enabled: false,
        },
        tooltip: {
            useHTML: true,
            pointFormatter(this: any): string {
                const demanda = Math.floor(this.demand);
                const cpi = this.x * 100;

                return `
                    <p>
                        Preço simulado: <b>R$ ${formatNumbersToPtBr(this.clientPreco, 'currency', formatMoney)}</b>
                    </p>
                    <p>
                        Preço concorrente: <b>R$ ${formatNumbersToPtBr(this.competitorPreco, 'currency', formatMoney)}</b>
                    </p>
                    <p>
                        CPI (INDEX DE PREÇO): <b>${Math.round(cpi)}%</b>
                    </p>
                    <br/>
                    <div>
                        <span style="color:#E663C9">
                            <b>●</b>
                        </span>
                        <span>
                            Receita: <b>
                                R$ ${this.revenue ? formatNumbersToPtBr(this.revenue, 'currency', formatMoney) : 0}
                            </b>
                        </span>
                    </div>
                    <div>
                        <span style="color:#0D66D0">
                            <b>■</b>
                        </span>
                        <span>
                            Demanda: <b>${demanda ? formatNumbersToPtBr(demanda, 'decimal', { maximumFractionDigits: 0 }) : 0} un</b>
                        </span>
                    </div>
                `;
            },
            headerFormat: '',
        },
        legend: {
            symbolWidth: 50,
        },
        yAxis: [
            {
                title: {
                    text: 'RECEITA',
                },
                accessibility: {
                    description: 'RECEITA',
                },
                opposite: true,
                labels: {
                    formatter(this: Highcharts.AxisLabelsFormatterContextObject) {
                        const price = this.axis.defaultLabelFormatter.call(this);
                        return `R$ ${price.replace(',', '.')}`;
                    },
                },
            },
            {
                title: {
                    text: 'IMPACTO DEMANDA',
                },
                accessibility: {
                    description: 'IMPACTO DEMANDA',
                },
            },
        ],

        xAxis: {
            title: {
                text: 'CPI (INDEX DE PREÇO)',
            },
            accessibility: {
                description: 'CPI (INDEX DE PREÇO)',
            },
            labels: {
                formatter(this: Highcharts.AxisLabelsFormatterContextObject) {
                    let cpi = this.axis.defaultLabelFormatter.call(this);

                    if (cpi.includes(',')) {
                        cpi = cpi.replace(',', '.');
                    }
                    return `${Math.round(Number(cpi) * 100)}%`;
                },
            },
        },
        plotOptions: {},
        series: [
            {
                name: 'Demanda',
                data: setDataChart('demanda', cpiChartData),
                color: '#0D66D0',
                marker: {
                    symbol: 'square',
                },
                yAxis: 1,
            },
            {
                name: 'Receita',
                data: setDataChart('receita', cpiChartData),
                color: '#E663C9',
            },
        ],
    };

    return <HighchartsReact ref={elasticCrossChartRef} highcharts={Highcharts} options={defaultChartOption} />;
};
