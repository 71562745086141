import React, { useState } from 'react';
import { DatePicker, InputGroup } from 'rsuite';
import { DatePickerProps } from 'rsuite/lib/DatePicker';
import styles from './DatePickerCustom.module.scss';

type Props = {
    icon?: React.ReactNode;
} & DatePickerProps;
export const DatePickerCustom = ({ icon, disabled, ...props }: Props) => {
    const ref = React.useRef<any>(null);
    const [open, setOpen] = useState(false);
    const customStyle = disabled ? { cursor: 'not-allowed' } : { cursor: 'pointer' };

    return (
        <InputGroup style={{ margin: 'auto' }}>
            {disabled ? (
                <div style={{ ...customStyle, width: '100%', height: '34px', background: '#EAEAEA' }} />
            ) : (
                <DatePicker {...props} disabled={disabled} className={styles.container} ref={ref} style={{ width: '100%' }} />
            )}
            {icon ? (
                <InputGroup.Addon
                    style={customStyle}
                    onClick={() => {
                        if (disabled) return;
                        setOpen(!open);

                        if (open) {
                            ref.current?.close();
                            return;
                        }
                        ref.current?.handleOpenDropdown();
                    }}
                >
                    {icon}
                </InputGroup.Addon>
            ) : null}
        </InputGroup>
    );
};
