/* eslint-disable react/jsx-one-expression-per-line */
import { useQuery } from '@tanstack/react-query';
import React, { memo, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GerenciadorPrecos } from '../../../../../@types/GerenciadorPrecos';
import { RootState } from '../../../../../@types/RootState';
import { Competitors, LoadingSpinerArea } from '../../../../../components';
import { AlertWithTimestamp } from '../../../../../components/AlertWithTimestamp';
import { Exception } from '../../../../../data';
import { UPDATE_GERENCIADOR_DATAPOINT_BY_ID } from '../../../../../reducers/gerenciadorPrecos/datapoints';
import { selectorDisabledCompetitors } from '../../../../../reducers/gerenciadorPrecos/disabledCompetitors';
import { GetCompetitorsInfo, getCompetitorsInfoOfferPrice } from '../../services';
import ExpandedRowDtoListOfferPrice from './ExpandedRowDtoListOfferPrice';
import ExpandedRowResumeCardOfferPrice from './ExpandedRowResumeCardOfferPrice';

const MemoExpandedRowResumeCardOfferPrice = memo(ExpandedRowResumeCardOfferPrice);
const MemoExpandedRowDtoListOfferPrice = memo(ExpandedRowDtoListOfferPrice);

type RowData = GerenciadorPrecos.RowData;

export type ExpandedRowCompetitorsProps = {
    rowData: RowData;
};

const ExpandedRowCompetitorsOffer = ({ rowData }: ExpandedRowCompetitorsProps) => {
    const options = useSelector((state: RootState) => {
        return state.gerenciadorPrecosReducer.expandedRowKey.options;
    });

    const { productId, storeId, productFamilyId, productsToBePricedId, productFamily, competitorDays = 7, unselectedCompetitors } = rowData;

    const dispatch = useDispatch();

    const disabledCompetitors = useSelector(selectorDisabledCompetitors);

    const model: GetCompetitorsInfo.Params = useMemo(() => {
        return {
            productId,
            storeId,
            productsToBePricedId,
            competitorDays,
            productFamilyId: productFamily ? productFamilyId : null,
            competitorStoreId: disabledCompetitors[productsToBePricedId] || [],
            unselectedCompetitors,
        };
    }, [productId, storeId, productsToBePricedId, competitorDays, productFamilyId, productFamily, disabledCompetitors, unselectedCompetitors]);

    const handleGetCompetitorsOfferPrice = useCallback(async () => {
        try {
            const res = await getCompetitorsInfoOfferPrice(model);
            return res;
        } catch {
            const { message } = new Exception(
                'Erro ao buscar informações dos concorrentes',
            );
            AlertWithTimestamp({ message });
        }
    }, [model]);

    const handleSuccess = useCallback(
        (competitorsInfoOffer: GetCompetitorsInfo.Result) => {
            const payload = {
                id: productsToBePricedId,
                data: { competitorsInfoOffer },
            };
            dispatch(UPDATE_GERENCIADOR_DATAPOINT_BY_ID(payload));
        },
        [dispatch, productsToBePricedId],
    );

    const { data } = useQuery({
        queryKey: ['ipa/gerenciador/competitors-info/offer-price', model],
        keepPreviousData: false,
        queryFn: handleGetCompetitorsOfferPrice,
        onSuccess: handleSuccess,
        enabled: !!rowData.offer?.competitorsPrice,
    });

    const resumeCardData = useMemo(() => {
        return data?.competitor ?? data?.competitorInfoPanel;
    }, [data?.competitor, data?.competitorInfoPanel]);

    const hasCompetitorsDto = data?.competitorPriceDTO !== undefined && Array.isArray(data?.competitorPriceDTO) && data.competitorPriceDTO.length > 0;

    if (!options.includes('OFFERPRICE_COMPETITORS')) return null;

    return (
        <Competitors>
            {!resumeCardData && <p className="competitor-empty">Informações de concorrência indisponíveis</p>}
            {!!resumeCardData && (
                <>
                    <MemoExpandedRowResumeCardOfferPrice rowData={rowData} resumeCardData={resumeCardData} competitor={data?.competitor} />

                    {hasCompetitorsDto && (
                        <>
                            <Competitors.Separator />
                            <Competitors.Section>
                                <Competitors.Header>
                                    <Competitors.Icon icon="DescArrowForward" />
                                    <Competitors.Title>Lojas</Competitors.Title>
                                </Competitors.Header>
                                <Competitors.Content>
                                    <MemoExpandedRowDtoListOfferPrice rowData={rowData} />
                                </Competitors.Content>
                            </Competitors.Section>
                        </>
                    )}
                </>
            )}
            <LoadingSpinerArea area={`competitor-row-${rowData.storeId}-${rowData.productId}`} height="100%" size="md" />
        </Competitors>
    );
};

export default ExpandedRowCompetitorsOffer;
