/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-interface */
import { RowData, TableFeature } from '@tanstack/react-table';

export type RowHeight = number;

export interface RowHeightOptions {
    rowHeight?: number;
}

declare module '@tanstack/react-table' {
    interface TableOptionsResolved<TData extends RowData> extends RowHeightOptions {}
}

export const RowHeightFeature: TableFeature<RowHeightOptions> = {
    getDefaultOptions: (): RowHeightOptions => {
        return {
            rowHeight: undefined,
        } as RowHeightOptions;
    },
};
