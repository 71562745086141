import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Content } from 'rsuite';
import HowToUseModuleReportModal from './HowToUseModuleReportModal';

const HowToUseModuleReportBanner = ({ diagonalBannerText, dataVideo, title, subtitle, onClickModal }) => {
    const history = useHistory();
    const [showModal, setShowModal] = useState(false);
    return (
        <>
            <Content className="how-to-use-module-report-banner">
                {diagonalBannerText ? (
                    <div className="diagonal-banner-container">
                        <div className="diagonal-banner">{diagonalBannerText}</div>
                    </div>
                ) : null}
                <div className="banner-title">
                    {title}
                </div>
                <div className="banner-subtitle">
                    {subtitle}
                </div>
                <Button color="orange" onClick={history.location.pathname === '/isa/analise-competitividade/varejo' ? onClickModal : () => setShowModal(true)}>
                    Veja na prática
                </Button>
            </Content>
            <HowToUseModuleReportModal
                dataVideo={dataVideo}
                show={showModal}
                onHide={() => setShowModal(false)}
            />
        </>
    );
};

export default HowToUseModuleReportBanner;
