import MarkerClusterer from '@googlemaps/markerclustererplus';
import mapDataBR from '@highcharts/map-collection/countries/br/br-all.geo.json';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsExport from 'highcharts/modules/export-data';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsMap from 'highcharts/modules/map';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    Alert,
    Button,
    Col,
    Content,
    Dropdown,
    FlexboxGrid,
    Icon,
    IconButton,
    SelectPicker,
} from 'rsuite';

import { trackPromise } from 'react-promise-tracker';
import clusterPoint2 from '../../../../../assets/icons/cluster_point-2.svg';
import singlePoint from '../../../../../assets/icons/single_point.svg';
import { MAPS_LOADER } from '../../../../../utils/GoogleChartsConfig';

import iconEllipsis from '../../../../../assets/icons/icon_ellipsis-v.svg';
import lockIcon from '../../../../../assets/icons/icon_lock.svg';
import iconMaximize from '../../../../../assets/icons/icon_maximize.svg';
import iconMinimize from '../../../../../assets/icons/icon_minimize.svg';
import noDataImage from '../../../../../assets/image/empty-state-2.svg';

import {
    getMapData,
    getMarkersData,
    setVisualizationTypeMap,
} from '../../../../../actions/actionsPainelGeral';
import { LoadingSpinerLocal } from '../../../../../components/LoadingSpinner';
import MobileSelectOptionComponent from '../../../../../components/MobileSelectOptionComponent';
import ModalConfirmation from '../../../../../components/ModalConfirmation';
import { setClickToLocalStorage } from '../../../../../components/NavBar';
import { lead } from '../../../../../services/EventLeadService';

import { trackEvent } from '../../../../../utils/MatomoConfig';
import EmptyState from './EmptyState';

HighchartsExporting(Highcharts);
HighchartsExport(Highcharts);
HighchartsMap(Highcharts);

Highcharts.setOptions({
    lang: {
        decimalPoint: ',',
        thousandsSep: '.',
        exitFullscreen: 'Sair da tela cheia',
    },
});

const optionsGroupBy = [
    { label: 'Estado', value: 'estado' },
    { label: 'Loja', value: 'loja' },
];

const createHTMLMapMarker = ({
    OverlayView = window.google.maps.OverlayView,
    ...args
}) => {
    class HTMLMapMarker extends OverlayView {
        constructor() {
            super();
            this.lat = args.lat;
            this.lng = args.lng;
            this.latlng = new window.google.maps.LatLng(args.lat, args.lng);
            this.price = args.price;
            this.html = args.html;
            this.setMap(args.map);
        }

        createDiv() {
            this.div = document.createElement('div');
            this.div.style.position = 'absolute';
            if (this.html) {
                this.div.innerHTML = this.html;
            }
            window.google.maps.event.addDomListener(this.div, 'click', () => {
                window.google.maps.event.trigger(this, 'click');
            });
        }

        appendDivToOverlay() {
            const panes = this.getPanes();
            panes.overlayImage.appendChild(this.div);
        }

        positionDiv() {
            const point = this.getProjection().fromLatLngToDivPixel(
                this.latlng,
            );
            if (point) {
                this.div.style.left = `${point.x}px`;
                this.div.style.top = `${point.y}px`;
            }
        }

        draw() {
            if (!this.div) {
                this.createDiv();
                this.appendDivToOverlay();
            }
            this.positionDiv();
        }

        remove() {
            if (this.div) {
                this.div.parentNode.removeChild(this.div);
                this.div = null;
            }
        }

        getPosition() {
            return this.latlng;
        }

        getDraggable() {
            return false;
        }

        getVisible() {
            return this.getVisible();
        }
    }

    return new HTMLMapMarker();
};

function createMarkerLayout(item) {
    return (
        `${"<div class='marker'>" + "<img src='"}${singlePoint}'>`
        + '<div>'
        + `<span>${item.price.toFixed(2).replace('.', ',')}</span></div>`
        + '</div>'
    );
}

// eslint-disable-next-line no-unused-vars
MarkerClusterer.CALCULATOR = function (markers, _numStyles) {
    const index = 0;

    const valueToSum = markers.reduce(
        (total, item) => (total += item.price),
        0,
    );

    const media = (valueToSum / markers.length).toFixed(2).replace('.', ',');

    return {
        text: `<span class='cluster__stores'>${markers.length}</span><span class='cluster__price-average'>${media}</span>`,
        index,
        title: `Preço médio: ${media}`,
    };
};

class MapComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            typeVisualization: 'estado',
            showCTA: false,
            expand: true,
            openDialog: false,
            filteredProduct: '',
            visualizationNameType: 'Estado',
        };

        this.exportCSV = this.exportCSV.bind(this);
        this.exportXLS = this.exportXLS.bind(this);
        this.exportPNG = this.exportPNG.bind(this);
        this.print = this.print.bind(this);
        this.viewFullScreen = this.viewFullScreen.bind(this);
        this.sendCTA = this.sendCTA.bind(this);
        this.handleOpenDialog = this.handleOpenDialog.bind(this);
        this.changeVisualization = this.changeVisualization.bind(this);
        this.expandMap = this.expandMap.bind(this);
        this.setPermitionState = this.setPermitionState.bind(this);
        this.changeProduct = this.changeProduct.bind(this);
        this.clearProduct = this.clearProduct.bind(this);
        this.setShowModalConfirm = this.setShowModalConfirm.bind(this);
        this.validatePlan = this.validatePlan.bind(this);
        this.handleNameOptions = this.handleNameOptions.bind(this);
    }

    componentDidMount() {
        const { usuario } = this.props;
        this.setState({
            hasPermition:
                !usuario?.servicoPanel?.pacotePainel?.name === 'LITE'
                || usuario?.servicoPanel?.pacotePainel?.name === 'FREE',
        });
    }

    shouldComponentUpdate(nextProps, nextState) {
        const {
            typeVisualization, expand, showCTA, openDialog, hasPermition,
        }
            = this.state;
        const { data, markersData, loadingMap } = this.props;

        return (
            nextProps.data !== data
            || nextState.typeVisualization !== typeVisualization
            || nextState.expand !== expand
            || nextState.showCTA !== showCTA
            || nextState.openDialog !== openDialog
            || nextState.hasPermition !== hasPermition
            || nextProps.markersData !== markersData
            || nextProps.loadingMap !== loadingMap
        );
    }

    componentDidUpdate() {
        const { typeVisualization, hasPermition } = this.state;
        const { usuario, produto } = this.props;

        if (
            usuario?.servicoPanel?.pacotePainel?.name === 'LIMIT'
            && produto?.cache.some((item) => item.limited === true)
        ) {
            this.setPermitionState(false);
        } else if (
            typeVisualization === 'loja'
            && (usuario?.servicoPanel?.pacotePainel?.name === 'FREE'
                || usuario?.servicoPanel?.pacotePainel?.name === 'LITE')
        ) {
            this.setPermitionState(false);
        } else if (
            typeVisualization === 'estado'
            && (usuario?.servicoPanel?.pacotePainel?.name === 'FREE'
                || usuario?.servicoPanel?.pacotePainel?.name === 'LITE')
        ) {
            this.setPermitionState(false);
        } else {
            this.setPermitionState(true);
        }

        if (typeVisualization === 'loja' && hasPermition) {
            setTimeout(() => {
                this.initMap();
            }, 1000);
        }
    }

    handleOpenDialog() {
        this.setState({
            openDialog: true,
        });
    }

    async getNewData() {
        const { onChangeVisualizationMap } = this.props;
        const { typeVisualization, filteredProduct } = this.state;
        onChangeVisualizationMap(typeVisualization, filteredProduct);
    }

    setPermitionState(hasPermition) {
        this.setState({
            hasPermition,
        });
    }

    getMax(data) {
        const copyData = [...data];

        const sortData = copyData.sort((a, b) => b.preco_medio - a.preco_medio);

        return sortData[0].preco_medio;
    }

    getMin(data) {
        const copyData = [...data];

        const sortData = copyData.sort((a, b) => a.preco_medio - b.preco_medio);

        return sortData[0].preco_medio;
    }

    mapDataSeries(data) {
        const receivedData = [...data];

        const mapData = receivedData
            .filter((item) => item.uf.indexOf('SP') === -1)
            .map((item) => [`br-${item.uf.toLowerCase()}`, item.preco_medio]);

        let media = 0;
        receivedData
            .filter((item) => item.uf.indexOf('SP') !== -1)
            .forEach((item) => {
                media += item.preco_medio;
            });

        if (media) {
            if (
                receivedData.filter((item) => item.uf.indexOf('SP') !== -1)
                    .length === 2
            ) {
                mapData.push(['br-sp', Number((media / 2).toFixed(2))]);
            } else {
                mapData.push(['br-sp', Number(media.toFixed(2))]);
            }
        }

        return mapData;
    }

    expandMap() {
        const { expand } = this.state;

        this.setState({
            expand: !expand,
        });
    }

    initMap() {
        const { markersData } = this.props;

        if (markersData?.length) {
            const newMakers = [...markersData];

            trackPromise(
                MAPS_LOADER.load().then(() => {
                    const map = new window.google.maps.Map(
                        document.getElementById('map'),
                        {
                            zoom: 3,
                            streetViewControl: false,
                            mapTypeControl: false,
                            center: new window.google.maps.LatLng(
                                newMakers[0].lat,
                                newMakers[0].lng,
                            ),
                        },
                    );

                    // eslint-disable-next-line no-unused-vars
                    const markers = newMakers.map((location) => {
                        const marker = createHTMLMapMarker({
                            lat: location.lat,
                            lng: location.lng,
                            price: location.price,
                            map,
                            html: createMarkerLayout(location),
                        });

                        const infowindow = new window.google.maps.InfoWindow({
                            content: `Rede: <b>${location.rede
                                }</b><br>Média de preços: <b>${location.price
                                    .toFixed(2)
                                    .replace('.', ',')}</b>`,
                        });

                        marker.addListener('click', () => {
                            infowindow.open(map, marker);
                        });

                        return marker;
                    });

                    new MarkerClusterer(map, markers, {
                        gridSize: 90,
                        styles: [
                            {
                                url: clusterPoint2,
                                height: 40,
                                width: 185,
                                textColor: '#2169a8',
                                fontWeight: 500,
                                marginTop: 22,
                            },
                        ],
                    });
                }),
            );
        }
    }

    handleNameOptions(label) {
        if (label) {
            this.setState({ visualizationNameType: label });
        }
    }

    changeVisualization(type, label) {
        this.handleNameOptions(label);
        const { setVisualizationTypeMap, usuario, produto } = this.props;

        setVisualizationTypeMap(type);

        if (usuario?.servicoPanel?.pacotePainel?.name === 'FREE') {
            this.setState({
                typeVisualization: type,
                hasPermition: false,
            });
            setClickToLocalStorage('addBlockFree', 'clique-adblock', usuario);
        } else if (
            (type === 'loja'
                && usuario?.servicoPanel?.pacotePainel?.name === 'LITE')
            || (usuario?.servicoPanel?.pacotePainel?.name === 'LIMIT'
                && produto?.cache.some((item) => item.limited === true))
        ) {
            this.setState({
                typeVisualization: type,
                hasPermition: false,
            });
        } else {
            this.setState(
                {
                    typeVisualization: type,
                    hasPermition: true,
                },
                this.getNewData,
            );
        }
    }

    sendCTA() {
        const { usuario } = this.props;
        const identificador = 'upsell_infopanel';

        lead(identificador, usuario).then(() => {
            this.setState({
                showCTA: false,
            });
            Alert.success('Em breve entraremos em contato!');
        });

        trackEvent('Upsell/Crossell', 'upsell-block-mapa');
        window.open(
            'https://wa.me/551137779670?text=Ol%C3%A1%21+Quero+saber+mais+sobre+os+planos+Flex+e+Pro+do+ISA+-+InfoPanel',
        );
    }

    setShowModalConfirm() {
        this.setState({ showCTA: true });
        trackEvent('Upsell/Crossell', 'click-funcao-bloqueada');
    }

    exportCSV() {
        if (this.chartTimeEvolution?.chart) {
            this.chartTimeEvolution.chart.downloadCSV();
        }
    }

    exportXLS() {
        if (this.chartTimeEvolution?.chart) {
            this.chartTimeEvolution.chart.downloadXLS();
        }
    }

    exportPNG() {
        if (this.chartTimeEvolution?.chart) {
            this.chartTimeEvolution.chart.exportChart({ type: 'image/png' });
        }
    }

    print() {
        if (this.chartTimeEvolution?.chart) {
            this.chartTimeEvolution.chart.print();
        }
    }

    viewFullScreen() {
        if (this.chartTimeEvolution?.chart) {
            this.chartTimeEvolution.chart.fullscreen.toggle();
        }
    }

    changeProduct(value, item) {
        const { typeVisualization } = this.state;
        const { onChangeMapProduct } = this.props;

        this.setState({
            filteredProduct: item.identificador,
        });

        onChangeMapProduct(typeVisualization, item.identificador);
    }

    clearProduct() {
        const { typeVisualization } = this.state;
        const { onCleanMapProduct } = this.props;

        this.setState({
            filteredProduct: '',
        });

        onCleanMapProduct(typeVisualization);
    }

    renderMap() {
        const { data } = this.props;
        const { typeVisualization } = this.state;

        return {
            chart: {
                map: mapDataBR,
                height: 435,
            },
            title: {
                text: '',
            },
            exporting: {
                enabled: false,
            },
            mapNavigation: {
                enabled: true,
                buttonOptions: {
                    verticalAlign: 'bottom',
                },
            },
            colorAxis: {
                min: this.getMin(data.content),
                max: this.getMax(data.content),
            },
            tooltip: {
                useHTML: true,
                shared: true,
                pointFormatter() {
                    return `<span><b>${this.name
                        }</b> <br> Preço médio: <b>${this.value
                            .toFixed(2)
                            .replace('.', ',')}</b></span>`;
                },
                headerFormat: '',
            },
            series: [
                {
                    data:
                        this.mapDataSeries(data.content, typeVisualization)
                        || [],
                    name: '',
                    dataLabels: {
                        enabled: false,
                        format: '{point.name}',
                    },
                },
            ],
        };
    }

    validatePlan() {
        const { usuario, produto } = this.props;

        if (
            usuario?.servicoPanel?.pacotePainel?.name === 'LIMIT'
            && produto?.cache.some((item) => item.limited === true)
        ) {
            return false;
        }
        if (
            usuario?.servicoPanel?.pacotePainel?.name === 'FREE'
            || usuario?.servicoPanel?.pacotePainel?.name === 'LITE'
        ) {
            return false;
        }
        return true;
    }

    render() {
        const {
            data, markersData, usuario, loadingMap, produto, pageBlocker
        } = this.props;
        const {
            typeVisualization,
            showCTA,
            expand,
            openDialog,
            hasPermition,
            visualizationNameType,
        } = this.state;

        return (
            <Content className="map-chart">
                <LoadingSpinerLocal loading={loadingMap} size="md" />
                <FlexboxGrid className="header-area">
                    <FlexboxGrid.Item
                        componentClass={Col}
                        lg={20}
                        md={20}
                        sm={17}
                        xs={17}
                        className={!expand ? 'minimized' : null}
                    >
                        <h4>MAPA DE PREÇOS</h4>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item
                        componentClass={Col}
                        lg={2}
                        md={2}
                        sm={3}
                        xs={3}
                        className={
                            !expand
                                ? 'expand-section minimized'
                                : 'expand-section'
                        }
                    >
                        <Button
                            id="boxplot-expand-btn"
                            appearance="subtle"
                            onClick={this.expandMap}
                        >
                            {expand ? (
                                <img alt="Maximizar" src={iconMinimize} />
                            ) : (
                                <img alt="Minimizar" src={iconMaximize} />
                            )}
                        </Button>
                    </FlexboxGrid.Item>
                    {this.validatePlan() ? (
                        <FlexboxGrid.Item
                            componentClass={Col}
                            lg={2}
                            md={2}
                            sm={3}
                            xs={3}
                            className={
                                !expand
                                    ? 'more-options-section minimized'
                                    : 'more-options-section'
                            }
                        >
                            <Dropdown
                                id="map-more-options"
                                placement="bottomEnd"
                                renderTitle={() => (
                                    <IconButton
                                        className="more-options-section__btn"
                                        appearance="subtle"
                                        icon={(
                                            <img
                                                alt="Mais opções"
                                                src={iconEllipsis}
                                            />
                                        )}
                                    />
                                )}
                            >
                                <Dropdown.Item
                                    onClick={this.viewFullScreen}
                                    id="btn-map-full-screen"
                                >
                                    Ver em tela cheia
                                </Dropdown.Item>
                                <Dropdown.Item
                                    disabled={
                                        usuario?.servicoPanel?.pacotePainel
                                            ?.name === 'FREE'
                                    }
                                    onClick={this.print}
                                    id="btn-map-print"
                                >
                                    Imprimir
                                </Dropdown.Item>
                                <Dropdown.Item divider />
                                <Dropdown.Item
                                    disabled={
                                        usuario?.servicoPanel?.pacotePainel
                                            ?.name === 'FREE'
                                    }
                                    onClick={this.exportPNG}
                                    id="btn-map-download-png"
                                >
                                    Download em PNG
                                </Dropdown.Item>
                                <Dropdown.Item
                                    disabled={
                                        usuario?.servicoPanel?.pacotePainel
                                            ?.name === 'FREE'
                                    }
                                    onClick={this.exportCSV}
                                    id="btn-map-download-csv"
                                >
                                    Download em CSV
                                </Dropdown.Item>
                                <Dropdown.Item
                                    disabled={
                                        usuario?.servicoPanel?.pacotePainel
                                            ?.name === 'FREE'
                                    }
                                    onClick={this.exportXLS}
                                    id="btn-map-download-xls"
                                >
                                    Download em XLS
                                </Dropdown.Item>
                            </Dropdown>
                        </FlexboxGrid.Item>
                    ) : null}
                </FlexboxGrid>
                {expand ? (
                    <>
                        <FlexboxGrid>
                            <FlexboxGrid.Item
                                componentClass={Col}
                                lg={24}
                                md={24}
                                xsHidden
                                smHidden
                                className="select-visualization-section"
                            >
                                <span className="title-select-visualization-section">
                                    Agrupar por:
                                    {' '}
                                </span>
                                <Dropdown title={visualizationNameType}>
                                    {optionsGroupBy.map((groupBy) => (
                                        <Dropdown.Item
                                            className="select-visualization-section__button"
                                            onClick={() => this.changeVisualization(
                                                groupBy.value,
                                                groupBy.label,
                                            )}
                                        >
                                            {groupBy.label}
                                        </Dropdown.Item>
                                    ))}
                                </Dropdown>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item
                                componentClass={Col}
                                xs={24}
                                sm={24}
                                lgHidden
                                mdHidden
                                className="select-visualization-section"
                            >
                                <IconButton
                                    className="select-visualization-section__dropdown"
                                    icon={<Icon icon="angle-down" />}
                                    onClick={this.handleOpenDialog}
                                    placement="right"
                                >
                                    Agrupar por:
                                    {' '}
                                    <span>
                                        {typeVisualization === 'estado'
                                            ? 'Estado'
                                            : typeVisualization === 'loja'
                                                ? 'Loja'
                                                : null}
                                    </span>
                                </IconButton>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item
                                componentClass={Col}
                                lg={24}
                                md={24}
                                xs={24}
                                className="select-product-section"
                            >
                                <span className="select-product-section__title">
                                    Produto:
                                    {' '}
                                </span>
                                <SelectPicker
                                    placeholder="Todos os produtos filtrados"
                                    className="select-product-input"
                                    maxHeight={240}
                                    appearance="subtle"
                                    onSelect={(value, item) => this.changeProduct(value, item)}
                                    onClean={() => this.clearProduct()}
                                    data={
                                        produto?.value?.length
                                            ? produto?.cache
                                            : []
                                    }
                                    cleanable
                                    disabled={!this.validatePlan()}
                                />
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                        <FlexboxGrid style={{ position: 'relative' }}>
                            {!hasPermition ? pageBlocker : null}
                            {typeVisualization === 'estado' ? (
                                <FlexboxGrid.Item
                                    componentClass={Col}
                                    lg={24}
                                    md={24}
                                    sm={24}
                                    xs={24}
                                    id="chart-area"
                                    className="chart-area"
                                >
                                    {data?.content?.length ? (
                                        <HighchartsReact
                                            ref={(ref) => {
                                                this.chartTimeEvolution = ref;
                                            }}
                                            constructorType="mapChart"
                                            highcharts={Highcharts}
                                            options={this.renderMap()}
                                        />
                                    ) : (
                                        <EmptyState
                                            title="Ops! Nenhum resultado encontrado"
                                            message="Sua busca não retornou nenhuma informação. Altere os filtros e tente novamente."
                                            image={noDataImage}
                                        />
                                    )}
                                </FlexboxGrid.Item>
                            ) : (
                                <>
                                    {markersData?.length === 3000 ? (
                                        <FlexboxGrid className="warning-map">
                                            <FlexboxGrid.Item
                                                componentClass={Col}
                                                lg={24}
                                                md={24}
                                                sm={24}
                                                xs={24}
                                                className="warning-visualization"
                                            >
                                                <p>
                                                    Você pode visualizar até
                                                    3.000 resultados por busca.
                                                    {' '}
                                                    Para visualizar outros
                                                    resultados altere seus
                                                    filtros.
                                                </p>
                                            </FlexboxGrid.Item>
                                        </FlexboxGrid>
                                    ) : null}
                                    <FlexboxGrid.Item
                                        componentClass={Col}
                                        lg={24}
                                        md={24}
                                        sm={24}
                                        xs={24}
                                        id="chart-area-map"
                                        className="markers-map"
                                    >
                                        <div
                                            style={{ height: '435px' }}
                                            id="map"
                                        />
                                    </FlexboxGrid.Item>
                                </>
                            )}
                        </FlexboxGrid>
                    </>
                ) : null}
                <ModalConfirmation
                    title="Informações para alteração do plano"
                    message="Caso deseje saber mais informações para evoluir o seu plano, clique em “Confirmar”"
                    show={showCTA}
                    btnConfirmClass="upsell"
                    buttonConfirm="Confirmar"
                    buttonCancel="Cancelar"
                    onConfirm={this.sendCTA}
                    onCancel={() => {
                        this.setState({
                            showCTA: false,
                        });
                    }}
                />
                <MobileSelectOptionComponent
                    open={openDialog}
                    handleClose={() => this.setState({ openDialog: false })}
                    options={optionsGroupBy}
                    optionSelected={typeVisualization}
                    handleSelect={this.changeVisualization}
                />
            </Content>
        );
    }
}

const mapStateToProps = (store) => ({
    data: store.painelGeralDataReducer.map.data,
    markersData: store.painelGeralDataReducer.map.markers,
    produto: store.painelGeralDataReducer.produto,
    servicoPainel: store.usuarioDataReducer.servicoPainel,
    loadingMap: store.painelGeralDataReducer.loadingMap,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        getMapData,
        setVisualizationTypeMap,
        getMarkersData,
    },
    dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(MapComponent);
