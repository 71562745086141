import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import React from "react";
import { useFormContext } from "react-hook-form";
import { Alert } from "rsuite";
import { z } from "zod";
import { AlertWithTimestamp } from "../../../../../../components/AlertWithTimestamp";
import { Exception } from "../../../../../../data";
import { getConfigFarma, updateConfigFarma, UpdateConfigFarmaPayload } from "../service/IPAConfigFarma";

export const setupFarmaSchema = z.object({
    usePMCForIPAPricing: z.boolean(),
});

export type SetupFarmaFormValues = z.infer<typeof setupFarmaSchema>;

export const useFarma = () => {
    const queryClient = useQueryClient();

    const { data: configFarma } = useQuery({
        queryKey: ['ipa.setup-farma'],
        queryFn: getConfigFarma,
        enabled: true,
    });

    const mutation = useMutation({
        mutationFn: (payload: UpdateConfigFarmaPayload) => updateConfigFarma(payload),
        onSuccess: () => {
            Alert.success('Configurações salvas com sucesso');
            queryClient.invalidateQueries(['ipa.setup-farma']);
        },
        onError: () => {
            const { message } = new Exception('Erro ao salvar as configurações');
            AlertWithTimestamp({ message });
        },
    });

    const { setValue, watch, handleSubmit } = useFormContext<SetupFarmaFormValues>();

    React.useEffect(() => {
        if (configFarma && setValue) {
            setValue("usePMCForIPAPricing", configFarma?.usePMCForIPAPricing);
        }
    }, [configFarma, setValue]);

    const usePMCForIPAPricing = watch("usePMCForIPAPricing");

    const onSubmit = (data: SetupFarmaFormValues) => {
        mutation.mutateAsync(data);
    };

    return {
        configFarma,
        updateConfigFarma: onSubmit,
        isUpdating: mutation.isLoading,
        usePMCForIPAPricing,
        handleSubmit,
        setValue,
    };
};
