import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import { isBefore } from 'date-fns';
import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
    Button,
    CheckPicker,
    Content,
    DatePicker,
    Input,
    Radio,
    RadioGroup,
    SelectPicker,
    Toggle,
    Tooltip,
    Whisper,
} from 'rsuite';
import { setGenerateReportData, setStateValue } from '../../../../../actions/actionsModuloRelatorio';
import infoIcon from '../../../../../assets/icons/icon_info-gray.svg';
import {
    dailyFrequency,
    setLocalDateISOFormat,
    weeklyFrequency,
    getLastSevenDays
} from '../utils';

import arrowLeftIcon2 from '../../../../../assets/icons/icon_arrow-left2.svg';

const EditSavedReportForm = ({ inputRef, isEmpty, onClickGoBack }) => {
    const dispatch = useDispatch();

    const { moduloRelatorioData, editSpecificDateRangeValue, dateIntervalKey } = useSelector(
        (state) => ({
            moduloRelatorioData:
                state.moduloRelatorioDataReducer?.moduloRelatorioData,
            editSpecificDateRangeValue: state.moduloRelatorioDataReducer?.editSpecificDateRangeValue,
            dateIntervalKey: state.moduloRelatorioDataReducer?.dateIntervalKey,
        }),
        shallowEqual
    );

    const handleChangeFileName = (value) => {
        dispatch(setGenerateReportData('fileName', value));
    };

    const handleChangeRecorrencia = (value) => {
        dispatch(setGenerateReportData('recorrencia', value));
        dispatch(setGenerateReportData('dataEspecifica', !value));

        if (!value) {
            dispatch(setGenerateReportData('reportEngineRecorrenciaDTO', null));
        } else {
            dispatch(
                setGenerateReportData('reportEngineRecorrenciaDTO', {
                    ...moduloRelatorioData.reportEngineRecorrenciaDTO,
                    recorrencia: 'DIARIA',
                    diasRecorrencia: ['WEEKLY'],
                    dataInicioEnvio: '',
                })
            );
            dispatch(setGenerateReportData('dataInicio', getLastSevenDays().dataInicio));
            dispatch(setGenerateReportData('dataFim', getLastSevenDays().dataFim));
            // força o componente DateInterval de rerenderizar para o estado inicial
            dispatch(setStateValue('dateIntervalKey', dateIntervalKey + 1));
        }
    };

    const handleChangeRadioRepetition = (value) => {
        if (value === 'DIARIA') {
            dispatch(
                setGenerateReportData('reportEngineRecorrenciaDTO', {
                    ...moduloRelatorioData.reportEngineRecorrenciaDTO,
                    recorrencia: 'DIARIA',
                    diasRecorrencia: ['WEEKLY'],
                })
            );
        } else {
            dispatch(
                setGenerateReportData('reportEngineRecorrenciaDTO', {
                    ...moduloRelatorioData.reportEngineRecorrenciaDTO,
                    recorrencia: 'SEMANAL',
                    diasRecorrencia: ['SEG'],
                })
            );
        }
    };

    const handleSelectPickerDaily = (type, value) => {
        dispatch(
            setGenerateReportData('reportEngineRecorrenciaDTO', {
                ...moduloRelatorioData.reportEngineRecorrenciaDTO,
                diasRecorrencia: type === 'select' ? [value] : value,
            })
        );
    };

    const handleStartDate = (value) => {
        dispatch(
            setGenerateReportData('reportEngineRecorrenciaDTO', {
                ...moduloRelatorioData.reportEngineRecorrenciaDTO,
                dataInicioEnvio:
                    value === null ? '' : setLocalDateISOFormat(value),
            })
        );
    };

    return (
        <div className="edit-saved-report-form">
            <Button className="go-back-button" onClick={onClickGoBack}>
                <img src={arrowLeftIcon2} alt="" />
                Voltar para Relatórios salvos
            </Button>
            <Content className="edit-saved-header">
                Editar relatório salvo
            </Content>
            <Content className="edit-saved-name">
                <label htmlFor="input-name" className="input-name-label">
                    Nome do relatório
                </label>
                <Input
                    inputRef={inputRef}
                    name="input-name"
                    className="input-name"
                    onChange={handleChangeFileName}
                    value={moduloRelatorioData?.fileName}
                />
            </Content>
            {editSpecificDateRangeValue.length && !moduloRelatorioData?.recorrencia ? (
                <div className="banner-alert">
                    <ReportProblemIcon className="banner-alert-icon" />
                    <p>Relatórios com datas específicas são salvos apenas no formato sem recorrência.</p>
                </div>
            ) : null}
            <Content className="edit-saved-recorrencia">
                <div className="toggle-wrapper">
                    <Toggle
                        size="sm"
                        onChange={handleChangeRecorrencia}
                        checked={moduloRelatorioData?.recorrencia}
                    />
                    <p className="bold-text">RECORRÊNCIA</p>
                    <p className="sub-text">
                        Defina uma recorrência para geração automática do
                        relatório
                    </p>
                </div>
                <div className="time-frequency-container">
                    {!moduloRelatorioData?.recorrencia ? (
                        <div className="disable-recorrencia" />
                    ) : null}
                    <div className="radio-wrapper">
                        <p className="bold-text">REPETIÇÃO</p>
                        <RadioGroup
                            inline
                            value={
                                moduloRelatorioData?.reportEngineRecorrenciaDTO
                                    ?.recorrencia
                            }
                            onChange={handleChangeRadioRepetition}
                        >
                            <Radio value="DIARIA">Diária</Radio>
                            <Radio value="SEMANAL">Semanal</Radio>
                        </RadioGroup>
                    </div>
                    <div className="select-wrapper">
                        {moduloRelatorioData?.reportEngineRecorrenciaDTO
                            ?.recorrencia === 'DIARIA' ? (
                            <SelectPicker
                                searchable={false}
                                data={dailyFrequency}
                                value={
                                    moduloRelatorioData
                                        ?.reportEngineRecorrenciaDTO
                                        ?.diasRecorrencia[0]
                                }
                                onChange={(value) =>
                                    handleSelectPickerDaily('select', value)
                                }
                            />
                        ) : (
                            <CheckPicker
                                searchable={false}
                                data={weeklyFrequency}
                                value={
                                    moduloRelatorioData
                                        ?.reportEngineRecorrenciaDTO
                                        ?.diasRecorrencia
                                }
                                onChange={(value) =>
                                    handleSelectPickerDaily('check', value)
                                }
                            />
                        )}
                    </div>
                    <div className="date-wrapper">
                        <p className="bold-text">PRÓXIMO RELATÓRIO</p>
                        <div className="date-container">
                            <DatePicker
                                placeholder="Selecione a data"
                                format="DD/MM/YYYY"
                                ranges={[]}
                                onChange={handleStartDate}
                                value={
                                    moduloRelatorioData
                                        ?.reportEngineRecorrenciaDTO
                                        ?.dataInicioEnvio || ''
                                }
                                disabledDate={(date) =>
                                    isBefore(date, new Date())
                                }
                            />
                            {isEmpty?.date ? (
                                <p className="date-error-text">
                                    Escolha uma data
                                </p>
                            ) : null}
                        </div>
                        <Whisper
                            placement="right"
                            speaker={
                                <Tooltip>
                                    No dia, o relatório será gerado assim que os
                                    dados do InfoPanel estiverem atualizados
                                </Tooltip>
                            }
                        >
                            <img src={infoIcon} alt="" />
                        </Whisper>
                    </div>
                </div>
            </Content>
        </div>
    );
};

export default EditSavedReportForm;
