import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation, useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import React, { ComponentProps, forwardRef, useCallback, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Alert } from 'rsuite';
import { Callout, InputBox } from '../../../../../components';
import { UPDATE_BASIC_CONFIG } from '../../../../../reducers/reducerIPA/basicConfig/competitorsDays';
import Layout from '../Layout';
import defaultStyles from '../SetupIpaProductsSegmentation/SetupIpaProductsSegmentation.module.scss';
import { SetupBasicConfigSchema, setupBasicConfigSchema } from './schema';
import { getSetupIpaBasicConfig, postSetupIpaBasicConfig, putSetupIpaBasicConfig } from './service';
import styles from './SetupIpaBasicConfig.module.scss';

const { Section, Footer, Subtitle, Title, Option, Action } = Layout;

export type SetupIpaBasicConfigProps = Omit<ComponentProps<'form'>, 'children'>;

const SetupIpaBasicConfig = forwardRef<HTMLFormElement, SetupIpaBasicConfigProps>(({ className, ...props }, ref) => {
    const { data, refetch } = useQuery({
        queryKey: ['ipa.setup.basicConfig'],
        queryFn: getSetupIpaBasicConfig,
    });

    const dispatch = useDispatch();

    const form = useForm<SetupBasicConfigSchema>({
        resolver: zodResolver(setupBasicConfigSchema),

    });

    const { mutateAsync } = useMutation({
        mutationFn: async (formData: SetupBasicConfigSchema) => {

            if (data?.id) {
                dispatch(UPDATE_BASIC_CONFIG({ pricing_preferences: formData.pricing_preferences, cost_field: formData.cost_field }));
                return await putSetupIpaBasicConfig({ ...formData, id: data.id });
            }
            else return await postSetupIpaBasicConfig(formData);
        },
        onSettled: () => refetch(),
        onSuccess: () => {
            Alert.success('Configurações salvas com sucesso');
        },
        onError: () => {
            Alert.error('Erro ao salvar as configurações');
        },
    });

    const handleSubmit = useCallback(async (formData: SetupBasicConfigSchema) => {
        await mutateAsync(formData);
    }, [mutateAsync]);

    const handleReset = useCallback(async () => {
        form.reset();
    }, [form]);


    useEffect(() => {
        form.setValue('pricing_preferences', data?.pricing_preferences || 'STORE');
        form.setValue('cost_field', data?.cost_field || 'MAX_UNIT_NET_COST');
    }, [data]);

    const getCostOptions = (pricingPreference: string) => {
        if (pricingPreference === 'CLUSTER') {
            return [
                {
                    value: 'MEAN_UNIT_NET_COST_CLUSTER',
                    label: 'Custo médio do cluster',
                    description: 'Calcula a média ponderada dos custos do produto no cluster, levando em conta o estoque disponível no varejo'
                },
                {
                    value: 'LAST_UNIT_NET_COST_CLUSTER',
                    label: 'Último custo do cluster',
                    description: 'Usa o último custo identificado entre as médias ponderadas de custos das lojas do cluster'
                },
                {
                    value: 'MAX_UNIT_NET_COST_CLUSTER',
                    label: 'Maior custo entre a média e o último custo do cluster',
                    description: 'Avalia tanto o último custo quanto o custo médio do produto no cluster e seleciona o valor mais alto'
                }
            ];
        }

        return [
            {
                value: 'MEAN_UNIT_NET_COST',
                label: 'Custo médio',
                description: 'Calcula a média ponderada dos custos do produto na loja, levando em conta o estoque disponível no varejo'
            },
            {
                value: 'GLOBAL_MEAN_UNIT_NET_COST',
                label: 'Custo médio global',
                description: 'Calcula a média global do custo do produto, considerando todas as lojas na rede.'
            },
            {
                value: 'LAST_UNIT_NET_COST',
                label: 'Último custo',
                description: 'Considera o custo mais recente registrado para o produto na loja específica'
            },
            {
                value: 'GLOBAL_MAX_UNIT_NET_COST',
                label: 'Maior custo global',
                description: 'Analisa os custos mais altos encontrados para o produto em todas as lojas da rede.'
            },
            {
                value: 'MAX_UNIT_NET_COST',
                label: 'Maior custo entre a média e o último custo',
                description: 'Avalia tanto o último custo quanto o custo médio do produto na loja e seleciona o valor mais alto'
            }
        ];
    };

    return (
        <Layout.Root {...props} ref={ref} className={classNames(styles['wrapper'], className)} onSubmit={form.handleSubmit(handleSubmit)} onReset={handleReset}>
            <Section.Root>
                <Section.Content>
                    <Callout>As alterações são salvas automaticamente e serão aplicadas na próxima integração de dados.</Callout>
                </Section.Content>
            </Section.Root>
            <Section.Root>
                <Section.Header>
                    <Title>Configurações Básicas</Title>
                    <Subtitle>Defina as configrações básicas do sistema</Subtitle>
                </Section.Header>
            </Section.Root>
            <Section.Root>
                <Section.Header>
                    <Title>Preferências na precificação</Title>
                    <Subtitle>Defina como você prefere que seja feita a precificação dos produtos no IPA.</Subtitle>
                </Section.Header>
                <Section.Content>
                    <Option.Root>
                        <Option.Content className={defaultStyles['option__content']}>
                            <Controller
                                control={form.control}
                                name="pricing_preferences"
                                render={({ field: { value, onChange }, fieldState: { error } }) => {
                                    return (
                                        <InputBox>
                                            <InputBox.RadioGroup value={value} onChange={onChange}>
                                                <InputBox.Radio className={defaultStyles['radio-description']} value="STORE">
                                                    <span>Loja a loja: parâmetros diferentes loja a loja</span>
                                                    <InputBox.FormDescription>
                                                        O custo, PMZ e preço concorrente serão calculados loja a loja.
                                                    </InputBox.FormDescription>
                                                </InputBox.Radio>
                                                <InputBox.Radio className={defaultStyles['radio-description']} value="CLUSTER">
                                                    <span>Por cluster: mesmos parâmetros para as lojas de um mesmo cluster</span>
                                                    <InputBox.FormDescription>
                                                        O custo, PMZ e preço concorrente serão calculados por cluster. Será possível criar regras de preço
                                                        específicas por lojas.
                                                    </InputBox.FormDescription>
                                                </InputBox.Radio>
                                            </InputBox.RadioGroup>
                                            <InputBox.Error message={error?.message} />
                                        </InputBox>
                                    );
                                }}
                            />
                        </Option.Content>
                    </Option.Root>
                </Section.Content>
            </Section.Root>
            <Section.Root>
                <Section.Header>
                    <Title>Custo do produto</Title>
                    <Subtitle>Escolha qual custo usar no cálculo dos preços no IPA</Subtitle>
                </Section.Header>
                <Section.Content>
                    <Option.Root>
                        <Option.Content className={defaultStyles['option__content']}>
                            <Controller
                                control={form.control}
                                name="cost_field"
                                render={({ field: { value, onChange }, fieldState: { error } }) => {
                                    const costOptions = getCostOptions(form.watch('pricing_preferences'));

                                    return (
                                        <InputBox
                                            className={form.watch('pricing_preferences') !== 'CLUSTER'
                                                ? styles['const-field__input-group']
                                                : styles['cluster-field__input-group']}>
                                            <InputBox.RadioGroup value={value} onChange={onChange}>
                                                {costOptions.map(option => (
                                                    <InputBox.Radio
                                                        key={option.value}
                                                        className={defaultStyles['radio-description']}
                                                        value={option.value}
                                                    >
                                                        <span>{option.label}</span>
                                                        <InputBox.FormDescription>
                                                            {option.description}
                                                        </InputBox.FormDescription>
                                                    </InputBox.Radio>
                                                ))}
                                            </InputBox.RadioGroup>
                                            <InputBox.Error message={error?.message} />
                                        </InputBox>
                                    );
                                }}
                            />
                        </Option.Content>
                    </Option.Root>
                </Section.Content>
            </Section.Root>
            <Footer>
                <Action theme="ghost" type="reset">
                    cancelar
                </Action>
                <Action type="submit">salvar</Action>
            </Footer>
        </Layout.Root>
    );
});

SetupIpaBasicConfig.displayName = 'SetupIpaBasicConfig';

export { SetupIpaBasicConfig };
