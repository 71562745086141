
import { create } from 'zustand';

interface ClusterState {
    selectedClusters: string[];
    setSelectedClusters: (clusters: string[]) => void;
}

export const useClusterStore = create<ClusterState>((set) => ({
    selectedClusters: [],
    setSelectedClusters: (clusters) => set({ selectedClusters: clusters }),
}));
