import React from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { Col, FlexboxGrid } from 'rsuite';
import { ModalSpeakWithSpecialist } from '../../../../../../components/ModalSpeakWithSpecialist';
import { setPainelGeralState } from '../../../../../../actions/actionsPainelGeral';
import BoxPlotChart from '../BoxPlotChart';
import MapChart from '../MapCharts';
import { PageBlocker } from '../PageBlocker';
import STopPricesChart from '../STopPricesChart';
import TimeEvolutionChart from '../TimeEvolutionChart';

import styles from './styles.module.scss';

const pageBlockerTitle = "Acompanhe a evolução dos preços de redes ou fabricantes para ser mais competitivo."
const pageBlockerSubtitle = "Disponível em outros planos do InfoPanel."
const pageBlockerTitleMap = "Navegue pelo Mapa de Preços e visualize os preços das redes e lojas da sua região."

const ChartsArea = ({
    productsSelected,
    usuario,
    filtros,
    onChangeVisualizationMap,
    onChangeMapProduct,
    onCleanMapProduct,
    onSelectTopFive,
    onCleanTopFive,
    onChangeVisualizationBoxplot,
    onChangeVisualizationTimeEvolution,
    onClickDownload,
    onSelectTimeEvolution,
    onCleanTimeEvolution,
    onClickSpeakWithSpecialist,
}) => {
    const dispatch = useDispatch();
    const {
        dataTopFive,
        loadingTopFive,
        produto,
        rede,
        userContratoLimiteData,
        showModalSpeakWithSpecialist,
    } = useSelector(
        (state) => ({
            dataTopFive: state.painelGeralDataReducer.topFive.data,
            produto: state.painelGeralDataReducer.produto,
            loadingTopFive: state.painelGeralDataReducer.loadingTopFive,
            rede: state.painelGeralDataReducer.rede,
            userContratoLimiteData:
                state.painelGeralDataReducer.userContratoLimiteData,
            showModalSpeakWithSpecialist:
                state.painelGeralDataReducer.showModalSpeakWithSpecialist,
        }),
        shallowEqual,
    );

    const pageBlocker = (title, subtitle) => (
        <PageBlocker
            title={title || pageBlockerTitle}
            subtitle={subtitle || pageBlockerSubtitle}
            buttonText="quero o teste grátis"
            onClick={() => dispatch(setPainelGeralState('showModalSpeakWithSpecialist', true))}
        />
    );

    return (
        <FlexboxGrid className={styles['charts-container']}>
            <FlexboxGrid.Item colspan={24} className="">
                <TimeEvolutionChart
                    pageBlocker={pageBlocker()}
                    onSelectTimeEvolution={onSelectTimeEvolution}
                    onCleanTimeEvolution={onCleanTimeEvolution}
                    usuario={usuario}
                    onChangeVisualizationTimeEvolution={
                        onChangeVisualizationTimeEvolution
                    }
                />
            </FlexboxGrid.Item>
            <FlexboxGrid.Item colspan={24} className="">
                <BoxPlotChart
                    usuario={usuario}
                    pageBlocker={pageBlocker()}
                    onChangeVisualizationBoxplot={onChangeVisualizationBoxplot}
                    onClickDownload={onClickDownload}
                />
            </FlexboxGrid.Item>
            <FlexboxGrid.Item
                componentClass={Col}
                lg={12}
                md={12}
                sm={24}
                xs={24}
                className=""
            >
                <MapChart
                    usuario={usuario}
                    filtros={filtros}
                    pageBlocker={pageBlocker(pageBlockerTitleMap)}
                    onChangeVisualizationMap={onChangeVisualizationMap}
                    onChangeMapProduct={onChangeMapProduct}
                    onCleanMapProduct={onCleanMapProduct}
                />
            </FlexboxGrid.Item>
            <FlexboxGrid.Item
                componentClass={Col}
                lg={12}
                md={12}
                sm={24}
                xs={24}
                className=""
            >
                <STopPricesChart
                    rede={rede}
                    usuario={usuario}
                    data={dataTopFive}
                    loading={loadingTopFive}
                    produto={produto}
                    onSelectTopFive={onSelectTopFive}
                    onCleanTopFive={onCleanTopFive}
                />
            </FlexboxGrid.Item>
            <ModalSpeakWithSpecialist 
                show={showModalSpeakWithSpecialist}
                title='Quer ter uma pesquisa de preço mais completa?'
                subtitle='Converse com os nossos especialistas para encontrar o melhor plano para sua necessidade.'
                onClick={onClickSpeakWithSpecialist}
                onCancel={() => dispatch(setPainelGeralState('showModalSpeakWithSpecialist', false))}
            />
        </FlexboxGrid>
    );
};

export default React.memo(ChartsArea);
