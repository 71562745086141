import classNames from 'classnames';
import React, { ComponentProps, forwardRef, useCallback, useState } from 'react';
import { MdDeleteOutline, MdEdit } from 'react-icons/md';
import { DeleteClusterModal, EditClusterModal } from '../Modals';
import styles from './ClusterSection.module.scss';

export type ClusterSectionItemProps = Omit<ComponentProps<'button'>, 'children'> & {
    cluster: DataItem;
    onSubmitEdit?: (name: string, cluster: DataItem) => Promise<void>;
    onSubmitDelete?: (cluster: DataItem) => Promise<void>;
};

type ModalType = 'EDIT' | 'DELETE';

const ClusterSectionItem = forwardRef<HTMLButtonElement, ClusterSectionItemProps>(({ cluster, onSubmitEdit, onSubmitDelete, className, ...props }, ref) => {
    const [modal, setModal] = useState<ModalType[]>([]);

    const handleOpenModal = useCallback((type: ModalType) => {
        return () => {
            setModal((prev) => [...prev, type]);
        };
    }, []);

    const handleCloseModal = useCallback((type: ModalType) => {
        return () => {
            setModal((prev) => prev.filter((modal) => modal !== type));
        };
    }, []);

    const isOpenModal = useCallback((type: ModalType) => modal.includes(type), [modal]);

    const handleSubmitEdit = useCallback(
        async (name: string, cluster: DataItem) => {
            await onSubmitEdit?.(name, cluster);
            handleCloseModal('EDIT')();
        },
        [onSubmitEdit, handleCloseModal],
    );

    const handleSubmitDelete = useCallback(
        async (cluster: DataItem) => {
            await onSubmitDelete?.(cluster);
            handleCloseModal('DELETE')();
        },
        [onSubmitDelete, handleCloseModal],
    );

    return (
        <>
            <button ref={ref} {...props} className={styles['button']}>
                <div className={classNames(styles['button__icon-container'], styles['button__start-icon'])} onClick={handleOpenModal('EDIT')}>
                    <MdEdit size={16} />
                </div>
                <span className={styles['button__content']}>{cluster.label}</span>
                <div className={classNames(styles['button__icon-container'], styles['button__end-icon'])} onClick={handleOpenModal('DELETE')}>
                    <MdDeleteOutline size={18} />
                </div>
            </button>
            <EditClusterModal cluster={cluster} show={isOpenModal('EDIT')} onHide={handleCloseModal('EDIT')} onSubmit={handleSubmitEdit} />
            <DeleteClusterModal
                cluster={cluster}
                show={isOpenModal('DELETE')}
                onHide={handleCloseModal('DELETE')}
                onSubmit={handleSubmitDelete}
                onCancel={handleCloseModal('DELETE')} />
        </>
    );
});

ClusterSectionItem.displayName = 'ClusterSectionItem';

export { ClusterSectionItem };
