import qs from 'qs';
import { trackPromise } from 'react-promise-tracker';

import api from '../../../../utils/API';

export const getLojas = (params) =>
    api({
        url: `${process.env.REACT_APP_IPA_URL}/setup/stores`,
        method: 'GET',
        expectToken: true,
        params: {
            clientId: JSON.parse(localStorage.getItem('cliente'))?.codigo,
            query: params?.query ? params.query : '',
        },
        paramsSerializer(params) {
            return qs.stringify(params, { arrayFormat: 'repeat' });
        },
    }).then((resp) => resp.data);

export const getProdutoValue = (params) =>
    trackPromise(
        api({
            url: `${process.env.REACT_APP_DEMAND_FORECAST}/demand-forecasts/products/${params.productId}`,
            method: 'GET',
            expectToken: true,
            params: {
                storeId: params.storeId,
            },
            paramsSerializer(params) {
                return qs.stringify(params, { arrayFormat: 'repeat' });
            },
        }).then((resp) => resp.data),
    );

export const getDemandForecastInputs = ({ productId, storeIds, fromDate, toDate, newPrice }) =>
    trackPromise(
        api({
            url: `${process.env.REACT_APP_DEMAND_FORECAST}/v2/demand-forecasts/inputs`,
            method: 'POST',
            expectToken: true,
            data: {
                query: {
                    product_id: productId,
                    store_ids: storeIds,
                    from_date: fromDate,
                    to_date: toDate,
                    new_price: newPrice,
                },
            },
            // paramsSerializer(params) {
            //     return qs.stringify(params, { arrayFormat: 'repeat' });
            // },
        }).then((resp) => resp.data),
    );

export const getSimulacaoPrevisao = (params) =>
    trackPromise(
        api({
            url: `${process.env.REACT_APP_DEMAND_FORECAST}/demand-forecasts/simulation`,
            method: 'GET',
            expectToken: true,
            params: {
                storeId: params.storeId,
                productId: params.productId,
                currentPrice: params.currentPrice,
                newPrice: params.newPrice,
            },
        }).then((resp) => resp.data),
    );

export const getGraficoSemanal = (params) =>
    trackPromise(
        api({
            url: `${process.env.REACT_APP_DEMAND_FORECAST}/demand-forecasts/week/seasonalities/total-sales`,
            method: 'GET',
            expectToken: true,
            params: {
                storeId: params.storeId,
                productId: params.productId,
                currentPrice: params.currentPrice,
                newPrice: params.newPrice,
            },
        }).then((resp) => resp.data),
    );

export const getGraficoMensal = (params) =>
    trackPromise(
        api({
            url: `${process.env.REACT_APP_DEMAND_FORECAST}/demand-forecasts/month/seasonalities/factor`,
            method: 'GET',
            expectToken: true,
            params: {
                storeId: params.storeId,
                productId: params.productId,
            },
        }).then((resp) => resp.data),
    );

export const getGraficoElasticidade = (params) =>
    trackPromise(
        api({
            url: `${process.env.REACT_APP_DEMAND_FORECAST}/demand-forecasts/elasticity`,
            method: 'GET',
            expectToken: true,
            params: {
                storeId: params.storeId,
                productId: params.productId,
            },
        }).then((resp) => resp.data),
    );

export const savePrevisao = (data) =>
    trackPromise(
        api({
            url: `${process.env.REACT_APP_DEMAND_FORECAST}/demand-forecasts`,
            method: 'POST',
            expectToken: true,
            data: JSON.stringify(data),
        }).then((resp) => resp),
    );

export const downloadPrevisao = (params) =>
    trackPromise(
        api({
            url: `${process.env.REACT_APP_DEMAND_FORECAST}/demand-forecasts`,
            method: 'GET',
            expectToken: true,
            download: true,
            responseType: 'arraybuffer',
            params: {
                ids: params.ids,
            },
            paramsSerializer(params) {
                return qs.stringify(params, { arrayFormat: 'repeat' });
            },
        })
            .then((res) => {
                if (res.status === 200) {
                    return res.data;
                }
                return res;
            })
            .then((resp) => {
                let download = resp;
                if (!download.status) {
                    const blob = new Blob([download]);
                    const hiddenElement = document.createElement('a');
                    hiddenElement.href = window.URL.createObjectURL(blob);
                    hiddenElement.target = '_blank';
                    hiddenElement.download =
                        params.produto && params.loja
                            ? `ipa_ previsao_demanda_${params.produto}_${params.loja}.csv`
                            : `ipa_ previsao_demanda_${new Date().getTime()}.csv`;
                    hiddenElement.click();
                    download = null;
                }
                return download;
            }),
    );

export const getClusters = () =>
    api({
        url: `${process.env.REACT_APP_IPA_URL}/setup/stores/clusters`,
        method: 'GET',
        expectToken: true,
        params: {
            clientId: JSON.parse(localStorage.getItem('cliente'))?.codigo,
        },
        paramsSerializer(params) {
            return qs.stringify(params, { arrayFormat: 'repeat' });
        },
    }).then((resp) => resp.data);
