import { createContext, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export const GlobalContext = createContext();

export const selectSearch = (
    search,
    searchProduto,
    searchStore,
    searchCluster,
    searchRuleType,
    searchSegmentos,
    searchSegmentosSensibilities,
    searchFamiliaProduto,
    filtro,
    value,
) => {
    const searchValue = value || '';
    switch (filtro.onSearch) {
        case 'searchProduto':
            searchProduto(filtro.nivel, searchValue, filtro.name);
            break;
        case 'searchFamiliaProduto':
            searchFamiliaProduto(filtro.nivel, searchValue, filtro.name);
            break;
        case 'searchStore':
            searchStore(filtro.nivel, searchValue, filtro.name);
            break;

        case 'searchCluster':
            searchCluster(filtro.nivel, searchValue, filtro.name);
            break;

        case 'searchSegmentos':
            searchSegmentos(filtro.nivel, searchValue, filtro.name);
            break;

        case 'searchSegmentosSensibilities':
            searchSegmentosSensibilities();
            break;

        case 'searchRuleType':
            searchRuleType(filtro.nivel, searchValue, filtro.name);
            break;

        default:
            search(filtro.nivel, searchValue, filtro.name);
            break;
    }
};

export const useQuery = () => {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
};

export const percentage = (a, b) => {
    const percentage = (a / b) * 100;
    return percentage;
};

export const getTextWidth = (text, font) => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    context.font = font || getComputedStyle(document.body).font;

    return Math.ceil(context.measureText(text).width) + 45;
};

export const setSelectPillSelectedWidth = (placeholderWidth, elementWidth) => {
    const percent = percentage(placeholderWidth, elementWidth);
    if (percent > 70 && percent < 75) {
        return elementWidth + 10;
    }

    if (percent > 75 && percent < 80) {
        return elementWidth + 20;
    }

    if (percent > 80 && percent < 85) {
        return elementWidth + 25;
    }

    if (percent > 85 && percent < 90) {
        return elementWidth + 40;
    }

    if (percent > 90 && percent < 95) {
        return elementWidth + 50;
    }

    if (percent > 95 && percent < 99) {
        return elementWidth + 58;
    }

    if (percent > 99) {
        return elementWidth + 68;
    }
    return elementWidth;
};

export const downloadFile = (data, fileName) => {
    const blob = new Blob([data]);
    const hiddenElement = document.createElement('a');
    hiddenElement.href = window.URL.createObjectURL(blob);
    hiddenElement.download = fileName;
    hiddenElement.click();
};

const insertZeroInDateString = (number) => (number > 9 ? number : `0${number}`);

export const handleDateFormat = (date) => {
    const dt = new Date(date);
    const year = dt.getUTCFullYear();
    const month = dt.getUTCMonth() + 1;
    const day = dt.getUTCDate();

    return `${insertZeroInDateString(day)}/${insertZeroInDateString(month)}/${year}`;
};

export function getModel(props, query, name, identificadores) {
    return {
        query: query || '',
        field: name || null,
        login: JSON.parse(localStorage.getItem('usuario'))?.email,
        cliente: JSON.parse(localStorage.getItem('cliente'))?.codigo,
        gtin: name && name === 'produto' ? null : props.produto?.cache?.length ? props.produto.cache.map((item) => item.identificador) : null,
        preco: props.filtrosData?.preco?.name ? { name: props.filtrosData.preco.name } : { name: 'PRECO_PAGO' },
        limitProducts: props.produto?.limitProducts || null,
        identificadores: identificadores || null,
        addNotFound: true,
        canal: props.filtrosData?.canal || ['Canal físico', 'Canal digital'],
    };
}
