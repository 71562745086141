import React, { useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
    Container, Content, Nav, Button, Icon,
} from 'rsuite';
import {
    resetAllFilters,
    setSingleReducer,
} from '../../../../actions/actionsLojaConcorrentes';
import Header from '../../../../components/Header';
import { getUserPlanType, sendLead } from '../InfoPanel/utils';
import ModalConfirmation from '../../../../components/ModalConfirmation';
import { ModalSpeakWithSpecialist } from '../../../../components/ModalSpeakWithSpecialist';
import SelectedStore from './Components/SelectedStore';
import StoreLocationMap from './Components/StoreLocationMap';
import TopTenChainCompetitors from './Components/TopTenChainCompetitors';
import TopTenStoreCompetitors from './Components/TopTenStoreCompetitors';
import { ButtonVideo } from '../AnaliseCompetitividade/Components';
import HowToUseModuleReportModal from '../ModuloRelatorio/Components/HowToUseModuleReportModal';
import useMapFilterChange from './Hooks/useMapFilterChange';
import { getInputFilter, getStoreByCnpj, getStoresByBounds } from './services';
import lockIcon from '../../../../assets/icons/icon_lock.svg';
import { trackEvent } from '../../../../utils/MatomoConfig';
import { dataVideoLojasConcorrentes } from './utils';
import iconStore from '../../../../assets/icons/icon_store.svg';

const StoreCompetitors = ({ usuario }) => {
    const [showCTA, setShowCTA] = useState(false);
    const [contentNav, setContentNav] = useState('map');
    const [searchStores, setSearchStores] = useState([]);
    const [selectedStore, setSelectedStore] = useState(null);
    const [searchStoreTimer, setSearchStoreTimer] = useState(null);
    const [showModalVideo, setShowModalVideo] = useState(false);

    const dispatch = useDispatch();

    const { stores, recenter, filtrosValue, showModalSpeakWithSpecialistLojasConcorrentes } = useSelector(
        (state) => ({
            stores: state.lojasConcorrentesDataReducer.stores,
            recenter: state.lojasConcorrentesDataReducer.recenter,
            filtrosValue: state.lojasConcorrentesDataReducer.filtrosValue,
            showModalSpeakWithSpecialistLojasConcorrentes: state.lojasConcorrentesDataReducer.showModalSpeakWithSpecialistLojasConcorrentes,
        }),
        shallowEqual,
    );

    const hasPermission
        = usuario?.tipoAdicionalContratos?.PAN?.includes('MODULO_LOJAS');

    const {
        handleCheckPickerFilterValue,
        handleOpenFilter,
        handleSelectPickerFilterValue,
        handleSearchFilter,
        handleSearchAddress,
        handleSelectAddress,
        handleCleanFilter,
        searchStoreByFilter,
        disabledRegion,
    } = useMapFilterChange();

    const mapAutoCompleteData = (data) => {
        const searchStoreResult = data.map((item) => ({
            ...item,
            label: item.alias,
            value: item.alias,
        }));
        setSearchStores(searchStoreResult);
    };

    const searchStoreFilterInput = async (query) => {
        clearTimeout(searchStoreTimer);

        const newTimer = setTimeout(() => {
            getInputFilter(query).then((response) => {
                if (response) {
                    mapAutoCompleteData(response.content);
                }
            });
        }, 500);

        setSearchStoreTimer(newTimer);
    };

    const setFiltersSearchStore = (filter, bounds) => ({
        bottomLeft: bounds.bottomLeft,
        upperRight: bounds.upperRight,
        filters: {
            rede: filter.rede,
            tipoLoja: filter.tipoLoja,
        },
    });

    const searchStoreByArea = (bottonLeftLatLng, upperRightLatLng) => {
        const filters = setFiltersSearchStore(filtrosValue, {
            bottomLeft: bottonLeftLatLng,
            upperRight: upperRightLatLng,
        });
        getStoresByBounds(filters).then((response) => {
            dispatch(setSingleReducer('stores', response.content));
            dispatch(setSingleReducer('recenter', false));
        });
    };

    const mapDataStore = (store) => ({
        alias: store?.loja?.alias,
        cidade: store?.endereco?.cidade?.nome,
        cnpj: store?.loja?.pessoaJuridica?.cnpj,
        endereco: store?.endereco?.resumeEndereco,
        latitude: store?.endereco?.latitude,
        longitude: store?.endereco?.longitude,
        rede: store?.loja?.rede?.alias,
        tipoLoja: store?.loja?.tipoLoja?.descricao,
        uf: store?.endereco?.cidade?.uf?.name,
    });

    const changeSelectedStore = async (marker) => {
        if (marker) {
            let store;
            if (stores.some((item) => item.cnpj === marker.id)) {
                store = stores.find((item) => item.cnpj === marker.id);
            } else {
                const response = await getStoreByCnpj(marker.id);
                store = mapDataStore(response[0]);
            }
            setSelectedStore(store);
        } else {
            setSelectedStore(marker);
        }
    };

    const handleClickPageBlocker = () => {
        dispatch(setSingleReducer('showModalSpeakWithSpecialistLojasConcorrentes', true));
    };

    const sendCTA = () => {
        sendLead(usuario, 'upsell-lojas-concorrentes');
        trackEvent('Upsell/Crossell', 'upsell-block-lojas-concorrentes');
        setShowCTA(false);
        window.open('https://wa.me/551137779670?text=Ol%C3%A1%21+Quero+saber+mais+sobre+os+planos+Flex+e+Pro+do+ISA+-+InfoPanel');
    };

    useEffect(() => {
        setSelectedStore(null);
    }, [stores]);

    useEffect(() => {
        if (hasPermission) {
            dispatch(resetAllFilters());
            searchStoreByFilter({});
        }
    }, [hasPermission]);

    const memorizeCompetitorsStores = useMemo(() => <TopTenStoreCompetitors referenceStore={selectedStore} />, [selectedStore]);
    const memorizeCompetitorsChains = useMemo(() => <TopTenChainCompetitors referenceStore={selectedStore} />, [selectedStore]);

    return (
        <Container className="main-container store-competitors-page">
            <div id="container">
                <Header
                    productTitle="InfoPanel"
                    title="Módulo de lojas concorrentes"
                    subtitle="Identifique os seus principais concorrentes por loja através do nosso score inteligente"
                    badgeLabel={usuario?.servicoPanelCliente?.pacotePainel?.description}
                    icon={<img src={iconStore} alt="Store" />}
                    rightChildren={<ButtonVideo onClick={() => setShowModalVideo(true)} />}
                />
            </div>
            <Content className="tabs-navigation">
                <Nav appearance="subtle" activeKey="map">
                    <Nav.Item eventKey="map" active={contentNav}>
                        Mapa
                    </Nav.Item>
                    <Nav.Item eventKey="extraction" className="coming-soon">
                        Extração
                        <div className="coming-soon-badge">Em breve</div>
                    </Nav.Item>
                </Nav>
            </Content>

            {contentNav === 'map' && (

                <div className="content-wrapper">
                    <StoreLocationMap
                        autoCompleteData={searchStores}
                        chartData={stores}
                        searchStoreByInput={searchStoreFilterInput}
                        recenterMap={recenter}
                        disabledRegion={disabledRegion}
                        searchStoreByArea={searchStoreByArea}
                        searchStoreByFilter={searchStoreByFilter}
                        handleSelectedStore={changeSelectedStore}
                        onOpen={handleOpenFilter}
                        onClean={handleCleanFilter}
                        onSelectRegion={handleSelectPickerFilterValue}
                        onSelectCheckPicker={handleCheckPickerFilterValue}
                        onSearchFilter={handleSearchFilter}
                        onSearchAddress={handleSearchAddress}
                        onSelectAddress={handleSelectAddress}
                        onClickPageBlocker={handleClickPageBlocker}
                        referenceStore={selectedStore}
                        hasPermission={!hasPermission}
                    />
                    {selectedStore ? (
                        <Content>
                            <SelectedStore store={selectedStore} />
                            {memorizeCompetitorsStores}
                            {memorizeCompetitorsChains}
                        </Content>
                    ) : null}
                </div>
            )}
            <ModalConfirmation
                title="Informações para alteração do plano"
                message="Caso deseje saber mais informações para evoluir o seu plano, clique em “Confirmar”"
                show={showCTA}
                btnConfirmClass="upsell"
                buttonConfirm="Confirmar"
                buttonCancel="Cancelar"
                onConfirm={sendCTA}
                onCancel={() => {
                    setShowCTA(false);
                }}
            />
            <ModalSpeakWithSpecialist 
                show={showModalSpeakWithSpecialistLojasConcorrentes}
                title='Analise concorrentes com base em distância, sortimento e competitividade.'
                subtitle='Converse com um de nossos especialistas e descubra todos os benefícios do Módulo de Lojas Concorrentes.'
                onClick={sendCTA}
                onCancel={() => dispatch(setSingleReducer('showModalSpeakWithSpecialistLojasConcorrentes', false))}
            />
            <HowToUseModuleReportModal
                dataVideo={dataVideoLojasConcorrentes}
                show={showModalVideo}
                onHide={() => setShowModalVideo(false)}
            />
        </Container>
    );
};

export default StoreCompetitors;
