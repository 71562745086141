import React from 'react';
import { useDispatch } from 'react-redux';

import { useDrag } from 'react-dnd';
import { Tooltip, Whisper } from 'rsuite';

import { setGenerateReportData } from '../../../../../../actions/actionsModuloRelatorio';
import { LoadingMenu } from '../../../../../../components/LoadingSpinner';
import SelectPill from '../../../../../../components/SelectPill';
import { filtrosDataType } from '../../utils';

import iconSixDots from '../../../../../../assets/icons/icon_six-dots.svg';

const DndMovableItem = ({
    name,
    setItems,
    hasList,
    data,
    column,
    onChange,
    onClean,
    onSelect,
    onSearch,
    onOpen,
    value,
    locale,
    canDrag,
    disabled,
    resetProduct,
    type,
    renderValue,
}) => {
    const dispatch = useDispatch();

    const handleCleanModuleDataByName = (name) => {
        if (hasList) {
            dispatch(setGenerateReportData(filtrosDataType[name], []));
            if (name === 'Produto') {
                resetProduct();
            }
        }
    };

    const changeItemColumn = (currentItem, columnName) => {
        setItems((prevState) =>
            prevState.map((e) => ({
                ...e,
                column: e.name === currentItem.name ? columnName : e.column,
            })),
        );
    };

    const [{ isDragging }, drag] = useDrag({
        type: 'block',
        item: { name, type: 'block' },
        canDrag,
        end: (item, monitor) => {
            const dropResult = monitor.getDropResult();
            if (dropResult && dropResult.name === '1') {
                changeItemColumn(item, '1');
                handleCleanModuleDataByName(item.name);
            } else {
                changeItemColumn(item, '2');
            }
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const opacity = isDragging ? 0.4 : 1;
    const isColumn2 = column === '2' ? 'column-2' : '';
    const isDisabled = disabled ? 'disabled' : '';

    const simpleMovableComponent = (
        <div
            ref={drag}
            className={`dnd-movable-item ${isColumn2} ${isDisabled}`}
            style={{ opacity }}
        >
            <img src={iconSixDots} alt="" />
            {name}
        </div>
    );

    if (column === '1' && name === 'Detalhes anúncio' && !canDrag) {
        return (
            <Whisper
                trigger={[disabled ? 'hover' : 'none']}
                placement="top"
                speaker={
                    <Tooltip>
                        Coluna disponível mediante a contratação adicional
                    </Tooltip>
                }
            >
                {simpleMovableComponent}
            </Whisper>
        );
    }

    return hasList ? (
        <div
            ref={drag}
            className={`dnd-movable-select ${isColumn2} ${isDisabled}`}
            style={{ opacity }}
        >
            <img src={iconSixDots} alt="" />
            <SelectPill
                type={type}
                id={`${type}-picker-pill`}
                name="modulo-relatorio-select"
                disabled={disabled}
                filter={false}
                placeholder={name}
                locale={locale}
                value={value}
                data={data}
                onOpen={onOpen}
                onSelect={onSelect}
                onSearch={onSearch}
                onChange={onChange}
                onClean={onClean}
                placement="bottomStart"
                renderMenu={(menu) => (
                    <span>
                        <LoadingMenu area="panel-filter" />
                        {menu}
                    </span>
                )}
                renderValue={renderValue}
            />
        </div>
    ) : (
        simpleMovableComponent
    );
};

export default DndMovableItem;
