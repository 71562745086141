import React from 'react';
import { Tooltip, Whisper } from 'rsuite';
import { BaseCell } from '../BaseCell';
import { BaseCellProps } from '../BaseCell/BaseCell';
import styles from './StoreProductClusterCell.module.scss';

const { MainContainer, SubContainer } = BaseCell;

type StoreProductClusterCellProps = BaseCellProps & {
    rowData: {
        productId: string;
        storeName: string;
        storeId: string;
    };
};

const StoreProductClusterCell = ({ rowData, ...props }: StoreProductClusterCellProps) => {

    const { storeName, storeId } =
        rowData;
    return (
        <BaseCell {...props}>
            <MainContainer>
                <span className={styles.product_id}>{storeId}</span>

            </MainContainer>
            <SubContainer>
                <Whisper
                    delayShow={0.2}
                    trigger="hover"
                    placement="right"
                    speaker={<Tooltip>{storeName}</Tooltip>}
                >
                    <p className={styles.description}>{storeName}</p>
                </Whisper>
            </SubContainer>
        </BaseCell>
    );
};

export default StoreProductClusterCell;
