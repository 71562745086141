import React from 'react';
import { Skeleton } from '../../../../../../../components';
import styles from './CardResult.module.scss';

interface CardResultProps {
    label: string;
    content: string | number;
    isLoading?: boolean;
}
export const CardResult = ({ content, label, isLoading }: CardResultProps) => {
    return (
        <section className={styles.container}>
            <div className={styles.label}>{isLoading ? <Skeleton width={100} height={20} /> : label}</div>
            <div className={styles.content}>{isLoading ? <Skeleton width={200} height={20} /> : content}</div>
        </section>
    );
};
