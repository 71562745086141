import React from 'react';
import { InputGroup, DatePicker, Modal, Button } from 'rsuite';
import CalendarIcon from '../../../../../../../assets/icons/icon_calendar.svg';
import styles from './ModalActivateProductPromo.module.scss';
import { useManageComercialTableStore } from '../../ManageComercialTable.store';
import { InputCurrency, LoadingSpinerArea } from '../../../../../../../components';
import { useAtivarOferta } from '../../hooks/useAtivarOferta';

type ModalActivateProductPromoProps = {
    show?: boolean,
    onCancel?: () => void,
}

export const ModalActivateProductPromo = ({ show, onCancel }: ModalActivateProductPromoProps) => {
    const {
        ativarPromoData,
        hasOferta,
    } = useManageComercialTableStore();

    const {
        handleClickAtivarPromo,
        handleClickCancelarPromo,
        handleBlurPrecoOferta,
        handleChangeDateVigencia,
    } = useAtivarOferta();

    return (
        <Modal
            id="modal-activate-product-promo"
            backdrop
            show={show}
            className={`modal-confirm ${styles['modal-activate-product-promo']}`}
            onHide={onCancel}
        >
            <LoadingSpinerArea area="criar-oferta" />
            <Modal.Header
                closeButton
                className="modal-confirm__header"
                onHide={onCancel}
            >
                <Modal.Title
                    className={`modal-confirm__header--title`}
                    style={{ fontSize: "16px" }}
                >
                    Ativar oferta do produto
                </Modal.Title>
                <p className={`modal-confirm__header--message`}>
                    Escolha o preço e o prazo vigente para a oferta
                </p>
            </Modal.Header>
            <Modal.Body className="modal-confirm__body">
                <form className={styles.form}>
                    <div className={styles['input-wrapper']}>
                        <label className={styles['input-label']}>Preço oferta</label>
                        <InputGroup>
                            <InputGroup.Addon>R$</InputGroup.Addon>
                            <InputCurrency
                                className={styles['currency-input']}
                                decimalSeparator=","
                                thousandSeparator='.'
                                value={ativarPromoData?.precoOferta}
                                onBlur={(e) => handleBlurPrecoOferta(e.target.value)}
                            />
                        </InputGroup>
                    </div>
                    <div className={styles['input-wrapper-date']}>
                        <label className={styles['input-label']}>Início de vigência</label>
                        <InputGroup>
                            <InputGroup.Addon>
                                <img src={CalendarIcon} alt="" className={styles['calendar-icon']} />
                            </InputGroup.Addon>
                            <DatePicker
                                className={styles['date-picker-inicio']}
                                format="DD/MM/YYYY"
                                value={ativarPromoData?.inicioVigencia}
                                onChange={(v) => handleChangeDateVigencia('inicioVigencia', v)}
                            />
                        </InputGroup>
                    </div>
                    <div className={styles['input-wrapper-date']}>
                        <label className={styles['input-label']}>Final de vigência</label>
                        <InputGroup>
                            <InputGroup.Addon>
                                <img src={CalendarIcon} alt="" className={styles['calendar-icon']} />
                            </InputGroup.Addon>
                            <DatePicker
                                className={styles['date-picker-termino']}
                                format="DD/MM/YYYY"
                                value={ativarPromoData?.terminoVigencia}
                                onChange={(v) => handleChangeDateVigencia('terminoVigencia', v)}
                            />
                        </InputGroup>
                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer className={`modal-confirm__footer footer-end`}>
                <Button
                    className={styles['btn-close']}
                    onClick={onCancel}
                    appearance="ghost"
                    color="blue"
                >
                    Fechar
                </Button>
                {hasOferta ? (
                    <Button
                        className={styles['btn-cancel-offer']}
                        onClick={handleClickCancelarPromo}
                        appearance="ghost"
                    >
                        Cancelar oferta
                    </Button>
                ) : null}
                {!hasOferta ? (
                    <Button
                        className={styles['btn-activate-offer']}
                        onClick={handleClickAtivarPromo}
                        appearance="primary"
                    >
                        Ativar oferta
                    </Button>
                ) : null}
            </Modal.Footer>
        </Modal>
    );
};
