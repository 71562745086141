import React, { Component } from 'react';
import CurrencyInput from 'react-currency-input';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import {
    Button,
    Col,
    Container,
    Content,
    ControlLabel,
    Divider,
    FlexboxGrid,
    Form,
    FormControl,
    FormGroup,
    HelpBlock,
    Input,
    InputGroup,
    Message,
    Panel,
    Toggle,
} from 'rsuite';

import {
    addSegmento,
    changeCacheSegmento,
    changeValueSegmento,
    clearSegmentos,
    deleteSegmento,
    searchItensSegmento,
    searchProduto,
    searchSegmentos,
    searchSegmentosClasses,
    searchSegmentosSensibilities,
    searchStore,
    selectValueSegmento,
    setSegmentosLojasUpdateLimite,
    setSegmentosProdutosUpdateLimite,
    // setSegmentosClassesUpdateLimite,
    setSegmentosSensibilitiesUpdateLimite,
    setSegmentosUpdateLimite,
} from '../../../../actions/actionsLimitesPrecoCadastro';
import { LoadingSpiner } from '../../../../components/LoadingSpinner';
import ModalNotification from '../../../../components/ModalNotification';
import { AddFiltro } from './Components/AddFiltro';
import FiltroCustomizado from './Components/FiltroCustomizado';
import { getLimite, saveLimite, updateLimite } from './services';

class LimitesPrecoCadastro extends Component {
    constructor(props) {
        super(props);

        this.state = {
            nome: '',
            errors: {},
            minMargem: null,
            maxMargem: null,
            minCpi: null,
            maxCpi: null,
            regraFamilia: false,
        };

        this.createLimite = this.createLimite.bind(this);
        this.hasNoSegmento = this.hasNoSegmento.bind(this);
        this.validValuesMinMax = this.validValuesMinMax.bind(this);
        this.validValuesMinMaxAtacado = this.validValuesMinMaxAtacado.bind(this);
        this.handleDeleteSegmento = this.handleDeleteSegmento.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeToggle = this.handleChangeToggle.bind(this);
        this.back = this.back.bind(this);
    }

    componentDidMount() {
        const { clearSegmentos, searchSegmentos, searchSegmentosSensibilities, searchSegmentosClasses, match } = this.props;
        clearSegmentos();
        searchSegmentos();
        searchSegmentosClasses();
        searchSegmentosSensibilities();

        if (match.params.id) {
            this.getDataLimite(match.params.id);
        }
    }

    handleChange(value, e) {
        this.setState({
            [e.target.name]: value,
        });
    }

    handleDeleteSegmento(index, name) {
        const { deleteSegmento } = this.props;
        deleteSegmento(index, name);
    }

    handleChangeToggle(checked, name) {
        this.setState({
            [name]: checked,
        });
    }

    setDataLimite(data) {
        this.setState({
            nome: data.name,
            toggleMaximoMargem: !!data.maxMargin,
            toggleMinimoMargem: !!data.minMargin,
            toggleMaximoCompetitividade: !!data.maxCpi,
            toggleMinimoCompetitividade: !!data.minCpi,
            minMargem: data.minMargin ? (data.minMargin * 100).toString().replace('.', ',') : null,
            maxMargem: data.maxMargin ? (data.maxMargin * 100).toString().replace('.', ',') : null,
            minCpi: data.minCpi ? (data.minCpi * 100).toString().replace('.', ',') : null,
            maxCpi: data.maxCpi ? (data.maxCpi * 100).toString().replace('.', ',') : null,
            regraFamilia: data.productFamily,
            // abcClass: data.abcClass || null,
            // sensibilityType: data.sensibilityType || null,
        });

        if (data.segments) {
            if (data.segments.findIndex((item) => item.level === 'categoryLevel1') !== -1) {
                const value = data.segments.filter((item) => item.level === 'categoryLevel1').map((item) => item.value);
                this.addSegmentoToLimite('categoryLevel1', value);
            }
            if (data.segments.findIndex((item) => item.level === 'categoryLevel2') !== -1) {
                const value = data.segments.filter((item) => item.level === 'categoryLevel2').map((item) => item.value);
                this.addSegmentoToLimite('categoryLevel2', value);
            }
            if (data.segments.findIndex((item) => item.level === 'categoryLevel3') !== -1) {
                const value = data.segments.filter((item) => item.level === 'categoryLevel3').map((item) => item.value);
                this.addSegmentoToLimite('categoryLevel3', value);
            }
            if (data.segments.findIndex((item) => item.level === 'categoryLevel4') !== -1) {
                const value = data.segments.filter((item) => item.level === 'categoryLevel4').map((item) => item.value);
                this.addSegmentoToLimite('categoryLevel4', value);
            }
            if (data.segments.findIndex((item) => item.level === 'categoryLevel5') !== -1) {
                const value = data.segments.filter((item) => item.level === 'categoryLevel5').map((item) => item.value);
                this.addSegmentoToLimite('categoryLevel5', value);
            }
            if (data.segments.findIndex((item) => item.level === 'categoryLevel6') !== -1) {
                const value = data.segments.filter((item) => item.level === 'categoryLevel6').map((item) => item.value);
                this.addSegmentoToLimite('categoryLevel6', value);
            }
            if (data.segments.findIndex((item) => item.level === 'categoryLevel7') !== -1) {
                const value = data.segments.filter((item) => item.level === 'categoryLevel7').map((item) => item.value);
                this.addSegmentoToLimite('categoryLevel7', value);
            }
            if (data.segments.findIndex((item) => item.level === 'productId') !== -1) {
                const value = data.segments.filter((item) => item.level === 'productId');
                this.addSegmentoProdutoToLimite('productId', value);
            }
        }
        if (data?.storeIds?.length > 0) {
            this.addSegmentoLojaToLimite('searchStoreIds', data.storeIds);
        }
        if (data?.clusters?.length > 0) {
            this.addSegmentoLojaToLimite('searchCluster', data.clusters);
        }
        if (data.abcClass) {
            this.addCurvaAbcToRegra('abcClass', data.abcClass);
        }
        if (data?.infoPriceAbcClass?.length) {
            this.addSegmentoToLimite('infoPriceAbcClass', data.infoPriceAbcClass);
        }
        if (data?.infoPriceGlobalAbcClass?.length) {
            this.addSegmentoToLimite('infoPriceGlobalAbcClass', data.infoPriceGlobalAbcClass);
        }
        if (data?.infoPriceSensibility?.length) {
            this.addSegmentoToLimite('infoPriceSensibility', data.infoPriceSensibility);
        }
        if (data.sensibilityType) {
            this.addSegmentoSensibilitiesToLimite('sensibilityType', data.sensibilityType);
        }
    }

    getDataLimite(limmiteId) {
        getLimite(limmiteId).then((response) => {
            this.setDataLimite(response);
        });
    }

    addSegmentoSensibilitiesToLimite(key, item) {
        const { searchSegmentosSensibilities, setSegmentosSensibilitiesUpdateLimite, addSegmento, segmentos, changeValueSegmento } = this.props;

        searchSegmentosSensibilities(key, '', key);
        setTimeout(() => {
            setSegmentosSensibilitiesUpdateLimite(key, item);
            addSegmento(segmentos[segmentos.findIndex((item) => item.name === key)]);
            changeValueSegmento([item], key);
        }, 300);
    }

    addSegmentoProdutoToLimite(key, item) {
        const { searchProduto, setSegmentosProdutosUpdateLimite, addSegmento, segmentos, changeValueSegmento } = this.props;

        searchProduto(key, '', key);
        setTimeout(() => {
            item.forEach((elem) => {
                setSegmentosProdutosUpdateLimite(key, elem);
            });
            addSegmento(segmentos[segmentos.findIndex((item) => item.name === key)]);
            changeValueSegmento(
                item.map((elem) => `${elem.value}-${elem.description}`),
                key,
            );
        }, 300);
    }

    addSegmentoLojaToLimite(key, item) {
        const { searchStore, setSegmentosLojasUpdateLimite, addSegmento, segmentos, changeValueSegmento } = this.props;

        searchStore(key, '', key);
        setTimeout(() => {
            item.forEach((elem) => {
                setSegmentosLojasUpdateLimite(key, elem);
            });
            addSegmento(segmentos[segmentos.findIndex((item) => item.name === key)]);
            changeValueSegmento(
                item.map((elem) => elem),
                key,
            );
        }, 300);
    }

    addSegmentoToLimite(key, value) {
        const { searchItensSegmento, setSegmentosUpdateLimite, addSegmento, segmentos, changeValueSegmento } = this.props;
        if (key !== 'infoPriceAbcClass' && key !== 'infoPriceGlobalAbcClass' && key !== 'infoPriceSensibility') {
            searchItensSegmento(key, '', key);
        }

        setTimeout(() => {
            value.forEach((item) => {
                setSegmentosUpdateLimite(key, item);
            });
            addSegmento(segmentos[segmentos.findIndex((item) => item?.name === key)]);
            changeValueSegmento(value, key);
        }, 300);
    }

    addCurvaAbcToRegra(key, value) {
        const { addSegmento, segmentos, changeValueSegmento, setSegmentosUpdateLimite } = this.props;

        setTimeout(() => {
            if (typeof value === 'object') {
                value.forEach((item) => {
                    setSegmentosUpdateLimite(key, item);
                });
            } else {
                setSegmentosUpdateLimite(key, value);
            }
            addSegmento(segmentos[segmentos.findIndex((item) => item.name === key)]);
            changeValueSegmento(value, key);
        }, 300);
    }

    hasNoSegmento() {
        const { segmentosData, segmentos } = this.props;

        const segmentoRegra = {
            nivel_1:
                segmentos.findIndex((item) => item.nivel === 1) !== -1
                    ? segmentosData[segmentos[segmentos.findIndex((item) => item.nivel === 1)].name].value
                    : null,
            nivel_2:
                segmentos.findIndex((item) => item.nivel === 2) !== -1
                    ? segmentosData[segmentos[segmentos.findIndex((item) => item.nivel === 2)].name].value
                    : null,
            nivel_3:
                segmentos.findIndex((item) => item.nivel === 3) !== -1
                    ? segmentosData[segmentos[segmentos.findIndex((item) => item.nivel === 3)].name].value
                    : null,
            nivel_4:
                segmentos.findIndex((item) => item.nivel === 4) !== -1
                    ? segmentosData[segmentos[segmentos.findIndex((item) => item.nivel === 4)].name].value
                    : null,
            nivel_5:
                segmentos.findIndex((item) => item.nivel === 5) !== -1
                    ? segmentosData[segmentos[segmentos.findIndex((item) => item.nivel === 5)].name].value
                    : null,
            nivel_6:
                segmentos.findIndex((item) => item.nivel === 6) !== -1
                    ? segmentosData[segmentos[segmentos.findIndex((item) => item.nivel === 6)].name].value
                    : null,
            nivel_7:
                segmentos.findIndex((item) => item.nivel === 7) !== -1
                    ? segmentosData[segmentos[segmentos.findIndex((item) => item.nivel === 7)].name].value
                    : null,
            productId:
                segmentos.findIndex((item) => item.nivel === 'productId') !== -1
                    ? segmentosData[segmentos[segmentos.findIndex((item) => item.nivel === 'productId')].name].value
                    : null,
        };

        if (
            (segmentoRegra.nivel_1 === null || !segmentoRegra.nivel_1.length) &&
            (segmentoRegra.nivel_2 === null || !segmentoRegra.nivel_2.length) &&
            (segmentoRegra.nivel_3 === null || !segmentoRegra.nivel_3.length) &&
            (segmentoRegra.nivel_4 === null || !segmentoRegra.nivel_4.length) &&
            (segmentoRegra.nivel_5 === null || !segmentoRegra.nivel_5.length) &&
            (segmentoRegra.nivel_6 === null || !segmentoRegra.nivel_6.length) &&
            (segmentoRegra.nivel_7 === null || !segmentoRegra.nivel_7.length) &&
            (segmentoRegra.productId === null || !segmentoRegra.productId.length)
            // (segmentoRegra.sensibilityType === null || !segmentoRegra.sensibilityType.length)
        ) {
            return true;
        }
        return false;
    }

    validForm() {
        const { nome, minMargem, maxMargem, minCpi, maxCpi } = this.state;

        const errors = {};
        let formIsValid = true;

        if (!nome.length) {
            formIsValid = false;
            errors.nome = 'Campo obrigatório';
            const nome = document.getElementsByName('nome')[0];
            if (nome) {
                nome.classList.add('form__input_invalid');
            }
        } else {
            const nome = document.getElementsByName('nome')[0];
            if (nome) {
                nome.classList.remove('form__input_invalid');
            }
        }

        if (this.hasNoSegmento()) {
            formIsValid = false;
            errors.segmentos = 'Selecione pelo menos um segmento';
        }

        if (!minMargem && !maxMargem && !minCpi && !maxCpi) {
            formIsValid = false;
            errors.limite = 'Adicione pelo menos um valor de limite';
        }

        this.setState({ errors });
        return formIsValid;
    }

    validValuesMinMax() {
        const { minMargem, maxMargem, minCpi, maxCpi } = this.state;

        const errors = {};

        if (minMargem && parseFloat(minMargem.replace(',', '.')) > 100) {
            errors.minMargem = 'O valor limite de margem não pode ser maior que 100';
            const minMargem = document.getElementsByName('minMargem')[0];
            if (minMargem) {
                minMargem.classList.add('form__input_invalid');
            }
        } else if (minMargem && maxMargem && parseFloat(minMargem.replace(',', '.')) > parseFloat(maxMargem.replace(',', '.'))) {
            errors.minMargem = 'O valor mínimo de limite não pode ser maior que o valor máximo';
            const minMargem = document.getElementsByName('minMargem')[0];
            if (minMargem) {
                minMargem.classList.add('form__input_invalid');
            }
        } else {
            const minMargem = document.getElementsByName('minMargem')[0];
            if (minMargem) {
                minMargem.classList.remove('form__input_invalid');
            }
        }

        if (maxMargem && parseFloat(maxMargem.replace(',', '.')) > 100) {
            errors.maxMargem = 'O valor limite de margem não pode ser maior que 100';
            const maxMargem = document.getElementsByName('maxMargem')[0];
            if (maxMargem) {
                maxMargem.classList.add('form__input_invalid');
            }
        } else {
            const maxMargem = document.getElementsByName('maxMargem')[0];
            if (maxMargem) {
                maxMargem.classList.remove('form__input_invalid');
            }
        }

        if (minCpi && parseFloat(minCpi.replace(',', '.')) === 0) {
            errors.minCpi = 'O valor limite de competitividade deve ser maior que 0';
            const minCpi = document.getElementsByName('minCpi')[0];
            if (minCpi) {
                minCpi.classList.add('form__input_invalid');
            }
        } else if (minCpi && maxCpi && parseFloat(minCpi.replace(',', '.')) > parseFloat(maxCpi.replace(',', '.'))) {
            errors.minCpi = 'O valor mínimo de limite não pode ser maior que o valor máximo';
            const minCpi = document.getElementsByName('minCpi')[0];
            if (minCpi) {
                minCpi.classList.add('form__input_invalid');
            }
        } else {
            const minCpi = document.getElementsByName('minCpi')[0];
            if (minCpi) {
                minCpi.classList.remove('form__input_invalid');
            }
        }

        if (maxCpi && parseFloat(maxCpi.replace(',', '.')) === 0) {
            errors.maxCpi = 'O valor limite de competitividade deve ser maior que 0';
            const maxCpi = document.getElementsByName('maxCpi')[0];
            if (maxCpi) {
                maxCpi.classList.add('form__input_invalid');
            }
        } else {
            const maxCpi = document.getElementsByName('maxCpi')[0];
            if (maxCpi) {
                maxCpi.classList.remove('form__input_invalid');
            }
        }

        this.setState({ errors });
    }

    validValuesMinMaxAtacado() {
        const { minMargemAtacado, maxMargemAtacado, maxMargem, minCpiAtacado, maxCpi, maxCpiAtacado } = this.state;

        const errors = {};

        if (minMargemAtacado && parseFloat(minMargemAtacado.replace(',', '.')) > 100) {
            errors.minMargemAtacado = 'O valor limite de margem não pode ser maior que 100';
            const minMargemAtacado = document.getElementsByName('minMargemAtacado')[0];
            if (minMargemAtacado) {
                minMargemAtacado.classList.add('form__input_invalid');
            }
        } else if (minMargemAtacado && maxMargem && parseFloat(minMargemAtacado.replace(',', '.')) > parseFloat(maxMargem.replace(',', '.'))) {
            errors.minMargemAtacado = 'O valor mínimo de limite não pode ser maior que o valor máximo';
            const minMargemAtacado = document.getElementsByName('minMargemAtacado')[0];
            if (minMargemAtacado) {
                minMargemAtacado.classList.add('form__input_invalid');
            }
        } else {
            const minMargemAtacado = document.getElementsByName('minMargemAtacado')[0];
            if (minMargemAtacado) {
                minMargemAtacado.classList.remove('form__input_invalid');
            }
        }

        if (maxMargemAtacado && parseFloat(maxMargemAtacado.replace(',', '.')) > 100) {
            errors.maxMargemAtacado = 'O valor limite de margem não pode ser maior que 100';
            const maxMargemAtacado = document.getElementsByName('maxMargemAtacado')[0];
            if (maxMargemAtacado) {
                maxMargemAtacado.classList.add('form__input_invalid');
            }
        } else {
            const maxMargemAtacado = document.getElementsByName('maxMargemAtacado')[0];
            if (maxMargemAtacado) {
                maxMargemAtacado.classList.remove('form__input_invalid');
            }
        }

        if (minCpiAtacado && parseFloat(minCpiAtacado.replace(',', '.')) === 0) {
            errors.minCpiAtacado = 'O valor limite de competitividade deve ser maior que 0';
            const minCpiAtacado = document.getElementsByName('minCpiAtacado')[0];
            if (minCpiAtacado) {
                minCpiAtacado.classList.add('form__input_invalid');
            }
        } else if (minCpiAtacado && maxCpi && parseFloat(minCpiAtacado.replace(',', '.')) > parseFloat(maxCpiAtacado.replace(',', '.'))) {
            errors.minCpiAtacado = 'O valor mínimo de limite não pode ser maior que o valor máximo';
            const minCpiAtacado = document.getElementsByName('minCpiAtacado')[0];
            if (minCpiAtacado) {
                minCpiAtacado.classList.add('form__input_invalid');
            }
        } else {
            const minCpiAtacado = document.getElementsByName('minCpiAtacado')[0];
            if (minCpiAtacado) {
                minCpiAtacado.classList.remove('form__input_invalid');
            }
        }

        if (maxCpiAtacado && parseFloat(maxCpiAtacado.replace(',', '.')) === 0) {
            errors.maxCpiAtacado = 'O valor limite de competitividade deve ser maior que 0';
            const maxCpiAtacado = document.getElementsByName('maxCpiAtacado')[0];
            if (maxCpiAtacado) {
                maxCpiAtacado.classList.add('form__input_invalid');
            }
        } else {
            const maxCpiAtacado = document.getElementsByName('maxCpiAtacado')[0];
            if (maxCpiAtacado) {
                maxCpiAtacado.classList.remove('form__input_invalid');
            }
        }

        this.setState({ errors });
    }

    modelSegmentos() {
        const { segmentos, segmentosData } = this.props;

        const categoryLevel1 =
            segmentos.findIndex((item) => item.nivel === 1) !== -1
                ? segmentosData[segmentos[segmentos.findIndex((item) => item.nivel === 1)].name].value.map((item) => ({
                      value: item,
                      level: 'categoryLevel1',
                  }))
                : [];
        const categoryLevel2 =
            segmentos.findIndex((item) => item.nivel === 2) !== -1
                ? segmentosData[segmentos[segmentos.findIndex((item) => item.nivel === 2)].name].value.map((item) => ({
                      value: item,
                      level: 'categoryLevel2',
                  }))
                : [];
        const categoryLevel3 =
            segmentos.findIndex((item) => item.nivel === 3) !== -1
                ? segmentosData[segmentos[segmentos.findIndex((item) => item.nivel === 3)].name].value.map((item) => ({
                      value: item,
                      level: 'categoryLevel3',
                  }))
                : [];
        const categoryLevel4 =
            segmentos.findIndex((item) => item.nivel === 4) !== -1
                ? segmentosData[segmentos[segmentos.findIndex((item) => item.nivel === 4)].name].value.map((item) => ({
                      value: item,
                      level: 'categoryLevel4',
                  }))
                : [];
        const categoryLevel5 =
            segmentos.findIndex((item) => item.nivel === 5) !== -1
                ? segmentosData[segmentos[segmentos.findIndex((item) => item.nivel === 5)].name].value.map((item) => ({
                      value: item,
                      level: 'categoryLevel5',
                  }))
                : [];
        const categoryLevel6 =
            segmentos.findIndex((item) => item.nivel === 6) !== -1
                ? segmentosData[segmentos[segmentos.findIndex((item) => item.nivel === 6)].name].value.map((item) => ({
                      value: item,
                      level: 'categoryLevel6',
                  }))
                : [];
        const categoryLevel7 =
            segmentos.findIndex((item) => item.nivel === 7) !== -1
                ? segmentosData[segmentos[segmentos.findIndex((item) => item.nivel === 7)].name].value.map((item) => ({
                      value: item,
                      level: 'categoryLevel7',
                  }))
                : [];
        const productId =
            segmentos.findIndex((item) => item.nivel === 'productId') !== -1
                ? segmentosData[segmentos[segmentos.findIndex((item) => item.nivel === 'productId')].name].value.map((item) => ({
                      value: item.substr(0, item.indexOf('-')),
                      level: 'productId',
                      description: item.substr(item.indexOf('-') + 1),
                  }))
                : [];

        const segmentosLevels = categoryLevel1.concat(
            categoryLevel2,
            categoryLevel3,
            categoryLevel4,
            categoryLevel5,
            categoryLevel6,
            categoryLevel7,
            productId,
        );

        return segmentosLevels;
    }

    createLimite() {
        const {
            nome,
            minMargem,
            maxMargem,
            minCpi,
            maxCpi,
            toggleMaximoMargem,
            toggleMinimoMargem,
            toggleMaximoCompetitividade,
            toggleMinimoCompetitividade,
            regraFamilia,
        } = this.state;

        const { segmentos, segmentosData, match, clearSegmentos, history } = this.props;

        if (this.validForm()) {
            const limite = {
                clientId: JSON.parse(localStorage.getItem('cliente'))?.codigo,
                name: nome,
                productFamily: regraFamilia,
                minMargin: minMargem && toggleMinimoMargem ? parseFloat(minMargem.replace(',', '.')) / 100 : null,
                maxMargin: maxMargem && toggleMaximoMargem ? parseFloat(maxMargem.replace(',', '.')) / 100 : null,
                minCpi: minCpi && toggleMinimoCompetitividade ? parseFloat(minCpi.replace(',', '.')) / 100 : null,
                maxCpi: maxCpi && toggleMaximoCompetitividade ? parseFloat(maxCpi.replace(',', '.')) / 100 : null,
                author: JSON.parse(localStorage.getItem('usuario'))?.email,
                storeIds:
                    segmentos.findIndex((item) => item.nivel === 'searchStoreIds') !== -1
                        ? segmentosData[segmentos[segmentos.findIndex((item) => item.nivel === 'searchStoreIds')].name].value
                        : [],
                clusters:
                    segmentos.findIndex((item) => item.nivel === 'searchCluster') !== -1
                        ? segmentosData[segmentos[segmentos.findIndex((item) => item.nivel === 'searchCluster')].name].value.map((id) => ({ id }))
                        : [],
                segments: this.modelSegmentos(),
                ...(segmentos.findIndex((item) => item.nivel === 'abcClass') !== -1 &&
                segmentosData[segmentos[segmentos.findIndex((item) => item.nivel === 'abcClass')].name].value
                    ? {
                          abcClass: segmentosData[segmentos[segmentos.findIndex((item) => item.nivel === 'abcClass')].name].value,
                      }
                    : null),
                ...(segmentos.findIndex((item) => item.nivel === 'infoPriceAbcClass') !== -1 &&
                segmentosData[segmentos[segmentos.findIndex((item) => item.nivel === 'infoPriceAbcClass')].name].value
                    ? {
                          infoPriceAbcClass: segmentosData[segmentos[segmentos.findIndex((item) => item.nivel === 'infoPriceAbcClass')].name].value,
                      }
                    : null),
                ...(segmentos.findIndex((item) => item.nivel === 'infoPriceGlobalAbcClass') !== -1 &&
                segmentosData[segmentos[segmentos.findIndex((item) => item.nivel === 'infoPriceGlobalAbcClass')].name].value
                    ? {
                          infoPriceGlobalAbcClass: segmentosData[segmentos[segmentos.findIndex((item) => item.nivel === 'infoPriceGlobalAbcClass')].name].value,
                      }
                    : null),
                ...(segmentos.findIndex((item) => item.nivel === 'infoPriceSensibility') !== -1 &&
                segmentosData[segmentos[segmentos.findIndex((item) => item.nivel === 'infoPriceSensibility')].name].value
                    ? {
                          infoPriceSensibility: segmentosData[segmentos[segmentos.findIndex((item) => item.nivel === 'infoPriceSensibility')].name].value,
                      }
                    : null),
            };

            const hasSensibilityType = segmentos.findIndex((item) => item.name === 'sensibilityType') !== -1;
            if (hasSensibilityType) {
                limite.sensibilityType = segmentosData?.sensibilityType?.value[0];
            }

            if (match.params.id) {
                limite.limitId = match.params.id;
                updateLimite(match.params.id, limite).then((response) => {
                    if (response.status === 200 || response.status === 201) {
                        clearSegmentos();
                        history.push('/ipa/limites-preco');
                    }
                });
            } else {
                saveLimite(limite).then((response) => {
                    if (response.status === 200 || response.status === 201) {
                        clearSegmentos();
                        history.push('/ipa/limites-preco');
                    }
                });
            }
        }
    }

    back() {
        const { clearSegmentos, history } = this.props;

        clearSegmentos();
        history.push('/ipa/limites-preco');
    }

    render() {
        const {
            nome,
            errors,
            toggleMinimoMargem,
            minMargem,
            toggleMaximoMargem,
            maxMargem,
            toggleMinimoCompetitividade,
            minCpi,
            toggleMaximoCompetitividade,
            maxCpi,
            showSectionAtacado,
            toggleMinimoMargemAtacado,
            minMargemAtacado,
            toggleMaximoMargemAtacado,
            maxMargemAtacado,
            toggleMinimoCompetitividadeAtacado,
            minCpiAtacado,
            toggleMaximoCompetitividadeAtacado,
            maxCpiAtacado,
            regraFamilia,
        } = this.state;

        const { segmentosAdicionados, match } = this.props;

        return (
            <Container className="main-container limite-preco-cadastro-page">
                <Content>
                    <LoadingSpiner size="md" />

                    <FlexboxGrid>
                        <FlexboxGrid.Item componentClass={Col} lg={24} md={24} sm={24} xs={24}>
                            <h3>Cadastro de limite</h3>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                    <Form fluid className="form">
                        <FlexboxGrid>
                            <FlexboxGrid.Item componentClass={Col} lg={12} md={12} sm={24} xs={24}>
                                <FormGroup>
                                    <ControlLabel>Nome do limite</ControlLabel>
                                    <FormControl name="nome" value={nome} onChange={this.handleChange} />
                                    <HelpBlock className="form__message_invalid" name="erroNome">
                                        {errors?.nome}
                                    </HelpBlock>
                                </FormGroup>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                        <Divider />

                        <FlexboxGrid>
                            <FlexboxGrid.Item>
                                <AddFiltro />
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                        <FlexboxGrid>
                            {segmentosAdicionados?.map((filtro, index) => (
                                <FiltroCustomizado key={filtro.name} filtro={filtro} deleteFiltro={this.handleDeleteSegmento} index={index} />
                            ))}
                            <FlexboxGrid.Item>
                                <HelpBlock className="form__message_invalid" name="erroSegmentos">
                                    {errors?.segmentos}
                                </HelpBlock>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                        <FlexboxGrid>
                            <FlexboxGrid.Item>
                                <h4>Varejo</h4>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                        <Panel className="panel" header="Margem" bordered>
                            <FlexboxGrid align="middle" className="panel__section">
                                <FlexboxGrid.Item className="panel__label-div">
                                    <span className="panel__label">Mínimo</span> <span>de</span>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item className="panel__input-div" componentClass={Col} lg={2} md={4} sm={20} xs={20}>
                                    {!toggleMinimoMargem ? (
                                        <InputGroup>
                                            <Input disabled />
                                            <InputGroup.Addon>%</InputGroup.Addon>
                                        </InputGroup>
                                    ) : (
                                        <InputGroup>
                                            <CurrencyInput
                                                className="rs-input"
                                                name="minMargem"
                                                value={minMargem}
                                                decimalSeparator=","
                                                thousandSeparator="."
                                                precision="2"
                                                allowEmpty
                                                allowNegative
                                                onBlur={this.validValuesMinMax}
                                                onChangeEvent={(e, value) => this.handleChange(value, e)}
                                            />
                                            <InputGroup.Addon>%</InputGroup.Addon>
                                        </InputGroup>
                                    )}
                                </FlexboxGrid.Item>

                                <FlexboxGrid.Item>
                                    <Toggle checked={toggleMinimoMargem} onChange={(checked) => this.handleChangeToggle(checked, 'toggleMinimoMargem')} />
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item className="panel-message-margem" componentClass={Col} lgOffset={12}>
                                    <Message
                                        showIcon
                                        type="info"
                                        description={
                                            <p>
                                                Crie um <strong>limite de margem</strong> para cada regra de <br /> competitividade, pois ele garante que caso
                                                haja <br /> variação agressiva de preço concorrência, suas <br /> margens sejam preservadas.
                                            </p>
                                        }
                                    />
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                            {errors?.minMargem ? (
                                <FlexboxGrid className="panel__section">
                                    <FlexboxGrid.Item>
                                        <HelpBlock className="form__message_invalid" name="erroMinMargem">
                                            {errors.minMargem}
                                        </HelpBlock>
                                    </FlexboxGrid.Item>
                                </FlexboxGrid>
                            ) : null}
                            <FlexboxGrid align="middle" className="panel__section panel__section_margin-top">
                                <FlexboxGrid.Item className="panel__label-div">
                                    <span className="panel__label">Máximo</span> <span>de</span>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item className="panel__input-div" componentClass={Col} lg={2} md={4} sm={20} xs={20}>
                                    {!toggleMaximoMargem ? (
                                        <InputGroup>
                                            <Input disabled />
                                            <InputGroup.Addon>%</InputGroup.Addon>
                                        </InputGroup>
                                    ) : (
                                        <InputGroup>
                                            <CurrencyInput
                                                className="rs-input"
                                                name="maxMargem"
                                                value={maxMargem}
                                                decimalSeparator=","
                                                thousandSeparator="."
                                                precision="2"
                                                allowEmpty
                                                allowNegative
                                                onBlur={this.validValuesMinMax}
                                                onChangeEvent={(e, value) => this.handleChange(value, e)}
                                            />
                                            <InputGroup.Addon>%</InputGroup.Addon>
                                        </InputGroup>
                                    )}
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item>
                                    <Toggle checked={toggleMaximoMargem} onChange={(checked) => this.handleChangeToggle(checked, 'toggleMaximoMargem')} />
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                            {errors?.maxMargem ? (
                                <FlexboxGrid className="panel__section">
                                    <FlexboxGrid.Item>
                                        <HelpBlock className="form__message_invalid" name="erroMaxMargem">
                                            {errors.maxMargem}
                                        </HelpBlock>
                                    </FlexboxGrid.Item>
                                </FlexboxGrid>
                            ) : null}
                        </Panel>
                        <Panel className="panel" header="Competitividade" bordered>
                            <FlexboxGrid align="middle" className="panel__section">
                                <FlexboxGrid.Item className="panel__label-div">
                                    <span className="panel__label">Mínimo</span> <span>de</span>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item className="panel__input-div" componentClass={Col} lg={2} md={4} sm={20} xs={20}>
                                    {!toggleMinimoCompetitividade ? (
                                        <InputGroup>
                                            <Input disabled />
                                            <InputGroup.Addon>%</InputGroup.Addon>
                                        </InputGroup>
                                    ) : (
                                        <InputGroup>
                                            <CurrencyInput
                                                className="rs-input"
                                                name="minCpi"
                                                value={minCpi}
                                                decimalSeparator=","
                                                thousandSeparator="."
                                                precision="2"
                                                allowEmpty
                                                onBlur={this.validValuesMinMax}
                                                onChangeEvent={(e, value) => this.handleChange(value, e)}
                                            />
                                            <InputGroup.Addon>%</InputGroup.Addon>
                                        </InputGroup>
                                    )}
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item>
                                    <Toggle
                                        checked={toggleMinimoCompetitividade}
                                        onChange={(checked) => this.handleChangeToggle(checked, 'toggleMinimoCompetitividade')}
                                    />
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item className="panel-message-competitividade" componentClass={Col} lgOffset={12}>
                                    <Message
                                        showIcon
                                        type="info"
                                        description={
                                            <p>
                                                Crie um <strong>limite de competitividade</strong> para cada <br /> regra de margem, ele será útil para
                                                verificar se <br /> suas estratégias não estão muito distantes da <br /> realidade do mercado.
                                            </p>
                                        }
                                    />
                                </FlexboxGrid.Item>
                            </FlexboxGrid>

                            {errors?.minCpi ? (
                                <FlexboxGrid className="panel__section">
                                    <FlexboxGrid.Item>
                                        <HelpBlock className="form__message_invalid" name="erroMinCpi">
                                            {errors.minCpi}
                                        </HelpBlock>
                                    </FlexboxGrid.Item>
                                </FlexboxGrid>
                            ) : null}
                            <FlexboxGrid align="middle" className="panel__section panel__section_margin-top">
                                <FlexboxGrid.Item className="panel__label-div">
                                    <span className="panel__label">Máximo</span> <span>de</span>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item className="panel__input-div" componentClass={Col} lg={2} md={4} sm={20} xs={20}>
                                    {!toggleMaximoCompetitividade ? (
                                        <InputGroup>
                                            <Input disabled />
                                            <InputGroup.Addon>%</InputGroup.Addon>
                                        </InputGroup>
                                    ) : (
                                        <InputGroup>
                                            <CurrencyInput
                                                className="rs-input"
                                                name="maxCpi"
                                                value={maxCpi}
                                                decimalSeparator=","
                                                thousandSeparator="."
                                                precision="2"
                                                allowEmpty
                                                onBlur={this.validValuesMinMax}
                                                onChangeEvent={(e, value) => this.handleChange(value, e)}
                                            />
                                            <InputGroup.Addon>%</InputGroup.Addon>
                                        </InputGroup>
                                    )}
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item>
                                    <Toggle
                                        checked={toggleMaximoCompetitividade}
                                        onChange={(checked) => this.handleChangeToggle(checked, 'toggleMaximoCompetitividade')}
                                    />
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                            {errors?.maxCpi ? (
                                <FlexboxGrid className="panel__section">
                                    <FlexboxGrid.Item>
                                        <HelpBlock className="form__message_invalid" name="erroMaxCpi">
                                            {errors.maxCpi}
                                        </HelpBlock>
                                    </FlexboxGrid.Item>
                                </FlexboxGrid>
                            ) : null}
                        </Panel>
                        <FlexboxGrid>
                            <FlexboxGrid.Item>
                                <HelpBlock className="form__message_invalid" name="erroLimite">
                                    {errors?.limite}
                                </HelpBlock>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                        {showSectionAtacado ? (
                            <>
                                <FlexboxGrid>
                                    <FlexboxGrid.Item>
                                        <h4>Atacado</h4>
                                    </FlexboxGrid.Item>
                                </FlexboxGrid>
                                <Panel className="panel" header="Margem" bordered>
                                    <FlexboxGrid align="middle" className="panel__section">
                                        <FlexboxGrid.Item className="panel__label-div">
                                            <span className="panel__label">Mínimo</span> <span>de</span>
                                        </FlexboxGrid.Item>
                                        <FlexboxGrid.Item className="panel__input-div" componentClass={Col} lg={2} md={4} sm={20} xs={20}>
                                            {!toggleMinimoMargemAtacado ? (
                                                <InputGroup>
                                                    <Input disabled />
                                                    <InputGroup.Addon>%</InputGroup.Addon>
                                                </InputGroup>
                                            ) : (
                                                <InputGroup>
                                                    <CurrencyInput
                                                        className="rs-input"
                                                        name="minMargemAtacado"
                                                        value={minMargemAtacado}
                                                        decimalSeparator=","
                                                        thousandSeparator="."
                                                        precision="2"
                                                        allowEmpty
                                                        allowNegative
                                                        onBlur={this.validValuesMinMaxAtacado}
                                                        onChangeEvent={(e, value) => this.handleChange(value, e)}
                                                    />
                                                    <InputGroup.Addon>%</InputGroup.Addon>
                                                </InputGroup>
                                            )}
                                        </FlexboxGrid.Item>
                                        <FlexboxGrid.Item>
                                            <Toggle
                                                checked={toggleMinimoMargemAtacado}
                                                onChange={(checked) => this.handleChangeToggle(checked, 'toggleMinimoMargemAtacado')}
                                            />
                                        </FlexboxGrid.Item>
                                    </FlexboxGrid>
                                    {errors?.minMargemAtacado ? (
                                        <FlexboxGrid className="panel__section">
                                            <FlexboxGrid.Item>
                                                <HelpBlock className="form__message_invalid" name="erroMinMargemAtacado">
                                                    {errors.minMargemAtacado}
                                                </HelpBlock>
                                            </FlexboxGrid.Item>
                                        </FlexboxGrid>
                                    ) : null}
                                    <FlexboxGrid align="middle" className="panel__section panel__section_margin-top">
                                        <FlexboxGrid.Item className="panel__label-div">
                                            <span className="panel__label">Máximo</span> <span>de</span>
                                        </FlexboxGrid.Item>
                                        <FlexboxGrid.Item className="panel__input-div" componentClass={Col} lg={2} md={4} sm={20} xs={20}>
                                            {!toggleMaximoMargemAtacado ? (
                                                <InputGroup>
                                                    <Input disabled />
                                                    <InputGroup.Addon>%</InputGroup.Addon>
                                                </InputGroup>
                                            ) : (
                                                <InputGroup>
                                                    <CurrencyInput
                                                        className="rs-input"
                                                        name="maxMargemAtacado"
                                                        value={maxMargemAtacado}
                                                        decimalSeparator=","
                                                        thousandSeparator="."
                                                        precision="2"
                                                        allowEmpty
                                                        allowNegative
                                                        onBlur={this.validValuesMinMaxAtacado}
                                                        onChangeEvent={(e, value) => this.handleChange(value, e)}
                                                    />
                                                    <InputGroup.Addon>%</InputGroup.Addon>
                                                </InputGroup>
                                            )}
                                        </FlexboxGrid.Item>
                                        <FlexboxGrid.Item>
                                            <Toggle
                                                checked={toggleMaximoMargemAtacado}
                                                onChange={(checked) => this.handleChangeToggle(checked, 'toggleMaximoMargemAtacado')}
                                            />
                                        </FlexboxGrid.Item>
                                    </FlexboxGrid>
                                    {errors?.maxMargemAtacado ? (
                                        <FlexboxGrid className="panel__section">
                                            <FlexboxGrid.Item>
                                                <HelpBlock className="form__message_invalid" name="erroMaxMargemAtacado">
                                                    {errors.maxMargemAtacado}
                                                </HelpBlock>
                                            </FlexboxGrid.Item>
                                        </FlexboxGrid>
                                    ) : null}
                                </Panel>
                                <Panel className="panel" header="Competitividade" bordered>
                                    <FlexboxGrid align="middle" className="panel__section">
                                        <FlexboxGrid.Item className="panel__label-div">
                                            <span className="panel__label">Mínimo</span> <span>de</span>
                                        </FlexboxGrid.Item>
                                        <FlexboxGrid.Item className="panel__input-div" componentClass={Col} lg={2} md={4} sm={20} xs={20}>
                                            {!toggleMinimoCompetitividadeAtacado ? (
                                                <InputGroup>
                                                    <Input disabled />
                                                    <InputGroup.Addon>%</InputGroup.Addon>
                                                </InputGroup>
                                            ) : (
                                                <InputGroup>
                                                    <CurrencyInput
                                                        className="rs-input"
                                                        name="minCpiAtacado"
                                                        value={minCpiAtacado}
                                                        decimalSeparator=","
                                                        thousandSeparator="."
                                                        precision="2"
                                                        allowEmpty
                                                        onBlur={this.validValuesMinMaxAtacado}
                                                        onChangeEvent={(e, value) => this.handleChange(value, e)}
                                                    />
                                                    <InputGroup.Addon>%</InputGroup.Addon>
                                                </InputGroup>
                                            )}
                                        </FlexboxGrid.Item>
                                        <FlexboxGrid.Item>
                                            <Toggle
                                                checked={toggleMinimoCompetitividadeAtacado}
                                                onChange={(checked) => this.handleChangeToggle(checked, 'toggleMinimoCompetitividadeAtacado')}
                                            />
                                        </FlexboxGrid.Item>
                                    </FlexboxGrid>
                                    {errors?.minCpiAtacado ? (
                                        <FlexboxGrid className="panel__section">
                                            <FlexboxGrid.Item>
                                                <HelpBlock className="form__message_invalid" name="erroMinCpiAtacado">
                                                    {errors.minCpiAtacado}
                                                </HelpBlock>
                                            </FlexboxGrid.Item>
                                        </FlexboxGrid>
                                    ) : null}
                                    <FlexboxGrid align="middle" className="panel__section panel__section_margin-top">
                                        <FlexboxGrid.Item className="panel__label-div">
                                            <span className="panel__label">Máximo</span> <span>de</span>
                                        </FlexboxGrid.Item>
                                        <FlexboxGrid.Item className="panel__input-div" componentClass={Col} lg={2} md={4} sm={20} xs={20}>
                                            {!toggleMaximoCompetitividadeAtacado ? (
                                                <InputGroup>
                                                    <Input disabled />
                                                    <InputGroup.Addon>%</InputGroup.Addon>
                                                </InputGroup>
                                            ) : (
                                                <InputGroup>
                                                    <CurrencyInput
                                                        className="rs-input"
                                                        name="maxCpiAtacado"
                                                        value={maxCpiAtacado}
                                                        decimalSeparator=","
                                                        thousandSeparator="."
                                                        precision="2"
                                                        allowEmpty
                                                        onBlur={this.validValuesMinMaxAtacado}
                                                        onChangeEvent={(e, value) => this.handleChange(value, e)}
                                                    />
                                                    <InputGroup.Addon>%</InputGroup.Addon>
                                                </InputGroup>
                                            )}
                                        </FlexboxGrid.Item>
                                        <FlexboxGrid.Item>
                                            <Toggle
                                                checked={toggleMaximoCompetitividadeAtacado}
                                                onChange={(checked) => this.handleChangeToggle(checked, 'toggleMaximoCompetitividadeAtacado')}
                                            />
                                        </FlexboxGrid.Item>
                                    </FlexboxGrid>
                                    {errors?.maxCpiAtacado ? (
                                        <FlexboxGrid className="panel__section">
                                            <FlexboxGrid.Item>
                                                <HelpBlock className="form__message_invalid" name="erroMaxCpiAtacado">
                                                    {errors.maxCpiAtacado}
                                                </HelpBlock>
                                            </FlexboxGrid.Item>
                                        </FlexboxGrid>
                                    ) : null}
                                </Panel>
                            </>
                        ) : null}
                        <FlexboxGrid>
                            <FlexboxGrid.Item>
                                <HelpBlock className="form__message_invalid" name="erroLimite">
                                    {errors?.limite}
                                </HelpBlock>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                        <FlexboxGrid className="buttons__wrapper">
                            <FlexboxGrid.Item componentClass={Col}>
                                <Button onClick={this.createLimite} appearance="primary" id="save-limit-btn">
                                    {match.params.id ? 'Editar limite' : 'Criar limite'}
                                </Button>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item componentClass={Col}>
                                <Button onClick={this.back}>Cancelar</Button>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </Form>
                </Content>
                <ModalNotification title="" message="" show={false} onHide="" close="" />
            </Container>
        );
    }
}

const mapStateToProps = (store) => ({
    tiposSegmento: store.limitesPrecoCadastroDataReducer.tiposSegmento,
    segmentos: store.limitesPrecoCadastroDataReducer.segmentos,
    segmentosAdicionados: store.limitesPrecoCadastroDataReducer.segmentosAdicionados,
    segmentosData: store.limitesPrecoCadastroDataReducer.segmentosData,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            addSegmento,
            deleteSegmento,
            selectValueSegmento,
            searchSegmentos,
            searchItensSegmento,
            searchSegmentosClasses,
            searchSegmentosSensibilities,
            changeValueSegmento,
            changeCacheSegmento,
            clearSegmentos,
            setSegmentosUpdateLimite,
            setSegmentosProdutosUpdateLimite,
            setSegmentosLojasUpdateLimite,
            // setSegmentosClassesUpdateLimite,
            setSegmentosSensibilitiesUpdateLimite,
            searchProduto,
            searchStore,
        },
        dispatch,
    );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LimitesPrecoCadastro));
