import { format } from 'date-fns';
import React from 'react';
import { Alert } from 'rsuite';

interface AlertWithTimestampProps {
    message: string;
    type?: 'info' | 'success' | 'warning' | 'error';
}

export const AlertWithTimestamp = ({ message, type = 'error' }: AlertWithTimestampProps) => {
    const timestamp = format(new Date(), "HH:mm, dd/MM/yyyy");

    return Alert[type](
        <div>
            <div>{message}</div>
            <div>{timestamp}</div>
        </div>,
        5000
    );
};

