import React from 'react';
import { Button } from 'rsuite';
import { AsyncCheckPill } from '../../../../../../../components';
import styles from './GridFilters.module.scss';
import { useGridFilters } from './useGridFilters';

export const GridFilters = () => {
    const { list: filters, segmentsQueriesByDefaultLevel, showOnlyDefaultCategoryLevel } = useGridFilters();

    return (
        <main className={styles.container}>
            <section className={styles.wrapper}>
                <div className={styles.content}>
                    <div>
                        {filters.map((filter) => {
                            return !filter.dataKey.match(/categoryLevel/) && <AsyncCheckPill key={filter.dataKey} {...filter} />;
                        })}
                    </div>
                    <div>
                        {showOnlyDefaultCategoryLevel &&
                            segmentsQueriesByDefaultLevel.map((filter) => {
                                return <AsyncCheckPill key={filter.dataKey} {...filter} />;
                            })}
                        {filters.map((filter) => {
                            return filter.dataKey.match(/categoryLevel/) && <AsyncCheckPill key={filter.dataKey} {...filter} />;
                        })}
                    </div>
                </div>
                <Button
                    className="filter-button"
                    onClick={() => {
                        filters.forEach((filter) => {
                            filter.onClean?.();
                        });
                        segmentsQueriesByDefaultLevel.forEach((filter) => {
                            filter.onClean?.();
                        });
                    }}
                >
                    Limpar filtros
                </Button>
            </section>
        </main>
    );
};
