import React from 'react';
import { MdDelete, MdEdit } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { optimizationPricesResultSelector } from '../../../../../../../reducers/OtimizacaoPrecos';
import { deleteOptimization } from '../../../../services';
import { Header } from '../../../OtimizacaoPrecos/components/Header';
import styles from './HeaderResult.module.scss';

export const HeaderResult = () => {
    const history = useHistory();
    const { id } = useParams() as Record<'id', string>;

    const { header } = useSelector(optimizationPricesResultSelector);
    const { status } = header;

    return (
        <Header badge={status} title="Resultado da Otimização" showAlert={false}>
            <div className={styles.buttons}>
                <button
                    className={styles['main-button']}
                    onClick={() => {
                        history.push('/ipa/gerenciador-de-precos');
                    }}
                >
                    Gerenciar preços
                </button>
                <button
                    onClick={() => {
                        history.push(`/ipa/otimizacao-de-precos/${id}`);
                    }}
                >
                    <MdEdit style={{ height: '20px', width: '20px', color: '#959595' }} />
                </button>
                <button onClick={() => deleteOptimization(id)}>
                    <MdDelete style={{ height: '20px', width: '20px', color: '#959595' }} />
                </button>
            </div>
        </Header>
    );
};
