/* eslint-disable import/no-mutable-exports */
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CheckPicker, Col, FlexboxGrid, Icon, IconButton, InputPicker, Tag } from 'rsuite';
import {
    changeCacheSegmento,
    changeValueSegmento,
    searchCluster,
    searchItensSegmento,
    searchProduto,
    searchSegmentos,
    searchSegmentosSensibilities,
    searchStore,
    selectValueSegmento,
} from '../../../../../actions/actionsLimitesPrecoCadastro';
import { selectSearch } from '../../../../../utils/Helpers';

let FiltroCustomizado = ({
    changeValueSegmento,
    searchItensSegmento,
    changeCacheSegmento,
    segmentosData,
    selectValueSegmento,
    filtro,
    deleteFiltro,
    index,
    searchProduto,
    searchStore,
    searchCluster,
    searchSegmentosSensibilities,
    searchSegmentos,
}) => {
    const handleChange = (value, event, item) => {
        changeValueSegmento(value, item.name);

        let cache;
        if (value === null) {
            cache = [];
            if (item.onSearch) {
                searchItensSegmento(item.nivel, { busca: '' }, item.name);
            }
            changeCacheSegmento(item.name, cache);
        }
        if (value !== null && value.length !== segmentosData[`${item.name}`].cache.length) {
            cache = segmentosData[`${item.name}`].lista.filter((item) => value.indexOf(item.value) !== -1);

            changeCacheSegmento(item.name, cache);
        }
    };

    const handleItensAfetados = (item) => {
        const filtro = item.name !== 'productId' ? { nivel: item.name, name: item.name } : item;
        if (
            item.name !== 'abcClass' &&
            item.name !== 'searchStoreIds' &&
            item.name !== 'searchCluster' &&
            item.name !== 'sensibilityType' &&
            item.name !== 'infoPriceAbcClass' &&
            item.name !== 'infoPriceGlobalAbcClass' &&
            item.name !== 'infoPriceSensibility'
        ) {
            selectSearch(searchItensSegmento, searchProduto, searchStore, searchCluster, '', searchSegmentos, searchSegmentosSensibilities, '', filtro, {
                query: '',
            });
        }
    };

    const handleSelect = (value, item, name) => {
        selectValueSegmento(item, name);
    };

    return (
        <FlexboxGrid.Item className="filtro-dinamico" componentClass={Col} lg={12} md={12}>
            <FlexboxGrid.Item componentClass={Col} lg={4} md={6}>
                <Tag className="filtro-dinamico__tag">{filtro.title}</Tag>
            </FlexboxGrid.Item>
            <FlexboxGrid.Item className="filtro-dinamico__input-section" componentClass={Col} lg={15} md={14} sm={24} xs={24}>
                {filtro.name === 'abcClass' ? (
                    <InputPicker
                        block
                        cleanable={filtro.cleanable}
                        searchable={filtro.searchable}
                        filter={filtro.filter}
                        data={segmentosData[filtro.name] ? segmentosData[filtro.name].lista : []}
                        value={segmentosData[filtro.name] ? segmentosData[filtro.name].value : []}
                        name={filtro.name}
                        placeholder={filtro.placeholder}
                        locale={filtro.locale}
                        onSelect={filtro.onSelect ? (value, i) => handleSelect(value, i, filtro.name) : null}
                        onClean={filtro.onClean ? () => filtro.onClean('') : null}
                        onChange={
                            filtro.onChange
                                ? (value, e) =>
                                      setTimeout(() => {
                                          handleChange(value, e, filtro);
                                      }, 100)
                                : null
                        }
                        onClose={() => handleItensAfetados(filtro)}
                    />
                ) : (
                    <CheckPicker
                        cleanable={filtro.cleanable}
                        searchable={filtro.searchable}
                        filter={filtro.filter}
                        sticky
                        block
                        data={segmentosData[filtro.name] ? segmentosData[filtro.name].lista : []}
                        value={segmentosData[filtro.name] ? segmentosData[filtro.name].value : []}
                        name={filtro.name}
                        placeholder={filtro.placeholder}
                        locale={filtro.locale}
                        onSelect={filtro.onSelect ? (value, i) => handleSelect(value, i, filtro.name) : null}
                        onClean={filtro.onClean ? () => filtro.onClean('') : null}
                        onChange={
                            filtro.onChange
                                ? (value, e) =>
                                      setTimeout(() => {
                                          handleChange(value, e, filtro);
                                      }, 100)
                                : null
                        }
                        onSearch={
                            filtro.onSearch &&
                            filtro.name !== 'searchStoreIds' &&
                            filtro.name !== 'sensibilityType' &&
                            filtro.name !== 'abcClass' &&
                            filtro.name !== 'infoPriceAbcClass' &&
                            filtro.name !== 'infoPriceGlobalAbcClass' &&
                            filtro.name !== 'infoPriceSensibility'
                                ? (search) => {
                                      const newFiltro =
                                          filtro.name !== 'productId' && filtro.name !== 'searchCluster'
                                              ? {
                                                    nivel: filtro.name,
                                                    name: filtro.name,
                                                }
                                              : filtro;

                                      selectSearch(
                                          searchItensSegmento,
                                          searchProduto,
                                          searchStore,
                                          searchCluster,
                                          '',
                                          searchSegmentos,
                                          searchSegmentosSensibilities,
                                          '',
                                          newFiltro,
                                          { query: search },
                                      );
                                  }
                                : null
                        }
                        onClose={() => handleItensAfetados(filtro)}
                    />
                )}
            </FlexboxGrid.Item>
            <FlexboxGrid.Item componentClass={Col} lg={2} md={2}>
                <IconButton className="filtro-dinamico__delete-button" icon={<Icon icon="trash2" />} onClick={() => deleteFiltro(index, filtro.name)} />
            </FlexboxGrid.Item>
        </FlexboxGrid.Item>
    );
};

const mapStateToProps = (store) => ({
    tiposSegmento: store.limitesPrecoCadastroDataReducer.tiposSegmento,
    segmentos: store.limitesPrecoCadastroDataReducer.segmentos,
    segmentosAdicionados: store.limitesPrecoCadastroDataReducer.segmentosAdicionados,
    segmentosData: store.limitesPrecoCadastroDataReducer.segmentosData,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            selectValueSegmento,
            searchSegmentos,
            searchItensSegmento,
            searchSegmentosSensibilities,
            changeValueSegmento,
            changeCacheSegmento,
            searchProduto,
            searchStore,
            searchCluster,
        },
        dispatch,
    );

FiltroCustomizado = connect(mapStateToProps, mapDispatchToProps)(FiltroCustomizado);

export default FiltroCustomizado;
