import React from 'react';
import { Container } from 'rsuite';
import { PageProps } from '../../../../../@types';
import { useCheckUserAllowAccess } from '../../hooks/useCheckUserAllowAccess';
import { HeaderResult } from './components/Header';
import { OptimizationTabs } from './components/OptimizationTabs';
import { ResultGroup } from './components/ResultGroup';
import styles from './OtimizacaoResultado.module.scss';

export type OtimizacaoResultadoProps = React.HTMLAttributes<HTMLElement> & PageProps;

export const OtimizacaoResultado = ({ usuario }: OtimizacaoResultadoProps) => {
    useCheckUserAllowAccess({ usuario });

    return (
        <Container className="main-container ">
            <main className={styles.container}>
                <HeaderResult />
                <ResultGroup />
                <OptimizationTabs />
            </main>
        </Container>
    );
};
