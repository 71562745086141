import { useQuery } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from '../../../../hooks/useSearchParams';
import { optimizationPricesListSelector } from '../../../../reducers/OtimizacaoPrecos';
import { setGridData } from '../../../../reducers/OtimizacaoPrecos/optimizationPricesList';
import { snakeCaseToCamelCase } from '../../../../utils/snakeCaseToCamelCase';
import { getOptimizations } from '../services';

export function useHandleSearchOptimization() {
    const { getParam } = useSearchParams();
    const { filters, pagination, search } = useSelector(optimizationPricesListSelector);
    const dispatch = useDispatch();
    const enabled = true; //search.length > 0 || Object.values(filters).some((item) => item.length > 0);
    const { isLoading: isLoadingSearchOptimizations } = useQuery({
        queryKey: ['optimization-prices-grid', { ...filters }, search, pagination.number, pagination.size, pagination.sortColumn, pagination.orderBy],
        retry: false,
        keepPreviousData: true,
        queryFn: () => {
            return getOptimizations({
                params: {
                    page: pagination?.number || 0,
                    size: pagination.size || 10,
                    order: pagination.orderBy,
                    sort: pagination.sortColumn,
                },
                data: {
                    category: search,
                    products_id: filters.productIds,
                    families_id: filters.productFamilyIds,
                    stores: filters.storeIds,
                    status: filters.status.length > 0 ? (filters.status as unknown as string) : '',
                    responsibles: filters.responsible,
                    categories_level_1: filters.categoryLevel1,
                    categories_level_2: filters.categoryLevel2,
                    categories_level_3: filters.categoryLevel3,
                    categories_level_4: filters.categoryLevel4,
                    categories_level_5: filters.categoryLevel5,
                    categories_level_6: filters.categoryLevel6,
                    categories_level_7: filters.categoryLevel7,
                },
            });
        },
        enabled,
        onSuccess: (data) => {
            const { content, ...paginationRest } = data;
            dispatch(setGridData({ data: snakeCaseToCamelCase(content), pagination: { ...pagination, ...paginationRest } }));
        },
    });

    return {
        handle: {},
        state: { isLoadingSearchOptimizations },
    };
}
