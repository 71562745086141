import React from 'react';
import styles from './ShowErrors.module.scss';
interface Props {
    message: string | undefined;
}
export const ShowErrors = ({ message }: Props) => {
    if (!message) return null;
    return (
        <div className={styles.container}>
            <p className={styles.error}>{message}</p>
        </div>
    );
};
