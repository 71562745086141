import { useQuery } from '@tanstack/react-query';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as services from '../../../../../components/FilterSection/services';
import { AsyncCheckPillProps } from '../../../../../components/Filters';
import { selectorPrecoAtacadoFiltersValue, UPDATE_PRECO_ATACADO_FILTERS_VALUE } from '../../../../../reducers/PrecoAtacado/filters/values';
import { SET_PRECO_ATACADO_SELECT_ALL } from '../../../../../reducers/PrecoAtacado/selectedDatapoints';
import { checkIfFiltersValuesIsEmpty } from '../../../../../utils';

export type FilterObject<K extends string = string> = {
    dataKey: K;
} & AsyncCheckPillProps;

type FILTER_KEYS = 'productIds' | 'productFamilyIds' | 'storeIds' | 'clusters' | 'productBrand' | 'supplier' | string;

type Queries = FilterObject<FILTER_KEYS>[];

const usePrecoAtacadoFilters = () => {
    const dispatch = useDispatch();

    const values = useSelector(selectorPrecoAtacadoFiltersValue);

    const set = useCallback(
        (key: FILTER_KEYS, value: string[]) => {
            dispatch(UPDATE_PRECO_ATACADO_FILTERS_VALUE({ key, value }));
        },
        [dispatch],
    );

    const handleClean = useCallback(
        (key: FILTER_KEYS) => {
            set(key, []);
            dispatch(SET_PRECO_ATACADO_SELECT_ALL(false));
        },
        [dispatch, set],
    );

    const queries: Queries = React.useMemo(
        () => [
            {
                dataKey: 'productIds',
                placeholder: 'Produto',
                queryFn: services.listProducts,
                value: values.productIds,
                onSelect: (value) => set('productIds', value),
                onClean: () => handleClean('productIds'),
            },
            {
                dataKey: 'productFamilyIds',
                placeholder: 'Família',
                queryFn: services.listFamilies,
                value: values.productFamilyIds,
                onSelect: (value) => set('productFamilyIds', value),
                onClean: () => handleClean('productFamilyIds'),
            },
            {
                dataKey: 'storeIds',
                placeholder: 'Loja',
                queryFn: services.listStores,
                value: values.storeIds,
                selectAll: true,
                onSelect: (value) => set('storeIds', value),
                onClean: () => handleClean('storeIds'),
            },
            {
                dataKey: 'clusters',
                placeholder: 'Clusters',
                queryFn: services.listClusters,
                value: values.clusters,
                onSelect: (value) => set('clusters', value),
                onClean: () => handleClean('clusters'),
            },
            {
                dataKey: 'productBrand',
                placeholder: 'Marca',
                queryFn: services.listProductBrands,
                value: values.productBrand,
                onSelect: (value) => set('productBrand', value),
                onClean: () => handleClean('productBrand'),
            },
            {
                dataKey: 'supplier',
                placeholder: 'Fornecedor',
                queryFn: services.listSuppliers,
                value: values.supplier,
                onSelect: (value) => set('supplier', value),
                onClean: () => handleClean('supplier'),
            },
        ],
        [values, set],
    );

    const { data: segments } = useQuery({
        queryKey: ['list-segments'],
        queryFn: services.listaSegmentos,
        initialData: [],
    });

    const segmentsQueries: Queries = React.useMemo(() => {
        return segments.map((segment) => ({
            dataKey: segment.field,
            placeholder: segment.name,
            value: values[segment.field],
            queryFn: (query) => services.listSegment(query, segment.level),
            level: segment.level,
            onSelect: (value) => set(segment.field, value),
            onClean: () => handleClean(segment.field),
        }));
    }, [segments, values, set]);

    const filtersWithSegments = React.useMemo(() => [...queries, ...segmentsQueries], [queries, segmentsQueries]);

    const memoValues = React.useMemo(() => values, [values]);

    const isEmpty = checkIfFiltersValuesIsEmpty(values);

    return {
        list: filtersWithSegments,
        values: memoValues,
        isEmpty,
    } as const;
};

export default usePrecoAtacadoFilters;
