import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { selectorDemandForecastOutputArea } from '../../../../../../../reducers/previsaoDemanda';
import { BigNumbers } from '../BigNumbersArea/BigNumbers';
import { Elasticity } from '../Charts/Elasticity';
import { TableArea } from '../TableArea/TableArea';
import { ScenariosBigNumbers } from './components/Scenarios';

import { ForecastDetails } from '../ForecastDetailsArea/ForecastDetails';

import { useSearchParams } from '../../../../../../../hooks/useSearchParams';
import styles from './ResultScenarios.module.scss';
import { DemandVersusRevenueChart } from './components/DemandVersusRevenueChart';
import { MessageSimulation } from './components/MessageSimulation';
import { SeasonalityChart } from './components/SeasonalityChart';

export interface ReloadStyleProps {
    width: string;
}
export const ResultScenarios = () => {
    const outputArea = useSelector(selectorDemandForecastOutputArea);
    const { hasSimulation, isSearchDone, isMultipleScenario } = outputArea.calculations;
    const { getParam } = useSearchParams();

    const isCompareScenario = getParam('scenario') === 'compare';
    const notShowChart = !isCompareScenario;

    const [reloadStyle, setReloadStyle] = useState<ReloadStyleProps>({ width: '97%' });

    useEffect(() => {
        setTimeout(() => {
            setReloadStyle({ width: '100%' });
        }, 100);
    }, []);

    if (!isSearchDone && !hasSimulation) {
        return <MessageSimulation />;
    }

    return (
        <main className={styles['result-scenario-container']}>
            {isMultipleScenario ? (
                <section className={styles['scenarios-container']}>
                    <div className={styles['big-numbers-container']}>
                        <ForecastDetails />
                        <ScenariosBigNumbers />
                    </div>

                    <div className={styles['charts-wrapper']}>
                        <Elasticity />

                        {notShowChart && (
                            <div className={styles['charts-curve']}>
                                <DemandVersusRevenueChart styleProps={reloadStyle} />
                                {/* <BestPriceChart />   // TODO  /profit-revenue aguardando implementação */}
                            </div>
                        )}

                        <div className={styles['chart-sales']}>
                            <SeasonalityChart styleProps={reloadStyle} />
                        </div>
                    </div>
                </section>
            ) : (
                <section className={styles['charts-container']}>
                    <div className={styles['charts-wrapper']}>
                        <ForecastDetails />
                        <BigNumbers />
                        <div className={styles['charts-curve']}>
                            <DemandVersusRevenueChart styleProps={reloadStyle} />
                            {/* <BestPriceChart />   // TODO  /profit-revenue aguardando implementação */}
                        </div>
                        <div className={styles['chart-sales']}>
                            <SeasonalityChart styleProps={reloadStyle} />
                        </div>
                    </div>

                    <TableArea />
                </section>
            )}
        </main>
    );
};
