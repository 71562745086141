import React, { memo, useDeferredValue } from 'react';
import { Whisper } from 'rsuite';
import { GerenciadorPrecos } from '../../../../../../@types/GerenciadorPrecos';
import {
    InputAddon,
    InputCurrency,
    InputGroup,
} from '../../../../../../components';
import { InputCurrencyProps } from '../../../../../../components/InputCurrency/InputCurrency';
import { VariationBoxIndicator } from '../../../../Negociacoes/pages/NegociacaoFornecedor/components';
import { CompetitivenessLimitInfo } from '../../CompetitivenessLimitInfo';
import BaseCell, { BaseCellProps } from '../BaseCell/BaseCell';

const { MainContainer, SubContainer } = BaseCell;

type RowData = GerenciadorPrecos.RowData;

type NovaMargemCellProps = BaseCellProps & {
    index: number;
    onChangeEvent: InputCurrencyProps['onChangeEvent'];
    handleBlur: (rowData: RowData, index: number) => void;
};

const NovaMargemCell = ({
    rowData,
    index,
    handleBlur,
    onChangeEvent,
    ...props
}: NovaMargemCellProps) => {
    const {
        competitorsPrice,
        maxCpi,
        novaCompetitividade,
        minCpi,
        novaMargem,
        price,
        retailPrice,
    } = rowData;

    const skin = (() => {
        if (!novaCompetitividade || !maxCpi || !minCpi) {
            return 'gray';
        }

        return !!competitorsPrice &&
            competitorsPrice > 0 &&
            ((maxCpi && novaCompetitividade > maxCpi * 100) ||
                (minCpi && novaCompetitividade < minCpi * 100))
            ? 'red'
            : 'gray';
    })();

    const value = useDeferredValue(novaCompetitividade);

    return (
        <BaseCell rowData={rowData} {...props}>
            <MainContainer>
                <InputGroup>
                    <InputCurrency
                        className="nova-competitividade"
                        name="novaCompetitividade"
                        value={value || 0}
                        skin={skin}
                        decimalSeparator=","
                        thousandSeparator="."
                        precision="1"
                        allowEmpty
                        allowNegative={false}
                        onChangeEvent={onChangeEvent}
                        onBlur={(e) => {
                            props.onBlur?.(e);
                            handleBlur(rowData, index);
                        }}
                        disabled={!competitorsPrice}
                    />
                    <Whisper
                        trigger="click"
                        placement="bottomEnd"
                        speaker={
                            <CompetitivenessLimitInfo
                                content={rowData}
                                margin={novaMargem}
                                cpi={novaCompetitividade}
                            />
                        }
                    >
                        <InputAddon>%</InputAddon>
                    </Whisper>
                </InputGroup>
            </MainContainer>
            <SubContainer>
                {!!price && !!competitorsPrice && !!retailPrice ? (
                    <VariationBoxIndicator
                        className="box-indicator"
                        value={
                            (price / competitorsPrice) * 100 -
                            (retailPrice / competitorsPrice) * 100
                        }
                    />
                ) : (
                    <>--</>
                )}
            </SubContainer>
        </BaseCell>
    );
};

export default memo(NovaMargemCell);
