import { useMutation, useQuery } from '@tanstack/react-query';
import * as service from '../services';
import { useEditBaseTableStore } from '../EditBaseTable.store';
import { useState } from 'react';
import { EditBaseTableItemDataType, TableRequestType } from '../types';

export const useEditBaseTableList = () => {
    const { setEditBaseTableState, custoFabricacaoBody, selectAllTable } = useEditBaseTableStore();
    const [ editBaseTableList, setEditBaseTableList ] = useState({ page: 0, size: 20 })
    const [ tableData, setTableData ] = useState({})

    const getTableList = async (data: TableRequestType) => {
        const resp = await service.getEditBaseTableListService(data);
        return resp;
    };

    const updateTableData = (data) => {
        const newTable = tableData?.data?.content.map((item) => item.produto.codigoInternoProduto === data.data.produto.codigoInternoProduto
            ? data.data
            : item
        );
        setTableData({...tableData, data: {...tableData.data, content: newTable}});
    }

    const {
        isLoading, isError, data, error, isFetching, refetch,
    } = useQuery(
        {
            queryKey: ['edit-base-table-list', editBaseTableList],
            queryFn: () => getTableList(editBaseTableList),
            retry: false,
            onSuccess: (data) => {
                setTableData(data)
                if (selectAllTable) {
                    setEditBaseTableState('productItemSelectedList', data.data.content)
                }
            },
        },
    );

    const mutation = useMutation({
        mutationFn: service.saveCustoFabricacao,
        onSuccess: (data) => {
            updateTableData(data);
        },
    });

    const handleSavePrecosTabela = (
        name: string,
        event: React.FocusEvent<HTMLInputElement>,
        productData: EditBaseTableItemDataType,
    ) => {
        const value = parseFloat(event.target.value.replace(/\./g, '').replace(',', '.'));
        const precoMedio = productData.parametros.precoMedio.valorAtual
        const novoPrecoTabela = productData.parametros.novoPrecoTabela.valorNovo
        const custoFabricacao = productData.parametros.custoFabricacao
        const updateCustoFabricacao = {
            ...custoFabricacaoBody,
            idRascunhoProduto: productData.id,
            precoMedio: name === 'precoMedio' ? value : precoMedio,
            novoPrecoTabela: name === 'novoPrecoTabela' ? value : novoPrecoTabela,
            custoFabricacao: name === 'custoFabricacao' ? value : custoFabricacao
        }

        if (name === 'precoMedio' && value.toFixed(2) === precoMedio.toFixed(2)) {
            return
        }

        if (name === 'novoPrecoTabela' && value.toFixed(2) === novoPrecoTabela.toFixed(2)) {
            return
        }

        if (name === 'custoFabricacao' && value.toFixed(2) === custoFabricacao.toFixed(2)) {
            return
        }

        mutation.mutateAsync(updateCustoFabricacao);
    }

    const handleBlurCustoFabricacao = (event: React.FocusEvent<HTMLInputElement>, productData: EditBaseTableItemDataType) => {
        const newData = tableData?.data.content.map(
            (item) => item.id === productData.id
                ? {...item, parametros: {...item.parametros, custoFabricacao: Number(event.target.value.replace(',', '.'))}}
                : item
        )
        setTableData({...tableData, data: {...tableData.data, content: newData}})
        handleSavePrecosTabela('custoFabricacao', event, productData)
    }

    const handleBlurPrecoMedio = (event: React.FocusEvent<HTMLInputElement>, productData: EditBaseTableItemDataType) => {
        const newData = tableData?.data.content.map(
            (item) => item.id === productData.id
                ? {
                    ...item, parametros: {
                        ...item.parametros,
                        precoMedio: {
                            ...item.parametros.precoMedio,
                            valorAtual: Number(event.target.value.replace(',', '.'))
                        }
                    }}
                : item
        )
        setTableData({...tableData, data: {...tableData.data, content: newData}})
        handleSavePrecosTabela('precoMedio', event, productData)
    }

    const handleBlurNovoPrecoTabela = (event: React.FocusEvent<HTMLInputElement>, productData: EditBaseTableItemDataType) => {
        const newData = tableData?.data.content.map(
            (item) => item.id === productData?.id
                ? {
                    ...item, parametros: {
                        ...item.parametros,
                        novoPrecoTabela: {
                            ...item.parametros.novoPrecoTabela,
                            valorNovo: Number(event.target.value.replace(',', '.'))
                        }
                    }}
                : item
        )
        setTableData({...tableData, data: {...tableData.data, content: newData}})
        handleSavePrecosTabela('novoPrecoTabela', event, productData)
    }

    const handleChangeEditBaseTableListPage = (value: number) => {
        setEditBaseTableList({...editBaseTableList, page: value - 1})
    }

    const handleChangeEditBaseTableListLength = (value: number) => {
        setEditBaseTableList({page: 0, size: value})
    }

    return {
        isLoading,
        isError,
        editTableBaseData: data,
        error,
        isFetching,
        tableData,
        setTableData,
        handleGetEditTableList: refetch,
        setEditBaseTableList,
        handleSavePrecosTabela,
        handleBlurCustoFabricacao,
        handleBlurPrecoMedio,
        handleBlurNovoPrecoTabela,
        handleChangeEditBaseTableListPage,
        handleChangeEditBaseTableListLength
    };
};
