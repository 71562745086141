import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'rsuite';
import { UpdatedHeader } from '../../../../../components/UpdatedHeader';
import styles from './CurrentBaseTable.module.scss';
import {
    FiltersArea,
    MainContainer,
    TableActionArea,
} from '../../components';
import { TableCurrentBase } from './components';
import { ModalCustosProduto } from '../EditBaseTable/components';
import { useTabelaBaseAtualList } from './hooks/useTabelaBaseAtualList';
import { useCurrentBaseTableStore } from './CurrentBaseTable.store';
import { useHeaderUpdateTimeView } from '../EditBaseTable/hooks/useHeaderUpdateTimeView';
import { downloadTabelaBaseAtual } from './services';

export const CurrentBaseTable = () => {
    const history = useHistory();

    const {
        setCurrentBaseTableState,
        tabelaBaseItemsSelectedList,
        openModalProductCosts,
        productItemData,
        currentBaseTableSelectAll,
    } = useCurrentBaseTableStore()

    const { updateData } = useHeaderUpdateTimeView();
    const {
        tabelaBaseAtualData,
        handleChangeTabelaBaseAtualPage,
        handleChangeTabelaBaseAtualLength
    } = useTabelaBaseAtualList()

    const handleClickSelectAllProducts = (all: boolean) => {
        setCurrentBaseTableState('tabelaBaseItemsSelectedList', all ? tabelaBaseAtualData?.data?.content : [])
        setCurrentBaseTableState('currentBaseTableSelectAll', all)
    }

    const handleClickDownload = () => {
        const ids = tabelaBaseItemsSelectedList.map((item) => item.produto.id)
        downloadTabelaBaseAtual(ids)
    }

    const handleCheckboxAddSelectedProductItems = (product, checked) => {
        const addProduct = checked
            ? [...tabelaBaseItemsSelectedList, product]
            : tabelaBaseItemsSelectedList.filter((item) => item.produto.id !== product.produto.id);

        setCurrentBaseTableState('tabelaBaseItemsSelectedList', addProduct)
        setCurrentBaseTableState('currentBaseTableSelectAll', false)
    }

    return (
        <MainContainer>
            <div className={styles.flex}>
                <UpdatedHeader title="Tabela base atual" date={updateData?.aplicadoEm} />
                <Button
                    className={styles['btn-edit-table']}
                    onClick={() => history.push('/ipa/plataforma-editar-tabela-base')}
                >
                    Editar tabela base
                </Button>
            </div>
            {/* <FiltersArea /> */}
            <TableActionArea
                data={tabelaBaseAtualData?.data}
                selectedItems={tabelaBaseItemsSelectedList}
                selectAll={currentBaseTableSelectAll}
                onClickSelectAll={handleClickSelectAllProducts}
            >
                <Button
                    className={styles['btn-apply']}
                    appearance="primary"
                    disabled={!tabelaBaseItemsSelectedList.length}
                    onClick={handleClickDownload}
                >
                    Download
                </Button>
            </TableActionArea>
            <TableCurrentBase
                data={tabelaBaseAtualData?.data}
                onChangeCheckbox={handleCheckboxAddSelectedProductItems}
                onChangePage={handleChangeTabelaBaseAtualPage}
                onChangeLength={handleChangeTabelaBaseAtualLength}
            />
            <ModalCustosProduto
                show={openModalProductCosts}
                title="Custos gerais da tabela base"
                subtitle="Defina os custos gerais para a formação da tabela base de preços. Esses valores refletirão em todos os itens da tabela"
                cancelButton="Fechar"
                disabledCustosVenda
                disabledImpostos
                disabledOutrasDespesas
                data={productItemData}
                onCancel={() => setCurrentBaseTableState('openModalProductCosts', false)}
            />
        </MainContainer>
    );
};
