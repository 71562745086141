import React, { useEffect, useState } from 'react';
import { Button, Panel } from 'rsuite';
import DeleteIcon from '@material-ui/icons/Delete';
import { ContentContainer, LoadingSpinerArea, NotificationMessage } from '../../../../../components';
import { MainContainer, ResultsFoundActionArea } from '../../components';
import {
    NewPriceTableDefine,
    NewPriceTableForm,
    PaymentTermAndDiscount,
    NewPriceTableTable,
    GeneralRules,
    PriceLimits,
    TablePanelHeader
} from './components';
import * as service from './services'
import styles from './CreateComercialTable.module.scss';
import { useCreateComercialTableStore } from './CreateComercialTable.store';
import { useHistory } from 'react-router-dom';

export const CreateComercialTable = () => {
    const [panelHeaderStatus, setPanelHeaderStatus] = useState(false);
    const history = useHistory();

    const { createComercialTableBody, isCreateComercialTable, setCreateComercialTableState, reset } = useCreateComercialTableStore();

    const canCreateTable = createComercialTableBody.produtosBase?.length &&
        createComercialTableBody.local !== '' &&
        createComercialTableBody.canal !== '' &&
        createComercialTableBody.subCanal !== '' &&
        createComercialTableBody.regras[0].percentualIdeal > 0

    const handleSaveTabelaComercial = async (isCreateComercialTable: boolean) => {
        if (canCreateTable) {
            const successText = isCreateComercialTable ? 'Tabela criada com sucesso' : 'Tabela editada com sucesso'
            const {idTabelaBase , ...editComercialTableBody} = createComercialTableBody
            const res = isCreateComercialTable
                ? await service.createSaveTabelaComercial(createComercialTableBody)
                : await service.saveEditTabelaComercial(editComercialTableBody)

            if (res.status === 200) {
                NotificationMessage(
                    'success',
                    5000,
                    successText,
                );
                history.push('/ipa/gerenciar-tabela-comercial')
            }
        } else {
            NotificationMessage(
                'error',
                5000,
                'Verifique se os campos obrigatórios estão preenchidos',
            );
        }
    }

    const handlePanelAction = () => {
        setPanelHeaderStatus(!panelHeaderStatus);
    };

    useEffect(() => {
        return () => {
            reset()
        }
    }, [reset])

    return (
        <MainContainer>
            <LoadingSpinerArea area="save-tabela-comercial" />
            <NewPriceTableForm />
            <NewPriceTableDefine />
            {/* <PaymentTermAndDiscount /> */}
            <GeneralRules />
            <PriceLimits />
            {/* <Panel
                className={styles['table-panel']}
                header={
                    <TablePanelHeader
                        title="Detalhamento por produto"
                        subtitle="Defina regras específicas por produto"
                        status={panelHeaderStatus}
                    />
                }
                collapsible
                onSelect={handlePanelAction}
            >
                <ResultsFoundActionArea value={23} className={styles['table-header']}>
                    <Button className={styles['btn-delete']} appearance="default">
                        <DeleteIcon />
                    </Button>
                    <Button className={styles['btn-change']} appearance="ghost">
                        Alterar
                    </Button>
                </ResultsFoundActionArea>
                <NewPriceTableTable />
            </Panel> */}
            <ContentContainer className={styles['save-wrapper']}>
                <Button
                    className={styles['btn-save']}
                    appearance="primary"
                    onClick={() => handleSaveTabelaComercial(isCreateComercialTable)}
                >
                    Salvar tabela
                </Button>
            </ContentContainer>
        </MainContainer>
    );
};
