import React, { useState } from 'react';
import { TabelaBaseFormBox } from '../TabelaBaseFormBox';
import { LabelInputGroup } from '../LabelInputGroup';
import styles from './CustoFabricacaoBox.module.scss';

type CustoFabricacaoBoxProps = {
    custoFabricacaoData?: number,
    totalCustoFabricacao?: number,
    disabled?: boolean,
    onChange?: (name: string, value: number) => void,
}

export const CustoFabricacaoBox = ({ custoFabricacaoData, totalCustoFabricacao, disabled, onChange }: CustoFabricacaoBoxProps) => {
    const [ custoFabricacao, setCustoFabricacao ] = useState(custoFabricacaoData)

    const handleCustoFabricacaoChange = (name: string, value: number) => {
        setCustoFabricacao(value)
        onChange?.(name, value)
    }

    return (
        <TabelaBaseFormBox
            className={styles['custo-fabricacao-box']}
            totalValue={totalCustoFabricacao?.toFixed(2).toString().replace('.', ',')}
        >
            <LabelInputGroup
                label="Custo de"
                subLabel="fabricação"
                symbol="R$"
                decimalSeparator=","
                disabled={disabled}
                value={custoFabricacao}
                onChangeEvent={(_e: any, _s: string, number: number) =>
                    handleCustoFabricacaoChange('custoFabricacao', number)
                }
            />
        </TabelaBaseFormBox>
    );
};
