import { useQuery } from '@tanstack/react-query';
import _ from 'lodash';
import { IMargemObjetivaFilters, useMargemObjetivaFilters, useMargemObjetivaTable } from '.';
import { listClusters, listOwners, listSegment, listStores } from '../../../../components/FilterSection/services';
import { checkIfIsLoading } from '../../../../utils';
import { useDataUltimaIntegracao } from '../../RevisaoPrecos/hooks';
import { getMargemObjetivaSegmento } from '../pages/MargemObjetiva/services';

type FilterKeys = 'category' | 'storeId' | 'clusterIds' | 'editedBy';

const FILTERS: IMargemObjetivaFilters<FilterKeys> = [
    {
        key: 'storeId',
        placeholder: 'Loja',
        queryFn: listStores,
        textLoading: 'Carregando produtos...',
        selectAll: true,
    },
    {
        key: 'clusterIds',
        placeholder: 'Cluster',
        queryFn: listClusters,
        textLoading: 'Carregando clusters...',
        selectAll: false,
    },
    {
        key: 'editedBy',
        placeholder: 'Responsável',
        queryFn: listOwners,
        textLoading: 'Carregando responsáveis...',
    },
];

export const useMargemObjetiva = () => {
    const { parsedDate: lastUpdatedDate } = useDataUltimaIntegracao();

    const { data: segment, fetchStatus: isLoadingSegment } = useQuery({
        queryKey: ['ipa/estrategia/margem-objetiva/segmento'],
        queryFn: getMargemObjetivaSegmento,
        retry: false,
        initialData: {
            name: 'Categoria',
            level: 4,
            field: 'categoryLevel4',
        },
    });

    const filtersWithSegment: IMargemObjetivaFilters<FilterKeys> = segment
        ? _.unionBy(
              [
                  {
                      key: 'category',
                      placeholder: segment.name,
                      queryFn: (query) => listSegment(query, segment.level),
                      level: segment.level,
                      textLoading: 'Carregando produtos...',
                  },
                  ...FILTERS,
              ],
              'key',
          )
        : FILTERS;

    const [filtersState, filterList, { onClearAll }] = useMargemObjetivaFilters<FilterKeys>(filtersWithSegment);

    const table = useMargemObjetivaTable({
        segment,
        filters: filtersState,
    });

    return {
        lastUpdatedDate,
        filterList,
        onClearAll,
        segment,
        isLoadingSegment: checkIfIsLoading(isLoadingSegment),
        table,
    } as const;
};
