import classNames from 'classnames';
import React, { useCallback, useMemo } from 'react';
import CurrencyFormat from 'react-currency-format';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { selectorGetClusterStoreModalIsOpen, selectorGetFamilyModalIsOpen } from '../../../../../../reducers/gerenciadorPrecos/datapointExhibitionType';
import {
    ADD_GERENCIADOR_EXPANDED_ROW_OPTION,
    REMOVE_GERENCIADOR_EXPANDED_ROW_OPTION,
    RESET_GERENCIADOR_EXPANDED_ROW,
    SET_GERENCIADOR_EXPANDED_ROW_KEY,
    selectorExpandedRowKey,
} from '../../../../../../reducers/gerenciadorPrecos/expandedRowKey';
import { CompetitivenesIcon } from '../../CompetitivenesIcon';
import { LinkButton } from '../../LinkButton';
import { BaseCell } from '../BaseCell';
import { BaseCellProps } from '../BaseCell/BaseCell';
import styles from './CompetitorPriceCell.module.scss';

const { MainContainer, SubContainer } = BaseCell;

export type CompetitorPriceCellProps = BaseCellProps;

const CompetitorPriceCell = ({
    className,
    rowData,
    ...props
}: CompetitorPriceCellProps) => {
    const { productsToBePricedId, competitorsPrice, competitivenessIndicator } =
        rowData;

    const dispatch = useDispatch();

    const { key, options } = useSelector(selectorExpandedRowKey);

    const isExpanded = useMemo(() => {
        return key === productsToBePricedId && options.includes('COMPETITORS');
    }, [key, options, productsToBePricedId]);

    const handleExpandCompetitorsRow = useCallback(() => {
        if (key !== productsToBePricedId) {
            dispatch(RESET_GERENCIADOR_EXPANDED_ROW());
        }

        if (isExpanded) {
            dispatch(REMOVE_GERENCIADOR_EXPANDED_ROW_OPTION('COMPETITORS'));
            return;
        }

        dispatch(SET_GERENCIADOR_EXPANDED_ROW_KEY(productsToBePricedId));
        dispatch(ADD_GERENCIADOR_EXPANDED_ROW_OPTION('COMPETITORS'));
    }, [dispatch, isExpanded, key]);

    const getFamilyModalIsOpen = useSelector(
        selectorGetFamilyModalIsOpen,
    );

    const getProductStoreCluster = useSelector(selectorGetClusterStoreModalIsOpen);


    return (
        <BaseCell
            className={classNames(styles.container, className)}
            rowData={rowData}
            {...props}
        >
            <MainContainer>
                {competitorsPrice ? (
                    <CurrencyFormat
                        fixedDecimalScale
                        decimalScale={2}
                        value={competitorsPrice ?? null}
                        displayType="text"
                        prefix="R$ "
                        decimalSeparator=","
                        thousandSeparator="."
                    />
                ) : (
                    '--'
                )}
                <CompetitivenesIcon
                    type={
                        competitivenessIndicator ||
                        (competitorsPrice && 'RULE_CPI_AVG') ||
                        null
                    }
                />
            </MainContainer>
            {!getFamilyModalIsOpen && competitorsPrice && !getProductStoreCluster && (
                <SubContainer>
                    <LinkButton onClick={handleExpandCompetitorsRow}>
                        {isExpanded ? (
                            <>
                                <MdKeyboardArrowUp size={10} />
                                <p>Fechar</p>
                            </>
                        ) : (
                            <>
                                <MdKeyboardArrowDown size={10} />
                                <p>Abrir</p>
                            </>
                        )}
                    </LinkButton>
                </SubContainer>
            )}
        </BaseCell >
    );
};

export default CompetitorPriceCell;
