import { format, parseISO } from 'date-fns';
import { CustosVendaDataType, DespesaVendaDataType, EditBaseTableItemDataType, ImpostosDataType } from "./pages/EditBaseTable/types";
import { ComercialTablePriceLimitAndRulesTypes } from './pages/ManageComercialTable/types';

export const filterTitle = ['PRODUTO', 'LOCAL', 'CANAL DE VENDA'];

export const paymentTermsData = [
    {
        label: 'À vista',
        value: 'AVISTA',
        percent: '(0,0%)',
    },
    {
        label: '15 dias',
        value: '15DIAS',
        percent: '(+0,75%)',
    },
    {
        label: '30 dias',
        value: '30DIAS',
        percent: '(+1,25%)',
    },
    {
        label: '45 dias',
        value: '45DIAS',
        percent: '(+1,75%)',
    },
    {
        label: '60 dias',
        value: '60DIAS',
        percent: '(+2,25%)',
    },
    {
        label: '75 dias',
        value: '75DIAS',
        percent: '(+2,75%)',
    },
];

export const discountData = [
    {
        label: '05%',
        value: '05',
    },
    {
        label: '10%',
        value: '10',
    },
    {
        label: '15%',
        value: '15',
    },
    {
        label: '20%',
        value: '20',
    },
    {
        label: '25%',
        value: '25',
    },
    {
        label: '30%',
        value: '30',
    },
];

export const tableLengthMenu = [
    {
        value: 20,
        label: 20,
    },
    {
        value: 40,
        label: 40,
    },
];

export const canaisFilterLabel = {
    REDES: 'Redes',
    VENDA_DIRETA: 'Venda direta',
    INDIRETA: 'Venda indireta',
    INTERNA: 'Venda interna'
}

export const tipoRegraFilterLabel = {
    PERCENTUAL_TABELA_BASE: 'Percentual sobre a tabela base',
}

export const tipoLimiteFilterLabel = {
    PERCENTUAL_TABELA_BASE: 'Percentual sobre a tabela base',
}

export const priceStatusIndicator = (data: EditBaseTableItemDataType) => {
    const status = data.parametros.novoPrecoTabela.statusPreco
    const indicator = status === 'EDITADO' ? 'edit-indicator' : 'applied-indicator'
    return indicator;
}

const insertZeroInDateString = (number) => (number > 9 ? number : `0${number}`);

export const handleDateFormat = (date) => {
    const dt = new Date(date);
    const year = dt.getUTCFullYear();
    const month = dt.getUTCMonth() + 1;
    const day = dt.getDate();

    return `${year}-${insertZeroInDateString(month)}-${insertZeroInDateString(day)}`;
};

export const promoDateFormat = (date: string) => {
    const data = parseISO(date);
    const dataFormatada = format(data, 'dd/MM');
    return dataFormatada;
}

export const promoDueDateFormat = (date) => {
    const dt = new Date(date);
    const year = dt.getUTCFullYear();
    const month = dt.getUTCMonth() + 1;
    const day = dt.getUTCDate();

    return `${insertZeroInDateString(day)}/${insertZeroInDateString(month)}/${year}`;
};

export const editComercialTableObj = (data: ComercialTablePriceLimitAndRulesTypes) => {
    const obj = {
        nome: data.tabela.nome,
        local: data.tabela.dadosVenda.local,
        ufs: data.tabela.ufs,
        canal: data.tabela.dadosVenda.canal,
        subCanal: data.tabela.dadosVenda.subCanal,
        regras: [
            {
                tipo: "PERCENTUAL_TABELA_BASE",
                percentualIdeal: data.regras[0].percentualIdeal,
                descontoMaximo: data.regras[0].descontoMaximo
            }
        ],
        limites: [
            {
                tipo: "PERCENTUAL_TABELA_BASE",
                percentualMinimo: data.limites[0].percentualMinimo,
                percentualMaximo: data.limites[0].percentualMaximo
            }
        ],
        produtosBase: [
            {
                idProduto: 0,
            }
        ],
        idTabelaComercial: data.tabela.id,
    }

    return obj;
}
