import { combineReducers } from '@reduxjs/toolkit';
import demandForecastSavedScenariosSlice, { DemandForecastSimulationsProps } from './demandForecastSimulations';
import filterAreaSlice, { FiltersStateProps } from './filterArea';
import inputsSlice, { InputStateProps } from './inputArea';
import outputSlice, { OutputAreaProps } from './outputArea';
import outputSavedSimulationsSlice, { OutputSavedSimulationProps } from './outputSavedSimulations';

export type DemandForecastStateProps = {
    simulatorArea: InputStateProps;
    filtersArea: FiltersStateProps;
    outputArea: OutputAreaProps;
    outputSavedSimulations: OutputSavedSimulationProps;
    demandForecastSavedScenarios: DemandForecastSimulationsProps;
};

const rootReducer = combineReducers({
    simulatorArea: inputsSlice,
    filtersArea: filterAreaSlice,
    outputArea: outputSlice,
    outputSavedSimulations: outputSavedSimulationsSlice,
    demandForecastSavedScenarios: demandForecastSavedScenariosSlice,
});

export const selectorDemandForecastSimulatorArea = (state: Record<'demandForecastReducer', DemandForecastStateProps>) =>
    state.demandForecastReducer.simulatorArea;

export const selectorDemandForecastFiltersArea = (state: Record<'demandForecastReducer', DemandForecastStateProps>) => state.demandForecastReducer.filtersArea;

export const selectorDemandForecastOutputArea = (state: Record<'demandForecastReducer', DemandForecastStateProps>) => state.demandForecastReducer.outputArea;

export const selectorDemandForecastOutputSavedSimulations = (state: Record<'demandForecastReducer', DemandForecastStateProps>) =>
    state.demandForecastReducer.outputSavedSimulations;

export const selectorDemandForecastDemandForecastSavedScenarios = (state: Record<'demandForecastReducer', DemandForecastStateProps>) =>
    state.demandForecastReducer.demandForecastSavedScenarios;

export type DemandForecastType = Record<'demandForecastReducer', DemandForecastStateProps>;

export default rootReducer;
