import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import React from 'react';
import { Button, Col, FlexboxGrid, Grid, Modal, Row } from 'rsuite';
import ValueCard from '../../SimulacoesAnalises/PrevisaoDemanda/Components/ValueCard';

const ModalPrevisaoDemanda = ({ handleSalvarSimulacao, goToPrevisao, show, closeModal, previsao }) => (
    <Modal className="modal-previsao-demanda" show={show} onHide={closeModal} size="md">
        <Modal.Header>
            <Modal.Title>
                <FlexboxGrid>
                    <FlexboxGrid.Item colspan={24}>
                        <h1>
                            Previsão de Demanda <span>BETA</span>
                        </h1>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={24}>
                        <h2>Previsão realizada para os próximos 7 dias</h2>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Grid fluid>
                {previsao?.base_price_scenario &&
                'demand_forecast' in previsao.base_price_scenario &&
                previsao?.new_price_scenario &&
                'demand_forecast' in previsao.new_price_scenario ? (
                    <>
                        <Row>
                            <Col xs={8}>
                                <ValueCard
                                    title="Previsão Venda"
                                    currentValue={previsao?.base_price_scenario?.demand_forecast}
                                    value={previsao?.new_price_scenario?.demand_forecast}
                                />
                            </Col>
                            <Col xs={8}>
                                <ValueCard
                                    title="Previsão Receita"
                                    currentValue={previsao?.base_price_scenario?.revenue_forecast}
                                    value={previsao?.new_price_scenario?.revenue_forecast}
                                    type="value"
                                />
                            </Col>
                            <Col xs={8}>
                                <ValueCard
                                    title="Previsão Lucro"
                                    currentValue={previsao?.base_price_scenario?.profit_forecast}
                                    value={previsao?.new_price_scenario?.profit_forecast}
                                    type="value"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={8}>
                                <ValueCard
                                    title="Variação venda"
                                    value={(previsao?.scenarios_variation?.demand_forecast || 0) * 100}
                                    type="variation"
                                    color={
                                        previsao?.scenarios_variation?.demand_forecast > 0
                                            ? 'positive'
                                            : previsao?.scenarios_variation?.demand_forecast < 0
                                              ? 'negative'
                                              : ''
                                    }
                                />
                            </Col>
                            <Col xs={8}>
                                <ValueCard
                                    title="Variação receita"
                                    value={(previsao?.scenarios_variation?.revenue_forecast || 0) * 100}
                                    type="variation"
                                    color={
                                        previsao?.scenarios_variation?.revenue_forecast > 0
                                            ? 'positive'
                                            : previsao?.scenarios_variation?.revenue_forecast < 0
                                              ? 'negative'
                                              : ''
                                    }
                                />
                            </Col>
                            <Col xs={8}>
                                <ValueCard
                                    title="Variação lucro"
                                    value={(previsao?.scenarios_variation?.profit_forecast || 0) * 100}
                                    type="variation"
                                    color={
                                        previsao?.scenarios_variation?.profit_forecast > 0
                                            ? 'positive'
                                            : previsao?.scenarios_variation?.profit_forecast < 0
                                              ? 'negative'
                                              : ''
                                    }
                                />
                            </Col>
                        </Row>
                    </>
                ) : (
                    <Row>
                        <Col xs={24}>
                            <div className="grafico-indisponivel">
                                <WarningRoundedIcon
                                    style={{
                                        fontSize: '24px',
                                    }}
                                />
                                <p>
                                    Não temos dados suficientes para calcular
                                    <br /> a previsão de demanda desse produto.
                                </p>
                            </div>
                        </Col>
                    </Row>
                )}
            </Grid>
        </Modal.Body>
        {previsao?.base_price_scenario &&
        'demand_forecast' in previsao.base_price_scenario &&
        previsao?.new_price_scenario &&
        'demand_forecast' in previsao.new_price_scenario ? (
            <Modal.Footer>
                <Button title="Ver resultados completos" appearance="link" onClick={goToPrevisao}>
                    Ver resultados completos
                </Button>
            </Modal.Footer>
        ) : null}
    </Modal>
);

export default ModalPrevisaoDemanda;
