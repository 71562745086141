import { combineReducers } from '@reduxjs/toolkit';
import { RootState } from '../../../@types/RootState';
import modals from './modals';

const rootReducer = combineReducers({ modals });

export type IPAFormStoresAndClusters = ReturnType<typeof rootReducer>;

export const selectorIpaFormStoresAndClusters = (state: RootState) => {
    return state.reducerIPA.storesAndClusters;
};

export default rootReducer;
