import React, { memo } from 'react';
import { MdAccountTree, MdStore } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown, Tooltip, Whisper } from 'rsuite';
import { RootState } from '../../../../../@types/RootState';
import { Layout } from '../../../../../components';
import { SET_DATA_POINTS_EXHIBITION_TYPE } from '../../../../../reducers/gerenciadorPrecos/datapointExhibitionType';
import { RESET_QUICK_ACTION_FILTER } from '../../../../../reducers/gerenciadorPrecos/quickActionsFilter';
import { useDataUltimaIntegracao } from '../../hooks';
import { DataUltimaIntegracao } from '../DataUltimaIntegracao';
import { FixedLayoutToggleButton } from './FixedLayoutToggleButton';
import styles from './Gerenciador.module.scss';

const GerenciadorHeader = () => {
    const dispatch = useDispatch();
    const datapointExhibitionType = useSelector((state: RootState) => {
        return state.datapointExhibitionType.datapointExhibitionType;
    });

    const { data } = useDataUltimaIntegracao();

    const basicConfig = useSelector((state: RootState) => {
        return state.reducerIPA.basicConfig;
    });

    const clusterVisionEnabled = basicConfig?.competitorsDays.pricing_preferences === "CLUSTER"

    const handleSelect = (value: 'PRODUCT' | 'FAMILY' | 'PRODUCT_CLUSTER') => {
        if (value === 'FAMILY') {
            dispatch(RESET_QUICK_ACTION_FILTER());
        }
        dispatch(SET_DATA_POINTS_EXHIBITION_TYPE(value));
    };

    const title = datapointExhibitionType === 'PRODUCT' || datapointExhibitionType === 'PRODUCT_CLUSTER' ? 'Produto' : 'Família';

    const groupByMap = {
        FAMILY: "Loja",
        PRODUCT_CLUSTER: "Cluster",
        PRODUCT: "Loja"
    };

    const selectedGroupBy = groupByMap[datapointExhibitionType];

    return (
        <div className={styles['gerenciador-header']}>
            <span className={styles['gerenciador-header__content']}>
                <Layout.Title>Gerenciador de preços</Layout.Title>
                <DataUltimaIntegracao />
            </span>

            <span className={styles['gerenciador-header__action-container']}>
                <FixedLayoutToggleButton />
                {clusterVisionEnabled && data?.engineStatus !== 'NOT_UPDATED' && (
                    <Whisper placement="top" speaker={<Tooltip>Agrupar por</Tooltip>}>
                        <Dropdown title={selectedGroupBy} icon={<MdStore className={styles['gerenciador-header__icon']} />}>
                            <Dropdown.Item className={styles['gerenciador-header__item']} onSelect={() => handleSelect('PRODUCT')}>
                                Loja
                            </Dropdown.Item>
                            <Dropdown.Item className={styles['gerenciador-header__item']} onSelect={() => handleSelect('PRODUCT_CLUSTER')}>
                                Cluster de lojas
                            </Dropdown.Item>
                        </Dropdown>
                    </Whisper>
                )}
                <Whisper placement="bottom" speaker={<Tooltip>Precificar por</Tooltip>}>
                    <Dropdown title={title} icon={<MdAccountTree className={styles['gerenciador-header__icon']} />}>
                        <Dropdown.Item className={styles['gerenciador-header__item']} onSelect={() => handleSelect('PRODUCT')}>
                            Produto
                        </Dropdown.Item>
                        {datapointExhibitionType !== 'PRODUCT_CLUSTER' && (
                            <Dropdown.Item className={styles['gerenciador-header__item']} onSelect={() => handleSelect('FAMILY')}>
                                Família
                            </Dropdown.Item>
                        )}
                    </Dropdown>
                </Whisper>
            </span>
        </div>
    );
};

export default memo(GerenciadorHeader);
