import { useMutation, useQueryClient } from '@tanstack/react-query';
import * as service from '../services';
import { useManageComercialTableStore } from '../ManageComercialTable.store';
import { NotificationMessage } from '../../../../../../components';

export const useComercialPriceApply = () => {
    const queryClient = useQueryClient();

    const {
        comercialPriceApplyList,
        comercialTableItem,
        setManageComercialTableState
    } = useManageComercialTableStore();

    const priceApplySuccess = () => {
        NotificationMessage(
            'success',
            5000,
            'Preço aplicado com sucesso',
        );

        setManageComercialTableState('comercialProductItemSelectedList', []);
        queryClient.invalidateQueries(['edit-comercial-table-list']); // chama e atualiza o atualizadoEm do header
        queryClient.invalidateQueries(['comercial-table-product-list']); // chama e atualiza a lista da tabela comercial
    }

    const mutation = useMutation({
        mutationFn: () => service.applyComercialPrices(comercialPriceApplyList),
        onSuccess: priceApplySuccess,
    });

    const mutationAll = useMutation({
        mutationFn: () => service.selectAllComercialAplicarPrecos(comercialTableItem?.id),
        onSuccess: priceApplySuccess,
    });

    return {
        handleConfirmPriceApply: mutation.mutateAsync,
        handleConfirmPriceApplyAll: mutationAll.mutateAsync,
    };
};
