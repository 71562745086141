import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'rsuite';
import { ModalSpeakWithSpecialist } from '../../../../../components/ModalSpeakWithSpecialist';

import gifFree from '../../../../../assets/image/banner_free.gif';
import { sendLead } from '../utils';

const ModalBanner = ({ show, onCancel, usuario }) => {
    const [showCTA, setShowCTA] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            onCancel();
        }, 13000);
    }, show);

    const handleShowCTA = () => {
        setShowCTA(!showCTA);
    };

    const confirmCTA = () => {
        setShowCTA(false);
        sendLead(usuario, 'upsell_infopanel');
    };

    return (
        <>
            <Modal backdrop="static" show={show} className="modal-banner-component">
                <Modal.Header onHide={onCancel} />
                <p className="top-text">
                    Não espere mais! <strong>Aumente sua</strong> <br />
                    <strong>rentabilidade</strong> com a pesquisa de preço <br />
                    mais completa dos seus concorrentes.
                </p>
                <div className="image-area">
                    <img src={gifFree} alt="Gif" />
                </div>
                <Button className="btn-more" appearance="primary" onClick={handleShowCTA}>
                    Quero saber mais
                </Button>
            </Modal>
            <ModalSpeakWithSpecialist
                show={showCTA}
                title="Explore as melhores estratégias e aumente sua rentabilidade."
                subtitle="Nossos especialistas estão à disposição para ajudar você."
                onClick={confirmCTA}
                onCancel={handleShowCTA}
            />
        </>
    );
};

export default ModalBanner;
