import { Slot } from '@radix-ui/react-slot';
import classNames from 'classnames';
import React, { ComponentProps, forwardRef } from 'react';
import styles from './Layout.module.scss';

export type FormProps = ComponentProps<'form'> & { asChild?: boolean };

const Form = forwardRef<HTMLFormElement, FormProps>(({ className, asChild, ...props }, ref) => {
    const Comp = asChild ? Slot : 'form';
    return <Comp {...props} ref={ref} className={classNames(styles.wrapper, className)} />;
});

Form.displayName = 'Form';

export { Form };
