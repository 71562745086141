import React from 'react';
import { withRouter } from 'react-router-dom';
import { Container, Content, FlexboxGrid } from 'rsuite';
import IconQuery from '../../../assets/icons/icon_query.svg';

const SimulacoesAnalises = ({ history }) => (
    <Container className="main-container simulacoes-analises-page">
        <FlexboxGrid>
            <FlexboxGrid.Item colspan={24}>
                <Content className="no-margin-bottom page-header">
                    <h2>Simulações e Análises</h2>
                </Content>
            </FlexboxGrid.Item>
        </FlexboxGrid>

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
                <Content className="item-card" onClick={() => history.push('/ipa/simulacoes-analises/previsao-demanda-v2')}>
                    <div>
                        <div>
                            <img alt="ícone de previsão" src={IconQuery} />
                        </div>
                        <div>
                            <div>
                                <div>
                                    <h3>Previsão de Demanda</h3>
                                </div>
                                <div>
                                    <p>Projete vendas para várias lojas e períodos, testando diferentes estratégias de preços.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Content>
            </div>
        </div>
    </Container>
);

export default withRouter(SimulacoesAnalises);
