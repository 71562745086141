import { useDispatch, useSelector } from 'react-redux';

import { optimizationPricesListSelector } from '../../../../reducers/OtimizacaoPrecos';
import { setPagination } from '../../../../reducers/OtimizacaoPrecos/optimizationPricesList';

export function useGridTableActions() {
    const optimizationPricesList = useSelector(optimizationPricesListSelector);

    const { data, pagination } = optimizationPricesList;
    const { sortColumn, orderBy } = pagination;
    const dispatch = useDispatch();

    const handleSetSortColumn = async (column: string, type: 'asc' | 'desc') => {
        dispatch(setPagination({ pagination: { ...pagination, sortColumn: column, orderBy: type } }));
    };

    const handleChangePage = (value: number) => {
        dispatch(setPagination({ pagination: { ...pagination, number: value - 1 } }));
    };

    const handleChangeSize = (value: number) => {
        dispatch(setPagination({ pagination: { ...pagination, size: value, number: 0 } }));
    };

    return {
        tableHandlers: {
            handleSetSortColumn,
            handleChangePage,
            handleChangeSize,
        },
        tableState: {
            sortColumn,
            orderBy,
            page: pagination.number,
            data,
            pagination,
        },
    };
}
