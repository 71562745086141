import { useState } from 'react';
import { getPriceComposition } from '../../services';
import { getDataModalComposition } from './getDataModalComposition';

export type PriceCompositionProps = {
    priceType: 'Preço vigente' | 'Novo preço' | 'PMZ';
    price: number;
    liquid_cost: {
        value: number;
        percentage: number;
    };
    profit: {
        value: number;
        percentage: number;
    };
    expenses: {
        value: number;
        percentage: number;
    };
    tax: {
        value: number;
        percentage: number;
    };
};

export const usePriceCompositionModal = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [data, setData] = useState<PriceCompositionProps[] | null>(null);

    function handleOpen(productId: string, storeId: string, clusterId?: string) {
        getPriceComposition({ productId, storeId, clusterId })
            .then((response) => {
                if (response) {
                    setData(getDataModalComposition(response));
                }
            })
            .finally(() => setIsOpen(true));
    }

    return {
        data,
        isOpen,
        setIsOpen,
        handleOpen,
    };
};
