import React, { useState } from 'react';
import { TabelaBaseFormBox } from '../../../../components/TabelaBaseFormBox';
import { LabelInputGroup } from '../../../../components/LabelInputGroup';
import { DespesaVendaDataType } from '../../types';
import { totalOutrasDespesas } from '../../../../calculos';
import styles from './OutrasDespesasBox.module.scss';

type OutrasDespesasBoxProps = {
    outrasDespesasData: DespesaVendaDataType,
    disabled?: boolean,
    onChange?: (name: string, value: number) => void,
}

export const OutrasDespesasBox = ({ outrasDespesasData, disabled, onChange }: OutrasDespesasBoxProps) => {
    const [ outrasDespesas, setOutrasDespesas ] = useState(outrasDespesasData)

    const handleOutrasDespesasChange = (name: string, value: number) => {
        setOutrasDespesas({...outrasDespesas, [name]: value})
        onChange(name, value)
    }
    return (
        <TabelaBaseFormBox
            title="Outras despesas"
            totalValue={totalOutrasDespesas(outrasDespesas)}
        >
            <LabelInputGroup
                label="Departamento"
                subLabel="comercial"
                symbol="%"
                decimalSeparator=","
                disabled={disabled}
                value={outrasDespesas?.comerciais}
                onChangeEvent={(_e: any, _s: string, number: number) => handleOutrasDespesasChange('comerciais', number)}
            />
            <LabelInputGroup
                label="Despesas"
                subLabel="administrativas"
                symbol="%"
                decimalSeparator=","
                disabled={disabled}
                value={outrasDespesas?.administrativas}
                onChangeEvent={(_e: any, _s: string, number: number) => handleOutrasDespesasChange('administrativas', number)}
            />
            <LabelInputGroup
                label="Outras"
                symbol="%"
                decimalSeparator=","
                disabled={disabled}
                value={outrasDespesas?.outras}
                onChangeEvent={(_e: any, _s: string, number: number) => handleOutrasDespesasChange('outras', number)}
            />
        </TabelaBaseFormBox>
    );
};
