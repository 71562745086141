import * as React from 'react';

interface BoxplotIconProps extends React.SVGAttributes<SVGElement> {
    defaultColor: string;
}
export const BoxplotIcon = ({ defaultColor, ...props }: BoxplotIconProps) => {
    return (
        <svg width={12} height={12} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path fill={defaultColor || props.fill} d="M3.88232 4.5H8.117609999999999V7.5H3.88232z" />
            <path d="M8.118 6H10M3.882 6H2M10 5v2M2 7V5" stroke={defaultColor || props.stroke} />
        </svg>
    );
};
