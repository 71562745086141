import { createSlice } from '@reduxjs/toolkit';
import { snakeCaseToCamelCase } from '../../utils/snakeCaseToCamelCase';

export type BaseFields = {
    price: number;
    margin: number;
    competitiveness: number;
    demandForecast: number;
    revenueForecast: number;
    profitForecast: number;
    marginLevel1: number;
    marginLevel2: number;
};

type PriceSimulations = {
    query: Query;
    output: OutputProps;
};

export type RevenueDemandChartData = {
    price: number;
    revenue: number;
    demand: number;
    inputPrice: boolean;
};

export type ProfitRevenueChartData = {
    price: number;
    revenue: number;
    profit: number;
    optimumRevenuePrice: boolean;
    optimumBalancedPrice: boolean;
    optimumProfitPrice: boolean;
};

export type DailyStoreSales = {
    title: string;
    subtitle: string;
    data: {
        dates: string[];
        demands: number[];
        storeId: string;
        store: string;
    }[];
};

export type SimulationProps = {
    newPrice: string;
    priceSimulations: PriceSimulations[];
};

export type OutputProps = {
    elasticity: number | null;
    basePriceScenario: BaseFields;
    newPriceScenario: BaseFields;
    scenariosVariation: BaseFields;
    metadata?: OutputMetadata;
};

type OutputMetadata = {
    productName: string;
    productId: string;
    scenarioId: string;
    simulationFamilyId?: string;
};

export type Query = {
    productId: string;
    productName: string;
    storeIds: string[];
    fromDate: string;
    toDate: string;
    newPrice: number;
    client: string;
    basePrice: number;
};

export interface Scenario {
    id: string | null;
    scenarioName: string | null;
    user: string | null;
    username: string | null;
    client: string | null;
    date: string | null;
}

export type SimulationSelectedTableProps = {
    query: Query;
    output: OutputProps[];
};

export type OutputAreaProps = {
    simulation: SimulationProps[];
    output: OutputProps[];
    revenueDemandChartData: RevenueDemandChartData[];
    profitRevenueChartData: ProfitRevenueChartData[];
    cpiChartData: any[]; // TODO - difinir contrato com ze - grafico eslasticidade cruzada
    dailyStoreSalesChartData: DailyStoreSales;
    query: Query;
    scenario: Scenario;
    bestScenario: BaseFields | null;
    calculations: {
        hasElasticity: boolean;
        hasSimulation: boolean;
        isSearchDone: boolean;
        isMultipleScenario: boolean;
    };
};

const initialState: OutputAreaProps = {
    simulation: [],
    output: [],
    revenueDemandChartData: [],
    profitRevenueChartData: [],
    cpiChartData: [],
    dailyStoreSalesChartData: {
        title: '',
        subtitle: '',
        data: [],
    },
    query: {
        productId: '',
        productName: '',
        storeIds: [],
        fromDate: '',
        toDate: '',
        newPrice: 0,
        basePrice: 0,
        client: '',
    },
    bestScenario: null,
    scenario: {
        id: null,
        scenarioName: null,
        user: null,
        username: null,
        client: null,
        date: null,
    },
    calculations: {
        hasElasticity: false,
        hasSimulation: false,
        isSearchDone: false,
        isMultipleScenario: false,
    },
};

type ignoreOutputsOnSetState =
    | 'calculations'
    | 'simulationSelectedTable'
    | 'bestScenario'
    | 'revenueDemandChartData'
    | 'profitRevenueChartData'
    | 'cpiChartData'
    | 'dailyStoreSalesChartData';

const calculateElasticity = (value: number) => {
    const absValue = Math.abs(Number(value));

    return Math.min(Math.max(absValue, 0), 4);
};

const mapBestValue = {
    price: 'bigger',
    competitiveness: 'minor',
    margin: 'bigger',
    marginLevel1: 'bigger',
    marginLevel2: 'bigger',
    demandForecast: 'bigger',
    revenueForecast: 'bigger',
    profitForecast: 'bigger',
};

const calculateBestValue = (output: OutputProps[]) => {
    const betterScenario: Record<keyof BaseFields, number> = {
        price: 0,
        competitiveness: 0,
        margin: 0,
        marginLevel1: 0,
        marginLevel2: 0,
        demandForecast: 0,
        revenueForecast: 0,
        profitForecast: 0,
    };

    output.forEach((out, index) => {
        if (index === output.length - 1) return;
        /*
            Na linha debaixo eu estou considerando newPriceScenario para comparar
            caso não seja este então no componente "Scenarios" na função "isBestScenario"
            mudar para o mesmo que for usar aqui no lugar do "newPriceScenario"
         */
        const newPriceScenarioEntries = Object.entries(out.newPriceScenario);
        const newPriceScenarioNext = Object.entries(output[index + 1]?.newPriceScenario);

        newPriceScenarioEntries.forEach((item, i) => {
            const mapBestValueKey = mapBestValue[item[0] as keyof typeof mapBestValue];

            const key = item[0] as keyof BaseFields;

            if (index === 0) {
                betterScenario[key] = item[1];
            }

            if (mapBestValueKey === 'bigger') {
                if (newPriceScenarioNext[i][1] >= betterScenario[key]) {
                    betterScenario[key] = newPriceScenarioNext[i][1];
                }
            } else if (mapBestValueKey === 'minor') {
                if (newPriceScenarioNext[i][1] < betterScenario[key]) {
                    betterScenario[key] = newPriceScenarioNext[i][1];
                }
            }
        });
    });

    return betterScenario;
};

const slice = createSlice({
    name: 'demand-forecast-output-area',
    initialState,
    reducers: {
        setOutputs(
            state,
            {
                payload,
            }: {
                payload: Omit<OutputAreaProps, ignoreOutputsOnSetState>;
            },
        ) {
            const isMultipleScenario = payload.output.length > 1;

            state.simulation = snakeCaseToCamelCase<SimulationProps[]>(payload.simulation);

            const outputWithPossibleElasticity = payload.output.find((output) => output.elasticity !== null);

            const output = snakeCaseToCamelCase<OutputProps[]>(payload.output);

            if (isMultipleScenario) {
                state.bestScenario = calculateBestValue(output);
            }

            state.output = output.map((output) => {
                const elasticityAbs = outputWithPossibleElasticity?.elasticity ? calculateElasticity(outputWithPossibleElasticity.elasticity) : null;

                return {
                    ...snakeCaseToCamelCase<OutputProps>(output),
                    elasticity: elasticityAbs,
                };
            });

            state.scenario = snakeCaseToCamelCase<Scenario>(payload.scenario);

            state.query = snakeCaseToCamelCase<Query>(payload.query);

            state.calculations = {
                hasElasticity: !!outputWithPossibleElasticity,
                hasSimulation: payload.simulation.length > 0,
                isSearchDone: true,
                isMultipleScenario: isMultipleScenario,
            };
        },
        setSimulationSelectedTable(state, { payload }: { payload: { query: Query; output: OutputProps[] } }) {
            state.calculations.isMultipleScenario = false;

            const simulation = state.simulation.find((simulation) => simulation.newPrice === payload.output[0].newPriceScenario.price.toString());

            if (!simulation) return;

            state.output = payload.output;
            state.simulation = [simulation];
        },
        setRevenueDemandChartData(state, { payload }: { payload: RevenueDemandChartData[] }) {
            state.revenueDemandChartData = snakeCaseToCamelCase(payload);
        },
        setProfitRevenueChartData(state, { payload }: { payload: ProfitRevenueChartData[] }) {
            state.profitRevenueChartData = snakeCaseToCamelCase(payload);
        },
        setDailyStoreSalesChartData(state, { payload }: { payload: DailyStoreSales }) {
            state.dailyStoreSalesChartData = snakeCaseToCamelCase(payload);
        },
        clearOutputData: () => initialState,
    },
});

export const { setOutputs, clearOutputData, setSimulationSelectedTable, setRevenueDemandChartData, setProfitRevenueChartData, setDailyStoreSalesChartData } =
    slice.actions;

export default slice.reducer;
