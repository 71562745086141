import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isAfter } from 'date-fns';
import { Store } from './optimizationPricesList';

export enum PeriodStatus {
    'weekly' = 'Semanal',
    'monthly' = 'Mensal',
}
interface Category {
    name: string;
    level: number | string;
    value: string;
}

interface Period {
    value: keyof typeof PeriodStatus;
    label: string;
}

interface Result {
    category: Category;
    stores: Store[];
    selectedStoresId: string[];
    totalProducts: number;
    period: Period;
    lastOptimization: string;
}

interface Validity {
    start: string;
    end?: string;
}

export interface BigNumbers {
    margin: {
        optimizedValue: number;
        value: number;
        differencePercentage: number;
    };
    competitiveness: {
        optimizedValue: number;
        value: number;
        differencePercentage: number;
    };
    salesVolume: {
        optimizedValue: number;
        value: number;
        differencePercentage: number;
    };
    revenue: {
        optimizedValue: number;
        value: number;
        differencePercentage: number;
    };
    profitForecast: {
        optimizedValue: number;
        value: number;
        differencePercentage: number;
    };
    startDate: string;
    endDate: string;
}

export type ChartForecast = {
    [key in 'base' | 'optimized']: {
        max: number;
        min: number;
        median: number;
        q1: number;
        q3: number;
    };
};

interface ForecastTab {
    bigNumbers: BigNumbers;
    chart: ChartForecast;
}

export interface OptimizationPricesResultProps {
    header: {
        status: {
            label: string;
            active: boolean;
        };
        validity: Validity;
    };
    result: Result;
    tabs: {
        forecast: ForecastTab;
        analysis: any[];
        history: any[];
    };
}

const initialState: OptimizationPricesResultProps = {
    header: {
        status: {
            label: '',
            active: false,
        },
        validity: {
            start: '',
        },
    },
    result: {
        category: {
            name: '',
            level: '',
            value: '',
        },
        stores: [],
        selectedStoresId: [],
        totalProducts: 0,
        period: {
            value: 'weekly',
            label: 'Semanal',
        },
        lastOptimization: '',
    },
    tabs: {
        forecast: {
            bigNumbers: {
                margin: {
                    optimizedValue: 0,
                    value: 0,
                    differencePercentage: 0,
                },
                competitiveness: {
                    optimizedValue: 0,
                    value: 0,
                    differencePercentage: 0,
                },
                salesVolume: {
                    optimizedValue: 0,
                    value: 0,
                    differencePercentage: 0,
                },
                revenue: {
                    optimizedValue: 0,
                    value: 0,
                    differencePercentage: 0,
                },
                profitForecast: {
                    optimizedValue: 0,
                    value: 0,
                    differencePercentage: 0,
                },
                startDate: '',
                endDate: '',
            },
            chart: {
                base: {
                    max: 0,
                    min: 0,
                    median: 0,
                    q1: 0,
                    q3: 0,
                },
                optimized: {
                    max: 0,
                    min: 0,
                    median: 0,
                    q1: 0,
                    q3: 0,
                },
            },
        },
        analysis: [],
        history: [],
    },
};

function handleStatus(validity: Validity) {
    if (validity.end && isAfter(new Date(validity.end), new Date())) {
        return {
            label: `Expirada no dia ${validity.end}`,
            active: false,
        };
    }

    if (validity.end) {
        return {
            label: `${validity.start} até ${validity.end}`,
            active: true,
        };
    }

    return {
        label: `Ativa por tempo indeterminado`,
        active: true,
    };
}

const slice = createSlice({
    name: 'optimization-prices-result',
    initialState,
    reducers: {
        setOptimizationResult(state, { payload }: PayloadAction<{ result: Result; validity: Validity }>) {
            state.result = payload.result;
            state.header.validity = payload.validity;
            state.header.status = handleStatus(payload.validity);
        },
        setOptimizationResultBigNumbers(state, { payload }: PayloadAction<{ data: BigNumbers }>) {
            state.tabs.forecast.bigNumbers = payload.data;
        },
        setSelectedStores(state, { payload }: PayloadAction<{ id: string[] }>) {
            state.result.selectedStoresId = payload.id;
        },
        setOptimizationResultChart(state, { payload }: PayloadAction<{ data: ChartForecast }>) {
            state.tabs.forecast.chart = payload.data;
        },
    },
});

export const { setOptimizationResult, setOptimizationResultBigNumbers, setSelectedStores, setOptimizationResultChart } = slice.actions;

export default slice.reducer;
