import React from 'react';
import { Checkbox } from 'rsuite';
import { LoadingMenu } from '../../../../../../../../../components/LoadingSpinner';
import SelectPill from '../../../../../../../../../components/SelectPill';
import arrowRightIcon from '../../../../../../../../../assets/icons/icon_arrow-right-gray.svg';
import styles from './Local.module.scss';
import { useUfFilter } from '../../../../hooks/useUfFilter';
import { useCreateComercialTableStore } from '../../../../CreateComercialTable.store';

const locale = {
    noResultsText: 'Nenhum item encontrado',
    searchPlaceholder: 'Buscar item',
};

const localData = [
    {
        label: 'Nacional',
        value: 'NACIONAL',
    },
    {
        label: 'Regional',
        value: 'REGIONAL',
    },
]

export const Local = () => {
    const { ufData } = useUfFilter()
    const { createComercialTableBody, setCreateComercialTableState } = useCreateComercialTableStore();

    const allUFsSelected = ufData?.length === createComercialTableBody.ufs?.length;

    const handleSelectLocal = (value: string) => {
        const local = {...createComercialTableBody, local: value}
        setCreateComercialTableState('createComercialTableBody', local)
    }

    const handleSelectUF = (value: string[]) => {
        const ufs = {...createComercialTableBody, ufs: value}
        setCreateComercialTableState('createComercialTableBody', ufs)
    }

    const handleCheckAllBaseProduct = (v) => {
        if (v) {
            setCreateComercialTableState('createComercialTableBody', { ...createComercialTableBody, ufs: [] })
        } else {
            const allUFs = ufData.map((item) => item.value)
            setCreateComercialTableState('createComercialTableBody', { ...createComercialTableBody, ufs: allUFs })
        }
    }

    return (
        <div className={styles['select-pill-wrapper']}>
            <SelectPill
                type="select"
                id="select-picker-pill"
                placeholder="Selecione uma opção"
                name="second"
                searchable={false}
                value={createComercialTableBody.local}
                data={localData}
                onSelect={handleSelectLocal}
                locale={locale}
                renderMenu={(menu) => (
                    <span>
                        <LoadingMenu area="panel-filter" />
                        {menu}
                    </span>
                )}
            />
            {createComercialTableBody.local === 'REGIONAL' ? (
                <>
                    <img
                        src={arrowRightIcon}
                        alt=""
                        className={styles['arrow-right-icon']}
                    />
                    <SelectPill
                        type="check"
                        id="check-picker-pill"
                        placeholder={createComercialTableBody.ufs.length ? 'UFs' : "Selecione as UFs"}
                        name="second"
                        searchable={false}
                        value={createComercialTableBody.ufs}
                        data={ufData}
                        onSelect={handleSelectUF}
                        locale={locale}
                        renderMenu={(menu) => (
                            <span>
                                <LoadingMenu area="panel-filter" />
                                {menu}
                            </span>
                        )}
                        renderExtraFooter={() => (
                            <Checkbox
                                inline
                                className={styles['check-all-ufs']}
                                value={allUFsSelected}
                                checked={allUFsSelected}
                                onChange={handleCheckAllBaseProduct}
                            >
                                Selecionar tudo
                                {' '}
                                <span>
                                        {createComercialTableBody.ufs?.length
                                            ? `(${createComercialTableBody.ufs?.length})`
                                            : null}
                                    </span>
                            </Checkbox>
                        )}
                    />
                </>
            ) : null}
        </div>
    );
};
